import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import qs from "query-string";
import _ from "lodash";

import ROUTES from "../../routes";
import {
  BoxSeparator,
  Confirm,
  ContentBox,

  ForbiddenSection,
  OperationsTable,
  Page,
  TitleBox,
} from "../../components";
import { user as userActions } from "../../actions";
import { Language } from "../../utils";

import { Add as AddIcon } from "@material-ui/icons";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import makeClasses from "./styles";
import {
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Policies } from "../../utils/Policies";

const ManagePosRequestsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [handyTaps, setHandyTaps] = useState({});
  const [paginationPage, setPaginationPage] = useState(
    searchParams.page ? parseInt(searchParams.page) : 1
  );
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
  const { policies, authTime, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    authTime: state.user.userData.authTime || Date.now(),
    languageTexts: state.language.texts || {},
  }));

  const i18n = Language(languageTexts);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!policies || !policies.includes(Policies.types.PROFILE)) {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (!forbiddenSection) {
      loadData();
    }
  }, [forbiddenSection, paginationPage]);

  const loadData = () => {
    dispatch(
      userActions.getHanyTaps(paginationPage, (data) => {
        if (data) {
          setHandyTaps(data);
        }
      })
    );
  };

  const _handleOnClickNewPaymentMethod = () => {
    const exiredSession =
      Math.floor((new Date() - new Date(authTime * 1000)) / (1000 * 60)) >= 15;

    if (exiredSession) {
      return _toggleSessionExpiredModal();
    }

    dispatch(push(ROUTES.MANAGE_HANDY_TAP_ADD.path));
  };

  const handleOnPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _toggleSessionExpiredModal = () => {
    setShowSessionExpiredModal(!showSessionExpiredModal);
  };

  const _logOut = () => {
    dispatch(userActions.logOut());
  };

  const renderPosRequestsHistory = () => {
    const headers = _.compact([
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get("ManageHandyTap.Grid.Column1"),
        sortable: false,
        type: "date",
        showTime: false
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get("ManageHandyTap.Grid.Column2"),
        sortable: false,
        type: "status",
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get("ManageHandyTap.Grid.Column3"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get("ManageHandyTap.Grid.Column4"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get("ManageHandyTap.Grid.Column5"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 6,
        name: i18n.get("ManageHandyTap.Grid.Column6"),
        sortable: false,
        align: "right",
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 7,
        name: i18n.get("ManageHandyTap.Grid.Column7"),
        sortable: false,
        align: "right",
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 8,
        name: i18n.get("ManageHandyTap.Grid.Column8"),
        sortable: false,
        align: "center",
        type: "check",
      },
      {
        canHide: false,
        columnNumber: 9,
        name: i18n.get("ManageHandyTap.Grid.Column9"),
        sortable: false,
        align: "center",
        type: "check",
      },
      {
        canHide: false,
        columnNumber: 10,
        name: i18n.get("ManageHandyTap.Grid.Column10"),
        sortable: false,
        align: "center",
        type: "check",
      }
    ]);

    const body = (handyTaps.elements || []).map((item) => ({
      rowId: item.traceId,
      columns: _.compact([
        {
          columnNumber: 1,
          data: item.createdAt,
        },
        {
          columnNumber: 2,
          data: item.state
        },
        {
          columnNumber: 3,
          data: item.deviceIdentifier,
        },
        {
          columnNumber: 4,
          data: item.name,
        },
        {
          columnNumber: 5,
          data: item.branchName,
        },
        {
          columnNumber: 6,
          data: item.installments,
        },
        {
          columnNumber: 7,
          data: item.vat
        },
        {
          columnNumber: 8,
          data: item.acceptUYU
        },
        {
          columnNumber: 9,
          data: item.acceptUSD
        },
        {
          columnNumber: 10,
          data: item.hasTip
        }
      ]),
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={handyTaps.hasNext}
        onPaginationChange={handleOnPaginationChange}
        emptyMessage={i18n.get("ManagePayments.GridEmptyMessage")}
        showDetails={false}
        showDates={false}
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get("ManageHandyTap.Title")}
        withActivePage={ROUTES.MANAGE_HANDY_TAP.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get("ManageHandyTap.Title")}
      withActivePage={ROUTES.MANAGE_HANDY_TAP.id}
    >
      <TitleBox
        title={i18n.get("ManageHandyTap.BoxTitle")}
        buttonsRight={_.compact([
          {
            text: isSmallScreen
              ? null
              : i18n.get("ManageHandyTap.BoxTitleButtonText"),
            icon: () => <AddIcon fontSize="small" />,
            onClick: _handleOnClickNewPaymentMethod,
          },
        ])}
      />
      <BoxSeparator />
      <ContentBox
        title={i18n.get("ManageHandyTap.BoxUsersTitle")}
        titleBold
        headerVertical={isSmallScreen}
      >
        {renderPosRequestsHistory()}
      </ContentBox>
      <Confirm
        open={showSessionExpiredModal}
        onClose={_toggleSessionExpiredModal}
        title={i18n.get("ManageHandyTap.ExpiredSessionDialogTitle")}
        onConfirm={_logOut}
        confirmText={i18n.get(
          "ManageHandyTap.ExpiredSessionDialogActionConfirm"
        )}
        cancelText={i18n.get(
          "ManageHandyTap.ExpiredSessionDialogActionCancel"
        )}
        onCancel={_toggleSessionExpiredModal}
      >
        <Alert
          severity="info"
          icon={false}
          className={clsx(classes.alert, classes.alertInfo)}
        >
          <div>
            <InfoOutlinedIcon className={classes.alertIcon} />
          </div>
          <div className={classes.alertMessage}>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get("ManageHandyTap.ExpiredSessionDialogContentTitle")}
            </AlertTitle>
            {i18n.get("ManageHandyTap.ExpiredSessionDialogContentMessage")}
          </div>
        </Alert>
      </Confirm>
    </Page>
  );
};

ManagePosRequestsScreen.id = "com.Handy.ManagePosRequests";

export default ManagePosRequestsScreen;
