import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  contentWrapper: {
    width: '60%',
    margin: '0 auto',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  sectionTitle: {
    marginTop: 20,
    marginBottom: 10,
  },
  //
  // List
  listWrapper: {
    marginTop: 20,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
  },
  listItemEmpty: {
    flex: 1,
    textAlign: 'center',
  },
  listItemImageWrapper: {
    width: 60,
    height: 60,
    boxSizing: 'border-box',
    background: Colors.rgb.contentBackgroundPrimary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    border: `1px solid ${Colors.rgb.contentLineSoft}`,

    '& > span': {
      fontSize: theme.fontSize.L,
      fontWeight: 'bold',
      color: Colors.rgb.contentText,
    },
  },
  listItemImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    color: Colors.rgb.transparent,
    fontSize: '0.6em',
  },
  listItemContent: {
    flexGrow: 1,
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 60px - 50px - 40px)',
    },
  },
  listItemContentName: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentTextHighlighted,
    fontSize: theme.fontSize.L,
    marginTop: `-${theme.spacing(4)}`,

    '& > div:nth-child(1)': {
      verflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      fontSize: theme.fontSize.M,
      color: Colors.rgb.contentText,
    },
  },
  listItemContentNameSeparator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: `-${theme.spacing(1)}`,
  },
  listItemStatus: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: 5,
    fontSize: theme.fontSize.S,
  },
  listItemStatus_OK: {
    background: Colors.rgb.alertSuccessBackground,
    border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
    color: Colors.rgb.alertSuccessText,
  },
  listItemStatus_EXPIRED: {
    background: Colors.rgb.alertWarningBackground,
    border: `1px solid ${Colors.rgb.alertWarningBorder}`,
    color: Colors.rgb.alertWarningText,
  },
  listItemStatus_USED: {
    background: Colors.rgb.alertInfoBackground,
    border: `1px solid ${Colors.rgb.alertInfoBorder}`,
    color: Colors.rgb.alertInfoText,
  },
  listItemStatus_BLOCKED: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: Colors.rgb.alertDangerText,
  },
  listItemContentTotal: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentTextSoft,
    fontSize: theme.fontSize.M,
    marginTop: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',

      '& > span:nth-child(2)': {
        display: 'none',
      },
    },
  },
  listItemActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > button': {
      width: 40,
      padding: 6
    },

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      top: 20,
    },
  },
  listItemExpired: {
    fontFamily: theme.fontFamily.bold,
    color: Colors.rgb.red,
    fontSize: theme.fontSize.L,
  },
  gridItemFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  gridItemFlexJustifyFlexStart: {
    justifyContent: 'flex-start',
  },
  gridItemFlexJustifyFlexEnd: {
    justifyContent: 'flex-end',
  },
  addButton: {
    textTransform: 'none',
  },
  infoTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    fontSize: '1.2rem',
    fontWeight: '500',
  },
  infoItem: {
    width: '100%',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
  },
  infoItemDisabled: {
    opacity: 0.6,
  },
  infoItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  infoItemImage: {
    width: 110,
    marginBottom: theme.spacing(4),
  },
  infoItemTitle: {
    fontSize: theme.fontSize.L,
  },
  infoItemText: {
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentTextSoft,
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  legendItem: {
    padding: theme.spacing(2),
  },
  legendItemWithBackgroundText: {
    marginTop: theme.spacing(1),
    fontFamily: theme.fontFamily.regular,
    textAlign: 'left',
    fontSize: theme.fontSize.M,
    color: Colors.rgb.contentTextSoft,

    '& a': {
      color: Colors.rgb.contentTextSoft,
    },
  },

  listItemMenu: {
    padding: theme.spacing(1),
  },
  listItemMenuItem: {
    color: Colors.rgb.contentText,
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.S,
  },
  searchWrapper: {
    marginTop: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pagination: {
    marginTop: 20,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
  },
  paginationList: {
    '& li > button': {
      background: Colors.rgb.white,
    },
  },
}));

export default makeClasses;
