import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { useSelector } from 'react-redux';

import { Language } from '../../utils';
import { Page, Confirm } from '../../components';

import makeClasses from './styles';
import clsx from 'clsx';

import appLogo from '../../static/images/logo.svg';
import HelpIcon from '../../static/images/icons/help.svg';

import Disclaimer from './steps/Disclaimer';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import StepThree from './steps/StepThree';
import StepFour from './steps/StepFour';
import StepFive from './steps/StepFive';
import StepSix from './steps/StepSix';
import StepSeven from './steps/StepSeven';

const LegalEntityRegistrationScreen = ({ history }) => {
  const classes = makeClasses();
  const [showHelp, setShowHelp] = useState(false);

  const { languageTexts } = useSelector((state) => ({
    languageTexts: state.language.texts || {},
  }));

  const i18n = Language(languageTexts);

  const searchParams = qs.parse(history.location.search);
 
  const [affiliationCode, setAffiliationCode] = useState( qs.parse(history?.location?.search)?.code ?? null );
  const [formData, setFormData] = useState({ data: searchParams.data || '' });

  const [registerStep, setRegisterStep] = useState(searchParams.step ? parseInt(searchParams.step) : 1);

  useEffect(() => {
    if (searchParams.result) {
      if (searchParams.result === 'success' || searchParams.result === 'error') {
        setRegisterStep(8);
      } else {
        setRegisterStep(1);
      }
    }
  }, [searchParams.result]);

  useEffect(() => {
    if (searchParams.step) {
      setRegisterStep(parseInt(searchParams.step));
    }
    if (searchParams.data) {
      setFormData(searchParams);
    }
  }, [searchParams.step, searchParams.data]);

  const _toggleShowHelp = () => {
    setShowHelp(!showHelp);
  };

  const nextStep = (data) => {
    const nextStep = registerStep + 1;
    updateUrlWithStep(nextStep, data?.data);
    setRegisterStep(nextStep);
  };

  const prevStep = (data) => {
    const prevStep = registerStep - 1;
    updateUrlWithStep(prevStep, data.data);
    setRegisterStep(prevStep);
  };

    const renderStep = () => {
        switch (registerStep) {
            case 1:
                return <Disclaimer history={history} nextStep={nextStep}  />;
            case 2:
                return <StepOne history={history} nextStep={nextStep} code={affiliationCode} />;
            case 3:
                return <StepTwo history={history} nextStep={nextStep} prevStep={prevStep}  stepOneData={formData.data} />;
            case 4:
                return <StepThree history={history} nextStep={nextStep} prevStep={prevStep}  stepOneData={formData.data} />;
            case 5:
                return <StepFour history={history} nextStep={nextStep} prevStep={prevStep}  stepOneData={formData.data} />;
            case 6:
                return <StepFive history={history} nextStep={nextStep} prevStep={prevStep}  stepOneData={formData.data} />;
            case 7:
                return <StepSix history={history} nextStep={nextStep} prevStep={prevStep}  stepOneData={formData.data} />;
            case 8:
                return <StepSeven history={history} nextStep={nextStep} prevStep={prevStep}  stepOneData={formData.data} />;
            default:
                return null;
        }
    };
  const updateUrlWithStep = (step, data) => {
    history.push({
      search: qs.stringify({ step, code: affiliationCode, data }),
    });
  };
 
  return (
    <Page
      withHeader={false}
      withSideMenu={false}
      withBanner={false}
      withBoxBanner={false}
      showBranchWithNoPaymentMethodWarning={false}
    >
      <div className={classes.container}>
        <div className={clsx(classes.contentContainer, registerStep === 7 ? classes.contentContainerWithIFrame : null)}>
          <div className={clsx(classes.contentWrapper, registerStep === 7 ? classes.contentWrapperWithIFrame : null)}>
            <div className={classes.brandContainer}>
              <img src={appLogo} className={classes.brandLogo} alt="Handy Logo" />
              <div className={classes.brandSeparator} />
              <div className={classes.brandTitle}>{i18n.get('LegalEntityRegistration.PageTitle')}</div>
            </div>
          </div>
          {renderStep()}
        </div>
        <div className={classes.helpContainer} onClick={_toggleShowHelp}>
          {i18n.get('LegalEntityRegistration.UserHelpTitle')}
          <img src={HelpIcon} className={classes.helpIcon} alt="ayuda" />
        </div>
      </div>
      <Confirm
        open={showHelp}
        onClose={_toggleShowHelp}
        title={i18n.get('LegalEntityRegistration.UserHelpDialogTitle')}
        text={i18n.get('LegalEntityRegistration.UserHelpDialogText')}
        confirmText={i18n.get('LegalEntityRegistration.UserHelpDialogButton')}
        onConfirm={_toggleShowHelp}
      />
    </Page>
  );
};
LegalEntityRegistrationScreen.id = 'com.Handy.LegalEntityRegistration';
export default LegalEntityRegistrationScreen;
