import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../utils';

const makeClasses = makeStyles(theme => ({
  productFormWrapper: {
    paddingBottom: theme.spacing(4),
    borderBottom: `1px solid ${Colors.rgb.contentLineSoft}`,
  },
  productForm: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  productFormInput: {
    width: '100%',

    '& > label': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentTextSoft,
      fontSize: theme.fontSize.L,
    },

    '& > div:before': {
      borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,
    },
    '& > div:after': {
      borderBottom: `1px solid ${Colors.rgb.primary}`,
    },
    '& > div:hover': {
      '&:before': {
        borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted} !important`,
      },
    },

    '& input': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.L,
    },
  },
  productFormInputEndAdornment: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentTextSoft,
    fontSize: theme.fontSize.S,
  },
  productFormInputSeparator: {
    height: theme.spacing(4),
  },
  productFormSelectLabel: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentTextSoft,
    fontSize: theme.fontSize.L,
  },
  productFormSelect: {
    width: '100%',

    '&:before': {
      borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,
    },
    '&:after': {
      borderBottom: `1px solid ${Colors.rgb.primary}`,
    },
    '&:hover': {
      '&:before': {
        borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted} !important`,
      },
    },

    '& > div': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.L,
    },
  },
  productFormSelectOption: {
    color: Colors.rgb.contentText,
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.regular,
  },
  productImagePreview: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    cursor: 'pointer',
  },
  productImage: {
    width: 148,
    height: 148,
    objectFit: 'cover',
    borderRadius: 5,
    border: `2px solid ${Colors.rgb.contentLineHighlighted}`,
    boxSizing: 'border-box',
  },
  productImagePlaceholder: {
    width: 148,
    height: 148,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px dashed ${Colors.rgb.primary}`,
    borderRadius: 5,
  },
  contentActionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing(4),

    '& > button:last-child': {
      marginRight: 0,
    },
  },
  contentActionSeparator: {
    width: theme.spacing(3),
  },
  contentMoreDetails: {
    padding: 0,
    border: 'none',
    borderRadius: '0 !important',
    boxShadow: 'none',

    '&:before': {
      height: 0,
    },
  },
  moreDetailsAction: {
    height: `31px !important`,
    minHeight: `31px !important`,
    color: Colors.rgb.contentTextHighlighted,
    fontSize: theme.fontSize.XL,
    fontFamily: theme.fontFamily.bold,
    borderBottom: `1px solid ${Colors.rgb.contentLine}`,
    paddingBottom: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,

    '& > div': {
      margin: `0 !important`,
    },
  },
  moreDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  moreDetailsSection: {
    position: 'relative',
    marginBottom: theme.spacing(4),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  moreDetailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  moreDetailsItem: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  moreDetailsLinkEpiration: {
    fontWeight: 'bold',
    marginTop: 10,
  },
  moreDetailsLinkExpired: {
    fontWeight: 'bold',
    color: Colors.rgb.red,
  },
}));

export default makeClasses;
