import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles(theme => ({
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  buttonContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonsRightWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > div': {
      marginLeft: theme.spacing(2),

      '&:first-child': {
        marginLeft: 0,
      },

      '& img': {
        width: 17,
        margin: `0 ${theme.spacing(1)}`,
        filter: 'brightness(0) invert(1)',
      },
    },
  },
}));

export default makeClasses;
