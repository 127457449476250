import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Language } from '../../utils';
import Button from '../Button';
import Confirm from '../Confirm';
import ContentBox from '../ContentBox';
import makeClasses from './styles';
import { user as userActions } from '../../actions';
import { Policies } from '../../utils/Policies';
import { push } from 'connected-react-router';
import ROUTES from '../../routes';

const ForbbidenSection = () => {
  const dispatch = useDispatch();

  const [ logoutDialogOpen, setLogoutDialogOpen ] = useState(false);
  const { policies, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const classes = makeClasses();

  const toggleLogoutDialog = () => {
    setLogoutDialogOpen(!logoutDialogOpen);
  };

  const confirmLogout = () => {
    dispatch(userActions.logOut());
  };

  const goToHome = () => {
    if (policies.includes(Policies.types.HOME)) {
      dispatch(push(ROUTES.HOME.path));
    } else if (policies.includes(Policies.types.ACCOUNT)) {
      dispatch(push(ROUTES.ACCOUNT.path));
    } else if (policies.includes(Policies.types.ACTIVITY)) {
      dispatch(push(ROUTES.ACTIVITY.path));
    }
  };

  return (
    <ContentBox
      title={i18n.get('Components.ForbiddenSection.BoxTitle')}
      titleBold
      alert
      alertTitle={i18n.get('Components.ForbiddenSection.MessageTitle')}
      alertMessage={i18n.get('Components.ForbiddenSection.Message')}
      alertType="danger"
    >
      <div className={classes.contentActionsWrapper}>
        <Button variant="outlined" onClick={toggleLogoutDialog}>
          {i18n.get('Components.ForbiddenSection.ButtonCancel')}
        </Button>
        <div className={classes.contentActionSeparator} />
        <Button onClick={goToHome}>{i18n.get('Components.ForbiddenSection.ButtonHome')}</Button>
      </div>
      <Confirm
        open={logoutDialogOpen}
        onClose={toggleLogoutDialog}
        title={i18n.get('Components.AppHeader.DialogTitle')}
        text={i18n.get('Components.AppHeader.DialogContentText')}
        confirmText={i18n.get('Components.AppHeader.DialogActionConfirm')}
        onConfirm={confirmLogout}
        cancelText={i18n.get('Components.AppHeader.DialogActionCancel')}
        onCancel={toggleLogoutDialog}
      />
    </ContentBox>
  );
};

export default ForbbidenSection;
