import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Language } from '../../utils';
import makeClasses from './styles';

const FiltersContainer = ({ children, showLabel = true, vertical = false }) => {
  const classes = makeClasses();

  const { languageTexts } = useSelector(state => ({
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  return (
    <div className={clsx(classes.filtersWrapper, vertical ? classes.filtersWrapperVertical : null)}>
      {showLabel ? <div className={classes.label}>{i18n.get('Components.FiltersContainer.FilterByLabel')}</div> : null}
      <div className={classes.filters}>{children}</div>
    </div>
  );
};

export default FiltersContainer;
