import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { goBack, push } from "connected-react-router";
import { toast } from "react-toastify";

import ROUTES from "../../../../../routes";
import {
  BoxSeparator,
  ContentBox,
  Page,
  TitleBox,
  Button,
  ForbiddenSection,
  Box,
  EmptyMessage,
} from "../../../../../components";
import { business as businessActions } from "../../../../../actions";
import { Language } from "../../../../../utils";

import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import makeClasses from "./styles";
import { Policies } from "../../../../../utils/Policies";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { uniqueId } from "lodash";

const CURRENCY_FILTER_OPTIONS = [
  { id: "URUGUAYAN_PESO", name: "Currency.UYU" },
  { id: "DOLLAR", name: "Currency.USD" },
];

const VAT_FILTER_OPTIONS = [
  { id: 22, name: "22%" },
  { id: 10, name: "10%" },
  { id: 0, name: "0%" },
];

const FREQUENCE_FILTER_OPTIONS = [
  { id: "MONTHLY", name: "Frequency.MONTHLY", active: true },
  { id: "ANNUAL", name: "Frequency.ANNUAL", active: true },
  { id: "WEEKLY", name: "Frequency.WEEKLY", active: true },
  { id: "HOURLY", name: "Frequency.HOURLY", active: false },
  { id: "DAILY", name: "Frequency.DAILY", active: false }
];

const DURATION_FILTER_OPTIONS = [
  { id: 0, name: "0" },
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
  { id: 7, name: "7" },
  { id: 8, name: "8" },
  { id: 9, name: "9" },
  { id: 10, name: "10" },
  { id: 11, name: "11" },
  { id: 12, name: "12" },
];

const FIELD_TYPES_OPTIONS = [
  {
    id: "STRING",
    name: "ProvidersManageSubscribersRequestNewCharge.AdditionalFieldTypeOption1",
  },
  {
    id: "INTEGER",
    name: "ProvidersManageSubscribersRequestNewCharge.AdditionalFieldTypeOption2",
  },
];

const ProvidersSubscriptionsAddPlanScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const planTraceId =
    match.params && match.params.traceId ? match.params.traceId : null;
  const isEditMode = planTraceId !== null;

  const [inputName, setInputName] = useState(null);
  const [inputDescription, setInputDescription] = useState(null);
  const [inputBranch, setInputBranch] = useState(null);
  const [inputCurrency, setInputCurrency] = useState(null);
  const [inputAmount, setInputAmount] = useState(null);
  const [inputTaxableAmount, setInputTaxableAmount] = useState(null);
  const [inputVat, setInputVat] = useState(22);
  const [inputFrequency, setInputFrequency] = useState(null);
  const [inputCode, setInputCode] = useState(null);
  const [inputGroup, setInputGroup] = useState(null);
  const [inputDuration, setInputDuration] = useState(null);
  const [inputCallback, setInputCallback] = useState(false);
  const [inputCallbackUrl, setInputCallbackUrl] = useState(null);
  const [inputPaymentDate, setInputPaymentDate] = useState(null);
  const [additionalData, setAdditionalData] = useState([]);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [planTerms, setPlanTerms] = useState(null);
  const [branches, setBranches] = useState([]);

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const canSubmitForm = !isEditMode
    ? inputName &&
      inputName.length > 0 &&
      inputDescription &&
      inputDescription.length > 0 &&
      inputBranch &&
      inputCurrency &&
      [0, 10, 22].includes(inputVat) &&
      inputAmount > 0 &&
      inputFrequency
    : inputDescription && inputDescription.length > 0 && inputBranch;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (policies && policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_PLANS_WRITE)) {
      dispatch(businessActions.getProvidersSubscriptionsMerchant((dataBranches) => {
        if (isEditMode) {
          dispatch(
            businessActions.getProvidersSubscriptionsPlan(planTraceId, (data) => {
              if (data) {
                setInputName(data.name);
                setInputDescription(data.description);
                setInputBranch(data.branchTraceId);
                setInputCurrency(data.currency);
                setInputAmount(data.amount);
                setInputTaxableAmount(data.taxableAmount);
                setInputVat(data.taxAmount);
                setInputFrequency(data.frequence);
                setInputCode(data.code);
                setInputGroup(data.group);
                setInputDuration(data.duration);
                setInputPaymentDate(data.paymentDay);
                if (data.callbackUrl) {
                  setInputCallback(true);
                  setInputCallbackUrl(data.callbackUrl);
                }
                if (data.additionalFields?.length > 0) {
                  setAdditionalData(data.additionalFields);
                }
  
                dispatch(
                  businessActions.getProvidersSubscriptionsTermsAndConditionsLink(data.branchTraceId, (link) => {
                    if (link) {
                      setPlanTerms(link);
                    }
                  })
                );
              }
            })
          );
        }
        setBranches(dataBranches.branches?.filter(b => b.serviceStatus === 'ACTIVE')?.map(b => ({ traceId: b.branchId, name: b.name })) || []);
      }))
    } else {
      setForbbidenSection(true);
    }
  }, [policies]);

  const _goBack = (e) => {
    e.preventDefault();
    dispatch(goBack());
  };

  const _submitForm = () => {
    if (!canSubmitForm) {
      return false;
    }

    if (isEditMode) {
      if (!inputDescription || !inputBranch) {
        return toast.error(
          i18n.get("ProvidersSubscriptionsAddPlan.FormValidationError")
        );
      }
    } else {
      if (
        !inputName ||
        !inputDescription ||
        !inputBranch ||
        !inputCurrency ||
        ![0, 10, 22].includes(inputVat) ||
        !inputAmount ||
        !inputFrequency
      ) {
        return toast.error(
          i18n.get("ProvidersSubscriptionsAddPlan.FormValidationError")
        );
      }
    }

    if (isEditMode) {
      const params = {
        branchTraceId: inputBranch,
        name: inputName,
        subscriptionPlanType: "TRADITIONAL",
        description: inputDescription,
        code: inputCode,
        group: inputGroup,
        taxableAmount: parseFloat(inputAmount),
        callbackUrl: inputCallback ? inputCallbackUrl : "",
        additionalFields: additionalData.map((field) => ({
          key: field.key,
          name: field.name,
          type: field.type,
        })),
      };

      dispatch(
        businessActions.providersSubscriptionsUpdatePlan(
          planTraceId,
          params,
          (result) => {
            if (result) {
              toast.success(
                i18n.get("ProvidersSubscriptionsAddPlan.SuccessUpdateMessage")
              );
              setTimeout(
                () =>
                  dispatch(
                    push(ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS.path)
                  ),
                1000
              );
            }
          }
        )
      );
    } else {
      const taxMultiplier = inputVat === 0 ? 1 : inputVat === 22 ? 1.22 : 1.1;
      const params = {
        branchTraceId: inputBranch,
        name: inputName,
        subscriptionPlanType: "TRADITIONAL",
        additionalFields: additionalData.map((field) => ({
          key: field.key,
          name: field.name,
          type: field.type,
        })),
        frequence: inputFrequency,
        taxableAmount: parseFloat((parseFloat(inputAmount) / taxMultiplier).toFixed(2)),
        taxAmount: parseFloat((parseFloat(inputAmount) - parseFloat(inputAmount) / taxMultiplier).toFixed(2)),
        currency: inputCurrency,
        description: inputDescription,
        duration: inputDuration,
        anticipatedCancellationEnable: true,
        code: inputCode,
        group: inputGroup,
        paymentDay: inputPaymentDate ? parseInt(inputPaymentDate) : null,
        tags: null,
        callbackUrl: inputCallback ? inputCallbackUrl : "",
      };
      
      dispatch(
        businessActions.providersSubscriptionsAddPlan(params, (result) => {
          if (result) {
            toast.success(
              i18n.get("ProvidersSubscriptionsAddPlan.SuccessAddMessage")
            );
            setTimeout(
              () =>
                dispatch(
                  push(ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS.path)
                ),
              1000
            );
          }
        })
      );
    }
  };

  const _getOptionLabel = (array) => (option) => {
    let foundOption = null;
    if (array === "branches") {
      foundOption = branches.find((c) => c.traceId === option);
      return foundOption?.name || foundOption?.internalName || "";
    } else if (array === "currencies") {
      foundOption = CURRENCY_FILTER_OPTIONS.find((c) => c.id === option);
      return i18n.get(foundOption.name);
    } else if (array === "vat") {
      foundOption = VAT_FILTER_OPTIONS.find((c) => c.id === option);
      return foundOption.name;
    } else if (array === "frequencies") {
      foundOption = FREQUENCE_FILTER_OPTIONS.find((c) => c.id === option);
      return i18n.get(foundOption.name);
    } else if (array === "durations") {
      foundOption = DURATION_FILTER_OPTIONS.find((c) => c.id === option);
      return foundOption.name;
    }

    return "";
  };

  const _handleOnChangeFrequency = (e, newValue) => {
    setInputFrequency(newValue);
  };

  const _handleOnChangeCurrency = (e, newValue) => {
    setInputCurrency(newValue);
  };

  const _handleOnChangeVat = (e, newValue) => {
    setInputVat(newValue);
  };

  const _handleOnChangeBranch = (e, newValue) => {
    setInputBranch(newValue);
  };

  const _handleOnChangeDuration = (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value) || value > 0) {
      setInputDuration(value);
    }
  };

  const _handleOnAddNewField = () => {
    setAdditionalData([
      ...additionalData,
      { id: uniqueId(), name: "", fieldName: "", type: "STRING" },
    ]);
  };

  const _handleOnClickRemove = (index) => () => {
    setAdditionalData([
      ...additionalData.slice(0, index),
      ...additionalData.slice(index + 1),
    ]);
  };

  const _handleOnAdditionalFieldChange = (index, field) => (e) => {
    const updatedField = {
      ...additionalData[index],
      [field]: e.target.value,
    };

    if (field === "type") {
      updatedField.value = "";
    }

    setAdditionalData([
      ...additionalData.slice(0, index),
      updatedField,
      ...additionalData.slice(index + 1),
    ]);
  };

  const handleOnChangePaymentDate = (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value) || (value >= 1 && value <= 28)) {
      setInputPaymentDate(value);
    }
  }

  const renderMainForm = () => {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.formWrapper}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form
            layout="vertical"
            onSubmit={_submitForm}
            className={classes.form}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={4}>
                {isEditMode ? (
                  <div
                    className={clsx(
                      classes.planDetailsItem,
                      classes.planDetailsItemDisabled
                    )}
                  >
                    <div>
                      {i18n.get("ProvidersSubscriptionsAddPlan.NameInputLabel")}
                    </div>
                    {inputName || "-"}
                  </div>
                ) : (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.formInput}
                    label={i18n.get(
                      "ProvidersSubscriptionsAddPlan.NameInputLabel"
                    )}
                    placeholder={i18n.get(
                      "ProvidersSubscriptionsAddPlan.NameInputPlaceholder"
                    )}
                    onChange={(e) => setInputName(e.target.value)}
                    value={inputName}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get(
                    "ProvidersSubscriptionsAddPlan.DescriptionInputLabel"
                  )}
                  placeholder={i18n.get(
                    "ProvidersSubscriptionsAddPlan.DescriptionInputPlaceholder"
                  )}
                  onChange={(e) => setInputDescription(e.target.value)}
                  value={inputDescription}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Autocomplete
                  options={branches.map((c) => c.traceId)}
                  getOptionLabel={_getOptionLabel("branches")}
                  onChange={_handleOnChangeBranch}
                  classes={{ inputRoot: classes.formInputSelect }}
                  value={inputBranch}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.formInput}
                      classes={{ root: classes.formInput }}
                      label={i18n.get(
                        "ProvidersSubscriptionsAddPlan.BranchInputLabel"
                      )}
                      placeholder={i18n.get(
                        "ProvidersSubscriptionsAddPlan.BranchInputPlaceholder"
                      )}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <div className={classes.formInputSeparator} />
            <div className={classes.formInputSeparator} />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={4}>
                {isEditMode ? (
                  <div
                    className={clsx(
                      classes.planDetailsItem,
                      classes.planDetailsItemDisabled
                    )}
                  >
                    <div>
                      {i18n.get(
                        "ProvidersSubscriptionsAddPlan.CurrencyInputLabel"
                      )}
                    </div>
                    {inputCurrency
                      ? i18n.get(
                          CURRENCY_FILTER_OPTIONS.find(
                            (c) => c.id === inputCurrency
                          )?.name
                        )
                      : "-"}
                  </div>
                ) : (
                  <Autocomplete
                    options={CURRENCY_FILTER_OPTIONS.map((c) => c.id)}
                    getOptionLabel={_getOptionLabel("currencies")}
                    onChange={_handleOnChangeCurrency}
                    classes={{ inputRoot: classes.formInputSelect }}
                    value={inputCurrency}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.formInput}
                        classes={{ root: classes.formInput }}
                        label={i18n.get(
                          "ProvidersSubscriptionsAddPlan.CurrencyInputLabel"
                        )}
                        placeholder={i18n.get(
                          "ProvidersSubscriptionsAddPlan.CurrencyPlaceholder"
                        )}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {isEditMode ? (
                  <div
                    className={clsx(
                      classes.planDetailsItem,
                      classes.planDetailsItemDisabled
                    )}
                  >
                    <div>
                      {i18n.get(
                        "ProvidersSubscriptionsAddPlan.AmountInputLabel"
                      )}
                    </div>
                    {inputAmount || "-"}
                  </div>
                ) : (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.formInput}
                    label={i18n.get(
                      "ProvidersSubscriptionsAddPlan.AmountInputLabel"
                    )}
                    placeholder={i18n.get(
                      "ProvidersSubscriptionsAddPlan.AmountInputPlaceholder"
                    )}
                    onChange={(e) => setInputAmount(e.target.value)}
                    value={inputAmount}
                    type="number"
                  />
                )}
              </Grid>
              {!isEditMode ? (
                <Grid item xs={12} sm={12} md={4}>
                  <Autocomplete
                    options={VAT_FILTER_OPTIONS.map((c) => c.id)}
                    getOptionLabel={_getOptionLabel("vat")}
                    onChange={_handleOnChangeVat}
                    classes={{ inputRoot: classes.formInputSelect }}
                    value={inputVat}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.formInput}
                        classes={{ root: classes.formInput }}
                        label={i18n.get(
                          "ProvidersSubscriptionsAddPlan.VatInputLabel"
                        )}
                        placeholder={i18n.get(
                          "ProvidersSubscriptionsAddPlan.VatInputPlaceholder"
                        )}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={4}>
                  <div
                    className={clsx(
                      classes.planDetailsItem,
                      classes.planDetailsItemDisabled
                    )}
                  >
                    <div>
                      {i18n.get(
                        "ProvidersSubscriptionsAddPlan.TaxableAmountLabel"
                      )}
                    </div>
                    {inputTaxableAmount || "-"}
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={4}>
                {isEditMode ? (
                  <div
                    className={clsx(
                      classes.planDetailsItem,
                      classes.planDetailsItemDisabled
                    )}
                  >
                    <div>
                      {i18n.get(
                        "ProvidersSubscriptionsAddPlan.FrequencyInputLabel"
                      )}
                    </div>
                    {inputFrequency
                      ? i18n.get(
                          FREQUENCE_FILTER_OPTIONS.find(
                            (f) => f.id === inputFrequency
                          )?.name || inputFrequency
                        )
                      : "-"}
                  </div>
                ) : (
                  <Autocomplete
                    options={FREQUENCE_FILTER_OPTIONS.filter(o => o.active).map((c) => c.id)}
                    getOptionLabel={_getOptionLabel("frequencies")}
                    onChange={_handleOnChangeFrequency}
                    classes={{ inputRoot: classes.formInputSelect }}
                    value={inputFrequency}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.formInput}
                        classes={{ root: classes.formInput }}
                        label={i18n.get(
                          "ProvidersSubscriptionsAddPlan.FrequencyInputLabel"
                        )}
                        placeholder={i18n.get(
                          "ProvidersSubscriptionsAddPlan.FrequencyPlaceholder"
                        )}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              {inputFrequency === 'MONTHLY' ? <Grid item xs={12} sm={12} md={4}>
                {isEditMode ? (
                  <div
                    className={clsx(
                      classes.planDetailsItem,
                      classes.planDetailsItemDisabled
                    )}
                  >
                    <div>
                      {i18n.get(
                        "ProvidersSubscriptionsAddPlan.PaymentDateInputViewLabel"
                      )}
                    </div>
                    {inputPaymentDate || "-"}
                  </div>
                ) : (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.formInput}
                    label={i18n.get(
                      "ProvidersSubscriptionsAddPlan.PaymentDateInputLabel"
                    )}
                    placeholder={i18n.get(
                      "ProvidersSubscriptionsAddPlan.PaymentDateInputPlaceholder"
                    )}
                    onChange={handleOnChangePaymentDate}
                    value={inputPaymentDate}
                    helperText={i18n.get(
                      "ProvidersSubscriptionsAddPlan.PaymentDateInputHelp"
                    )}
                    type="number"
                  />
                )}
              </Grid> : null}
              {isEditMode ? (
                <Grid item xs={12} sm={12} md={3}>
                  <div className={classes.planDetailsItem}>
                    <div>
                      {i18n.get(
                        "ProvidersSubscriptionsAddPlan.TermsInputLabel"
                      )}
                      :
                    </div>
                    {planTerms ? (
                      <a href={planTerms} target="_blank" rel="noreferrer">{i18n.get(
                        "ProvidersSubscriptionsAddPlan.TermsInputValue"
                      )}</a>
                    ) : (
                      "-"
                    )}
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  };

  const renderOptionalForm = () => {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.formWrapper}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form
            layout="vertical"
            onSubmit={_submitForm}
            className={classes.form}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get(
                    "ProvidersSubscriptionsAddPlan.CodeInputLabel"
                  )}
                  placeholder={i18n.get(
                    "ProvidersSubscriptionsAddPlan.CodeInputPlaceholder"
                  )}
                  onChange={(e) => setInputCode(e.target.value)}
                  value={inputCode}
                  inputProps={{ maxLength: 25 }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get(
                    "ProvidersSubscriptionsAddPlan.GroupInputLabel"
                  )}
                  placeholder={i18n.get(
                    "ProvidersSubscriptionsAddPlan.GroupInputPlaceholder"
                  )}
                  onChange={(e) => setInputGroup(e.target.value)}
                  value={inputGroup}
                  inputProps={{ maxLength: 25 }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {isEditMode ? (
                  <div
                    className={clsx(
                      classes.planDetailsItem,
                      classes.planDetailsItemDisabled
                    )}
                  >
                    <div>
                      {i18n.get(
                        "ProvidersSubscriptionsAddPlan.DurationInputLabel"
                      )}
                    </div>
                    {/[0-9]+/.test(inputDuration) ? inputDuration : "-"}
                  </div>
                ) : (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.formInput}
                    label={i18n.get(
                      "ProvidersSubscriptionsAddPlan.DurationInputLabel"
                    )}
                    placeholder={i18n.get(
                      "ProvidersSubscriptionsAddPlan.DurationInputPlaceholder"
                    )}
                    onChange={_handleOnChangeDuration}
                    value={inputDuration}
                    type="number"
                  />
                )}
              </Grid>
            </Grid>
            <div className={classes.formInputSeparator} />
            <div className={classes.formInputSeparator} />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={8}>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    className={classes.callbackCheckboxWrapper}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={inputCallback}
                          onChange={() => setInputCallback(!inputCallback)}
                        />
                      }
                      label={i18n.get(
                        "ProvidersSubscriptionsAddPlan.CallbackInputLabel"
                      )}
                      classes={{ label: classes.autoApprovalLabel }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <div
                      className={
                        !inputCallback
                          ? classes.autoApprovalInputDisabledWrapper
                          : null
                      }
                    >
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={!inputCallback}
                        className={classes.formInput}
                        label={i18n.get(
                          "ProvidersSubscriptionsAddPlan.CallbackUrlInputLabel"
                        )}
                        placeholder={i18n.get(
                          "ProvidersSubscriptionsAddPlan.CallbackUrlInputPlaceholder"
                        )}
                        onChange={(e) => setInputCallbackUrl(e.target.value)}
                        value={inputCallback ? inputCallbackUrl : ''}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  };

  const renderAdditionalDataForm = () => {
    if (additionalData.length === 0) {
      return (
        <EmptyMessage showShadow={false}>
          {i18n.get("ProvidersSubscriptionsAddPlan.AdditionalDataNoFields")}
        </EmptyMessage>
      );
    }

    return (
      <div>
        {additionalData.length > 0
          ? additionalData.map((field, index) => (
              <ContentBox
                key={index}
                title={`${i18n.get(
                  "ProvidersSubscriptionsAddPlan.AdditionalFieldTitle"
                )} ${index + 1}`}
                button
                buttonRightIcon={() => <DeleteIcon />}
                buttonOnClick={_handleOnClickRemove(index)}
                showShadow={false}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.formInput}
                      label={i18n.get(
                        "ProvidersSubscriptionsAddPlan.AdditionalFieldNameInputLabel"
                      )}
                      placeholder={i18n.get(
                        "ProvidersSubscriptionsAddPlan.AdditionalFieldNameInputPlaceholder"
                      )}
                      value={field.name}
                      onChange={_handleOnAdditionalFieldChange(index, "name")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.formInput}
                      label={i18n.get(
                        "ProvidersSubscriptionsAddPlan.AdditionalFieldInternalNameInputLabel"
                      )}
                      placeholder={i18n.get(
                        "ProvidersSubscriptionsAddPlan.AdditionalFieldInternalNameInputPlaceholder"
                      )}
                      value={field.key}
                      onChange={_handleOnAdditionalFieldChange(index, "key")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <InputLabel className={classes.formInputSelectLabel} shrink>
                      {i18n.get(
                        "ProvidersSubscriptionsAddPlan.AdditionalFieldTypeInputLabel"
                      )}
                    </InputLabel>
                    <Select
                      className={classes.formInputSelect}
                      onChange={_handleOnAdditionalFieldChange(index, "type")}
                      value={field.type}
                    >
                      {FIELD_TYPES_OPTIONS.map((fieldType, index) => (
                        <MenuItem
                          key={index}
                          value={fieldType.id}
                          className={classes.formInputSelectOption}
                        >
                          {i18n.get(fieldType.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </ContentBox>
            ))
          : null}
      </div>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get("ProvidersSubscriptionsAddPlan.Title")}
        withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get("ProvidersSubscriptionsAddPlan.Title")}
      withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
    >
      <TitleBox
        title={
          isEditMode
            ? i18n.get("ProvidersSubscriptionsAddPlan.BoxTitleEdit")
            : i18n.get("ProvidersSubscriptionsAddPlan.BoxTitleNew")
        }
        back
        backTitle={i18n.get("ProvidersSubscriptionsAddPlan.BoxBackButton")}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator size="small" />
      <Box direction="column">
        <ContentBox
          title={i18n.get("ProvidersSubscriptionsAddPlan.MainFormTitle")}
          titleBold
          showShadow={false}
          padding={false}
        >
          {renderMainForm()}
        </ContentBox>
        <BoxSeparator type="line" />
        <ContentBox
          title={i18n.get("ProvidersSubscriptionsAddPlan.OptionalFormTitle")}
          titleBold
          showShadow={false}
          padding={false}
        >
          {renderOptionalForm()}
        </ContentBox>
        <BoxSeparator type="line" />
        <ContentBox
          title={i18n.get(
            "ProvidersSubscriptionsAddPlan.AdditionalDataFormTitle"
          )}
          titleBold
          button
          buttonText={i18n.get(
            "ProvidersSubscriptionsAddPlan.AdditionalDataButton"
          )}
          buttonLeftIcon={() => <AddIcon fontSize="small" />}
          buttonOnClick={_handleOnAddNewField}
          showShadow={false}
          padding={false}
        >
          {renderAdditionalDataForm()}
        </ContentBox>
        <BoxSeparator type="line" />
        <div className={classes.contentActionsWrapper}>
          <Button variant="outlined" onClick={_goBack}>
            {i18n.get("ProvidersSubscriptionsAddPlan.CancelButton")}
          </Button>
          <div className={classes.contentActionSeparator} />
          <Button disabled={!canSubmitForm} onClick={_submitForm}>
            {isEditMode
              ? i18n.get("ProvidersSubscriptionsAddPlan.SaveButton")
              : i18n.get("ProvidersSubscriptionsAddPlan.CreateButton")}
          </Button>
        </div>
      </Box>
    </Page>
  );
};

ProvidersSubscriptionsAddPlanScreen.id =
  "com.Handy.ProvidersSubscriptionsAddPlan";

export default ProvidersSubscriptionsAddPlanScreen;
