import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Language, Utils } from '../../../utils';
import { Alert, AlertTitle } from '@material-ui/lab';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import makeClasses from '../styles';
import clsx from 'clsx';

const StepSeven = ({ history, nextStep, prevStep, handleChange, stepOneData }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const { loading, languageTexts } = useSelector((state) => ({
    loading: state.user.loading,
    languageTexts: state.language.texts || {},
  }));

  const i18n = Language(languageTexts);

  return (
    <React.Fragment>
      <div className={classes.formContent}>
        <div className={classes.formTitleContainer}>
          <div className={classes.formTitle}>{i18n.get('LegalEntityRegistration.StepFormTitle')}</div>
          <div className={classes.formText}>{i18n.get('LegalEntityRegistration.Step7FormText')}</div>
        </div>
        <Alert
          severity="warning"
          icon={false}
          className={clsx(classes.alert, classes.alertWarning, classes.alertWithNoMarginBottom)}
        >
          <FlagOutlinedIcon className={clsx(classes.alertIcon, classes.alertIconWithBorder)} />
          <div className={classes.alertMessage}>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get('LegalEntityRegistration.RegisterConfirmationTitle')}
            </AlertTitle>
            <div>{i18n.get('LegalEntityRegistration.RegisterConfirmationMessage')}</div>
          </div>
        </Alert>
      </div>
    </React.Fragment>
  );
};
StepSeven.id = 'com.Handy.LegalEntityRegistration.StepSeven';
export default StepSeven;
