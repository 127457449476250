import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import _, { replace } from 'lodash';
import { toast } from 'react-toastify';

import ROUTES from '../../../../routes';
import { BoxSeparator, ContentBox, Page, TitleBox, Button, ForbiddenSection } from '../../../../components';
import { user as userActions } from '../../../../actions';
import { Language, Colors } from '../../../../utils';

import {
  Grid,
  TextField,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  withStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import makeClasses from './styles';
import { checkForExpiredSession, inputs } from '../../../../utils/Utils';
import { Policies } from '../../../../utils/Policies';
import clsx from 'clsx';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: Colors.rgb.grey,
    },
    '&:nth-of-type(even)': {
      backgroundColor: Colors.rgb.white,
    },
  },
}))(TableRow);

const EditUsersScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [ user, setUser ] = useState(null);
  const [ userName, setName ] = useState('');
  const [ userUsername, setUserUsername ] = useState('');
  const [ userRoles, setUserRoles ] = useState([]);
  const [ userRole, setUserRole ] = useState(null);
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const { policies, authTime, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    authTime: state.user.userData.authTime || Date.now(),
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const canModifyUsers = policies && policies.includes(Policies.types.MANAGE_USERS_WRITE);

  const canSubmitForm = userName.length > 0 && userUsername.length > 0 && userRole && userRole.traceId;

  useEffect(
    () => {
      const exiredSession = checkForExpiredSession(authTime);

      if (exiredSession) {
        return dispatch(replace(ROUTES.MANAGE_USERS_CHANGE_PASSWORD.path));
      }
    },
    [ authTime ]
  );

  useEffect(
    () => {
      window.scrollTo(0, 0);

      if (policies && policies.includes(Policies.types.MANAGE_USERS)) {
        dispatch(
          userActions.getUser(match.params.traceId, user => {
            setUser(user);
            setName(user.name);
            setUserUsername(user.username);
            setUserRole(user.role);
          })
        );

        if (policies.includes(Policies.types.MANAGE_USERS_WRITE)) {
          dispatch(
            userActions.getUserRoles(roles => {
              setUserRoles(roles.elements ? _.orderBy(roles.elements, [ 'name' ], [ 'asc' ]) : []);
            })
          );
        }
      } else {
        setForbbidenSection(true);
      }
    },
    [ policies ]
  );

  const _goBack = e => {
    e.preventDefault();
    dispatch(goBack());
  };

  const _handleUserNameOnChange = e => setName(e.target.value.substring(0, 100));

  const _handleUserUsernameOnChange = e => {
    setUserUsername(e.target.value);
  };

  const _handleOnChangeUserRole = role => {
    if (userRole && userRole.traceId === role.traceId) {
      setUserRole(null);
    } else {
      setUserRole(role);
    }
  };

  const _submitForm = () => {
    if (!canSubmitForm) {
      return false;
    }

    if (!inputs.username.regex.test(userUsername)) {
      return toast.error(i18n.get('ManageUsersEdit.ValidationUsernameError'));
    }

    dispatch(
      userActions.updateUser(
        user.traceId,
        {
          name: userName,
          username: userUsername,
          roleTraceId: userRole.traceId,
        },
        () => {
          toast.success(i18n.get('ManageUsersEdit.SuccessMessage'));
          setTimeout(() => dispatch(push(ROUTES.MANAGE_USERS.path)), 1000);
        }
      )
    );
  };

  const renderManageUsersEditForm = () => {
    return (
      <Grid container justify="center" alignItems="center" className={classes.userFormWrapper}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form layout="vertical" onSubmit={_submitForm} className={classes.userForm}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={i18n.get('ManageUsersEdit.UserNameInputLabel')}
                  placeholder={i18n.get('ManageUsersEdit.UserNameInputPlaceholder')}
                  className={classes.userFormInput}
                  onChange={_handleUserNameOnChange}
                  value={userName}
                  InputProps={{
                    endAdornment:
                      userName.length > 0 ? (
                        <InputAdornment position="start">
                          <span className={classes.userFormInputEndAdornment}>{userName.length} / 100</span>
                        </InputAdornment>
                      ) : null,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.userFormInput}
                  label={i18n.get('ManageUsersEdit.UserUsernameInputLabel')}
                  placeholder={i18n.get('ManageUsersEdit.UserUsernameInputPlaceholder')}
                  onChange={_handleUserUsernameOnChange}
                  value={userUsername}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  };

  const renderRolesForm = () => {
    if (isSmallScreen) {
      return (
        <div>
          {userRoles.map((role, index) => (
            <React.Fragment key={role.traceId}>
              {index === 0 ? null : <div className={classes.boxItemSeparator} />}
              <div className={classes.boxContainer}>
                <div className={classes.boxContainerCheckboxWrapper}>
                  <Checkbox
                    checked={userRole && userRole.traceId === role.traceId ? true : false}
                    onChange={() => _handleOnChangeUserRole(role)}
                  />
                </div>
                <div>
                  <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                    <span>{i18n.get('ManageUsersEdit.RolesTableHeaderRole')}:</span>
                    <span>{role.name}</span>
                  </div>
                  <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                    <span>{i18n.get('ManageUsersEdit.RolesTableHeaderRoleDescription')}:</span>
                    <span>{role.description}</span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }

    return (
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get('ManageUsersEdit.RolesTableHeaderRole')}
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get('ManageUsersEdit.RolesTableHeaderRoleDescription')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userRoles.map(role => (
              <StyledTableRow key={role.traceId}>
                <TableCell className={classes.tableBodyCell}>
                  <Checkbox
                    checked={userRole && userRole.traceId === role.traceId ? true : false}
                    onChange={() => _handleOnChangeUserRole(role)}
                  />
                </TableCell>
                <TableCell className={classes.tableBodyCell}>{role.name}</TableCell>
                <TableCell className={classes.tableBodyCell}>{role.description}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ManageUsersEdit.Title')}
        withActivePage={ROUTES.MANAGE_USERS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ManageUsersEdit.Title')}
      withActivePage={ROUTES.MANAGE_USERS.id}
    >
      <TitleBox
        title={i18n.get('ManageUsersEdit.BoxTitle')}
        back
        backTitle={i18n.get('ManageUsersEdit.BoxBackButton')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <ContentBox title={i18n.get('ManageUsersEdit.BoxContentTitle')} titleBold>
        {renderManageUsersEditForm()}
      </ContentBox>
      <ContentBox title={i18n.get('ManageUsersEdit.BoxRolesTitle')} titleBold>
        {renderRolesForm()}
        <div>
          <div className={classes.contentActionsWrapper}>
            <Button variant="outlined" onClick={_goBack}>
              {i18n.get('ManageUsersEdit.CancelButton')}
            </Button>
            {canModifyUsers ? (
              <React.Fragment>
                <div className={classes.contentActionSeparator} />
                <Button disabled={!canSubmitForm} onClick={_submitForm}>
                  {i18n.get('ManageUsersEdit.ConfirmButton')}
                </Button>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </ContentBox>
    </Page>
  );
};

EditUsersScreen.id = 'com.Handy.ManageUsersEdit';

export default EditUsersScreen;
