import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../utils';

const makeClasses = makeStyles((theme) => ({
  listItemMenu: {
    padding: theme.spacing(1),
  },
  listItemMenuItem: {
    color: Colors.rgb.contentText,
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.S,
  },
  listItemActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '& > button': {
      width: 40,
      padding: 6
    },

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      top: 20,
    },
  },
  noDataMessage: {
    fontFamily: theme.fontFamily.semiBold,
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentTextSoft,
    textAlign: 'center',
    width: '100%',
  },
  menuItemText: {
    minWidth: 0,
    marginTop: 0,
  },
  menuItemIcon: {
    minWidth: 35,
  },
}));

export default makeClasses;
