const Constants = {
  API_PUBLIC: 'https://7red1ckigi.execute-api.us-east-1.amazonaws.com/Stage/public',
  API_PRIVATE: 'https://7red1ckigi.execute-api.us-east-1.amazonaws.com/Stage/private/api',
  SHAREABLE_LINK: `${process.env.REACT_APP_PAYMENT_LINK_URL}/details/?sessionId={traceId}`,
  SHAREABLE_WHATSAPP_LINK: 'https://api.whatsapp.com/send?text={text} {link}',
  SHAREABLE_TELEGRAM_LINK: 'https://t.me/share/url?url={link}&text={text}',
  SHAREABLE_EMAIL_LINK: 'mailto:?body={text} {link}',
};

export default Constants;
