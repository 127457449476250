import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { Language } from '../../../utils';
import { user as userActions } from '../../../actions';
import { replace } from 'connected-react-router';
import makeClasses from '../styles';
import { toast } from "react-toastify";


const StepSix = ({ history, nextStep, prevStep, handleChange, stepOneData }) => {

    const dispatch = useDispatch();
    const classes = makeClasses();

    const { loading, languageTexts } = useSelector(state => ({
        loading: state.user.loading,
        languageTexts: state.language.texts || {},
    }));

    const i18n = Language(languageTexts);

    const searchParams = qs.parse(history.location.search);

    const [affiliationVerificationLink, setAffiliationVerificationLink] = useState(
        searchParams.verificationLink || null
    );

    useEffect(
        () => {
            const requestCameraPermission = async () => {
                await navigator.mediaDevices.getUserMedia({
                    video: true,
                });
            };
            requestCameraPermission();
        }, [affiliationVerificationLink]);

    useEffect(
        () => {

            dispatch(

                userActions.merchantAffiliationIdentityVerification(stepOneData, result => {
                    if (result) {
                        setAffiliationVerificationLink(encodeURIComponent(result.verificationLink));

                        searchParams.verificationLink = result.verificationLink;
                        dispatch(
                            replace({
                                search: qs.stringify(searchParams),
                            })
                        );
                    }
                    else
                        toast.error(i18n.get('LegalEntityRegistration.IdentityVerificationError'), { autoClose: 5000 });
                })
            );
        },
        []
    );


    return (
        <React.Fragment>
            <div className={classes.formContent}>
                <div className={classes.formTitleContainer}>
                    <div className={classes.formTitle}>{i18n.get('LegalEntityRegistration.StepFormTitle')}</div>
                    {!affiliationVerificationLink ? (
                        <div className={classes.formText}>{i18n.get('LegalEntityRegistration.Step6FormText')}</div>
                    ) : null}
                </div>
            </div>
            {affiliationVerificationLink ? (
                <div className={classes.iframeContainer}>
                    <iframe src={decodeURIComponent(affiliationVerificationLink)} title="Verification" allow="camera" />
                </div>
            ) : null}
        </React.Fragment>
    );
}
StepSix.id = 'com.Handy.LegalEntityRegistration.StepSix';
export default StepSix;