import React, { useEffect } from 'react';
import makeClasses from './styles';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import _ from 'lodash';

const FiltersText = ({
  value = null,
  onChange,
  fullWidth = false,
  disabled = false,
  placeholder = null,
  inputType = null,
}) => {
  const classes = makeClasses();
  let debouncedOnChange = null;

  useEffect(
    () => {
      if (typeof onChange === 'function') {
        debouncedOnChange = _.debounce(onChange, 300);
      }
    },
    [ onChange ]
  );

  const _handleOnChange = e => {
    if (debouncedOnChange) {
      debouncedOnChange(e.target.value);
    }
  };

  return (
    <div className={clsx(classes.container, fullWidth ? classes.inputContainerFullWidth : null)}>
      <TextField
        defaultValue={value}
        className={clsx(classes.inputContainer, fullWidth ? classes.inputContainerFullWidth : null)}
        disabled={disabled}
        onChange={_handleOnChange}
        placeholder={placeholder}
        type={inputType}
      />
    </div>
  );
};

export default FiltersText;
