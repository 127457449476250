import { makeStyles } from '@material-ui/core';
import { THEME_LAYOUT } from '../../theme';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    color: Colors.rgb.white,
    fontSize: theme.fontSize.L,
    fontFamily: theme.fontFamily.regular,
    paddingTop: theme.spacing(THEME_LAYOUT.VERTICAL.INNER.XS),
    paddingBottom: theme.spacing(THEME_LAYOUT.VERTICAL.INNER.XS),
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      marginRight: 7,
    },
  },
  titleBold: {
    fontFamily: theme.fontFamily.bold,
  },
  titleSemibold: {
    fontFamily: theme.fontFamily.semibold,
  },
  titleLight: {
    fontFamily: theme.fontFamily.light,
  },
  titleWithPopover: {
    maxWidth: '100%',

    '& > span': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: `calc(100% - 20px)`,
    },
  },
  content: {
    marginTop: theme.spacing(THEME_LAYOUT.VERTICAL.INNER.XS),
    marginLeft: theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.L),
    width: `calc(100% - ${theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.L)})`,
  },
  popover: {
    pointerEvents: 'none',
    marginTop: theme.spacing(THEME_LAYOUT.VERTICAL.INNER.XS),

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  popoverPaper: {
    padding: theme.spacing(THEME_LAYOUT.VERTICAL.INNER.XS),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  popoverText: {
    fontSize: '0.85rem',
    color: Colors.rgba(Colors.rgb.black, 85),
  },
}));

export default makeClasses;
