import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  contentTile: {
    padding: 0,
    width: '100%',
    marginTop: `-${theme.spacing(2)}`,

    '& *': {
      fontFamily: theme.fontFamily.regular,
      fontSize: theme.fontSize.M,
      color: Colors.rgb.contentText,
    },
  },
  contentItemColumn: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column !important',
      alignItems: 'flex-start !important',
      justifyContent: 'center !important',
      paddingBottom: theme.spacing(3),
    },
  },
  contentItem: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentItemText: {
    color: Colors.rgb.contentTextHighlighted,
  },
  contentItemColumnText: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  contentItemEven: {
    background: Colors.rgb.grey,
  },
  contentItemLink: {
    color: Colors.rgb.primary,
  },
  plansWrapper: {
    boxShadow: 'none',
    margin: `0 !important`,

    '&:before': {
      content: 'none',
    },
  },
  plansWrapperExpanded: {
    marginBottom: '-5px !important',
  },
  plansAccordion: {
    minHeight: `42px !important`,
    height: 42,
    padding: `0 ${theme.spacing(3)}`,
    background: Colors.rgb.grey,

    '& > div': {
      justifyContent: 'space-between',
    },
  },
  plansLinesAccordion: {
    flexDirection: 'column',
    background: Colors.rgb.grey,
    borderTop: `1px solid ${Colors.rgb.contentLine}`,
  },
  plansAdditionalsTitle: {
    fontWeight: 'bold',
  },
  plansPlan712AdditionalsContainer: {
    background: Colors.rgb.white,
    padding: 20,
    marginTop: 30,
    marginBottom: 30,
  },
  plansPlan712AdditionalsTitle: {
    fontWeight: 'bold',
  },
  plansPlan712AdditionalsInfo: {
    marginTop: 10,
    padding: `0 ${theme.spacing(2)}`,

    '& > div': {
      fontSize: `${theme.fontSize.M} !important`,
      color: Colors.rgb.contentTextSoft,
    },
  },
  //

  table: {},
  tableHeaderCell: {
    fontFamily: theme.fontFamily.bold,
  },
  tableBodyCellStatus: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: 5,

    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(1)}`,
      fontSize: `${theme.fontSize.S} !important`,
    },
  },
  tableBodyCellStatusPending: {
    background: Colors.rgb.alertWarningBackground,
    border: `1px solid ${Colors.rgb.alertWarningBorder}`,
    color: Colors.rgb.alertWarningText,
  },
  tableBodyCell: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    borderBottomColor: Colors.rgba(Colors.rgb.contentLineSoft, 60),
    color: Colors.rgb.contentText,
  },
  tableBodyCellAccountNumber: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',

      '& > button': {
        padding: '0 10px !important',
      },
    },
  },
  listItemMenu: {
    padding: theme.spacing(1),
  },
  listItemMenuItem: {
    color: Colors.rgb.contentText,
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.S,
  },
  listItemActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '& > button': {
     width: 40,
      padding: 6
    },

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      top: 20,
    },
  },
  pagination: {
    marginTop: 20,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
  },
  paginationList: {
    '& li > button': {
      background: Colors.rgb.white,
    },
  },
  noDataMessage: {
    fontFamily: theme.fontFamily.semiBold,
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentTextSoft,
    textAlign: 'center',
    width: '100%',
  },
  menuItemText: {
    minWidth: 0,
    marginTop: 0,
  },
  menuItemIcon: {
    minWidth: 35,
  },
  boxContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginLeft: `-${theme.spacing(2)}`,
    marginRight: `-${theme.spacing(2)}`,
    position: 'relative',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: Colors.rgb.grey,

    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${Colors.rgb.contentLineSoft}`,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),

      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  boxContainerEven: {
    background: Colors.rgb.white,
  },
  boxItemSeparator: {
    height: 1,
    width: '100%',
    background: Colors.rgb.contentLineSoft,
    margin: `${theme.spacing(3)} 0`,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  boxItem: {
    display: 'flex',
    marginBottom: theme.spacing(1),

    '& > span': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentTextSoft,

      '&:last-child': {
        marginLeft: theme.spacing(2),
        color: Colors.rgb.contentText,
      },
    },

    '&:last-child': {
      marginBottom: 0,
    },

    [theme.breakpoints.down('sm')]: {
      '& > span': {
        fontSize: theme.fontSize.M,
      },
    },
  },
  boxItemCompact: {
    fontSize: theme.fontSize.M,
  },
  usersCountContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '60%',
    justifyContent: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
    },
  },
  usersCountTitle: {
    background: Colors.rgb.dark_gray,
    color: Colors.rgb.white,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
    fontSize: theme.fontSize.L,
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      fontSize: theme.fontSize.M,
      padding: theme.spacing(1),
      width: '100%',
      textAlign: 'center',
    },
  },
  usersCountItemsWrapper: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  usersCountItem: {
    background: Colors.rgb.clickElement,
    color: '#3d4245',
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    fontSize: theme.fontSize.L,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: theme.fontSize.M,
      padding: theme.spacing(1),
      justifyContent: 'center',
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: theme.spacing(1),
    },
  },
  usersCountItemCount: {
    marginLeft: theme.spacing(1),
    background: Colors.rgb.primaryGreen,
    width: 20,
    height: 20,
    borderRadius: 20,
    fontSize: theme.fontSize.S,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  usersCountItemLast: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderLeft: `2px solid ${Colors.rgb.white}`,

    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: theme.spacing(1),
      borderBottomLeftRadius: 0,
    },
  },
  alertDanger: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
  gridBoxTitleContainer: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'block',
    },
  },
  gridBoxTitleWrapper: {
    marginRight: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  gridBoxTitleFilterWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 10,
    },
  },
  alert: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    width: '100%',
    boxSizing: 'border-box',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)} !important`,
      '& > div': {
        alignItems: 'flex-start',
      },
    },
  },
  alertTitle: {
    fontFamily: theme.fontFamily.bold,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.M,
    },
  },
  alertMessage: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.S,
    },
  },
  alertIcon: {
    fontSize: 50,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
  },
  alertInfo: {
    background: Colors.rgb.alertInfoBackground,
    border: `1px solid ${Colors.rgb.alertInfoBorder}`,
    color: `${Colors.rgb.alertInfoText} !important`,
  },
}));

export default makeClasses;
