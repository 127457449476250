import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  dialog: {
    boxShadow: 'none',
    width: '65%',
    maxWidth: '65%',
    minHeight: 500,

    [theme.breakpoints.down('sm')]: {
      width: '95%',
      maxWidth: '95%',
      margin: 0,
      minHeight: '50%',
    },
  },
  dialogTitleWrapper: {
    background: Colors.rgb.black_light,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,

    '& > h2': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
  },
  dialogTitle: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.XL,
    color: Colors.rgb.white,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: theme.fontSize.M,
    },
  },
  dialogTitleWithCloseButton: {
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
  },
  dialogTitleClose: {
    position: 'absolute',
    right: 0,
    height: 23,
    cursor: 'pointer',
  },
  dialogContentTitle: {
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentText,
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.M,
    },
  },
  dialogTitleCloseIcon: {
    color: Colors.rgb.white,
  },
  dialogContentWrapper: {
    padding: 0,
  },
  dialogContentFiltersWrapper: {
    margin: `${theme.spacing(4)} 0 0 0`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  dialogContentFiltersTitle: {
    fontSize: theme.fontSize.M,
    color: Colors.rgba(Colors.rgb.contentText, 60),
    marginRight: theme.spacing(2),
  },
  dialogContent: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentText,
  },
  dialogActionsWrapper: {
    padding: `${theme.spacing(2)} 0`,
    margin: `0 ${theme.spacing(4)}`,
    borderTop: `1px solid ${Colors.rgb.contentLineSoft}`,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',

      '& > div': {
        width: '100%',
        margin: '0 !important',
        marginTop: `${theme.spacing(2)} !important`,
        '&:last-child': {
          marginTop: `0 !important`,
        },
      },
    },
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  modalBodyCentered: {
    alignItems: 'center',
  },
  modalContentFooter: {
    fontSize: theme.fontSize.S,
    color: Colors.rgb.contentText,
    marginTop: theme.spacing(6),
  },
  plansWrapper: {
    boxShadow: 'none',
    margin: `0 !important`,

    '&:before': {
      content: 'none',
    },
  },
  plansAccordion: {
    minHeight: `42px !important`,
    height: 42,
    padding: `0 ${theme.spacing(3)}`,
    background: Colors.rgb.grey,

    '& > div': {
      justifyContent: 'space-between',
    },
  },
  plansLinesAccordion: {
    flexDirection: 'column',
    background: Colors.rgb.grey,
    paddingBottom: theme.spacing(3),
  },
  plansAdditionalsTitle: {
    fontWeight: 'bold',
  },
  plansPlan712AdditionalsContainer: {
    background: Colors.rgb.white,
    marginTop: 20,
    marginBottom: 20,
  },
  plansPlan712AdditionalsTitle: {
    fontWeight: 'bold',
  },
  plansPlan712AdditionalsInfo: {
    marginTop: 10,
    padding: `0 ${theme.spacing(2)}`,

    '& > div': {
      fontSize: `${theme.fontSize.M} !important`,
      color: Colors.rgb.contentTextSoft,
    },
  },
  plansSeparator: {
    height: theme.spacing(4),
  },
}));

export default makeClasses;
