import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  container: {
    width: '40%',
    flexShrink: 0,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    },
  },
  title: {
    fontFamily: theme.fontFamily.bold,
    fontSize: theme.fontSize.XXL,
    color: Colors.rgb.contentTextHighlighted,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${Colors.rgb.contentLine}`,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.XL,
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 7,
    marginTop: -5,
    color: Colors.rgb.contentText,

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      padding: 5,
    },
  },
  content: {
    border: `1px solid ${Colors.rgb.contentLine}`,
    padding: theme.spacing(4),
    maxHeight: '85%',
    overflow: 'auto',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}));

export default makeClasses;
