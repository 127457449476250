import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { Language } from '../../utils';
import makeClasses from './styles';

const VouchersTypesSelector = ({ selectedVoucherType, onVoucherTypeSelected, align }) => {
  const classes = makeClasses();

  const [ vouchersTypes, setVouchersTypes ] = useState([]);
  const { languageTexts } = useSelector(state => ({
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  useEffect(() => {
    setVouchersTypes([
      { id: 'E_FACTURA', label: 'TypeFilter.Invoice' },
      { id: 'E_RESGUARDO', label: 'TypeFilter.TaxCertificate' },
      { id: 'E_NOTA_CREDITO', label: 'TypeFilter.CreditNote' },
      { id: 'E_TICKET', label: 'TypeFilter.Ticket' },
      { id: 'E_NOTA_CREDITO_TICKET', label: 'TypeFilter.TicketCreditNote' },
    ]);
  }, []);

  const _handleOnChange = e => {
    if (typeof onVoucherTypeSelected === 'function') {
      onVoucherTypeSelected(e.target.value);
    }
  };

  return (
    <div className={align === 'right' ? classes.alignRight : null}>
      <Select value={selectedVoucherType} onChange={_handleOnChange} className={classes.selectContainer}>
        <MenuItem value="all" className={classes.selectOption}>
          {i18n.get('TypeFilter.Default')}
        </MenuItem>
        {vouchersTypes.map((voucherType, index) => (
          <MenuItem key={index} value={voucherType.id} className={classes.selectOption}>
            {i18n.get(voucherType.label)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default VouchersTypesSelector;
