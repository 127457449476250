import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  boxMenuContainer: {
    background: `${Colors.rgb.grey} !important`,
    borderBottom: `1px solid ${Colors.rgb.contentLine}`,
  },
  boxMenuContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      gap: 5
    }
  },
}));

export default makeClasses;
