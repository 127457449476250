import * as types from './Language.actionTypes';

export const initialize = () => {
	return {
		type: types.INITIALIZE
	};
};

export const setRequesting = (value) => {
	return {
		type: types.SET_REQUESTING,
		value
	};
};

export const setLanguageTexts = (data) => {
	return {
		type: types.SET_LANGUAGE_TEXTS,
		data
	};
};

export const clear = () => {
	return {
		type: types.CLEAR
	};
};
