import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import makeClasses from './styles';

const Button = ({
  disabled = false,
  onClick,
  variant = 'normal',
  leftIcon = null,
  rightIcon = null,
  active = false,
  size = 'md',
  children,
  loading = false,
  height,
  width,
  className = null,
}) => {
  const classes = makeClasses();

  const getClasses = () => {
    if (variant === 'outlined') {
      return clsx(
        classes.buttonOutlined,
        !children ? classes.buttonWithNoText : null,
        size === 'sm' ? classes.buttonSm : classes.buttonMd,
        disabled ? classes.buttonOutlinedDisabled : null,
        active ? classes.buttonOutlinedActive : null,
        className || null
      );
    }
    if (variant === 'secondary') {
      return clsx(
        classes.buttonSecondary,
        !children ? classes.buttonWithNoText : null,
        size === 'sm' ? classes.buttonSm : classes.buttonMd,
        disabled ? classes.buttonSecondaryDisabled : null,
        active ? classes.buttonActive : null,
        className || null
      );
    }
    if (variant === 'text-only') {
      return clsx(
        classes.buttonTextOnly,
        !children ? classes.buttonWithNoText : null,
        size === 'sm' ? classes.buttonSm : classes.buttonMd,
        disabled ? classes.buttonTextOnlyDisabled : null,
        active ? classes.buttonActive : null,
        className || null
      );
    }
    return clsx(
      classes.button,
      !children ? classes.buttonWithNoText : null,
      size === 'sm' ? classes.buttonSm : classes.buttonMd,
      disabled ? classes.buttonDisabled : null,
      active ? classes.buttonActive : null,
      className || null
    );
  };

  return (
    <div
      className={getClasses()}
      onClick={!disabled ? onClick : null}
      style={{
        height: typeof height !== 'undefined' ? height : null,
        width: typeof width !== 'undefined' ? width : null,
      }}
      tabIndex={0}
    >
      {loading ? (
        <CircularProgress className={classes.buttonLoadingIndicator} size={'sm'} color="inherit" />
      ) : (
        <React.Fragment>
          {leftIcon ? (
            <div className={clsx(classes.buttonLeftIconWrapper, !children ? classes.buttonIconWithNoText : null)}>
              {leftIcon()}
            </div>
          ) : null}
          {children}
          {rightIcon ? (
            <div className={clsx(classes.buttonRightIconWrapper, !children ? classes.buttonIconWithNoText : null)}>
              {rightIcon()}
            </div>
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
};

export default Button;
