import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  separator: {
    height: theme.spacing(4),
  },
  separatorSm: {
    height: theme.spacing(2),
  },
  lineSeparatorWrapper: {
    background: Colors.rgb.white,
    padding: 0,
    margin: `${theme.spacing(6)} 0`
  },
  lineSeparatorWrapperFull: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  lineSeparatorWrapperWithNoPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  lineSeparator: {
    height: 1,
    background: Colors.rgb.contentLine,
  },
}));

export default makeClasses;
