import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../utils';

const makeClasses = makeStyles(theme => ({
  userFormWrapper: {
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${Colors.rgb.contentLineSoft}`,
  },
  userForm: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  userFormInput: {
    width: '100%',

    '& > label': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentTextSoft,
      fontSize: theme.fontSize.L,
    },

    '& > div:before': {
      borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,
    },
    '& > div:after': {
      borderBottom: `1px solid ${Colors.rgb.primary}`,
    },
    '& > div:hover': {
      '&:before': {
        borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted} !important`,
      },
    },

    '& input': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.L,
    },
  },
  userFormInputEndAdornment: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentTextSoft,
    fontSize: theme.fontSize.S,
  },
  userFormInputSeparator: {
    height: theme.spacing(4),
  },
  userFormSelectLabel: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentTextSoft,
    fontSize: theme.fontSize.L,
  },
  userFormSelect: {
    width: '100%',

    '&:before': {
      borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,
    },
    '&:after': {
      borderBottom: `1px solid ${Colors.rgb.primary}`,
    },
    '&:hover': {
      '&:before': {
        borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted} !important`,
      },
    },

    '& > div': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.L,
    },
  },
  userFormSelectOption: {
    color: Colors.rgb.contentText,
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.regular,
  },
  productImagePreview: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    cursor: 'pointer',
  },
  productImage: {
    width: 148,
    height: 148,
    objectFit: 'cover',
    borderRadius: 5,
    border: `2px solid ${Colors.rgb.contentLineHighlighted}`,
    boxSizing: 'border-box',
  },
  productImagePlaceholder: {
    width: 148,
    height: 148,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px dashed ${Colors.rgb.primary}`,
    borderRadius: 5,
  },
  contentActionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing(4),

    '& > button:last-child': {
      marginRight: 0,
    },
  },
  contentActionSeparator: {
    width: theme.spacing(3),
  },

  //

  table: {},
  tableHeaderCell: {
    fontFamily: theme.fontFamily.bold,
  },
}));

export default makeClasses;
