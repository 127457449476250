import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Language } from '../../utils';
import { Page, Button, Confirm } from '../../components';
import makeClasses from './styles';
import appLogo from '../../static/images/logo.svg';
import HelpIcon from '../../static/images/icons/help.svg';
import clsx from 'clsx';

const CentralRegister = ({ history }) => {
  const classes = makeClasses();

  const { languageTexts } = useSelector((state) => ({
    loading: state.user.loading,
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const [showHelp, setShowHelp] = useState(false);
  const [selectedRegisterOption, setSelectedRegisterOption] = useState('primary');

  const toggleShowHelp = () => {
    setShowHelp(!showHelp);
  };

  const handleNext = () => {
    if (selectedRegisterOption === 'primary') {
      history.push('/registro-empresa');
    } else {
      history.push('/registro-persona');
    }
  };

  return (
    <Page
      withHeader={false}
      withSideMenu={false}
      withBanner={false}
      withBoxBanner={false}
      showBranchWithNoPaymentMethodWarning={false}
    >
      <div className={classes.container}>
        <div className={classes.contentWrapperBorder}>
          <div className={classes.contentWrapper}>
            <div className={classes.brandContainer}>
              <img src={appLogo} className={classes.brandLogo} alt="Handy Logo" />
              <div className={classes.brandSeparator} />
              <div className={classes.brandTitle}>{i18n.get('Register.PageTitle')}</div>
            </div>
            <div className={classes.registerOptionsContainer}>
              <div
                className={clsx(
                  classes.registerOption,
                  selectedRegisterOption === 'primary' ? classes.registerOptionSelected : null
                )}
                onClick={() => setSelectedRegisterOption('primary')}
              >
                <div
                  className={clsx(
                    classes.registerOptionCheckbox,
                    selectedRegisterOption === 'primary' ? classes.registerOptionCheckboxSelected : null
                  )}
                >
                  {selectedRegisterOption === 'primary' ? (
                    <div className={classes.registerOptionCheckboxSelectedIndicator} />
                  ) : null}
                </div>
                <div className={classes.registerOptionTitle}>{i18n.get('Register.CompanyBoxRootTitle')}</div>
                <div className={classes.registerOptionText}>{i18n.get('Register.CompanyBoxRootText')}</div>
              </div>
              <div
                className={clsx(
                  classes.registerOption,
                  selectedRegisterOption === 'secondary' ? classes.registerOptionSelected : null
                )}
                onClick={() => setSelectedRegisterOption('secondary')}
              >
                <div
                  className={clsx(
                    classes.registerOptionCheckbox,
                    selectedRegisterOption === 'secondary' ? classes.registerOptionCheckboxSelected : null
                  )}
                >
                  {selectedRegisterOption === 'secondary' ? (
                    <div className={classes.registerOptionCheckboxSelectedIndicator} />
                  ) : null}
                </div>
                <div className={classes.registerOptionTitle}>{i18n.get('Register.UserBoxRootTitle')}</div>
                <div className={classes.registerOptionText}>{i18n.get('Register.UserBoxRootText')}</div>
              </div>
            </div>
            <div className={clsx(classes.formSubmitContainer, classes.formSubmitContainerWithOneMiniButton)}>
              <Button className={classes.formSubmitButton} onClick={handleNext}>
                {i18n.get('LegalEntityRegistration.NextButton')}
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.helpContainer} onClick={toggleShowHelp}>
          {i18n.get('Login.UserHelpTitle')}
          <img src={HelpIcon} className={classes.helpIcon} alt="ayuda" />
        </div>
      </div>
      <Confirm
        open={showHelp}
        onClose={toggleShowHelp}
        title={i18n.get('Login.UserHelpDialogTitle')}
        text={i18n.get('Login.UserHelpDialogText')}
        confirmText={i18n.get('Login.UserHelpDialogButton')}
        onConfirm={toggleShowHelp}
      />
    </Page>
  );
};

CentralRegister.id = 'com.Handy.CentralRegister';

export default CentralRegister;
