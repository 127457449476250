import React, { useState } from 'react';
import { Colors } from '../../utils';
import makeClasses from './styles';
import Box from '../Box';

import Popover from '@material-ui/core/Popover';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import clsx from 'clsx';

const TitleContentBox = ({
  title,
  titleBold,
  titleSemibold,
  titleLight,
  popover,
  popoverMessage,
  children,
  onClick,
  customRender,
}) => {
  const [ anchorEl, setAnchorEl ] = useState(null);
  const open = Boolean(anchorEl);
  const classes = makeClasses();

  const getTitleClasses = () => {
    let titleClasses = classes.title;

    if (titleBold) {
      titleClasses = clsx(titleClasses, classes.titleBold);
    } else if (titleSemibold) {
      titleClasses = clsx(titleClasses, classes.titleSemibold);
    } else if (titleLight) {
      titleClasses = clsx(titleClasses, classes.titleLight);
    }

    if (popover) {
      titleClasses = clsx(titleClasses, classes.titleWithPopover);
    }

    return titleClasses;
  };

  const togglePopover = (useEvent = true) => event => {
    if (useEvent) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!anchorEl && useEvent) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  return (
    <React.Fragment>
      <Box left={false} direction="column" align="start" onClick={onClick}>
        <div className={classes.titleWrapper}>
          <Box background={Colors.rgb.black_light} top={false} bottom={false}>
            {popover ? (
              <ClickAwayListener onClickAway={togglePopover(false)}>
                <div className={clsx(getTitleClasses())} onClick={togglePopover()}>
                  <span>{title}</span> <HelpOutlineIcon fontSize="small" />
                </div>
              </ClickAwayListener>
            ) : (
              <div className={getTitleClasses()}>{title}</div>
            )}
          </Box>
          {customRender ? customRender() : null}
        </div>
        <div className={classes.content}>{children}</div>
      </Box>
      {popover && popoverMessage ? (
        <Popover
          className={classes.popover}
          classes={{
            paper: classes.popoverPaper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={togglePopover}
          disableRestoreFocus
        >
          <span className={classes.popoverText}>{popoverMessage}</span>
        </Popover>
      ) : null}
    </React.Fragment>
  );
};

export default TitleContentBox;
