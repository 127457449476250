export const INITIALIZE = 'com.Handy.business.INITIALIZE';
export const SET_LOADING = 'com.Handy.business.SET_LOADING';
export const CLEAR = 'com.Handy.business.CLEAR';
export const GET_BUSINESS_DATA = 'com.Handy.business.GET_BUSINESS_DATA';
export const SET_BUSINESS_DATA = 'com.Handy.business.SET_BUSINESS_DATA';
export const GET_SUMMARY = 'com.Handy.business.GET_SUMMARY';
export const SET_SUMMARY = 'com.Handy.business.SET_SUMMARY';
export const GET_BRANCHES = 'com.Handy.business.GET_BRANCHES';
export const SET_BRANCHES = 'com.Handy.business.SET_BRANCHES';
export const GET_BRANCH_ADDRESS = 'com.Handy.business.GET_BRANCH_ADDRESS';
export const GET_BRANCHES_WITH_NO_PAYMENT_METHOD = 'com.Handy.business.GET_BRANCHES_WITH_NO_PAYMENT_METHOD';
export const SET_BRANCHES_WITH_NO_PAYMENT_METHOD = 'com.Handy.business.SET_BRANCHES_WITH_NO_PAYMENT_METHOD';
export const GET_PAYMENT_SCHEDULE = 'com.Handy.business.GET_PAYMENT_SCHEDULE';
export const GET_PAYMENT_DETAILS_BY_DAY = 'com.Handy.business.GET_PAYMENT_DETAILS_BY_DAY';
export const SET_PAYMENT_SCHEDULE = 'com.Handy.business.SET_PAYMENT_SCHEDULE';
export const GET_OPERATION_DETAILS = 'com.Handy.business.GET_OPERATION_DETAILS';
export const GET_ACTIVITY = 'com.Handy.business.GET_ACTIVITY';
export const SET_ACTIVITY = 'com.Handy.business.SET_ACTIVITY';
export const GET_REVERSALS = 'com.Handy.business.GET_REVERSALS';
export const SET_REVERSALS = 'com.Handy.business.SET_REVERSALS';
export const SET_PAYMENT_PLAN = 'com.Handy.business.SET_PAYMENT_PLAN';
export const GET_VOUCHERS = 'com.Handy.business.GET_VOUCHERS';
export const GET_HISTORY = 'com.Handy.business.GET_HISTORY';
export const SET_HISTORY = 'com.Handy.business.SET_HISTORY';
export const GET_LOANS = 'com.Handy.business.GET_LOANS';
export const SET_LOANS = 'com.Handy.business.SET_LOANS';
export const CHECK_LOANS_AVAILABILITY = 'com.Handy.business.CHECK_LOANS_AVAILABILITY';
export const SET_LOAN_AVAILABILITY = 'com.Handy.business.SET_LOAN_AVAILABILITY';
export const GET_LOAN_LINK = 'com.Handy.business.GET_LOAN_LINK';
export const EXPORT_TABLE = 'com.Handy.business.EXPORT_TABLE';
export const GET_PAYMENT_LINKS = 'com.Handy.business.GET_PAYMENT_LINKS';
export const SET_PAYMENT_LINKS = 'com.Handy.business.SET_PAYMENT_LINKS';
export const CREATE_PAYMENT_LINK = 'com.Handy.business.CREATE_PAYMENT_LINK';
export const DELETE_PAYMENT_LINK = 'com.Handy.business.DELETE_PAYMENT_LINK';
export const GET_PAYMENT_LINK = 'com.Handy.business.GET_PAYMENT_LINK';
export const GET_PAYMENT_LINK_TRANSACTIONS = 'com.Handy.business.GET_PAYMENT_LINK_TRANSACTIONS';
export const GET_PAYMENT_LINK_RESTRICTIONS = 'com.Handy.business.GET_PAYMENT_LINK_RESTRICTIONS';
export const EXPORT_PAYMENT_LINKS = 'com.Handy.business.EXPORT_PAYMENT_LINKS';
export const GET_NOTIFICATIONS = 'com.Handy.business.GET_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'com.Handy.business.SET_NOTIFICATIONS';
export const DELETE_NOTIFICATION = 'com.Handy.business.DELETE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'com.Handy.business.REMOVE_NOTIFICATION';
export const GET_SECRETS = 'com.Handy.business.GET_SECRETS';
export const GET_BRANCH_SECRET = 'com.Handy.business.GET_BRANCH_SECRET';
export const CHECK_FOR_ACTIVE_SERVICES = 'com.Handy.business.CHECK_FOR_ACTIVE_SERVICES';
export const GET_MOBILE_BALANCE_STATUS = 'com.Handy.business.GET_MOBILE_BALANCE_STATUS';
export const GET_MOBILE_BALANCE = 'com.Handy.business.GET_MOBILE_BALANCE';
export const GET_MOBILE_BALANCE_ACTIVE_POS = 'com.Handy.business.GET_MOBILE_BALANCE_ACTIVE_POS';
export const GET_MOBILE_BALANCE_HISTORY = 'com.Handy.business.GET_MOBILE_BALANCE_HISTORY';
export const GET_ACCOUNTS = 'com.Handy.business.GET_ACCOUNTS';
export const GET_ACCOUNT_DETAILS = 'com.Handy.business.GET_ACCOUNT_DETAILS';
export const GET_POS_ALIASES = 'com.Handy.business.GET_POS_ALIASES';
export const GET_PHONE_COMPANIES = 'com.Handy.business.GET_PHONE_COMPANIES';
export const GET_ITAU_BRANCHES = 'com.Handy.business.GET_ITAU_BRANCHES';
export const GET_BRANCH_ISSUERS = 'com.Handy.business.GET_BRANCH_ISSUERS';
export const GET_ITAU_SALES = 'com.Handy.business.GET_ITAU_SALES';
export const MAKE_ITAU_SALE = 'com.Handy.business.MAKE_ITAU_SALE';
export const GET_TRANSACTIONS_FOR_HOME = 'com.Handy.business.GET_TRANSACTIONS_FOR_HOME';
export const GET_HISTORY_FOR_HOME = 'com.Handy.business.GET_HISTORY_FOR_HOME';
export const GET_BALANCES_FOR_HOME = 'com.Handy.business.GET_BALANCES_FOR_HOME';
export const GET_PROVIDER_STATUS = 'com.Handy.business.GET_PROVIDER_STATUS';
export const GET_PROVIDERS = 'com.Handy.business.GET_PROVIDERS';
export const GET_SERVICES = 'com.Handy.business.GET_SERVICES';
export const ADD_SUBSCRIPTION = 'com.Handy.business.ADD_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = 'com.Handy.business.UPDATE_SUBSCRIPTION';
export const GET_SUBSCRIPTIONS = 'com.Handy.business.GET_SUBSCRIPTIONS';
export const GET_SUBSCRIPTION = 'com.Handy.business.GET_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION = 'com.Handy.business.CANCEL_SUBSCRIPTION';
export const GET_PENDING_PAYMENTS = 'com.Handy.business.GET_PENDING_PAYMENTS';
export const GET_PAYMENTS_HISTORY = 'com.Handy.business.GET_PAYMENTS_HISTORY';
export const SUBSCRIPTION_APPROVE_PAYMENT = 'com.Handy.business.SUBSCRIPTION_APPROVE_PAYMENT';
export const SUBSCRIPTION_REJECT_PAYMENT = 'com.Handy.business.SUBSCRIPTION_REJECT_PAYMENT';
export const GET_SUBSCRIPTION_PAYMENT = 'com.Handy.business.GET_SUBSCRIPTION_PAYMENT';
export const GET_PRODUCTS = 'com.Handy.business.GET_PRODUCTS';
export const GET_PRODUCT = 'com.Handy.business.GET_PRODUCT';
export const UPDATE_PRODUCT = 'com.Handy.business.UPDATE_PRODUCT';
export const GET_SUBSCRIBERS = 'com.Handy.business.GET_SUBSCRIBERS';
export const GET_SUBSCRIBER = 'com.Handy.business.GET_SUBSCRIBER';
export const GET_PENDING_CHARGES = 'com.Handy.business.GET_PENDING_CHARGES';
export const GET_CHARGES_HISTORY = 'com.Handy.business.GET_CHARGES_HISTORY';
export const ADD_CHARGE = 'com.Handy.business.ADD_CHARGE';
export const GET_CHARGE = 'com.Handy.business.GET_CHARGE';
export const CANCEL_CHARGE = 'com.Handy.business.CANCEL_CHARGE';
export const RETRY_CHARGE = 'com.Handy.business.RETRY_CHARGE'
export const GET_PROVIDERS_SUBSCRIPTIONS_MERCHANT = 'com.Handy.business.GET_PROVIDERS_SUBSCRIPTIONS_MERCHANT';
export const GET_SUBSCRIPTION_PROVIDERS = 'com.Handy.business.GET_SUBSCRIPTION_PROVIDERS';
export const GET_SUBSCRIPTION_SERVICES = 'com.Handy.business.GET_SUBSCRIPTION_SERVICES';
export const GET_PROVIDER_SERVICES = 'com.Handy.business.GET_PROVIDER_SERVICES';
export const GET_PROVIDERS_SUBSCRIPTIONS_PLANS = 'com.Handy.business.GET_PROVIDERS_SUBSCRIPTIONS_PLANS';
export const TOGGLE_PROVIDERS_SUBSCRIPTIONS_PLAN_STATUS =
  'com.Handy.business.TOGGLE_PROVIDERS_SUBSCRIPTIONS_PLAN_STATUS';
export const GET_PROVIDERS_SUBSCRIPTIONS_PLAN_SHARE_LINK =
  'com.Handy.business.GET_PROVIDERS_SUBSCRIPTIONS_PLAN_SHARE_LINK';
export const PROVIDERS_SUBSCRIPTIONS_ADD_PLAN = 'com.Handy.business.PROVIDERS_SUBSCRIPTIONS_ADD_PLAN';
export const PROVIDERS_SUBSCRIPTIONS_UPDATE_PLAN = 'com.Handy.business.PROVIDERS_SUBSCRIPTIONS_UPDATE_PLAN';
export const GET_PROVIDERS_SUBSCRIPTIONS_PLAN = 'com.Handy.business.GET_PROVIDERS_SUBSCRIPTIONS_PLAN';
export const GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS = 'com.Handy.business.GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS';
export const GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIBERS = 'com.Handy.business.GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIBERS';
export const UPDATE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTION_STATUS =
  'com.Handy.business.UPDATE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTION_STATUS';
export const GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTION = 'com.Handy.business.GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTION';
export const GET_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS = 'com.Handy.business.GET_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS';
export const UPDATE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIBER = 'com.Handy.business.UPDATE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIBER';
export const GET_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS = 'com.Handy.business.GET_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS';
export const UPDATE_PROVIDERS_SUBSCRIPTIONS_PROMOTION = 'com.Handy.business.UPDATE_PROVIDERS_SUBSCRIPTIONS_PROMOTION';
export const GET_PROVIDERS_SUBSCRIPTIONS_PROMOTION = 'com.Handy.business.GET_PROVIDERS_SUBSCRIPTIONS_PROMOTION';
export const ADD_PROVIDERS_SUBSCRIPTIONS_PROMOTION = 'com.Handy.business.ADD_PROVIDERS_SUBSCRIPTIONS_PROMOTION';
export const GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTION_SHARE =
  'com.Handy.business.GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTION_SHARE';
export const GET_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS_LINK =
  'com.Handy.business.GET_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS_LINK';
export const PROVIDERS_SUBSCRIPTIONS_ADD_TERMS_AND_CONDITIONS =
  'com.Handy.business.PROVIDERS_SUBSCRIPTIONS_ADD_TERMS_AND_CONDITIONS';
export const EXPORT_SUBSCRIPTIONS = 'com.Handy.business.EXPORT_SUBSCRIPTIONS';
export const EXPORT_SUBSCRIPTION_PAYMENTS = 'com.Handy.business.EXPORT_SUBSCRIPTION_PAYMENTS';
export const EXPORT_PROVIDER_SERVICES = 'com.Handy.business.EXPORT_PROVIDER_SERVICES';
export const EXPORT_PROVIDER_SUBSCRIBERS = 'com.Handy.business.EXPORT_PROVIDER_SUBSCRIBERS';
export const EXPORT_PROVIDER_CHARGES = 'com.Handy.business.EXPORT_PROVIDER_CHARGES';
export const EXPORT_PROVIDERS_SUBSCRIPTIONS_PLANS = 'com.Handy.business.EXPORT_PROVIDERS_SUBSCRIPTIONS_PLANS';
export const EXPORT_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS =
  'com.Handy.business.EXPORT_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS';
export const EXPORT_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS =
  'com.Handy.business.EXPORT_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS';
export const EXPORT_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS = 'com.Handy.business.EXPORT_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS';
