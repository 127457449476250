import { makeStyles } from '@material-ui/core';
import { THEME_LAYOUT } from '../../theme';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  //
  // Content
  boxContentWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.M,
    color: Colors.rgb.contentText,
  },
  boxContentWrapperMain: {
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentTextHighlighted,
  },
  boxContent: {
    width: '100%',
    padding: '0 !important',

    '& *': {
      fontFamily: theme.fontFamily.regular,
      fontSize: theme.fontSize.M,
      color: Colors.rgb.contentText,
      margin: 0,
    },
  },
  boxContentItem: {
    padding: `${theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.M)} ${theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.L)}`,
  },
  boxAccordion: {
    boxShadow: 'none',
    margin: '0 !important',

    '&:before': {
      content: 'none',
    },
  },
  boxAccordionSummary: {
    minHeight: `51px !important`,
    height: `51px !important`,
    boxSizing: 'border-box',
    padding: `${theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.M)} ${theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.L)}`,
    background: Colors.rgb.grey,

    '& > div': {
      justifyContent: 'space-between',
      margin: '0 !important',

      '&:nth-child(2)': {
        position: 'absolute',
        marginRight: '40px !important',
        marginTop: `${theme.spacing(1)} !important`,

        [theme.breakpoints.down('sm')]: {
          right: 5,
          top: 5,
          marginRight: '0 !important',
          marginTop: `0 !important`,
        },
      },

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        fontFamily: theme.fontFamily.bold,

        '&:nth-child(1)': {
          '& > div': {
            '&:nth-child(1)': {
              textAlign: 'left',
            },
            '&:nth-child(2)': {
              textAlign: 'right',
              paddingTop: theme.spacing(2),
            },
          },
        },
      },
    },

    [theme.breakpoints.down('sm')]: {
      height: 'auto !important',
    },
  },
  boxAccordionLines: {
    flexDirection: 'column',
    background: Colors.rgb.grey,
    padding: 0,
  },
  boxAccordionLinesWrapper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    background: Colors.rgb.white,

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  boxBrandImage: {
    display: 'flex',

    '& > img': {
      height: 30,
    },
  },
  summaryItem: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  summaryItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,

    '& > div': {
      '&:nth-child(1)': {
        textAlign: 'left',
      },
      '&:nth-child(2)': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: 'right',
      },
    },
  },
  summaryItemNoBorder: {
    borderBottom: 'none',
  },
}));

export default makeClasses;
