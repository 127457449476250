import * as types from './Common.actionTypes';

export const initialize = (callback) => {
	return {
		type: types.INITIALIZE,
		callback
	};
};

export const setLoading = (value) => {
	return {
		type: types.SET_LOADING,
		value
	};
};

export const setSideMenuOpen = (value) => {
	return {
		type: types.SET_SIDEMENU_OPEN,
		value
	};
};

export const clear = () => {
	return {
		type: types.CLEAR
	};
};

export const checkIsMobile = () => {
	return {
		type: types.CHECK_IS_MOBILE
	};
};

export const checkLocale = () => {
	return {
		type: types.CHECK_LOCALE
	};
};
