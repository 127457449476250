import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
	container: {
		overflow: 'hidden'
	},
	header: {
		fontSize: '1rem',
		fontWeight: '500',
		background: Colors.rgba(Colors.rgb.black, 5)
	},
	rowInfo: {
		fontSize: '1rem'
	},
	rowTotals: {
		fontWeight: 'bold',
		fontSize: '1rem'
	},
	accordionTableCellWrapper: {
		padding: 0
	},
	accordion: {
		margin: 0,
		borderRadius: 0,
		boxShadow: 'none'
	},
	accordionSummary: {
		padding: 0,
		minHeight: 'unset !important',

		'& > div': {
			justifyContent: 'space-between',
			margin: '0 !important'
		},

		'& svg': {
			position: 'absolute',
			color: Colors.rgba(Colors.rgb.black, 40),
			marginLeft: 3,
			transform: 'rotateZ(180deg)'
		},

		'&:not(.Mui-expanded)': {
			'& table > tbody > tr td': {
				border: 'none'
			},

			'& svg': {
				transform: 'rotateZ(0deg)'
			}
		}
	},
	accordionDetails: {
		padding: 0,

		'& > table > tbody > tr': {
			'& > td:first-child': {
				paddingLeft: 40
			},

			'&:last-child td': {
				border: 'none'
			}
		}
	}
}));

export default makeClasses;
