import { API } from '../utils';
import { API_PATHS } from '../utils/Api';

export const postMerchantData = async (data) => {
    const response = await API.put(API_PATHS().MERCHANTS().CRM.MERCHANT_AFFILIATION_DATA(data.traceId), data.merchantData, true);
    return response;
};

export const postMerchantFile = async (fileData) => {
    const response = await API.post(API_PATHS(`type=${fileData.type}`).MERCHANTS().CRM.MERCHANT_AFFILIATION_FILE(fileData.traceId),
        { }, true, { file: fileData.file });
    return response;
};

export const confirmMerchantData = async (traceId) => {
    const response = await API.put(API_PATHS().MERCHANTS().CRM.MERCHANT_AFFILIATION_DATA_CONFIRM(traceId), {}, true);
    return response;
};


export const postRepresentativeData = async (data) => {
    const response = await API.put(API_PATHS().MERCHANTS().CRM.MERCHANT_REPRESENTATIVE_DATA(data.traceId), data.representativeData, true);
    return response;
};

export const confirmRepresentativeData = async (traceId) => {
    const response = await API.put(API_PATHS().MERCHANTS().CRM.MERCHANT_REPRESENTATIVE_DATA_CONFIRM(traceId), {}, true);
    return response;
};