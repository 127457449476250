import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  alert: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(2),

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  alertWithNoIcon: {
    padding: `${theme.spacing(2)} ${theme.spacing(4)} !important`,
  },
  alertTitle: {
    fontFamily: theme.fontFamily.regular,

    '& strong': {
      fontFamily: theme.fontFamily.bold,
    },
  },
  alertMessage: {
    marginBottom: 7,
  },
  alertButton: {
    display: 'inline-block',
    border: 'none',
    borderRadius: 5,
    padding: `7px ${theme.spacing(2)}`,
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
  },
  alertButtonInfo: {
    background: '#055160',
    borderColor: '#055160',
    color: Colors.rgb.white,

    '&:hover': {
      background: Colors.rgba('#055160', 90),
    },
  },
  alertIcon: {
    fontSize: 30,
    padding: 5,
    marginRight: theme.spacing(1),
  },
  alertSuccess: {
    background: Colors.rgb.alertSuccessBackground,
    border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
    color: `${Colors.rgb.alertSuccessText} !important`,
  },
  alertWarning: {
    background: Colors.rgb.alertWarningBackground,
    border: `1px solid ${Colors.rgb.alertWarningBorder}`,
    color: `${Colors.rgb.alertWarningText} !important`,
  },
  alertError: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
  alertInfo: {
    background: '#cff4fd',
    border: `1px solid #b8effb`,
    color: `#055160 !important`,
  },
}));

export default makeClasses;
