import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push, replace } from "connected-react-router";
import qs from "query-string";

import ROUTES from "../../../routes";
import {
  BoxMenu,
  BoxSeparator,
  BranchesSelector,
  Button,
  Confirm,
  ContentBox,
  EmptyMessage,
  FiltersContainer,
  FiltersSelector,
  FiltersSeparator,
  FiltersText,
  ForbiddenSection,
  OperationsTable,
  Page,
  ShareModal,
  TitleBox,
} from "../../../components";
import { business as businessActions } from "../../../actions";
import { Language, Utils } from "../../../utils";

import ListItemText from "@material-ui/core/ListItemText";
import {
  Add as AddIcon,
  Visibility as VisisbilityIcon,
  Close as InactiveIcon,
  Check as ActiveIcon,
  Edit as EditIcon,
  Share as ShareIcon,
} from "@material-ui/icons";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import MoreVerticalIcon from "../../../static/images/icons/more_vertical.svg";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

import makeClasses from "./styles";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Policies } from "../../../utils/Policies";
import moment from "moment";
import clsx from "clsx";
import { Alert, AlertTitle } from "@material-ui/lab";
import { toast } from "react-toastify";

const STATUS_FILTER_OPTIONS = [
  { id: "ACTIVE", name: "ProvidersSubscriptionsManagePlans.ValueStatusActive" },
  {
    id: "INACTIVE",
    name: "ProvidersSubscriptionsManagePlans.ValueStatusInactive",
  },
];

const CURRENCY_FILTER_OPTIONS = [
  { id: "URUGUAYAN_PESO", name: "Currency.UYU" },
  { id: "DOLLAR", name: "Currency.USD" },
];

const FREQUENCE_FILTER_OPTIONS = [
  { id: "HOURLY", name: "Frequency.HOURLY" },
  { id: "DAILY", name: "Frequency.DAILY" },
  { id: "MONTHLY", name: "Frequency.MONTHLY" },
  { id: "ANNUAL", name: "Frequency.ANNUAL" },
  { id: "WEEKLY", name: "Frequency.WEEKLY" },
];

const ProvidersSubscriptionsManagePlansScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const searchParams = qs.parse(history.location.search);

  const [plans, setPlans] = useState({});

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [paginationPage, setPaginationPage] = useState(
    searchParams.page ? parseInt(searchParams.page) : 1
  );
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(
    searchParams.bid || "all"
  );
  const [selectedCurrency, setSelectedCurrency] = useState(
    searchParams.cid || "all"
  );
  const [selectedStatus, setSelectedStatus] = useState(
    searchParams.tid ? searchParams.tid : "all"
  );
  const [selectedPlanName, setSelectedPlanName] = useState(
    searchParams.u ? searchParams.u : ""
  );
  const [showTogglePlanStatusDialog, setShowTogglePlanStatusDialog] =
    useState(false);
  const [planToToggleStatus, setPlanToToggleStatus] = useState(null);
  const [shareModalData, setShareModalData] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [branches, setBranches] = useState([]);

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const hasPlans = plans && plans.elements && plans.elements.length > 0;
  const canShare = policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_SHARE_PLAN);
  const canExport = policies.includes(Policies.types.SUPER_READ);
  const canManage = policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_PLANS_WRITE);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (policies && policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_PLANS)) {
      loadPlans();
    } else {
      setForbbidenSection(true);
    }
  }, [
    policies,
    paginationPage,
    selectedBranch,
    selectedCurrency,
    selectedStatus,
    selectedPlanName,
  ]);


  const loadPlans = () => {
    dispatch(
      businessActions.getProvidersSubscriptionsPlans(
        selectedBranch !== "all" ? selectedBranch : null,
        selectedStatus !== "all" ? selectedStatus : null,
        null,
        selectedCurrency !== "all" ? selectedCurrency : null,
        selectedPlanName,
        15,
        paginationPage,
        (data) => {
          dispatch(businessActions.getProvidersSubscriptionsMerchant((branches) => {
            setPlans(data);
            setBranches(branches.branches?.filter(b => b.serviceStatus === 'ACTIVE')?.map(b => ({ traceId: b.branchId, name: b.name })) || []);
          }));
        }
      )
    );
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const toggleShareModal = () => {
    if (showShareModal) {
      setShowShareModal(false);
      setShareModalData(null);
    } else {
      setShowShareModal(true);
    }
  };

  const handleOnViewPlan = (plan) => () => {
    toggleMenu()();
    dispatch(
      push(
        ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLAN_VIEW.path.replace(
          ":traceId",
          plan.subscriptionPlanTraceId
        )
      )
    );
  };

  const handleOnEditPlan = (plan) => () => {
    toggleMenu()();
    dispatch(
      push(
        ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLAN_EDIT.path.replace(
          ":traceId",
          plan.subscriptionPlanTraceId
        )
      )
    );
  };

  const handleOnSharePlan = (plan) => () => {
    toggleMenu()();
    dispatch(
      businessActions.getProvidersSubscriptionsPlanShareLink(
        plan.subscriptionPlanTraceId,
        (link) => {
          if (link) {
            setShareModalData(link?.link);
            toggleShareModal();
          }
        }
      )
    );
  };

  const handleOnPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const renderPlans = () => {
    if (!plans || !plans.elements || plans.elements.length === 0) {
      return (
        <EmptyMessage textOnly>
          {i18n.get("ProvidersSubscriptionsManagePlans.GridEmptyMessage")}
        </EmptyMessage>
      );
    }

    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get("ProvidersSubscriptionsManagePlans.PlansGrid.Column1"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get("ProvidersSubscriptionsManagePlans.PlansGrid.Column2"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get("ProvidersSubscriptionsManagePlans.PlansGrid.Column3"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get("ProvidersSubscriptionsManagePlans.PlansGrid.Column4"),
        sortable: false,
        type: "status",
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get("ProvidersSubscriptionsManagePlans.PlansGrid.Column5"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 6,
        name: i18n.get("ProvidersSubscriptionsManagePlans.PlansGrid.Column6"),
        sortable: false,
        type: "custom",
        align:'right',
        show: isSm,
      },
    ];
    const body = (plans.elements || []).map((item, index) => ({
      rowId: item.traceId,
      columns: [
        {
          columnNumber: 1,
          data: item.code ? `${item.name} - ${item.code}` : item.name,
        },
        {
          columnNumber: 2,
          data: i18n.get(
            CURRENCY_FILTER_OPTIONS.find((c) => c.id === item.currency)?.name
          ),
        },
        {
          columnNumber: 3,
          data: i18n.get(
            FREQUENCE_FILTER_OPTIONS.find((c) => c.id === item.frequence)
              ?.name || item.frequence
          ),
        },
        {
          columnNumber: 4,
          data: item.subscriptionPlanStatus,
        },
        {
          columnNumber: 5,
          data: item.branch.name,
          
        },
        {
          columnNumber: 6,
          data: () => {
            return (
              <div className={classes.listItemActions}>
                <IconButton onClick={toggleMenu(index)}>
                  <img src={MoreVerticalIcon} alt="More" />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  onClose={toggleMenu()}
                  open={menuOpenIndex === index}
                  className={classes.listItemMenu}
                >
                  <MenuItem
                    onClick={handleOnViewPlan(item)}
                    className={classes.listItemMenuItem}
                  >
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get(
                        "ProvidersSubscriptionsManagePlans.ActionViewDetails"
                      )}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={handleOnEditPlan(item)}
                    className={classes.listItemMenuItem}
                  >
                    <ListItemIcon className={classes.menuItemIcon}>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get(
                        "ProvidersSubscriptionsManagePlans.ActionEdit"
                      )}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  {item.subscriptionPlanStatus !== 'INACTIVE' && canShare ? <MenuItem
                    onClick={handleOnSharePlan(item)}
                    className={classes.listItemMenuItem}
                  >
                    <ListItemIcon className={classes.menuItemIcon}>
                      <ShareIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get(
                        "ProvidersSubscriptionsManagePlans.ActionShare"
                      )}
                      className={classes.menuItemText}
                    />
                  </MenuItem> : null}
                  {canManage ? (
                    <MenuItem
                      onClick={handleOnTogglePlanStatus(item)}
                      className={classes.listItemMenuItem}
                    >
                      <ListItemIcon className={classes.menuItemIcon}>
                        {item.subscriptionPlanStatus === "ACTIVE" ? (
                          <InactiveIcon fontSize="small" />
                        ) : (
                          <ActiveIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          item.subscriptionPlanStatus === "ACTIVE"
                            ? i18n.get(
                                "ProvidersSubscriptionsManagePlans.ActionToggleStatusInactive"
                              )
                            : i18n.get(
                                "ProvidersSubscriptionsManagePlans.ActionToggleStatusActive"
                              )
                        }
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                </Menu>
              </div>
            );
          },
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={plans.hasNext}
        onPaginationChange={handleOnPaginationChange}
        emptyMessage={i18n.get(
          "ProvidersSubscriptionsManagePlansView.GridEmptyMessage"
        )}
        showDetails={false}
      />
    );
  };

  const handleOnExport = () => {
    dispatch(
      businessActions.exportProvidersSubscriptionsPlans(
        selectedBranch !== "all" ? selectedBranch : null,
        selectedStatus !== "all" ? selectedStatus : null,
        null,
        selectedCurrency !== "all" ? selectedCurrency : null,
        selectedPlanName,
        paginationPage,
        (file) =>
          Utils.downloadFile(
            file,
            "Cobranzas_Suscripciones_Planes_" +
              moment().format(i18n.get("ExportDateFormat")) +
              ".xlsx"
          )
      )
    );
  };

  const handleOnBranchSelected = (branch) => {
    setSelectedBranch(branch);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.bid = branch;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnCurrencySelected = (currency) => {
    setSelectedCurrency(currency);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.cid = currency;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnStatusSelected = (status) => {
    setSelectedStatus(status);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.tid = status;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnChangePlanName = (user) => {
    setSelectedPlanName(user);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.u = user;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnMenuItemClick = (item) => {
    dispatch(push(item.path));
  };

  const handleOnClickAddNewPlan = () => {
    dispatch(push(ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS_ADD.path));
  };

  const handleOnTogglePlanStatus = (plan) => () => {
    toggleMenu()();
    toggleTogglePlanStatusDialog();
    setPlanToToggleStatus(plan);
  };

  const toggleTogglePlanStatusDialog = () => {
    setShowTogglePlanStatusDialog(!showTogglePlanStatusDialog);
  };

  const confirmTogglePlanStatus = () => {
    toggleTogglePlanStatusDialog();
    const newPlanStatus =
      planToToggleStatus.subscriptionPlanStatus === "ACTIVE"
        ? "INACTIVE"
        : "ACTIVE";
    dispatch(
      businessActions.toggleProvidersSubscriptionsPlanStatus(
        planToToggleStatus.subscriptionPlanTraceId,
        newPlanStatus,
        (result) => {
          if (result) {
            toast.success(
              newPlanStatus === "ACTIVE"
                ? i18n.get(
                    "ProvidersSubscriptionsManagePlans.SuccessTogglePlanStatusMessageActive"
                  )
                : i18n.get(
                    "ProvidersSubscriptionsManagePlans.SuccessTogglePlanStatusMessageInactive"
                  )
            );
            loadPlans();
          } else {
            toast.error(
              newPlanStatus === "ACTIVE"
                ? i18n.get(
                    "ProvidersSubscriptionsManagePlans.ErrorTogglePlanStatusMessageActive"
                  )
                : i18n.get(
                    "ProvidersSubscriptionsManagePlans.ErrorTogglePlanStatusMessageInactive"
                  )
            );
          }
        }
      )
    );
  };

  const renderBranchesSelector = () => (
    <BranchesSelector
      branches={branches}
      selectedBranch={selectedBranch}
      onBranchSelected={handleOnBranchSelected}
    />
  );

  const renderCurrencySelector = () => {
    return (
      <FiltersSelector
        options={CURRENCY_FILTER_OPTIONS.map((s) => ({
          ...s,
          name: i18n.get(s.name),
        }))}
        selected={selectedCurrency}
        onSelected={handleOnCurrencySelected}
        defaultOption={i18n.get(
          "ProvidersSubscriptionsManagePlans.FilterCurrencyDefaultOption"
        )}
      />
    );
  };

  const renderStatusesSelector = () => {
    return (
      <FiltersSelector
        options={STATUS_FILTER_OPTIONS.map((s) => ({
          ...s,
          name: i18n.get(s.name),
        }))}
        selected={selectedStatus}
        onSelected={handleOnStatusSelected}
        defaultOption={i18n.get(
          "ProvidersSubscriptionsManagePlans.FilterStatusesDefaultOption"
        )}
      />
    );
  };

  const renderPlanNameFilter = () => {
    return (
      <FiltersText
        value={selectedPlanName}
        placeholder={i18n.get(
          "ProvidersSubscriptionsManagePlans.FiltersPlanNamePlaceholder"
        )}
        onChange={handleOnChangePlanName}
      />
    );
  };

  const renderExportButton = () => {
    if (!canExport) {
      return null;
    }
    return (
      <Button
        rightIcon={() => <SaveAltIcon fontSize="small" />}
        onClick={handleOnExport}
        disabled={!plans || !plans.elements || plans.elements.length === 0}
      >
        {isSm
          ? null
          : i18n.get("ProvidersSubscriptionsManagePlans.ExportButton")}
      </Button>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get("ProvidersSubscriptionsManagePlans.Title")}
        withActivePage={ROUTES.MANAGE_PROVIDERS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get("ProvidersSubscriptionsManagePlans.Title")}
      withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
    >
      <BoxMenu
        items={[
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS,
            label: i18n.get("ProvidersSubscriptionsManagePlans.BoxMenu.Item1"),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS,
            label: i18n.get("ProvidersSubscriptionsManagePlans.BoxMenu.Item3"),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS,
            label: i18n.get("ProvidersSubscriptionsManagePlans.BoxMenu.Item2"),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS,
            label: i18n.get("ProvidersSubscriptionsManagePlans.BoxMenu.Item4"),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS,
            label: i18n.get("ProvidersSubscriptionsManagePlans.BoxMenu.Item5"),
          },
        ]}
        selectedItem={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS.id}
        onClickItem={handleOnMenuItemClick}
      />
      <TitleBox
        title={i18n.get("ProvidersSubscriptionsManagePlans.BoxTitle")}
        buttonRight={canManage}
        buttonRightIcon={() => <AddIcon fontSize="small" />}
        buttonRightText={
          isSm
            ? null
            : i18n.get("ProvidersSubscriptionsManagePlans.BoxTitleButtonText")
        }
        buttonRightOnClick={handleOnClickAddNewPlan}
      />
      <BoxSeparator size="small" />
      <ContentBox
        title={i18n.get("ProvidersSubscriptionsManagePlans.BoxFiltersTitle")}
      >
        <FiltersContainer>
          {renderBranchesSelector()}
          <FiltersSeparator />
          {renderPlanNameFilter()}
          <FiltersSeparator />
          {renderStatusesSelector()}
          <FiltersSeparator />
          {renderCurrencySelector()}
        </FiltersContainer>
      </ContentBox>
      <BoxSeparator size="small" />
      <ContentBox
        title={i18n.get(
          "ProvidersSubscriptionsManagePlans.SubscribersGridTitle"
        )}
        titleBold
        customRightAction={renderExportButton}
      >
        {hasPlans ? (
          renderPlans()
        ) : (
          <div className={classes.noDataMessage}>
            {i18n.get("ProvidersSubscriptionsManagePlans.NoSubscribersMessage")}
          </div>
        )}
      </ContentBox>
      <Confirm
        open={showTogglePlanStatusDialog}
        onClose={toggleTogglePlanStatusDialog}
        title={
          planToToggleStatus &&
          planToToggleStatus.subscriptionPlanStatus === "ACTIVE"
            ? i18n.get(
                "ProvidersSubscriptionsManagePlans.TogglePlanStatusDialogTitleDeactivate"
              )
            : i18n.get(
                "ProvidersSubscriptionsManagePlans.TogglePlanStatusDialogTitleActivate"
              )
        }
        confirmText={i18n.get(
          "ProvidersSubscriptionsManagePlans.TogglePlanStatusDialogActionConfirm"
        )}
        onConfirm={confirmTogglePlanStatus}
        cancelText={i18n.get(
          "ProvidersSubscriptionsManagePlans.TogglePlanStatusDialogActionCancel"
        )}
        onCancel={toggleTogglePlanStatusDialog}
      >
        <Alert
          severity="error"
          icon={false}
          className={clsx(classes.alert, classes.alertDanger)}
        >
          <div>
            <ErrorOutlineOutlinedIcon className={classes.alertIcon} />
          </div>
          <div>
            <AlertTitle className={classes.alertTitle}>
              {planToToggleStatus &&
              planToToggleStatus.subscriptionPlanStatus === "ACTIVE"
                ? i18n.get(
                    "ProvidersSubscriptionsManagePlans.TogglePlanStatusDialogContentTitleDeactivate"
                  )
                : i18n.get(
                    "ProvidersSubscriptionsManagePlans.TogglePlanStatusDialogContentTitleActivate"
                  )}
            </AlertTitle>
            {i18n.get(
              "ProvidersSubscriptionsManagePlans.TogglePlanStatusDialogContentText"
            )}
          </div>
        </Alert>
      </Confirm>
      <ShareModal
        open={showShareModal}
        link={shareModalData}
        onClose={toggleShareModal}
        headerTitle={i18n.get(
          "ProvidersSubscriptionsManagePlans.ShareModalHeaderTitle"
        )}
        bodyTitle={i18n.get(
          "ProvidersSubscriptionsManagePlans.ShareModalBodyTitle"
        )}
        shareText={i18n.get(
          "ProvidersSubscriptionsManagePlans.ShareModalShareText"
        )}
      />
    </Page>
  );
};

ProvidersSubscriptionsManagePlansScreen.id =
  "com.Handy.ProvidersSubscriptionsManagePlans";

export default ProvidersSubscriptionsManagePlansScreen;
