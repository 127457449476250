import { Drawer, IconButton } from '@material-ui/core';
import React from 'react';

import makeClasses from './styles';
import { useSelector } from 'react-redux';
import { Language } from '../../utils';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

const HelpDrawer = ({ children, open, onClose }) => {
  const classes = makeClasses();

  const { languageTexts } = useSelector(state => ({
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  return (
    <Drawer classes={{ paper: classes.container }} anchor={'right'} open={open} onClose={onClose}>
      <div className={classes.title}>
        {i18n.get('Components.HelpDrawer.title')}
        <IconButton className={classes.closeIcon} color="primary" component="span" onClick={onClose}>
          <CancelOutlinedIcon />
        </IconButton>
      </div>
      <div className={classes.content}>{children}</div>
    </Drawer>
  );
};

export default HelpDrawer;
