import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = ({
  top = true,
  right = true,
  bottom = true,
  left = true,
  background,
  direction,
  align,
  border,
  borderWidth,
  borderColor,
  isTitle = false,
  contentOnly = false,
  fullHeight = false,
  showShadow = true,
}) =>
  makeStyles((theme) => ({
    box: {
      maxWidth: '100%',
      boxSizing: 'border-box',
      position: 'relative',
      display: 'flex',
      flexDirection: direction || 'row',
      background: background || Colors.rgb.white,
      paddingTop: top ? theme.spacing(4) : null,
      paddingBottom: bottom ? theme.spacing(4) : null,
      paddingRight: right ? theme.spacing(4) : null,
      paddingLeft: left ? theme.spacing(4) : null,
      alignItems:
        align === 'start' ? 'flex-start' : align === 'end' ? 'flex-end' : align === 'center' ? 'center' : null,
      borderBottom: border ? `${borderWidth}px solid ${borderColor}` : null,
      height: fullHeight ? '100%' : null,
      boxShadow: showShadow ? '2px 2px 5px 0px rgba(0,0,0,0.1)' : null,

      [theme.breakpoints.down('sm')]: {
        paddingTop: top ? (isTitle ? theme.spacing(2) : contentOnly ? theme.spacing(2) : theme.spacing(4)) : null,
        paddingBottom: bottom ? (isTitle ? theme.spacing(2) : contentOnly ? theme.spacing(2) : theme.spacing(4)) : null,
        paddingRight: right ? (isTitle ? theme.spacing(2) : contentOnly ? theme.spacing(2) : theme.spacing(2)) : null,
        paddingLeft: left ? (isTitle ? theme.spacing(2) : contentOnly ? theme.spacing(2) : theme.spacing(2)) : null,
      },
    },
    boxClickable: {
      cursor: 'pointer',
    },
  }));

export default makeClasses;
