import React from 'react';
import makeClasses from './styles';
import Box from '../Box';
import clsx from 'clsx';
import { Button } from '..';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const ContentBox = ({
  title,
  titleBold = false,
  titleLight = false,
  titleSemibold = false,
  showTitle = true,
  iconLeft = null,
  iconRight = null,
  iconInline = false,
  button = false,
  buttonText = '',
  buttonOnClick,
  buttonRightIcon,
  buttonLeftIcon,
  accordion = false,
  customRightAction = null,
  children,
  onClick = null,
  background = null,
  color = null,
  fullHeightBox = false,
  alert = false,
  alertType = 'success',
  alertTitle = null,
  alertMessage = null,
  headerVertical = false,
  sm = false,
  titleWithLeftIndicator = false,
  padding = true,
  customClass = null,
  showShadow = true,
}) => {
  const classes = makeClasses();

  const renderTitle = () => {
    let titleClasses = classes.title;
    if (titleBold) {
      titleClasses = clsx(titleClasses, classes.titleBold);
    } else if (titleSemibold) {
      titleClasses = clsx(titleClasses, classes.titleSemibold);
    } else if (titleLight) {
      titleClasses = clsx(titleClasses, classes.titleLight);
    }
    return (
      <div className={clsx(titleClasses, titleWithLeftIndicator ? classes.titleMdLight : null)} style={{ color }}>
        {typeof title === 'function' ? title() : title}
      </div>
    );
  };

  if (accordion) {
    return (
      <Accordion className={classes.accordionContainer} classes={{ expanded: classes.accordionContainerExpanded }}>
        <AccordionSummary
          className={classes.accordionSummary}
          classes={{
            content: classes.accordionSummaryContent,
            expanded: classes.accordionSummaryExpanded,
            expandIcon: classes.accordionSummaryExpandIcon,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <div
            className={clsx(
              classes.titleWrapper,
              !iconInline ? classes.titleWrapperInlineIcon : null,
              !button ? classes.titleWrapperFullWidth : null
            )}
          >
            {iconLeft ? <img src={iconLeft} className={classes.iconLeft} alt="content_box_left_icon" /> : null}
            {renderTitle()}
            {iconRight ? <img src={iconRight} className={classes.iconRight} alt="content_box_right_icon" /> : null}
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsContainer}>
          <div className={classes.contentSeparator} />
          <div className={classes.contentWrapper}>{children}</div>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Box
      direction="column"
      onClick={onClick}
      background={background}
      fullHeight={fullHeightBox}
      top={padding}
      right={padding}
      bottom={padding}
      left={padding}
      customClass={customClass}
      showShadow={showShadow}
    >
      {alert ? (
        <div
          className={clsx(
            classes.alertWrapper,
            alertType === 'success'
              ? classes.alertSuccess
              : alertType === 'danger'
              ? classes.alertDanger
              : alertType === 'warning'
              ? classes.alertWarning
              : null
          )}
        >
          <div className={classes.alertTitle}>{alertTitle}</div>
          {alertMessage ? <div className={classes.alertMessage}>{alertMessage}</div> : null}
        </div>
      ) : null}
      <div className={clsx(classes.headerWrapper, headerVertical ? classes.headerWrapperVertical : null)}>
        {showTitle ? (
          <div
            className={clsx(
              classes.titleWrapper,
              !iconInline ? classes.titleWrapperInlineIcon : null,
              !button ? classes.titleWrapperFullWidth : null
            )}
          >
            {titleWithLeftIndicator ? <div className={classes.leftIndicator} /> : null}
            {iconLeft ? <img src={iconLeft} className={classes.iconLeft} alt="content_box_left_icon" /> : null}
            {renderTitle()}
            {iconRight ? <img src={iconRight} className={classes.iconRight} alt="content_box_right_icon" /> : null}
          </div>
        ) : null}
        {button ? (
          <Button onClick={buttonOnClick} rightIcon={buttonRightIcon} leftIcon={buttonLeftIcon}>
            {buttonText}
          </Button>
        ) : null}
        {customRightAction ? customRightAction() : null}
      </div>
      <div
        className={clsx(classes.contentSeparator, sm ? classes.contentSeparatorSm : null)}
        style={{ background: color }}
      />
      <div className={classes.contentWrapper}>{children}</div>
    </Box>
  );
};

export default ContentBox;
