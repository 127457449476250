import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.98,
    },
  },
  banner: {
    width: '100%',
    marginBottom: 25,
  },
  bannerTextWrapper: {
    position: 'absolute',
    color: Colors.rgb.white,
    top: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '0 32px',
    marginBottom: '6%',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      padding: 10,
      right: 0,
    },
  },
  bannerTitle: {
    fontSize: '2.7rem',
    marginBottom: 20,
    color: Colors.rgb.primary,
    fontFamily: theme.fontFamily.bold,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      marginBottom: 20,
      lineHeight: '2rem',
    },
  },
  bannerText: {
    fontSize: '1.4rem',
    color: Colors.rgb.black,
    fontFamily: theme.fontFamily.light,
    lineHeight: '1.8rem',
    width: '70%',

    '& > strong': {
      marginTop: -10,
      marginRight: 5,
      fontFamily: theme.fontFamily.bold,
    },

    '& > span': {
      fontWeight: 'bold',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
      lineHeight: '1.2rem',
      letterSpacing: 0,
      marginTop: 0,
      width: '60%',

      '& > strong': {
        marginTop: 0,
        marginBottom: 0,
      },
    },

    [theme.breakpoints.down(344)]: {
      fontSize: '0.9rem',
      lineHeight: '1.2rem',
      letterSpacing: 0,
      marginTop: 0,
      width: '60%',

      '& > strong': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
  bannerButton: {
    left: 32,
    height: 45,
    bottom: 0,
    position: 'absolute',
    marginRight: 0,
    marginBottom: '1.5%',

    [theme.breakpoints.down('sm')]: {
      left: 10,
      bottom: 0,
      height: 'auto',
      position: 'absolute',
      marginRight: 0,
      marginBottom: '5%',
      width: '45%',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    position: 'relative',
    zIndex: 0,
  },
  dialogTitle: {
    color: theme.palette.secondary.main,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: theme.fontSize.M,
    },
  },
}));

export default makeClasses;
