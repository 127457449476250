import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(3),
    background: Colors.rgb.notification_background,
    boxSizing: 'border-box',
    color: Colors.rgb.white,
    fontSize: theme.fontSize.M,
    borderRadius: 5,

    '& > div': {
      marginTop: -3,
    },
  },
  rootInfo: {
    background: '#cce5ff',
    color: `#004185 !important`,
  },
  infoIcon: {
    alignSelf: 'flex-start',
    fontSize: '1rem',
    marginRight: 10,
  },
  infoIconFull: {
    fontSize: '3rem',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(4),
    right: 7,
    cursor: 'pointer',
    zIndex: 10,
    color: '#fff',

    '&:hover': {
      opacity: 0.9,
    },
  },
  closeIcon: {
    fontSize: '1rem',
  },
}));

export default makeClasses;
