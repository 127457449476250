import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import qs from 'query-string';

import ROUTES from '../../routes';
import {
  Page,
  OperationsTable,
  BranchesSelector,
  FiltersDateSelector,
  TitleBox,
  BoxSeparator,
  ContentBox,
  TitleContentBox,
  FiltersContainer,
  FiltersSeparator,
  EmptyMessage,
  ForbiddenSection,
} from '../../components';
import { CUSTOM_FILTER, getDateRangeForFilter } from '../../components/FiltersDateSelector';
import { business as businessActions } from '../../actions';
import { Utils, Language } from '../../utils';

import SaveAltIcon from '@material-ui/icons/SaveAlt';

import makeClasses from './styles';
import clsx from 'clsx';
import moment from 'moment';
import { Policies } from '../../utils/Policies';
import { toISOString } from '../../utils/Utils';

const ReversalsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);

  const [ selectedBranch, setSelectedBranch ] = useState(searchParams.bid || 'all');
  const [ selectedFilter, setSelectedFilter ] = useState(searchParams.fid ? parseInt(searchParams.fid) : 3);
  const [ selectedCustomFilter, setSelectedCustomFilter ] = useState(
    parseInt(searchParams.fid) === CUSTOM_FILTER ? { startDate: searchParams.fsd, endDate: searchParams.fed } : {}
  );
  const [ paginationPage, setPaginationPage ] = useState(searchParams.pid ? parseInt(searchParams.pid) : 1);
  const [ columnOrder, setColumnOrder ] = useState(
    searchParams.columnNumber ? parseInt(searchParams.columnNumber) : ''
  );
  const [ columnOrderType, setColumnOrderType ] = useState(searchParams.sortType ? searchParams.sortType : '');
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const { policies, branches, reversals, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    branches: state.business.branches || [],
    reversals: state.business.reversals || {},
    languageTexts: state.language.texts || {},
  }));
  const prevPaginationPage = useRef(null);
  const i18n = Language(languageTexts);
  const hasReversals = reversals && reversals.grid && reversals.grid.rowsColumnAndSale.length > 0;
  const canExportReversals =
    policies && policies.includes(Policies.types.REVERSALS) && policies.includes(Policies.types.SUPER_READ);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(
    () => {
      if (!policies || !policies.includes(Policies.types.REVERSALS)) {
        setForbbidenSection(true);
      }
    },
    [ policies ]
  );

  useEffect(
    () => {
      if (!forbiddenSection) {
        if (paginationPage > 1 && prevPaginationPage.current === paginationPage) {
          _handleOnPaginationChange(1);
          prevPaginationPage.current = null;
        } else {
          loadData();
          prevPaginationPage.current = paginationPage;
        }
      }
    },
    [
      forbiddenSection,
      selectedBranch,
      selectedFilter,
      selectedCustomFilter,
      paginationPage,
      columnOrder,
      columnOrderType,
    ]
  );

  const loadData = () => {
    const branchId = selectedBranch !== 'all' ? selectedBranch : null;
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(businessActions.getReversals(branchId, startDate, endDate, paginationPage, columnOrder, columnOrderType));
  };

  const _handleOnBranchSelected = branch => {
    setSelectedBranch(branch);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.bid = branch;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnFilterSelected = (filter, startDate, endDate) => {
    setSelectedFilter(filter);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _goToOperationDetails = operationId => e => {
    e.preventDefault();

    const branchId = selectedBranch !== 'all' ? selectedBranch : null;

    if (branchId) {
      dispatch(push(`operacion/${operationId}/${branchId}`));
    } else {
      dispatch(push(`operacion/${operationId}`));
    }
  };

  const _handleOnPaginationChange = nextPage => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.pid = nextPage;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnTableSortChange = (columnNumber, sortType) => {
    setColumnOrder(columnNumber);
    setColumnOrderType(sortType);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.columnNumber = columnNumber;
    searchParams.sortType = sortType;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnTableExport = () => {
    const branchId = selectedBranch !== 'all' ? selectedBranch : null;
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.exportTable(
        'reversals',
        {
          traceId: branchId,
          start: startDate,
          end: endDate,
          columnOrder,
          columnOrderType,
        },
        file =>
          Utils.downloadFile(
            file,
            'Contracargos_' +
              dateFilter.startDate.format(i18n.get('ExportDateFormat')) +
              '_' +
              dateFilter.endDate.format(i18n.get('ExportDateFormat')) +
              '.xlsx'
          )
      )
    );
  };

  const renderBranchesSelector = () => (
    <BranchesSelector branches={branches} selectedBranch={selectedBranch} onBranchSelected={_handleOnBranchSelected} />
  );

  const renderFiltersDateSelector = () => (
    <FiltersDateSelector
      selectedFilter={selectedFilter}
      onFilterSelected={_handleOnFilterSelected}
      align="right"
      dateFormat={i18n.get('DateFormat', false)}
    />
  );

  const renderOperationsTable = () => {
    const headers = reversals.grid.columnsNameAndType;
    const body = reversals.grid.rowsColumnAndSale;

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        exportable={reversals.grid.exportable}
        hasMorePages={reversals.grid.hasNextPage}
        paginationSimple
        onPaginationChange={_handleOnPaginationChange}
        onClick={_goToOperationDetails}
        onTableSortChange={_handleOnTableSortChange}
        onExport={_handleOnTableExport}
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page withHeader withSidebar withHeaderTitle={i18n.get('Reversals.Title')} withActivePage={ROUTES.REVERSALS.id}>
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('Reversals.Title')} withActivePage={ROUTES.REVERSALS.id}>
      <TitleBox title={i18n.get('Reversals.BoxTitle')} />
      <BoxSeparator />
      <ContentBox title={i18n.get('Reversals.BoxFiltersTitle')} titleBold>
        <FiltersContainer>
          {renderBranchesSelector()}
          <FiltersSeparator />
          {renderFiltersDateSelector()}
        </FiltersContainer>
      </ContentBox>
      <BoxSeparator size="small" />
      {hasReversals ? (
        <React.Fragment>
          <TitleContentBox title={reversals.title}>
            <div className={clsx(classes.tile, classes.tileTotal)}>
              {reversals.total.map((total, index) => (
                <div key={index} className={classes.tileAmount}>
                  {total.currency} {Utils.formatCurrency(total.total)}
                </div>
              ))}
            </div>
          </TitleContentBox>
          <BoxSeparator size="small" />
        </React.Fragment>
      ) : null}
      <ContentBox
        title={i18n.get('Reversals.GridTitle')}
        titleBold
        button={hasReversals && canExportReversals ? reversals.grid.exportable : false}
        buttonText={i18n.get('Reversals.GridExportButton')}
        buttonOnClick={_handleOnTableExport}
        buttonRightIcon={() => <SaveAltIcon fontSize="small" />}
      >
        {hasReversals ? (
          renderOperationsTable()
        ) : (
          <EmptyMessage textOnly>{i18n.get('Reversals.GridEmptyMessage')}</EmptyMessage>
        )}
      </ContentBox>
    </Page>
  );
};

ReversalsScreen.id = 'com.Handy.Reversals';

export default ReversalsScreen;
