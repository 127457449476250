import React from 'react';
import Box from '../Box';
import makeClasses from './styles';
import Button from '../Button';

const BoxMenu = ({ items, selectedItem, onClickItem }) => {
  const classes = makeClasses();

  const handleOnClick = item => () => {
    if (item.id !== selectedItem && typeof onClickItem === 'function') {
      onClickItem(item);
    }
  };

  return (
    <Box customClass={classes.boxMenuContainer}>
      <div className={classes.boxMenuContentContainer}>
        {items.map((item, key) => (
          <Button key={key} variant={selectedItem !== item.id ? 'outlined' : null} onClick={handleOnClick(item)}>
            {item.label}
          </Button>
        ))}
      </div>
    </Box>
  );
};

export default BoxMenu;
