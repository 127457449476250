import { makeStyles } from '@material-ui/core';
import { THEME_LAYOUT } from '../../theme';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  box: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '70% !important',
      whiteSpace: 'nowrap !important',
    },
  },
  title: {
    color: Colors.rgb.white,
    fontSize: theme.fontSize.L,
    fontFamily: theme.fontFamily.regular,
    paddingTop: theme.spacing(THEME_LAYOUT.VERTICAL.INNER.XS),
    paddingBottom: theme.spacing(THEME_LAYOUT.VERTICAL.INNER.XS),

    [theme.breakpoints.down('md')]: {
      fontSize: theme.fontSize.M,
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  titleBold: {
    fontFamily: theme.fontFamily.bold,
  },
  titleSemibold: {
    fontFamily: theme.fontFamily.semibold,
  },
  titleLight: {
    fontFamily: theme.fontFamily.light,
  },
  iconRight: {
    marginLeft: theme.spacing(THEME_LAYOUT.VERTICAL.INNER.S),
    height: 20,
  },
}));

export default makeClasses;
