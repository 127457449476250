import { languageTypes as types } from '../actions';

const initialState = {
	requesting: false,
	locale: 'es',
	texts: {}
};

export default function language(state = initialState, action = {}) {
	switch (action.type) {
		case types.SET_REQUESTING:
			return {
				...state,
				requesting: action.value
			};
		case types.SET_LANGUAGE_TEXTS:
			return {
				...state,
				texts: action.data
			};
		case types.CLEAR:
			return initialState;
		default:
			return state;
	}
}
