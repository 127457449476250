import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  selectContainer: {
    width: 'auto',
    textAlign: 'left',
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentText,

    '&::before': {
      borderBottomColor: `${Colors.rgb.contentLine} !important`,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  selectOption: {
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentText,
  },
  alignRight: {
    textAlign: 'right',

    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
}));

export default makeClasses;
