import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles((theme) => ({
	root: {
		display: 'flex',
		position: 'relative'
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		position: 'relative',
		zIndex: 0
	},
	shapeTop: {
		height: 170,
		width: '100%',
		marginBottom: 25
	},
	shapeBottom: {
		height: 170,
		width: '100%',
		marginTop: 35,
		marginBottom: 35
	},
	shapeSidemenu: {
		height: 200,
		width: 200
	},
	dismissButton: {
		position: 'absolute',
		top: 5,
		right: 5,
		cursor: 'pointer',
		zIndex: 10,
		color: '#fff',

		'&:hover': {
			opacity: 0.9
		}
	}
}));

export default makeClasses;
