import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  contentWrapper: {
    marginTop: `-${theme.spacing(2)}`,
  },
  boxItem: {
    padding: 0,
  },
  boxItemRoot: {
    padding: `${theme.spacing(2)} ${theme.spacing(6)}`,

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    },
  },
  boxItemWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Colors.rgb.contentTextHighlighted,

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.fontSize.XL,

      '& > span': {
        '&:nth-child(2)': {
          fontFamily: theme.fontFamily.bold,
          marginLeft: theme.spacing(1),
        },
        '&:nth-child(3)': {
          margin: `0 ${theme.spacing(2)}`,
        },
        '&:nth-child(4)': {
          fontFamily: theme.fontFamily.regular,
        },
        '&:nth-child(5)': {
          fontFamily: theme.fontFamily.bold,
          marginLeft: theme.spacing(1),
        },
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: theme.fontSize.L,
      },
    },
  },
  boxItemWrapperSm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: Colors.rgb.contentTextHighlighted,

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.fontSize.L,

      '&:nth-child(1)': {
        fontFamily: theme.fontFamily.bold,
        borderBottom: `1px solid ${Colors.rgb.contentLine}`,
        width: '100%',
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },

      '&:nth-child(2)': {
        justifyContent: 'space-between',
        width: '100%',
        marginTop: theme.spacing(1),

        '& > div': {
          display: 'flex',
          flex: 1,
          alignItems: 'center',

          '&:nth-child(1)': {
            justifyContent: 'flex-start',
          },
          '&:nth-child(2)': {
            justifyContent: 'flex-end',
          },

          '& > span': {
            '&:nth-child(2)': {
              fontFamily: theme.fontFamily.bold,
              marginLeft: theme.spacing(1),
            },
            '&:nth-child(3)': {
              margin: `0 ${theme.spacing(2)}`,
            },
            '&:nth-child(4)': {
              fontFamily: theme.fontFamily.regular,
            },
            '&:nth-child(5)': {
              fontFamily: theme.fontFamily.bold,
              marginLeft: theme.spacing(1),
            },
          },
        },
      },
    },
  },
  boxItemTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSize.XL,

    '& > span': {
      '&:nth-child(1)': {
        fontFamily: theme.fontFamily.bold,
        marginLeft: theme.spacing(1),
      },
      '&:nth-child(2)': {
        fontFamily: theme.fontFamily.bold,
        marginLeft: theme.spacing(2),
      },
      '&:nth-child(3)': {
        fontFamily: theme.fontFamily.regular,
        margin: `0 ${theme.spacing(2)}`,
      },
      '&:nth-child(5)': {
        fontFamily: theme.fontFamily.bold,
        marginLeft: theme.spacing(1),
      },
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.L,

      '& > span': {
        '&:nth-child(1)': {
          fontFamily: theme.fontFamily.regular,
          marginLeft: theme.spacing(1),
        },
        '&:nth-child(2)': {
          fontFamily: theme.fontFamily.regular,
          marginLeft: theme.spacing(2),
        },
        '&:nth-child(3)': {
          fontFamily: theme.fontFamily.regular,
          margin: `0 ${theme.spacing(2)}`,
        },
        '&:nth-child(5)': {
          fontFamily: theme.fontFamily.regular,
          marginLeft: theme.spacing(1),
        },
      },
    },
  },
  boxItemTitleSm: {
    display: 'flex',
    flexDirection: 'column',

    '& div': {
      '&:nth-child(2)': {
        '& > span': {
          '&:nth-child(1)': {
            fontFamily: theme.fontFamily.regular,
          },
          '&:nth-child(2)': {
            fontFamily: theme.fontFamily.bold,
          },
        },
      },
    },
  },
  boxItemBold: {
    fontFamily: theme.fontFamily.bold,

    [theme.breakpoints.down('sm')]: {
      fontFamily: theme.fontFamily.regular,
    },
  },
  boxItemIcon: {
    width: 20,
    marginRight: theme.spacing(1),
  },
  boxItemSeparator: {
    background: Colors.rgb.contentLineSoft,
  },
  viewFilterWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > div': {
      '&:first-child': {
        fontFamily: theme.fontFamily.bold,
        fontSize: theme.fontSize.XL,
        marginRight: theme.spacing(4),
      },
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',

      '& > div': {
        width: '100%',

        '&:first-child': {
          width: '100%',
          marginBottom: theme.spacing(2),
          paddingBottom: theme.spacing(1),
          marginRight: 0,
          fontSize: `${theme.fontSize.L} !important`,
          borderBottom: `1px solid ${Colors.rgb.contentLine}`,
        },
      },
    },
  },
}));

export default makeClasses;
