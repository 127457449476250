import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(3),
    borderTop: `1px solid ${Colors.rgb.contentLine}`,

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      borderTop: `1px solid ${Colors.rgb.contentLine}`,
      marginTop: 0,
    },
  },
}));

export default makeClasses;
