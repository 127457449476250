import React from 'react';
import Box from '../Box';
import Title from '../Title';
import { Button } from '..';
import makeClasses from './styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Hidden } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Language } from '../../utils';

const TitleBox = ({
  title,
  titleBold = false,
  titleSemibold = false,
  titleLight = false,
  back = false,
  backTitle = '',
  onBackClick,
  backButtonVariant = 'primary',
  iconRight = null,
  buttonsRight = null,
  buttonRight = false,
  buttonRightText = '',
  buttonRightOnClick,
  buttonRightIcon = null,
  customClass = null,
}) => {
  const classes = makeClasses();
  const { languageTexts } = useSelector((state) => ({
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const renderRightButton = () => {
    if (back) {
      return (
        <React.Fragment>
          <Hidden xsDown>
            <Button
              onClick={onBackClick}
              leftIcon={() => <ChevronLeftIcon style={{ width: 19, height: 22 }} />}
              variant={backButtonVariant}
            >
              {backTitle}
            </Button>
          </Hidden>
          <Hidden smUp>
            <Button variant="outlined" onClick={onBackClick} leftIcon={() => <ChevronLeftIcon />}>
              {i18n.get('Components.TitleBox.BackButton')}
            </Button>
          </Hidden>
        </React.Fragment>
      );
    }
    if (buttonRight) {
      return (
        <Button onClick={buttonRightOnClick} leftIcon={buttonRightIcon}>
          {buttonRightText}
        </Button>
      );
    } else if (buttonsRight && buttonsRight.length > 0) {
      return (
        <div className={classes.buttonsRightWrapper}>
          {buttonsRight.map((button, key) => (
            <Button key={key} onClick={button.onClick} leftIcon={button.icon} variant={button.variant || 'normal'} disabled={button.disabled}>
              {button.text}
            </Button>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <Box left={false} isTitle customClass={customClass}>
      <div className={classes.contentWrapper}>
        <Title bold={titleBold} semibold={titleSemibold} light={titleLight} iconRight={iconRight}>
          {title}
        </Title>
        {renderRightButton()}
      </div>
    </Box>
  );
};

export default TitleBox;
