import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import configureStore from './store/configureStore';
import App from './containers/App';
import { Utils } from './utils';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import theme from './theme';

import './index.css';

ReactGA.initialize(process.env.REACT_APP_GA4_TRACKING_ID, {
  testMode: process.env.NODE_ENV !== 'production',
});
TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID,
});

Utils.checkForBrowserVersion();

const history = createHistory();
const initialState = {};

const { store, persistor } = configureStore(initialState, history);

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.send({ hitType: 'pageview', page: location.pathname });
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history} context={ReactReduxContext}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
