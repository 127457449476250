import React from 'react';
import HelpDrawer from '../HelpDrawer';

import makeStyles from './styles';
import { useSelector } from 'react-redux';
import { Language } from '../../utils';
import moreImage from '../../static/images/icons/more_vertical.svg';

const HelpBranchesWithNoPaymentMethod = ({ open, onToggleHelp }) => {
  const classes = makeStyles();

  const { languageTexts } = useSelector(({ language }) => ({
    languageTexts: language.texts || {},
  }));
  const i18n = Language(languageTexts);

  return (
    <HelpDrawer open={open} onClose={onToggleHelp}>
      <div className={classes.helpContentTitle}>
        {i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpContentTitle')}
      </div>
      <div className={classes.helpParagraph}>
        <div className={classes.helpTitleBold}>{i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpTitle1')}</div>
        <div>{i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpLine1')}</div>
      </div>
      <div className={classes.helpParagraph}>
        <div className={classes.helpTitleBold}>{i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpTitle2')}</div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpLine2') }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpLine3') }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpLine4') }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpLine5') }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpLine6') }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpLine7') }} />
        </div>
      </div>
      <div className={classes.helpParagraph}>
        <div className={classes.helpTitleBold}>{i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpTitle3')}</div>
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpLine9').replace('{image}', moreImage),
            }}
          />
          <div
            dangerouslySetInnerHTML={{ __html: i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpLine10') }}
          />
          <div
            dangerouslySetInnerHTML={{ __html: i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpLine11') }}
          />
        </div>
      </div>
    </HelpDrawer>
  );
};

export default HelpBranchesWithNoPaymentMethod;
