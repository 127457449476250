import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  sectionTitle: {
    marginBottom: 10,
  },
  branchesSelectorWrapper: {
    marginBottom: 15,
  },
  //
  // Boxes
  boxBalance: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.white,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  boxBalanceTitle: {
    fontSize: theme.fontSize.M,
  },
  boxBalanceContent: {
    display: 'flex',
    flexDirection: 'row',

    '& > div': {
      '&:nth-child(1)': {
        flex: 1,

        '& > div': {
          textAlign: 'left',
        },
      },
      '&:nth-child(2)': {
        flex: 1,

        '& > div': {
          textAlign: 'right',
        },
      },
    },
  },
  boxBalanceCurrency: {
    fontSize: theme.fontSize.S,
  },
  boxBalanceAmount: {
    fontSize: theme.fontSize.L,
  },

  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 130,
    boxSizing: 'border-box',
    background: theme.palette.secondary.main,
    color: Colors.rgb.white,
  },
  boxHoverable: {
    cursor: 'pointer',

    '&:hover': {
      background: Colors.rgba(theme.palette.secondary.main, 90),
    },
  },
  boxAmount: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontSize: theme.fontSize.L,
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.white,

    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',

      '&:first-child': {
        marginBottom: theme.spacing(),
      },

      '& > div:last-child': {
        marginLeft: theme.spacing(),
      },
    },
  },
  boxText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.85rem',
    marginTop: 13,
    color: Colors.rgba(Colors.rgb.white, 85),
    cursor: 'pointer',

    '& > span': {
      marginRight: 5,
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0.4rem',
      marginTop: 4,
    },
  },
  //
  // Table
  activityWrapper: {
    marginTop: 35,
    position: 'relative',
  },
  // Popover
  popover: {
    pointerEvents: 'none',
    marginTop: 7,

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  popoverPaper: {
    padding: '0.5rem 1rem',
  },
  popoverText: {
    fontSize: '0.85rem',
    color: Colors.rgba(Colors.rgb.black, 85),
  },
}));

export default makeClasses;
