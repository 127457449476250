import React from 'react';
import { useSelector } from 'react-redux';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { Language } from '../../utils';
import makeClasses from './styles';
import clsx from 'clsx';

const BranchesSelector = ({ branches = [], idValue = null, selectedBranch, onBranchSelected, align }) => {
  const classes = makeClasses();

  const { languageTexts } = useSelector(state => ({
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const _handleOnChange = e => {
    if (typeof onBranchSelected === 'function') {
      onBranchSelected(e.target.value);
    }
  };

  return (
    <div className={align === 'right' ? clsx(classes.container, classes.alignRight) : classes.container}>
      <Select value={selectedBranch} onChange={_handleOnChange} className={classes.selectContainer}>
        <MenuItem value="all" className={classes.selectOption}>
          {i18n.get('BranchFilterDefault')}
        </MenuItem>
        {branches.map((branch, index) => (
          <MenuItem key={index} value={idValue ? branch[idValue] : branch.traceId} className={classes.selectOption}>
            {branch.name || branch.branchName}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default BranchesSelector;
