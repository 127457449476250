import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import moment from 'moment';
import _ from 'lodash';
import qs from 'query-string';

import {
  Page,
  OperationsTable,
  TitleBox,
  BoxSeparator,
  ContentBox,
  Title,
  EmptyMessage,
  ForbiddenSection,
} from '../../components';
import { business as businessActions } from '../../actions';
import { Utils, Language } from '../../utils';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import makeClasses from './styles';
import { Policies } from '../../utils/Policies';
import { toISOString } from '../../utils/Utils';

const PaymentDetailsByDayScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);

  const [ paymentDetails, setPaymentDetails ] = useState({});
  const [ paginationPage, setPaginationPage ] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [ columnOrder, setColumnOrder ] = useState(
    searchParams.columnNumber ? parseInt(searchParams.columnNumber) : ''
  );
  const [ columnOrderType, setColumnOrderType ] = useState(searchParams.sortType ? searchParams.sortType : '');
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const hasPaymentDetails =
    paymentDetails &&
    paymentDetails.rowsColumnAndSale &&
    paymentDetails.rowsColumnAndSale.rowsColumnAndSale &&
    paymentDetails.rowsColumnAndSale.rowsColumnAndSale.length > 0;
  const date = match.params.date || null;
  const branchId = match.params.branchId || null;
  const formattedDate = `${date.substring(0, 4)}/${date.substring(4, 6)}/${date.substring(6, 8)}`;
  const referenceDate = moment().date();
  const { policies, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const prevPaginationPage = useRef(null);
  const i18n = Language(languageTexts);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(
    () => {
      if (!policies || !policies.includes(Policies.types.OPERATION_DEATAILS)) {
        setForbbidenSection(true);
      }
    },
    [ policies ]
  );

  useEffect(
    () => {
      if (!forbiddenSection) {
        if (paginationPage > 1 && prevPaginationPage.current === paginationPage) {
          _handleOnPaginationChange(1);
          prevPaginationPage.current = null;
        } else {
          loadData();
          prevPaginationPage.current = paginationPage;
        }
      }
    },
    [ forbiddenSection, paginationPage, columnOrder, columnOrderType, branchId, formattedDate ]
  );

  const loadData = () => {
    dispatch(
      businessActions.getPaymentDetailsByDay(
        branchId,
        toISOString(moment(formattedDate).startOf('day')),
        toISOString(moment(formattedDate).endOf('day')),
        paginationPage,
        columnOrder,
        columnOrderType,
        details => {
          setPaymentDetails(details);
        }
      )
    );
  };

  const currentDate = moment(formattedDate, 'YYYY-MM-DD');
  const currentDateName = Utils.capitalize(currentDate.format('dddd'));

  const _goBack = e => {
    e.preventDefault();
    dispatch(goBack());
  };

  const _goToOperationDetails = operationId => e => {
    e.preventDefault();

    dispatch(push(`/operacion/${operationId}`));
  };

  const _handleOnPaginationChange = nextPage => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnTableSortChange = (columnNumber, sortType) => {
    setColumnOrder(columnNumber);
    setColumnOrderType(sortType);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.columnNumber = columnNumber;
    searchParams.sortType = sortType;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnTableExport = () => {
    dispatch(
      businessActions.exportTable(
        'dayDetails',
        {
          branchId,
          start: toISOString(moment(formattedDate).startOf('day')),
          end: toISOString(moment(formattedDate).endOf('day')),
          columnOrder,
          columnOrderType,
        },
        file => Utils.downloadFile(file, 'Ventas_a_cobrar-' + formattedDate + '.xlsx')
      )
    );
  };

  const _getDay = () => {
    return referenceDate === currentDate.date()
      ? i18n.get('DayDetails.Today')
      : `${currentDateName} ${currentDate.format('DD, MMMM')}`;
  };

  const renderDayDetails = () => {
    const body = paymentDetails.dayDetail.rows.slice(1);

    const renderRow = (row, index) => {
      const titleValue = row.columns[0].value;
      const title = i18n.get(titleValue.substring(1, titleValue.length - 1));
      const valueUyu = `${row.columns[1].id} ${Utils.formatCurrency(row.columns[1].value)}`;
      const valueUsd = `${row.columns[2].id} ${Utils.formatCurrency(row.columns[2].value)}`;

      return (
        <div key={index} className={classes.detailsItem}>
          <Title>{title}</Title>
          <div className={classes.detailsItemValuesWrapper}>
            <div>{valueUyu}</div>
            <div>{valueUsd}</div>
          </div>
        </div>
      );
    };

    return body.map(renderRow);
  };

  const renderOperationsTable = () => {
    const headers = paymentDetails.rowsColumnAndSale.columnsNameAndType;
    const body = paymentDetails.rowsColumnAndSale.rowsColumnAndSale;

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={paymentDetails.rowsColumnAndSale.hasNextPage}
        onPaginationChange={_handleOnPaginationChange}
        onClick={_goToOperationDetails}
        onTableSortChange={_handleOnTableSortChange}
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page withHeader withSidebar withHeaderTitle={i18n.get('DayDetails.Title')}>
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('DayDetails.Title')}>
      <TitleBox
        title={i18n.get('DayDetails.BoxTitle').replace('{day}', _getDay())}
        back
        backTitle={i18n.get('DayDetails.BoxBackTitle')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      {hasPaymentDetails ? (
        <React.Fragment>
          <ContentBox title={i18n.get('DayDetails.BoxDetailsTitle')} titleBold>
            <div className={classes.detailsWrapper}>{renderDayDetails()}</div>
          </ContentBox>
          <BoxSeparator size="small" />
        </React.Fragment>
      ) : null}
      <ContentBox
        title={paymentDetails.listingTitle}
        titleBold
        button={hasPaymentDetails ? paymentDetails.rowsColumnAndSale.exportable : false}
        buttonText={i18n.get('History.GridExportButton')}
        buttonOnClick={_handleOnTableExport}
        buttonRightIcon={() => <SaveAltIcon fontSize="small" />}
      >
        {hasPaymentDetails ? (
          renderOperationsTable()
        ) : (
          <EmptyMessage textOnly>{i18n.get('DayDetails.GridEmptyMessage')}</EmptyMessage>
        )}
      </ContentBox>
    </Page>
  );
};

PaymentDetailsByDayScreen.id = 'com.Handy.PaymentDetailsByDay';

export default PaymentDetailsByDayScreen;
