import { makeStyles } from '@material-ui/core';
import { THEME_LAYOUT } from '../../theme';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  summaryWrapper: {
    maxHeight: 360,
  },
  summaryTitle: {
    padding: '7px 0',
    fontSize: '1.2rem',
  },
  summaryAmount: {
    fontWeight: 'bold',
  },
  chartsContainer: {
    marginTop: theme.spacing(THEME_LAYOUT.VERTICAL.INNER.M),
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.M,
    color: Colors.rgb.contentText,
  },
  chartContainer: {
    marginTop: theme.spacing(THEME_LAYOUT.VERTICAL.INNER.M),
  },

  //
  // Transactions
  transactionsWrapper: {
    marginTop: 30,

    [theme.breakpoints.down('md')]: {
      marginTop: 20,
    },
  },
  // Charts
  pieChartLabelsWrapper: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  pieChartLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.M,
    color: Colors.rgb.contentText,
  },
  pieChartLabelIndicator: {
    marginRight: 10,
    width: 10,
    height: 10,
    border: `1px solid ${Colors.rgba(Colors.rgb.black, 70)}`,
  },
  activityWrapper: {
    position: 'relative',
  },
  chartTotalsWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    height: '100%',
  },
  chartTotalTitle: {
    fontSize: theme.fontSize.L,
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderRight: `3px solid ${Colors.rgba(Colors.rgb.black, 50)}`,
    display: 'flex',
    color: Colors.rgb.contentText,
    alignItems: 'center',
  },
  chartTotals: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    color: Colors.rgb.contentText,
  },
  chartTotal: {
    fontSize: theme.fontSize.S,

    '& > span': {
      fontSize: theme.fontSize.XXS,
      color: Colors.rgb.contentText,
    },
  },
  chartTotalPositive: {
    color: Colors.rgb.number_green,
  },
  chartTotalNegative: {
    color: Colors.rgb.number_red,
  },
  toggleFiltersWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: Colors.rgb.primary,
    fontSize: theme.fontSize.M,
    userSelect: 'none',

    '& > div': {
      textWrap: 'nowrap',
    },

    '& > svg': {
      marginTop: 3,
    },
  },
  toggleFiltersWrapperActive: {
    '& > svg': {
      transform: 'rotateZ(180deg)',
    },
  },
}));

export default makeClasses;
