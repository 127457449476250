import { makeStyles } from '@material-ui/core';
import { THEME_LAYOUT } from '../../theme';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  //
  detailsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(THEME_LAYOUT.VERTICAL.INNER.S),
  },
  detailsItem: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Colors.rgb.contentTextHighlighted,
    marginRight: theme.spacing(9),

    '&:last-child': {
      marginRight: 0,
    },
  },
  detailsItemValuesWrapper: {
    textAlign: 'right',
  },
}));

export default makeClasses;
