import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';
import BackgroundImage from '../../static/images/login-bg.png';

const makeClasses = makeStyles((theme) => ({
  container: {
    height: '100%',
    background: Colors.rgb.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: '100% 100%',

    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'auto 100%',
      backgroundPosition: '50% 50%',
      flexDirection: 'column',
    },
  },
  contentWrapper: {
    padding: `10px 0 30px`,

    [theme.breakpoints.down('xs')]: {
      padding: `10px 0 20px`,
    },
  },
  contentWrapperWithIFrame: {
    padding: 0,
    paddingBottom: 15,
  },
  brandContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  brandLogo: {
    height: 50,

    [theme.breakpoints.down('md')]: {
      height: 40,
    },

    [theme.breakpoints.down('xs')]: {
      height: 30,
    },
  },
  brandSeparator: {
    height: 55,
    width: 1,
    margin: '0 15px',
    background: Colors.rgb.white,

    [theme.breakpoints.down('xs')]: {
      height: 35,
    },
  },
  brandTitle: {
    fontFamily: theme.fontFamily.regular,
    fontSize: 24,
    color: Colors.rgb.white,

    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  contentContainer: {
    width: 700,
    background: Colors.rgba(Colors.rgb.contentTextSuperHighlighted, 80),
    borderRadius: 10,
    padding: 20,

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      background: Colors.rgb.contentTextSuperHighlighted,
      width: '90%',
      boxSizing: 'border-box',
    },

    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  contentContainerWithIFrame: {
    width: '33%',
    height: '94%',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      width: '95%',
      height: '90%',
    },
  },
  formContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  formTitleContainer: {
    textAlign: 'left',
    padding: `0 ${theme.spacing(3)} ${theme.spacing(4)}`,
    width: '100%',
    color: Colors.rgb.white,
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      paddingBottom: 10,
    },

    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
      paddingBottom: 10,
    },
  },
  formTitleContainerWithAlert: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      paddingBottom: theme.spacing(2),
    },
  },
  formTitle: {
    fontFamily: theme.fontFamily.semibold,
    fontSize: theme.fontSize.L,
  },
  formText: {
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.M,
    marginTop: 10,
  },
  form: {
    width: '100%',
  },
  formContent: {
    width: '100%',
    boxSizing: 'border-box',
  },
  formVerificationCode: {
    width: '65%',
    margin: '0 auto 30px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 auto 25px',
    },
  },
  formInput: {
    width: '100% !important',
    fontFamily: `${theme.fontFamily.light} !important`,
    fontSize: theme.fontSize.L,
    background: Colors.rgb.white,
    borderRadius: 4,

    '& input': {
      height: 'auto !important',
      padding: `${theme.spacing(3)} !important`,
    },

    [theme.breakpoints.down('sm')]: {
      '& input': {
        padding: `${theme.spacing(2)} !important`,
        fontSize: theme.fontSize.S,
      },
    },
  },
  formInputFileButton: {
    paddingRight: 0,
  },
  formInputTitleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: theme.spacing(2),
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
  formInputTitle: {
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.M,
    color: Colors.rgb.white,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1),

    '& > a': {
      display: 'block',
      color: Colors.rgb.white,
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  formInputSelect: {
    padding: '0 !important',
  },
  formInputSeparator: {
    height: theme.spacing(2),
  },
  formSubmitContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
    },
  },
  disclaimerFormSubmitContainer: {
    margin: `0 0 0 auto !important`,
  },
  formSubmitContainerWithButtonAlone: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: theme.spacing(1),
    },
  },
  formSubmitContainerWithOneButton: {
    width: '50%',
    margin: `${theme.spacing(4)} 0 0 auto`,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: `${theme.spacing(2)} 0 0 auto`,
    },
  },
  formSubmitContainerWithOneMiniButton: {
    width: '30%',
    margin: `${theme.spacing(4)} 0 0 auto`,

    [theme.breakpoints.down('sm')]: {
      width: '50%',
      margin: `${theme.spacing(2)} 0 0 auto`,
    },
  },
  formSubmitContainerWithNoMargin: {
    [theme.breakpoints.down('sm')]: {
      margin: `0 0 0 auto !important`,
    },
  },
  codeFormSubmitContainer: {
    marginTop: theme.spacing(4),
  },
  formSubmitButton: {
    height: 40,
    display: 'flex',
    flex: 1,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formSubmitButtonSeparator: {
    width: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(2),
    },
  },
  formSubtitle: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.S,
    width: '55%',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    color: Colors.rgb.white,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  verificationCodeInputContainer: {
    height: 65,
    width: 65,
    marginLeft: 5,
    marginRight: 5,
  },
  verificationCodeInput: {
    textAlign: 'center',
    fontSize: '1.3rem',
    height: 43,
    background: Colors.rgb.white,
    borderRadius: 4,

    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },
  helpContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(3),
    color: Colors.rgb.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: theme.fontSize.S,
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.9,
    },

    [theme.breakpoints.down('sm')]: {
      top: 'unset',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  helpIcon: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  alert: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(5),

    '& > div': {
      display: 'flex',
      alignItems: 'flex-start',
    },

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)} !important`,
      '& > div': {
        alignItems: 'flex-start',
      },
    },
  },
  disclaimerAlert: {
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  alertTitle: {
    fontFamily: theme.fontFamily.bold,
    fontSize: theme.fontSize.L,
    margin: 0,
    lineHeight: '1.4',
    marginBottom: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.M,
    },
  },
  alertBodyText: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.M,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.S,
      paddingRight: theme.spacing(2),
    },

    '& strong': {
      fontFamily: theme.fontFamily.bold,
    },
    '& a': {
      color: 'inherit'
    }
  },
  alertMessage: {
    fontSize: theme.fontSize.L,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.S,
    },
  },
  alertTimer: {
    fontSize: theme.fontSize.XXL,
    color: Colors.rgb.alertWarningText,
    marginTop: theme.spacing(6),
    margin: `0 ${theme.spacing(1)}`,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.XL,
    },
  },
  alertIcon: {
    fontSize: 60,
    marginRight: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      marginRight: theme.spacing(1),
    },
  },
  alertIconWithBorder: {
    fontSize: 40,
    border: '3px solid',
    borderRadius: 60,
    padding: 5,

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      borderRadius: 40,
    },
  },
  alertWithNoMarginBottom: {
    marginBottom: 0,
  },
  alertSuccess: {
    background: Colors.rgb.alertSuccessBackground,
    border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
    color: `${Colors.rgb.alertSuccessText} !important`,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 5,
    },
  },
  alertWarning: {
    background: Colors.rgb.alertWarningBackground,
    border: `1px solid ${Colors.rgb.alertWarningBorder}`,
    color: `${Colors.rgb.alertWarningText} !important`,
  },
  alertError: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
  iframeContainer: {
    width: '100%',
    height: 'calc(100% - 40px)',
    overflow: 'hidden',

    '& > iframe': {
      width: '100%',
      height: 'calc(100% - 80px)',
      border: 'none',
    },
  },
  affiliationConfirmationContainer: {
    background: Colors.rgb.alertWarningBackground,
    border: `1px solid ${Colors.rgb.alertWarningBorder}`,
    color: `${Colors.rgb.alertWarningText} !important`,
    padding: theme.spacing(3),
    borderRadius: 5,
  },
  affiliationErrorContainer: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
  affiliationConfirmationTitle: {
    fontFamily: theme.fontFamily.bold,
    fontSize: theme.fontSize.XL,
  },
  affiliationConfirmationMessage: {
    fontSize: theme.fontSize.M,
  },
  termsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: Colors.rgb.white,
    fontSize: theme.fontSize.M,
    alignItems: 'flex-start',
    marginTop: 10,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.S,
      marginTop: 10,
    },
  },
  disclaimerTermsWrapper: {
    marginTop: '0 !important',
  },
  termsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: '3px 0',

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: theme.spacing(1),
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
  termsLabel: {
    display: 'block',

    '& > a': {
      padding: '0 3px',
      color: Colors.rgb.white,
    },
  },
  checkboxRoot: {
    padding: 0,
    color: `${Colors.rgb.white} !important`,
    marginRight: 10,
  },
  checkboxLabel: {
    color: `${Colors.rgb.white} !important`,
    marginLeft: 5,
  },
}));

export default makeClasses;
