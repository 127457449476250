import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { toast } from 'react-toastify';

import ROUTES from '../../../../../routes';
import { BoxSeparator, ContentBox, Page, TitleBox, Button, ForbiddenSection, Box } from '../../../../../components';
import { business as businessActions } from '../../../../../actions';
import { Language, Utils } from '../../../../../utils';

import { Grid, TextField } from '@material-ui/core';

import makeClasses from './styles';
import { Policies } from '../../../../../utils/Policies';
import moment from 'moment';
import { inputs } from '../../../../../utils/Utils';

const CURRENCY_FILTER_OPTIONS = [
  { id: 'URUGUAYAN_PESO', name: 'Currency.UYU' },
  { id: 'DOLLAR', name: 'Currency.USD' },
];

const FIELD_TYPES_OPTIONS = [
  {
    id: 'STRING',
    name: 'ProvidersManageSubscribersRequestNewCharge.AdditionalFieldTypeOption1',
  },
  {
    id: 'INTEGER',
    name: 'ProvidersManageSubscribersRequestNewCharge.AdditionalFieldTypeOption2',
  },
];

const ProvidersSubscriptionsManageSubscriptionEditScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const [inputEmail, setInputEmail] = useState('');
  const [inputPhoneNumber, setInputPhoneNumber] = useState('');
  const [subscription, setSubscription] = useState('');
  const [forbiddenSection, setForbbidenSection] = useState(false);

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const subscriptionTraceId = match && match.params ? match.params.traceId : null;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (policies && policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS_WRITE) && subscriptionTraceId) {
      dispatch(
        businessActions.getProvidersSubscriptionsSubscription(subscriptionTraceId, (data) => {
          setInputEmail(data.subscriber?.email || '');
          setInputPhoneNumber(data.subscriber?.phoneNumber || '');
          setSubscription(data);
        })
      );
    } else {
      setForbbidenSection(true);
    }
  }, [policies]);

  const _goBack = (e) => {
    e.preventDefault();
    dispatch(goBack());
  };
  
  const _submitForm = () => {
    if (!inputs.email.regex.test(inputEmail)) {
      return toast.error(i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.ValidationEmailError'));
    }

    if (!inputs.phone.regex.test(inputPhoneNumber)) {
      return toast.error(i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.ValidationPhoneNumberError'));
    }

    const payload = {
      Email: inputEmail.trim(),
      PhoneNumber: inputPhoneNumber.trim(),
    };

    dispatch(
      businessActions.updateProvidersSubscruptionsSubscriber(
        subscription.subscriber?.subscriberTraceId,
        payload,
        (result) => {
          if (result) {
            toast.success(i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.SuccessUpdateMessage'));
            setTimeout(() => dispatch(push(ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS.path)), 1000);
          }
        }
      )
    );
  };

  const renderProvidersSubscriptionsManageSubscriptionEditForm = () => {
    return (
      <Grid container justify="center" alignItems="center" className={classes.formWrapper}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form layout="vertical" onSubmit={_submitForm} className={classes.form}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.EmailInputLabel')}
                  placeholder={i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.EmailInputPlaceholder')}
                  onChange={(e) => setInputEmail(e.target.value)}
                  value={inputEmail}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.PhoneNumberInputLabel')}
                  placeholder={i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.PhoneNumberInputPlaceholder')}
                  onChange={(e) => setInputPhoneNumber(e.target.value)}
                  value={inputPhoneNumber}
                />
              </Grid>
            </Grid>
          </form>
          <Grid container spacing={4} className={classes.gridContainer}>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.SubscriptionBoxDateLabel')}:</div>
                {subscription.createdAt
                  ? moment(subscription.createdAt).format(`${i18n.get('DateFormat')} ${i18n.get('TimeFormat')}`)
                  : '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.SubscriptionBoxPlanLabel')}:</div>
                {subscription.subscriptionPlan
                  ? subscription.subscriptionPlan?.code ?
                    `${subscription.subscriptionPlan?.name} - ${subscription.subscriptionPlan?.code}`
                    : subscription.subscriptionPlan?.name
                  : '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.SubscriptionBoxBranchLabel')}:</div>
                {subscription.branch ? subscription.branch?.name || '-' : '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.SubscriptionBoxSubscriberLabel')}:</div>
                {subscription.subscriber
                  ? `${subscription.subscriber?.document || '-'} - ${subscription.subscriber?.firstName || '-'} ${
                      subscription.subscriber?.lastName || '-'
                    }`
                  : '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.SubscriptionBoxStatusLabel')}:</div>
                {subscription.subscriptionStatus ? i18n.get(`Statuses.${subscription.subscriptionStatus}`) : '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.SubscriptionBoxPlanAmountLabel')}:</div>
                {subscription.amount
                  ? `${
                      i18n.get(CURRENCY_FILTER_OPTIONS.find((c) => c.id === subscription.currency)?.name) || ''
                    } ${Utils.formatCurrency(subscription.amount)}`
                  : '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>
                  {i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.SubscriptionBoxCollectedAmountLabel')}:
                </div>
                {subscription.collectedAmount
                  ? `${
                      i18n.get(CURRENCY_FILTER_OPTIONS.find((c) => c.id === subscription.currency)?.name) || ''
                    } ${Utils.formatCurrency(subscription.collectedAmount)}`
                  : '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>
                  {i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.SubscriptionBoxLastChargeStatusLabel')}:
                </div>
                {subscription.lastPaymentStatus ? i18n.get(`Statuses.${subscription.lastPaymentStatus}`) : '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.SubscriptionBoxExternalIdLabel')}:</div>
                {subscription.externalId || '-'}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
    >
      <TitleBox
        title={i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.BoxTitle')}
        back
        backTitle={i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.BoxBackButton')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <ContentBox title={i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.BoxContentTitle')} titleBold>
        {renderProvidersSubscriptionsManageSubscriptionEditForm()}
      </ContentBox>
      {subscription.subscriptionPlan?.additionalFields && subscription.subscriptionPlan?.additionalFields.length > 0 ? (
        <React.Fragment>
          <BoxSeparator size="small" />
          <ContentBox
            title={i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.SubscriptionBoxAdditionalDataLabel')}
          >
            {subscription.subscriptionPlan?.additionalFields.map((field, key) => (
              <Grid container spacing={4} key={key}>
                <Grid item xs={12} sm={12} md={3}>
                  <div className={classes.suscriptionDetailsItem}>
                    <div>
                      {i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.SubscriptionBoxAdditionalDataNameLabel')}:
                    </div>
                    {field.name || '-'}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <div className={classes.suscriptionDetailsItem}>
                    <div>
                      {i18n.get(
                        'ProvidersSubscriptionsManageSubscriptionEdit.SubscriptionBoxAdditionalDataInternalNameLabel'
                      )}
                      :
                    </div>
                    {field.key || '-'}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <div className={classes.suscriptionDetailsItem}>
                    <div>
                      {i18n.get('ProvidersSubscriptionsManageSubscriptionEdit.SubscriptionBoxAdditionalDataTypeLabel')}:
                    </div>
                    {field.type ? i18n.get(FIELD_TYPES_OPTIONS.find((s) => s.id === field.type).name) : '-'}
                  </div>
                </Grid>
              </Grid>
            ))}
          </ContentBox>
        </React.Fragment>
      ) : null}
      <BoxSeparator size="small" />
      <Box>
        <div className={classes.contentActionsWrapper}>
          <Button variant="outlined" onClick={_goBack}>
            {i18n.get('Buttons.CANCEL')}
          </Button>
          <div className={classes.contentActionSeparator} />
          <Button onClick={_submitForm}>{i18n.get('Buttons.SAVE')}</Button>
        </div>
      </Box>
    </Page>
  );
};

ProvidersSubscriptionsManageSubscriptionEditScreen.id = 'com.Handy.ProvidersSubscriptionsManageSubscriptionEdit';

export default ProvidersSubscriptionsManageSubscriptionEditScreen;
