import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  formWrapper: {
    padding: theme.spacing(3),
  },
  formInput: {
    width: '100%',

    '& > label': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentTextSoft,
      fontSize: theme.fontSize.L,
    },

    '& > div:before': {
      borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,
    },
    '& > div:after': {
      borderBottom: `1px solid ${Colors.rgb.primary}`,
    },
    '& > div:hover': {
      '&:before': {
        borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted} !important`,
      },
    },

    '& input': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.L,
    },
  },
  formSelect: {
    width: '100%',

    '&:before': {
      borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,
    },
    '&:after': {
      borderBottom: `1px solid ${Colors.rgb.primary}`,
    },
    '&:hover': {
      '&:before': {
        borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted} !important`,
      },
    },

    '& > div': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.L,
    },
  },
  formSelectOption: {
    color: Colors.rgb.contentText,
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.regular,
  },
  formActionsContainer: {
    borderTop: `1px solid ${Colors.rgb.contentLineSoft}`,
    marginTop: theme.spacing(7),
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  formActionsSeparator: {
    width: theme.spacing(2),
  },
  alertSuccess: {
    background: Colors.rgb.alertSuccessBackground,
    border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
    color: `${Colors.rgb.alertSuccessText} !important`,
  },
  alertPending: {
    background: Colors.rgb.alertWarningBackground,
    border: `1px solid ${Colors.rgb.alertWarningBorder}`,
    color: `${Colors.rgb.alertWarningText} !important`,
  },
  alertError: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
}));

export default makeClasses;
