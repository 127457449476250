import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import ROUTES from '../../../../routes';
import {
  BoxSeparator,
  ContentBox,
  Page,
  TitleBox,
  Button,
  ForbiddenSection,
  AlertBranchesWithNoPaymentMethod,
} from '../../../../components';
import { business as businessActions } from '../../../../actions';
import { Colors, Language, Utils } from '../../../../utils';
import { getFileSize } from '../../../../utils/Utils';

import { Grid, TextField, InputAdornment, InputLabel, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { InsertPhotoOutlined as InsertPhotoOutlinedIcon } from '@material-ui/icons';

import makeClasses from './styles';
import moment from 'moment';
import { Policies } from '../../../../utils/Policies';
import HelpBranchesWithNoPaymentMethod from '../../../../components/HelpBranchesWithNoPaymentMethod';

const AddPaymentLinkScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const [ branchesWithSecrets, setBranchesWithSecrets ] = useState([]);
  const [ modificatorType, setModificatorType ] = useState('');
  const [ productName, setProductName ] = useState('');
  const [ productDescription, setProductDescription ] = useState('');
  const [ productInvoiceNumber, setProductInvoiceNumber ] = useState('');
  const [ productBranch, setProductBranch ] = useState('');
  const [ productDaysNumber, setProductDaysNumber ] = useState('3');
  const [ productPrice, setProductPrice ] = useState('');
  const [ productTaxedPrice, setProductTaxedPrice ] = useState('');
  const [ productCurrency, setProductCurrency ] = useState('');
  const [ productCurrencies, setProductCurrencies ] = useState([]);
  const [ productImage, setProductImage ] = useState('');
  const [ loadingProductImage, setLoadingProductImage ] = useState(false);
  const { languageTexts, branchesWithNoPaymentMethod } = useSelector(state => ({
    languageTexts: state.language.texts || {},
    branchesWithNoPaymentMethod: state.business.branchesWithNoPaymentMethod || [],
  }));
  const [ secrets, setSecrets ] = useState([]);
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const [ isCiLogin, setIsCiLogin ] = useState(false);
  const [ showBranchWithNoPaymentMethodWarning, setShowBranchWithNoPaymentMethodWarning ] = useState(false);
  const [ showBranchWithNoPaymentMethodHelp, setShowBranchWithNoPaymentMethodHelp ] = useState(false);
  const i18n = Language(languageTexts);

  const { policies, branches, userData } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    branches: state.business.branches || [],
    userData: state.user.userData || null,
  }));

  const hasProductImage = productImage && productImage.length > 0;
  const canSubmitForm =
    productBranch.length > 0 &&
    productName.length > 0 &&
    productPrice >= 0 &&
    productTaxedPrice >= 0 &&
    productInvoiceNumber.length > 0;

  useEffect(
    () => {
      window.scrollTo(0, 0);

      if (policies && policies.includes(Policies.types.HANDY_LINK_WRITE)) {
        dispatch(
          businessActions.getSecrets(true, secrets => {
            setSecrets(secrets);
            const _secretsTraceIds = secrets.map(s => s.branchTraceId);
            const _branchesWithSecrets = branches.filter(b => _secretsTraceIds.includes(b.traceId));
            setBranchesWithSecrets(_branchesWithSecrets);
            setProductBranch(_branchesWithSecrets[0].traceId);

            if (match.params.traceId) {
              setModificatorType(match.params.modificator);

              dispatch(
                businessActions.getPaymentLink(match.params.traceId, paymentLink => {
                  if (match.params.modificator === 'clone') {
                    setProductImage(paymentLink.bundleImageUrl);
                  }

                  setProductName(paymentLink.productName);
                  setProductDescription(paymentLink.productDescription);
                  setProductInvoiceNumber(paymentLink.invoiceNumber);
                  setProductDaysNumber(moment(paymentLink.expirationDate).diff(paymentLink.creationDate, 'days'));
                  setProductPrice(paymentLink.amount.toString());
                  setProductTaxedPrice(paymentLink.taxedAmount);
                  setProductCurrency(paymentLink.currency.alpha);
                })
              );
            }
          })
        );
      } else {
        setForbbidenSection(true);
      }
    },
    [ policies ]
  );

  useEffect(
    () => {
      if (userData && /^uy\.ci\.\d{8}$/i.test(userData['preferred_username'])) {
        setIsCiLogin(true);
        setProductTaxedPrice(0);
      }
    },
    [ userData ]
  );

  useEffect(
    () => {
      if (productBranch) {
        if (branchesWithNoPaymentMethod.find(b => b.branchTraceId === productBranch)) {
          setShowBranchWithNoPaymentMethodWarning(true);
        } else {
          setShowBranchWithNoPaymentMethodWarning(false);
        }

        dispatch(
          businessActions.getPaymentLinkRestrictions(productBranch, restrictions => {
            setProductCurrencies(restrictions.currencies?.length > 0 ? restrictions.currencies : ['UYU']);
            const hasCurrentProductCurrency = !!restrictions.currencies.find(c => c === productCurrency);
            if (restrictions.currencies.length > 0 && modificatorType !== 'clone' && !hasCurrentProductCurrency) {
              setProductCurrency(restrictions.currencies[0]);
            } else if (restrictions.currencies.length === 0) {
              setProductCurrency('UYU');
            }
          })
        );
      }
    },
    [ productBranch ]
  );

  const _goBack = e => {
    e.preventDefault();
    dispatch(goBack());
  };

  const _handleProductNameOnChange = e => setProductName(e.target.value.substring(0, 100));

  const _handleProductDescriptionOnChange = e => setProductDescription(e.target.value);

  const _handleProductInvoiceNumberOnChange = e => {
    if (
      e.target.value.length === 0 ||
      (/^[0-9]+$/.test(e.target.value) && parseInt(e.target.value) <= Number.MAX_SAFE_INTEGER)
    ) {
      setProductInvoiceNumber(e.target.value);
    }
  };
  const _handleProductBranchOnChange = e => {
    setProductBranch(e.target.value);
  };
  const _handleProductDaysNumberOnChange = e => {
    if (
      e.target.value.length === 0 ||
      (/^[0-9]+$/.test(e.target.value) && parseInt(e.target.value) >= 1 && parseInt(e.target.value) <= 15)
    ) {
      setProductDaysNumber(e.target.value);
    }
  };
  const _handleProductPriceOnChange = e => {
    if (e.target.value.length === 0 || parseFloat(e.target.value) >= 0) {
      setProductPrice(e.target.value);
    }
  };
  const _handleProductPriceOnBlur = () => {
    if (!isCiLogin) {
      setProductTaxedPrice(Utils.taxedAmount(productPrice));
    }
  };
  const _handleProductTaxedPriceOnChange = e => {
    if (e.target.value.length === 0 || parseFloat(e.target.value) >= 0) {
      setProductTaxedPrice(e.target.value);
    }
  };
  const _handleProductCurrencyOnChange = e => setProductCurrency(e.target.value);

  const _submitForm = () => {
    const secret = secrets.find(s => s.branchTraceId === productBranch);

    if (!secret) {
      return false;
    }

    dispatch(
      businessActions.createPaymentLink(
        secret.secret,
        {
          amount: parseFloat(productPrice) || 0,
          taxedAmount: parseFloat(productTaxedPrice) || 0,
          alphaCurrency: productCurrency,
          description: productDescription,
          invoiceNumber: productInvoiceNumber,
          bundleImageUrl: productImage || null,
          productName: productName,
          expirationDays: productDaysNumber ? parseInt(productDaysNumber) : 3,
        },
        traceId => {
          toast.success('El handy link fue creado exitosamente');
          setTimeout(() => dispatch(push(ROUTES.PAYMENT_LINK_SHARE.path.replace(':traceId', traceId))), 1000);
        }
      )
    );
  };

  const _toggleHelp = () => {
    setShowBranchWithNoPaymentMethodHelp(!showBranchWithNoPaymentMethodHelp);
  };

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(async file => {
      const reader = new FileReader();

      if (/(.jpg|.jpeg|.png|.gif|.webp)$/.test(file.name.toLowerCase())) {
        setLoadingProductImage(true);

        const fileSize = await getFileSize(file);
        if (fileSize === -1 || fileSize > 2) {
          setLoadingProductImage(false);
          setProductImage('');
          return toast.error('El tamaño de la imágen no puede superar los 2.5mb');
        }

        reader.onabort = () => {
          setProductImage('');
          setLoadingProductImage(false);
        };
        reader.onerror = () => {
          setProductImage('');
          setLoadingProductImage(false);
        };
        reader.onload = () => {
          const base64Str = reader.result;
          setProductImage(base64Str);
          setLoadingProductImage(false);
        };

        reader.readAsDataURL(file);
      }
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const renderAddPaymentLinkForm = () => {
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} sm={10} md={10} lg={10}>
          <div className={classes.productForm}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label={i18n.get('AddPaymentLink.ProductTitleInputLabel')}
              placeholder={i18n.get('AddPaymentLink.ProductTitleInputPlaceholder')}
              className={classes.productFormInput}
              onChange={_handleProductNameOnChange}
              value={productName}
              InputProps={{
                endAdornment:
                  productName.length > 0 ? (
                    <InputAdornment position="start">
                      <span className={classes.productFormInputEndAdornment}>{productName.length} / 100</span>
                    </InputAdornment>
                  ) : null,
              }}
            />
            <div className={classes.productFormInputSeparator} />
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.productFormInput}
              label={i18n.get('AddPaymentLink.ProductInvoiceNumberInputLabel')}
              placeholder={i18n.get('AddPaymentLink.ProductInvoiceNumberInputPlaceholder')}
              onChange={_handleProductInvoiceNumberOnChange}
              value={productInvoiceNumber}
            />
            <div className={classes.productFormInputSeparator} />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <InputLabel className={classes.productFormSelectLabel} shrink>
                  {i18n.get('AddPaymentLink.ProductBranchInputLabel')}
                </InputLabel>
                <Select
                  className={classes.productFormSelect}
                  onChange={_handleProductBranchOnChange}
                  value={productBranch}
                  displayEmpty
                >
                  {branchesWithSecrets.map((branch, index) => (
                    <MenuItem key={index} value={branch.traceId} className={classes.productFormSelectOption}>
                      {branch.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.productFormInput}
                  label={i18n.get('AddPaymentLink.ProductDaysNumberInputLabel')}
                  placeholder={i18n.get('AddPaymentLink.ProductDaysNumberInputPlaceholder')}
                  onChange={_handleProductDaysNumberOnChange}
                  value={productDaysNumber}
                />
              </Grid>
            </Grid>
            <div className={classes.productFormInputSeparator} />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={2}>
                <InputLabel className={classes.productFormSelectLabel} shrink>
                  {i18n.get('AddPaymentLink.MoreCurrencyInputLabel')}
                </InputLabel>
                <Select
                  className={classes.productFormSelect}
                  onChange={_handleProductCurrencyOnChange}
                  value={productCurrency}
                  displayEmpty
                  renderValue={value => (
                    <div style={{ color: !value ? Colors.rgba(Colors.rgb.black, 50) : null }}>
                      {value ? value : i18n.get('AddPaymentLink.MoreCurrencyDefaultValue')}
                    </div>
                  )}
                >
                  <MenuItem value={''} disabled className={classes.productFormSelectOption}>
                    {i18n.get('AddPaymentLink.MoreCurrencyDefaultValue')}
                  </MenuItem>
                  {productCurrencies.map((currency, index) => (
                    <MenuItem key={index} value={currency} className={classes.productFormSelectOption}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.productFormInput}
                  type="number"
                  label={i18n.get('AddPaymentLink.ProductPriceInputLabel')}
                  placeholder={i18n.get('AddPaymentLink.ProductPriceInputPlaceholder')}
                  onChange={_handleProductPriceOnChange}
                  onBlur={_handleProductPriceOnBlur}
                  value={productPrice}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.productFormInput}
                  type="number"
                  label={i18n.get('AddPaymentLink.ProductTaxedPriceInputLabel')}
                  placeholder={i18n.get('AddPaymentLink.ProductTaxedPriceInputPlaceholder')}
                  onChange={_handleProductTaxedPriceOnChange}
                  value={productTaxedPrice}
                  disabled={isCiLogin}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid className={classes.gridItemFlex} item xs={12} sm={2} md={2} lg={2}>
          <div className={classes.productImagePreview} {...getRootProps()}>
            <input {...getInputProps()} />
            {hasProductImage && !loadingProductImage ? (
              <img src={productImage} className={classes.productImage} alt="Payment Link" />
            ) : (
              <div className={classes.productImagePlaceholder}>
                {loadingProductImage ? (
                  <CircularProgress color="primary" />
                ) : (
                  <InsertPhotoOutlinedIcon color="primary" fontSize="large" />
                )}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    );
  };

  const renderAddPaymentLinkMoreInformationForm = () => {
    return (
      <div className={classes.productFormWrapper}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.productForm}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                label={i18n.get('AddPaymentLink.ProductDescriptionInputLabel')}
                placeholder={i18n.get('AddPaymentLink.ProductDescriptionInputPlaceholder')}
                className={classes.productFormInput}
                onChange={_handleProductDescriptionOnChange}
                value={productDescription}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('AddPaymentLink.Title')}
        withActivePage={ROUTES.PAYMENT_LINK.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('AddPaymentLink.Title')}
      withActivePage={ROUTES.PAYMENT_LINK.id}
      showBranchWithNoPaymentMethodWarning={false}
    >
      <TitleBox
        title={i18n.get('AddPaymentLink.BoxTitle')}
        back
        backTitle={i18n.get('AddPaymentLink.BoxBackButton')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <ContentBox title={i18n.get('AddPaymentLink.BoxContentTitle')} titleBold>
        {showBranchWithNoPaymentMethodWarning ? (
          <AlertBranchesWithNoPaymentMethod
            title={i18n.get('AddPaymentLink.BranchWithNoPaymentMethodModalTitle')}
            showIcon={false}
            onToggleHelp={_toggleHelp}
          />
        ) : null}
        {renderAddPaymentLinkForm()}
      </ContentBox>
      <ContentBox title={i18n.get('AddPaymentLink.MoreInfoBoxContentTitle')} titleBold>
        {renderAddPaymentLinkMoreInformationForm()}
        <div className={classes.contentActionsWrapper}>
          <Button variant="outlined" onClick={_goBack}>
            {i18n.get('AddPaymentLink.CancelButton')}
          </Button>
          <div className={classes.contentActionSeparator} />
          <Button disabled={!canSubmitForm} onClick={_submitForm}>
            {modificatorType === 'modify' ? (
              i18n.get('AddPaymentLink.ConfirmModifyButton')
            ) : (
              i18n.get('AddPaymentLink.ConfirmButton')
            )}
          </Button>
        </div>
      </ContentBox>
      <HelpBranchesWithNoPaymentMethod open={showBranchWithNoPaymentMethodHelp} onToggleHelp={_toggleHelp} />
    </Page>
  );
};

AddPaymentLinkScreen.id = 'com.Handy.AddPaymentLink';

export default AddPaymentLinkScreen;
