import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { toast } from 'react-toastify';

import ROUTES from '../../../../routes';
import {
  BoxSeparator,
  ContentBox,
  Page,
  TitleBox,
  Button,
  ForbiddenSection,
  ChangePaymentMethodModal,
  Confirm,
} from '../../../../components';
import { user as userActions, business as businessActions } from '../../../../actions';
import { Language } from '../../../../utils';

import { Grid, TextField, InputLabel, Select, MenuItem } from '@material-ui/core';

import makeClasses from './styles';
import { Policies } from '../../../../utils/Policies';
import { Alert, AlertTitle } from '@material-ui/lab';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';

const AddPaymentMethodScreen = () => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const [paymentEntities, setPaymentEntities] = useState([]);
  const [paymentCurrencies, setPaymentCurrencies] = useState([]);
  const [paymentEntityRegex, setPaymentEntityRegex] = useState('');
  const [inputEntity, setInputEntity] = useState(0);
  const [inputAccountType, setInputAccountType] = useState(0);
  const [inputCurrency, setInputCurrency] = useState(0);
  const [inputAccountNumber, setInputAccountNumber] = useState('');
  const [inputOwner, setInputOwner] = useState('');
  const [showVerifyCodeModal, setShowVerifyCodeModal] = useState(false);
  const [requestTraceId, setRequestTraceId] = useState(null);
  const [hasValidAccountNumber, setHasValidAccountNumber] = useState(false);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);

  const [forbiddenSection, setForbbidenSection] = useState(false);
  const { policies, authTime, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    authTime: state.user.userData.authTime || Date.now(),
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const ACCOUNT_TYPE_OPTIONS = [
    {
      id: 0,
      name: i18n.get('ManagePaymentMethodsAdd.AccountTypesOptions.DEFAULT_OPTION'),
    },
    {
      id: 'CUENTA_CORRIENTE',
      name: i18n.get('ManagePaymentMethodsAdd.AccountTypesOptions.CUENTA_CORRIENTE'),
    },
    {
      id: 'CAJA_DE_AHORRO',
      name: i18n.get('ManagePaymentMethodsAdd.AccountTypesOptions.CAJA_DE_AHORRO'),
    },
  ];

  const canSubmitForm =
    inputEntity !== 0 &&
    inputAccountType !== 0 &&
    inputCurrency !== 0 &&
    inputAccountNumber.length > 0 &&
    inputOwner.length > 0;

  useEffect(() => {
    if (paymentEntityRegex.accountExpression) {
      setHasValidAccountNumber(new RegExp(paymentEntityRegex.accountExpression).test(inputAccountNumber));
    } else {
      setHasValidAccountNumber(true);
    }
  }, [paymentEntityRegex, inputAccountNumber]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const exiredSession = Math.floor((new Date() - new Date(authTime * 1000)) / (1000 * 60)) >= 15;
    if (exiredSession) {
      return _toggleSessionExpiredModal(true);
    }

    if (policies && policies.includes(Policies.types.SENSITIVE_DATA)) {
      dispatch(
        userActions.getPaymentEntities((data) => {
          setPaymentEntities(data);
        })
      );
      dispatch(
        userActions.getPaymentCurrencies((data) => {
          setPaymentCurrencies(data);
        })
      );
    } else {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (inputEntity) {
      const entityName = paymentEntities.find((e) => e.id === inputEntity).name;
      dispatch(
        userActions.getPaymentEntityRegex(entityName, (data) => {
          setPaymentEntityRegex(data);
        })
      );
    }
  }, [inputEntity]);

  const _goBack = () => {
    dispatch(goBack());
  };

  const _goToPendingPaymentMethods = () => {
    dispatch(businessActions.getBranchesWithNoPaymentMethod());
    dispatch(
      push({
        pathname: ROUTES.MANAGE_PAYMENT_METHODS.path,
        search: '?mode=2',
      })
    );
  };

  const _handleInputEntityOnChange = (e) => setInputEntity(e.target.value);

  const _handleInputCurrencyOnChange = (e) => setInputCurrency(e.target.value);

  const _handleInputAccountTypeOnChange = (e) => setInputAccountType(e.target.value);

  const _handleInputAccountNumberOnChange = (e) => {
    setInputAccountNumber(e.target.value);
  };

  const _handleInputOwnerOnChange = (e) => {
    setInputOwner(e.target.value);
  };

  const _submitForm = () => {
    if (!canSubmitForm) {
      return false;
    }

    if (
      paymentEntityRegex.accountExpression &&
      !new RegExp(paymentEntityRegex.accountExpression).test(inputAccountNumber)
    ) {
      return toast.error(paymentEntityRegex.accountExpressionDescription);
    }

    const entityName = paymentEntities.find((e) => e.id === inputEntity).name;
    dispatch(
      userActions.addPaymentMethod(
        {
          paymentEntityKey: entityName,
          accountType: inputAccountType,
          currencyISO: inputCurrency,
          accountNumber: inputAccountNumber,
          ownerName: inputOwner,
        },
        (traceId) => {
          setRequestTraceId(traceId);
          setShowVerifyCodeModal(true);
        }
      )
    );
  };

  const _handleOnCloseChangePaymentMethodModal = (result) => {
    setShowVerifyCodeModal(false);
    if (result) {
      _goToPendingPaymentMethods();
    }
  };

  const _toggleSessionExpiredModal = (value) => {
    setShowSessionExpiredModal(value);
  };

  const _logOut = () => {
    dispatch(userActions.logOut());
  };

  const renderManagePaymentMethodsAddForm = () => {
    return (
      <Grid container justify="center" alignItems="center" className={classes.userFormWrapper}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form layout="vertical" onSubmit={_submitForm} className={classes.userForm}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                <InputLabel className={classes.formSelectLabel} shrink>
                  {i18n.get('ManagePaymentMethodsAdd.PaymentEntityInputLabel')}
                </InputLabel>
                <Select
                  className={classes.formSelect}
                  onChange={_handleInputEntityOnChange}
                  value={inputEntity}
                  displayEmpty
                >
                  <MenuItem value={0} className={classes.formSelectOption}>
                    {i18n.get('ManagePaymentMethodsAdd.DefaultPaymentEntityOption')}
                  </MenuItem>
                  {paymentEntities.map((entity, index) => (
                    <MenuItem key={index} value={entity.id} className={classes.formSelectOption}>
                      {entity.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputLabel className={classes.formSelectLabel} shrink>
                  {i18n.get('ManagePaymentMethodsAdd.CurrencyInputLabel')}
                </InputLabel>
                <Select
                  className={classes.formSelect}
                  onChange={_handleInputCurrencyOnChange}
                  value={inputCurrency}
                  displayEmpty
                >
                  <MenuItem value={0} className={classes.formSelectOption}>
                    {i18n.get('ManagePaymentMethodsAdd.DefaultCurrencyOption')}
                  </MenuItem>
                  {paymentCurrencies.map((currency, index) => (
                    <MenuItem key={index} value={currency.alpha} className={classes.formSelectOption}>
                      {currency.alpha}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get('ManagePaymentMethodsAdd.AccountNumberInputLabel')}
                  onChange={_handleInputAccountNumberOnChange}
                  value={inputAccountNumber}
                  helperText={paymentEntityRegex ? paymentEntityRegex.accountExpressionDescription : null}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputLabel className={classes.formSelectLabel} shrink>
                  {i18n.get('ManagePaymentMethodsAdd.AccountTypeInputLabel')}
                </InputLabel>
                <Select
                  className={classes.formSelect}
                  onChange={_handleInputAccountTypeOnChange}
                  value={inputAccountType}
                  displayEmpty
                >
                  {ACCOUNT_TYPE_OPTIONS.map((accountType, index) => (
                    <MenuItem key={index} value={accountType.id} className={classes.formSelectOption}>
                      {accountType.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get('ManagePaymentMethodsAdd.OwnerInputLabel')}
                  onChange={_handleInputOwnerOnChange}
                  value={inputOwner}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ManagePaymentMethodsAdd.Title')}
        withActivePage={ROUTES.MANAGE_PAYMENT_METHODS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ManagePaymentMethodsAdd.Title')}
      withActivePage={ROUTES.MANAGE_PAYMENT_METHODS.id}
    >
      <TitleBox
        title={i18n.get('ManagePaymentMethodsAdd.BoxTitle')}
        back
        backTitle={i18n.get('ManagePaymentMethodsAdd.BoxBackButton')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <ContentBox title={i18n.get('ManagePaymentMethodsAdd.BoxContentTitle')} titleBold>
        {renderManagePaymentMethodsAddForm()}
        <div>
          <div className={classes.contentActionsWrapper}>
            <Button variant="outlined" onClick={_goBack}>
              {i18n.get('ManagePaymentMethodsAdd.CancelButton')}
            </Button>
            <div className={classes.contentActionSeparator} />
            <Button disabled={!canSubmitForm || !hasValidAccountNumber} onClick={_submitForm}>
              {i18n.get('ManagePaymentMethodsAdd.ContinueButton')}
            </Button>
          </div>
        </div>
      </ContentBox>
      <ChangePaymentMethodModal
        open={showVerifyCodeModal}
        requestTraceId={requestTraceId}
        onClose={_handleOnCloseChangePaymentMethodModal}
        onRequestNewCode={_submitForm}
      />
      <Confirm
        open={showSessionExpiredModal}
        onClose={_goBack}
        title={i18n.get('ManagePaymentMethods.ExpiredSessionDialogTitle')}
        onConfirm={_logOut}
        confirmText={i18n.get('ManagePaymentMethods.ExpiredSessionDialogActionConfirm')}
        cancelText={i18n.get('ManagePaymentMethods.ExpiredSessionDialogActionCancel')}
        onCancel={_goBack}
      >
        <Alert severity="info" icon={false} className={clsx(classes.alert, classes.alertInfo)}>
          <div>
            <InfoOutlinedIcon className={classes.alertIcon} />
          </div>
          <div className={classes.alertMessage}>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get('ManagePaymentMethods.ExpiredSessionDialogContentTitle')}
            </AlertTitle>
            {i18n.get('ManagePaymentMethods.ExpiredSessionDialogContentMessage')}
          </div>
        </Alert>
      </Confirm>
    </Page>
  );
};

AddPaymentMethodScreen.id = 'com.Handy.ManagePaymentMethodsAdd';

export default AddPaymentMethodScreen;
