import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles(theme => ({
  contentActionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing(2),

    '& > button:last-child': {
      marginRight: 0,
    },
  },
  contentActionSeparator: {
    width: theme.spacing(2),
  },
}));

export default makeClasses;
