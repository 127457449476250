import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  dialog: {
    boxShadow: 'none',
  },
  dialogTitleWrapper: {
    background: Colors.rgb.black_light,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
  dialogTitle: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.XL,
    color: Colors.rgb.white,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: theme.fontSize.M,
    },
  },
  dialogContentWrapper: {
    padding: theme.spacing(4),
  },
  dialogContent: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentText,

    '& h2': {
      fontFamily: theme.fontFamily.regular,
      fontSize: theme.fontSize.XL,
      color: Colors.rgb.contentText,
      marginTop: 0,
      marginBottom: theme.spacing(4),
    },
  },
  dialogActionsWrapper: {
    padding: theme.spacing(2),
  },
}));

export default makeClasses;
