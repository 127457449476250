import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import qs from 'query-string';

import ROUTES from '../../../routes';
import {
  Box,
  BoxSeparator,
  BoxMenu,
  Button,
  ContentBox,
  EmptyMessage,
  FiltersContainer,
  ForbiddenSection,
  OperationsTable,
  Page,
  TitleBox,
  FiltersSeparator,
  BranchesSelector,
  FiltersSelector,
} from '../../../components';
import { business as businessActions } from '../../../actions';
import { Language, Utils } from '../../../utils';

import ListItemText from '@material-ui/core/ListItemText';
import { Visibility as VisisbilityIcon, Edit as EditIcon } from '@material-ui/icons';
import MoreVerticalIcon from '../../../static/images/icons/more_vertical.svg';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import makeClasses from './styles';
import { IconButton, ListItemIcon, Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import { Policies } from '../../../utils/Policies';
import moment from 'moment';

const STATUS_FILTER_OPTIONS = [
  { id: 'ACTIVE', name: 'ManageSubscriptions.ValueStatusActive' },
  { id: 'INACTIVE', name: 'ManageSubscriptions.ValueStatusInactive' },
];

const ProvidersManageServicesScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [services, setServices] = useState({});

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [paginationPage, setPaginationPage] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(searchParams.bid || 'all');
  const [selectedService, setSelectedService] = useState(searchParams.sid || 'all');
  const [providersServicesOptions, setProvidersServicesOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(searchParams.tid ? searchParams.tid : 'all');
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const { policies, languageTexts, branches } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
    branches: state.business.branches || [],
  }));
  const i18n = Language(languageTexts);
  const hasServices = services && services.elements && services.elements.length > 0;
  const canExport = policies.includes(Policies.types.PROVIDER) && policies.includes(Policies.types.SUPER_READ);
  const canManage = policies.includes(Policies.types.PROVIDER_WRITE);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (policies && policies.includes(Policies.types.PROVIDER)) {
      loadServices();
    } else {
      setForbbidenSection(true);
    }
  }, [policies, selectedBranch, selectedService, selectedStatus, paginationPage]);

  const loadServices = () => {
    if (!filtersLoaded) {
      loadFiltersData();
    }

    dispatch(
      businessActions.getProducts(
        selectedBranch !== 'all' ? selectedBranch : null,
        selectedService !== 'all' ? selectedService : null,
        selectedStatus !== 'all' ? selectedStatus === 'ACTIVE' : null,
        paginationPage,
        (data) => {
          setServices(data);
        }
      )
    );
  };

  const loadFiltersData = () => {
    dispatch(
      businessActions.getProviderServices((data) => {
        const options = data.elements
          ? data.elements.filter((e) => e).map((e) => ({ id: e.traceId, name: e.name }))
          : [];
        setProvidersServicesOptions(options);
      })
    );
    setFiltersLoaded(true);
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const handleOnViewService = (service) => () => {
    toggleMenu()();
    dispatch(push(ROUTES.MANAGE_PROVIDERS_SERVICES_VIEW.path.replace(':traceId', service.traceId)));
  };

  const handleOnEditService = (service) => () => {
    toggleMenu()();
    dispatch(push(ROUTES.MANAGE_PROVIDERS_SERVICES_EDIT.path.replace(':traceId', service.traceId)));
  };

  const handleOnPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const renderServices = () => {
    if (!services || !services.elements || services.elements.length === 0) {
      return <EmptyMessage textOnly>{i18n.get('ProvidersManageServices.GridEmptyMessage')}</EmptyMessage>;
    }

    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('ProvidersManageServices.ServicesGrid.Column1'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('ProvidersManageServices.ServicesGrid.Column2'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get('ProvidersManageServices.ServicesGrid.Column3'),
        sortable: false,
        type: 'status',
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get('ProvidersManageServices.ServicesGrid.Column4'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get('ProvidersManageServices.ServicesGrid.Column5'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 6,
        name: i18n.get('ProvidersManageServices.ServicesGrid.Column6'),
        sortable: false,
        type: 'custom',
        align: 'right',
        show: !isSm,
      },
    ];
    const body = (services.elements || []).map((item, index) => ({
      rowId: item.traceId,
      columns: [
        {
          columnNumber: 1,
          data: item.name,
        },
        {
          columnNumber: 2,
          data: item.internalName,
        },
        {
          columnNumber: 3,
          data: item.isActive,
        },
        {
          columnNumber: 4,
          data: item.branch.name,
        },
        {
          columnNumber: 5,
          data: item.currency,
        },
        {
          columnNumber: 6,
          data: () => {
            return (
              <div className={classes.listItemActions}>
                <IconButton onClick={toggleMenu(index)}>
                  <img src={MoreVerticalIcon} alt="More" />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  onClose={toggleMenu()}
                  open={menuOpenIndex === index}
                  className={classes.listItemMenu}
                >
                  <MenuItem onClick={handleOnViewService(item)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ProvidersManageServices.ActionViewDetails')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  {canManage ? (
                    <MenuItem onClick={handleOnEditService(item)} className={classes.listItemMenuItem}>
                      <ListItemIcon className={classes.menuItemIcon}>
                        <EditIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.get('ProvidersManageServices.ActionEdit')}
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                </Menu>
              </div>
            );
          },
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={services.hasNext}
        onPaginationChange={handleOnPaginationChange}
        emptyMessage={i18n.get('ProvidersManageServices.GridEmptyMessage')}
        showDetails={false}
      />
    );
  };

  const handleOnExport = () => {
    dispatch(
      businessActions.exportProviderServices(
        paginationPage,
        selectedBranch !== 'all' ? selectedBranch : null,
        selectedService !== 'all' ? selectedService : null,
        selectedStatus === 'ACTIVE' ? true : selectedStatus === 'INACTIVE' ? false : null,
        (file) =>
          Utils.downloadFile(file, 'Cobranzas_Servicios_' + moment().format(i18n.get('ExportDateFormat')) + '.xlsx')
      )
    );
  };

  const handleOnMenuItemClick = (item) => {
    dispatch(push(item.path));
  };

  const handleOnBranchSelected = (branch) => {
    setSelectedBranch(branch);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.bid = branch;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnServiceSelected = (service) => {
    setSelectedService(service);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.sid = service;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnStatusSelected = (status) => {
    setSelectedStatus(status);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.tid = status;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const renderBranchesSelector = () => (
    <BranchesSelector branches={branches} selectedBranch={selectedBranch} onBranchSelected={handleOnBranchSelected} />
  );

  const renderServicesSelector = () => {
    return (
      <FiltersSelector
        options={providersServicesOptions}
        selected={selectedService}
        onSelected={handleOnServiceSelected}
        defaultOption={i18n.get('ProvidersManageServices.FilterServicesDefaultOption')}
      />
    );
  };

  const renderStatusesSelector = () => {
    return (
      <FiltersSelector
        options={STATUS_FILTER_OPTIONS.map((s) => ({
          ...s,
          name: i18n.get(s.name),
        }))}
        selected={selectedStatus}
        onSelected={handleOnStatusSelected}
        defaultOption={i18n.get('ProvidersManageServices.FilterStatusesDefaultOption')}
      />
    );
  };

  const renderExportButton = () => {
    if (!canExport) {
      return null;
    }
    return (
      <Button rightIcon={() => <SaveAltIcon fontSize="small" />} onClick={handleOnExport}>
        {isSm ? null : i18n.get('ProvidersManageServices.ExportButton')}
      </Button>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ProvidersManageServices.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ProvidersManageServices.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS.id}
    >
      <BoxMenu
        items={[
          {
            ...ROUTES.MANAGE_PROVIDERS_SERVICES,
            label: i18n.get('ProvidersManageServices.BoxMenu.Item1'),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIBERS,
            label: i18n.get('ProvidersManageServices.BoxMenu.Item2'),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_CHARGES,
            label: i18n.get('ProvidersManageServices.BoxMenu.Item3'),
          },
        ]}
        selectedItem={ROUTES.MANAGE_PROVIDERS_SERVICES.id}
        onClickItem={handleOnMenuItemClick}
      />
      <TitleBox title={i18n.get('ProvidersManageServices.BoxTitle')} />
      <BoxSeparator size="small" />
      <React.Fragment>
        <ContentBox title={i18n.get('ProvidersManageServices.BoxFiltersTitle')}>
          <FiltersContainer>
            {renderBranchesSelector()}
            <FiltersSeparator />
            {renderServicesSelector()}
            <FiltersSeparator />
            {renderStatusesSelector()}
          </FiltersContainer>
        </ContentBox>
        <BoxSeparator size="small" />
        <ContentBox
          title={i18n.get('ProvidersManageServices.ServicesGridTitle')}
          titleBold
          customRightAction={renderExportButton}
        >
          {hasServices ? (
            renderServices()
          ) : (
            <div className={classes.noDataMessage}>{i18n.get('ProvidersManageServices.NoServicesMessage')}</div>
          )}
        </ContentBox>
      </React.Fragment>
    </Page>
  );
};

ProvidersManageServicesScreen.id = 'com.Handy.ProvidersManageServices';

export default ProvidersManageServicesScreen;
