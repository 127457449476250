import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Language, Utils } from '../../../utils';
import { user as userActions } from '../../../actions';
import { Button } from '../../../components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import makeClasses from '../styles';
import clsx from 'clsx';
import FileUploader from '../../../components/FileUploader';

const StepFive = ({ history, nextStep, prevStep, handleChange, stepOneData }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const { loading, languageTexts } = useSelector((state) => ({
    loading: state.user.loading,
    languageTexts: state.language.texts || {},
  }));

  const i18n = Language(languageTexts);

  const [positions, setPositions] = useState([]);

  const [inputCi, setInputCi] = useState('');
  const [inputPosition, setInputPositions] = useState(null);
  const [filesPoliticalExpose, setFilesPoliticalExpose] = useState([]);

  const canMoveToStep6 = inputCi.length > 0 && inputPosition !== null;

  useEffect(() => {
    dispatch(
      userActions.getPositions((positions) => {
        setPositions(positions);
      })
    );
  }, []);

  const _handleOnChangePositions = (e, newValue) => {
    setInputPositions(newValue);
  };

  const _getOptionLabel = (array) => (option) => {
    let foundOption = null;

    if (array === 'positions') {
      foundOption = positions.find((p) => p.id === option);
    }

    if (foundOption) {
      return foundOption.name;
    }
    return '';
  };

  const handleNext = () => {
    if (!Utils.inputs.ci.regex.test(inputCi.trim())) {
      return toast.error(i18n.get('LegalEntityRegistration.RegisterInvalidCi'), { autoClose: 5000 });
    }

    dispatch(
      userActions.merchantRepresentativeData(
        stepOneData,
        {
          countryISO: 'UY',
          documentTypeKey: 'CI',
          document: inputCi.trim(),
          positionId: inputPosition,
        },
        filesPoliticalExpose,
        (result) =>
          result == 'ok'
            ? nextStep({ data: stepOneData })
            : toast.error(i18n.get('LegalEntityRegistration.UploadError'))
      )
    );
  };

  const handleFileChange = (newFiles, setFile) => {
    setFile(newFiles);
  };

  const handleButtonClick = () => {
    document.getElementById('file').click();
  };

  return (
    <React.Fragment>
      <div className={classes.formContent}>
        <div className={classes.formTitleContainer}>
          <div className={classes.formTitle}>{i18n.get('LegalEntityRegistration.StepFormTitle')}</div>
          <div className={classes.formText}>{i18n.get('LegalEntityRegistration.Step5FormText')}</div>
        </div>
        <form layout="vertical" onSubmit={handleNext} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <TextField
                placeholder={i18n.get('LegalEntityRegistration.InputCiTitle')}
                className={classes.formInput}
                onChange={(e) => setInputCi(e.target.value)}
                classes={{ root: classes.formInput }}
                variant="outlined"
                value={inputCi}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <Autocomplete
                options={positions.map((p) => p.id)}
                getOptionLabel={_getOptionLabel('positions')}
                onChange={_handleOnChangePositions}
                classes={{ inputRoot: classes.formInputSelect }}
                value={inputPosition}
                renderInput={(params) => (
                  <TextField
                    className={classes.formInput}
                    classes={{ root: classes.formInput }}
                    variant="outlined"
                    placeholder={i18n.get('LegalEntityRegistration.InputPositionTitle')}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <div className={classes.formInputTitle}>{i18n.get('LegalEntityRegistration.PepTitle')}</div>
              <FileUploader
                onFileChange={(files) => handleFileChange(files, setFilesPoliticalExpose)}
                title={i18n.get('LegalEntityRegistration.InputDDJJTitle')}
              />
            </Grid>
          </Grid>
        </form>
      </div>
      <div className={classes.termsWrapper}></div>
      <div className={clsx(classes.formSubmitContainer, classes.formSubmitContainerWithOneMiniButton)}>
        <Button className={classes.formSubmitButton} disabled={loading || !canMoveToStep6} onClick={handleNext}>
          {i18n.get('LegalEntityRegistration.NextButton')}
        </Button>
      </div>
    </React.Fragment>
  );
};

StepFive.id = 'com.Handy.LegalEntityRegistration.StepFive';
export default StepFive;
