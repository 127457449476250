import { makeStyles } from '@material-ui/core';
import { THEME_LAYOUT } from '../../theme';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  searchWrapper: {
    width: 400,
    height: 44,
    boxShadow: 'none',
    border: `1px solid ${Colors.rgb.contentLine}`,
    borderRadius: 0,
    boxSizing: 'border-box',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  searchWrapperWithMargin: {
    marginTop: theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.S),
    marginBottom: theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.M),
  },
  searchInput: {
    marginLeft: 0,
    flex: 1,
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.L,
    height: '100%',
    width: 'calc(100% - 50px)',
  },
  searchIconButton: {
    padding: 10,

    '& svg': {
      fontSize: '1.1em',
      marginTop: `-${theme.spacing(1)}`,
    },
  },
}));

export default makeClasses;
