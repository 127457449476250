import { makeStyles } from '@material-ui/core';
import { THEME_LAYOUT } from '../../theme';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  calendarHeader: {
    marginBottom: theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.S),
    paddingBottom: theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.S),
    borderBottom: `1px solid ${Colors.rgb.contentLine}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  calendarHeaderTitle: {
    fontFamily: theme.fontFamily.semibold,
    fontSize: theme.fontSize.XL,
    textAlign: 'center',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.L,
      justifyContent: 'flex-end',
    },
  },
  calendarHeaderSide: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',

    [theme.breakpoints.down('sm')]: {
      width: '55%',
    },
  },
  calendarHeaderSideLeft: {
    justifyContent: 'flex-start',
  },
  calendarHeaderSideRight: {
    justifyContent: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  calendarHeaderButtonSeparator: {
    width: theme.spacing(2),
  },
  headerNavigationButton: {
    padding: `${theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.XS)} ${theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.S)}`,
    background: Colors.rgb.primary,
    fontSize: theme.fontSize.M,
    color: Colors.rgb.white,
    fontFamily: theme.fontFamily.light,
    borderRadius: theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.XS),
    marginRight: theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.XS),
    cursor: 'pointer',

    '&:last-child': {
      marginRight: 0,
    },

    '&:hover': {},
  },
  headerNavigationButtonDisabled: {
    background: Colors.rgba(Colors.rgb.primary, 80),
    cursor: 'default',
  },
  headerNavigationButtonSelected: {
    background: Colors.rgb.secondary,
  },
  calendarTh: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: Colors.rgb.contentTextHighlighted,
    fontFamily: theme.fontFamily.light,
    background: Colors.rgb.white,
    fontSize: theme.fontSize.L,
    borderRadius: 0,
    border: `1px solid ${Colors.rgb.contentLine}`,
    borderLeft: 'none',
  },
  calendarThFirst: {
    borderLeft: `1px solid ${Colors.rgb.contentLine}`,
  },
  calendarThHighlighted: {
    background: theme.palette.primary.main,
    color: Colors.rgb.white,
  },
  calendarTd: {
    position: 'relative',
    textAlign: 'center',
    color: Colors.rgba(Colors.rgb.black, 60),
    background: Colors.rgb.white,
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.M,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 140,
    cursor: 'default',
    border: `1px solid ${Colors.rgb.contentLine}`,
    borderLeft: 'none',
    padding: theme.spacing(2),
  },
  calendarTdFirst: {
    borderLeft: `1px solid ${Colors.rgb.contentLine}`,
  },
  calendarTdClickable: {
    cursor: 'pointer',

    '&:hover': {
      background: Colors.rgba(Colors.rgb.black, 5),
    },
  },
  calendarTdCurrent: {
    color: Colors.rgb.white,
    border: `1px solid ${Colors.rgb.primary}`,

    '&:hover': {
      background: Colors.rgba(Colors.rgb.black, 5),
    },
  },
  calendarTdPast: {
    background: Colors.rgb.contentBackgroundPrimary,
  },
  calendarTdDisabled: {
    background: Colors.rgb.contentBackgroundLight,
  },
  calendarTdCaption: {
    position: 'absolute',
    bottom: theme.spacing(2),
    color: Colors.rgb.white,
    background: Colors.rgb.primary,
    fontSize: theme.fontSize.XS,
    borderRadius: 2,
    padding: `0 ${theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.L)}`,
  },
  calendarDateNumber: {
    position: 'absolute',
    background: Colors.rgb.contentBackgroundPrimary,
    width: 20,
    height: 20,
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 5,
    right: 5,
    color: Colors.rgb.contentText,
    fontSize: theme.fontSize.XXS,
  },
  calendarTdContent: {
    width: '100%',
    fontSize: theme.fontSize.S,
    color: Colors.rgb.white,
    background: Colors.rgb.primary,
    borderRadius: 2,
  },
  calendarTdContentLast: {
    marginTop: 1,
  },
  calendarTdContentFuture: {
    background: Colors.rgb.gold,
  },
  calendarBodyListItemWrapper: {
    borderRadius: '0 !important',
    boxShadow: 'none !important',
  },
  calendarBodyListItem: {
    border: `1px solid ${Colors.rgb.contentLine}`,
    padding: `${theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.XS)} ${theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.L)}`,
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.M,

    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: `${theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.S)} 0`,
    },

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
  },
  calendarBodyListItemExpanded: {
    minHeight: '48px !important',

    '& > div': {
      margin: `${theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.S)} 0 !important`,
    },
  },
  calendarBodyListItemDetailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.S)} ${theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.L)}`,
    border: `1px solid ${Colors.rgb.contentLine}`,
    borderTop: 'none',
  },
  calendarBodyListItemDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: '100',
    borderBottom: `1px solid ${Colors.rgb.contentLine}`,
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.M,
    color: Colors.rgb.contentTextHighlighted,
    padding: `${theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.S)} 0`,
  },
  calendarBodyListItemToday: {
    backgroundColor: Colors.rgb.primary,
    border: `1px solid ${Colors.rgb.primary}`,
    color: Colors.rgb.white,

    '& span': {
      color: Colors.rgb.white,
    },
  },
  calendarBodyListItemTomorrow: {
    backgroundColor: Colors.rgb.secondary,
    border: `1px solid ${Colors.rgb.secondary}`,
    color: Colors.rgb.white,

    '& span': {
      color: Colors.rgb.white,
    },
  },
  calendarBodyListItemFuture: {
    backgroundColor: Colors.rgb.grey,
    border: `1px solid ${Colors.rgb.contentLine}`,
  },
  calendarBodyListItemDate: {
    fontWeight: '500',

    '& > span': {
      fontWeight: '400',
      fontSize: '0.8rem',
      marginLeft: 5,
    },
  },
  calendarBodyListItemAmount: {
    color: Colors.rgba(Colors.rgb.black, 80),

    '& > span:last-child': {
      marginLeft: 15,
    },
  },
  calendarBodyListItemLinkWrapper: {
    width: '100%',
    paddingTop: theme.spacing(2),
    textAlign: 'right',
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.M,
  },
  headerDisplayOptions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: theme.fontSize.M,

    '& > span': {
      marginRight: theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.XS),
    },
  },
}));

export default makeClasses;
