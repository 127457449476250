import { makeStyles } from "@material-ui/core";
import { Colors } from "../../../../../utils";

const makeClasses = makeStyles((theme) => ({
  formWrapper: {
    paddingBottom: theme.spacing(1),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  formInput: {
    width: "100%",

    "& > label": {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentTextSoft,
      fontSize: theme.fontSize.L,
    },

    "& > div:before": {
      borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,
    },
    "& > div:after": {
      borderBottom: `1px solid ${Colors.rgb.primary}`,
    },
    "& > div:hover": {
      "&:before": {
        borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted} !important`,
      },
    },

    "& input": {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.L,
    },
  },
  formInputSelect: {
    padding: "0 !important",
    width: "100%",

    "&:before": {
      borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,
    },
    "&:after": {
      borderBottom: `1px solid ${Colors.rgb.primary}`,
    },
    "&:hover": {
      "&:before": {
        borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted} !important`,
      },
    },

    "& > div": {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.L,
    },
  },
  formInputSeparator: {
    height: theme.spacing(2),
  },
  formSeparator: {
    height: theme.spacing(1),
  },
  contentActionsWrapper: {
    width: '100%',
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    "& > button:last-child": {
      marginRight: 0,
    },
  },
  contentActionSeparator: {
    width: theme.spacing(3),
  },
  autoApprovalWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: `${theme.spacing(6)} 0 ${theme.spacing(2)}`,
  },
  autoApprovalLabel: {
    display: "block",
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentText,
    fontSize: theme.fontSize.L,
  },
  autoApprovalInputDisabledWrapper: {
    opacity: 0.5,
  },
  //
  planDetailsItem: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentTextHighlighted,
    fontSize: theme.fontSize.L,

    "& > div": {
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.M,
    },

    '& a': {
      color: Colors.rgb.primary
    }
  },
  planDetailsItemDisabled: {
    opacity: 0.5,
  },
  subscriptionDetailsStatus: {
    display: "inline-block",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: 5,
    fontSize: theme.fontSize.S,
  },
  subscriptionDetailsStatusActive: {
    background: Colors.rgb.alertSuccessBackground,
    border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
    color: `${Colors.rgb.alertSuccessText} !important`,
  },
  subscriptionDetailsStatusInactive: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
  callbackCheckboxWrapper: {
    overflow: "hidden",
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
}));

export default makeClasses;
