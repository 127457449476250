import { makeStyles } from '@material-ui/core';
import { THEME_LAYOUT } from '../../theme';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  //
  // Tiles
  tile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tileTotal: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tileAmount: {
    fontSize: '1.5rem',
    fontFamily: theme.fontFamily.bold,
    color: Colors.rgb.contentTextHighlighted,

    '&:last-child': {
      borderLeft: `2px solid ${Colors.rgb.contentTextHighlighted}`,
      marginLeft: theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.S),
      paddingLeft: theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.S),
    },
  },
  //
  // Table
  reversalsWrapper: {
    marginTop: 35,
    position: 'relative',
  },
}));

export default makeClasses;
