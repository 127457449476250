import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router"; // react-router v4/v5
import ROUTES from "../../routes";

import Login from "../Login";
import Register from "../Register";
import LegalEntityRegistration from "../LegalEntityRegistration";
import CentralRegister from "../CentralRegister";

import Home from "../Home";
import YourMoney from "../YourMoney";
import PaymentDetailsByDay from "../PaymentDetailsByDay";
import OperationDetails from "../OperationDetails";
import Activity from "../Activity";
import Vouchers from "../Vouchers";
import Reversals from "../Reversals";
import Loans from "../Loans";
import LoanDetails from "../LoanDetails";
import History from "../History";
import PaymentLink from "../PaymentLink";
import {
  AddPaymentLink,
  SharePaymentLink,
  ViewPaymentLink,
} from "../PaymentLink/screens";
import Profile from "../Profile";
import MobileBalance from "../MobileBalance";
import Account from "../Account";
import AccountDetails from "../AccountDetails";
import Itau from "../Itau";
import ItauSale from "../ItauSale";
import HandyQr from "../HandyQr";
import ManageUsers from "../ManageUsers";
import {
  ManageUsersAdd,
  ManageUsersEdit,
  ManageUsersChangePassword,
} from "../ManageUsers/screens";
import ManagePaymentMethods from "../ManagePaymentMethods";
import { ManagePaymentMethodsAdd } from "../ManagePaymentMethods/screens";
import ManagePosRequests from "../ManagePosRequests";
import { AddPosRequest } from "../ManagePosRequests/screens";
import ManageHandyTap from "../ManageHandyTap";
import { AddHandyTap } from "../ManageHandyTap/screens";
import ManageSubscriptions from "../ProvidersPayments/ManageSubscriptions";
import {
  ManageSubscriptionsAdd,
  ManageSubscriptionsView,
  ManageSubscriptionsPaymentDetails,
} from "../ProvidersPayments/ManageSubscriptions/screens";
import ManagePayments from "../ProvidersPayments/ManagePayments";
import { useSelector } from "react-redux";
import Smooch from "smooch";
import ManageProvidersServices from "../Providers/ManageServices";
import { ManageProvidersServicesEdit } from "../Providers/ManageServices/screens";
import ManageProvidersSubscribers from "../Providers/ManageSubscribers";
import {
  ManageProvidersViewSubscriber,
  ManageProvidersChargeDetails,
  ManageProvidersRequestNewCharge,
} from "../Providers/ManageSubscribers/screens";
import ManageProvidersCharges from "../Providers/ManageCharges";
import ManageProvidersSubscriptionsPlans from "../ProvidersSubscriptions/ManagePlans";
import {
  ProvidersSubscriptionsAddPlan,
  ProvidersSubscriptionsPlanDetails,
} from "../ProvidersSubscriptions/ManagePlans/screens";
import ManageProvidersSubscriptionsSubscriptions from "../ProvidersSubscriptions/ManageSubscriptions";
import {
  ManageProvidersSubscriptionsSubscriptionDetails,
  ManageProvidersSubscriptionsEditSubscription,
} from "../ProvidersSubscriptions/ManageSubscriptions/screens";
import ManageProvidersSubscriptionsTransactions from "../ProvidersSubscriptions/ManageTransactions";
import { ManageProvidersSubscriptionsTransactionDetails } from "../ProvidersSubscriptions/ManageTransactions/screens";
import ManageProvidersSubscriptionsPromotions from "../ProvidersSubscriptions/ManagePromotions";
import {
  ManageProvidersSubscriptionsPromotionDetails,
  ManageProvidersSubscriptionsAddPromotion,
} from "../ProvidersSubscriptions/ManagePromotions/screens";
import ManageProvidersSubscriptionsTermsAndConditions from "../ProvidersSubscriptions/ManageTermsAndConditions";
import { ManageProvidersSubscriptionsAddTermsAndConditions } from "../ProvidersSubscriptions/ManageTermsAndConditions/screens";
import HandyTap from "../HandyTap";

const App = () => {
  const { userData } = useSelector(({ user }) => ({
    userData: user.userData || null,
  }));

  useEffect(() => {
    if (
      userData &&
      userData.merchantTraceId &&
      userData.authTime > 0 &&
      process.env.REACT_APP_ZENDESK_SHOW_CHATBOT === "YES"
    ) {
      Smooch.init({
        integrationId: process.env.REACT_APP_ZENDESK_ID,
        embedded: false,
        businessName: process.env.REACT_APP_ZENDESK_BUSINESS_NAME,
        businessIconUrl: process.env.REACT_APP_ZENDESK_BUSINESS_ICON_URL,
        canUserCreateMoreConversations: false,
        canUserSeeConversationList: false,
        customColors: {
          conversationColor: "333333",
          brandColor: process.env.REACT_APP_ZENDESK_BRAND_COLOR,
        },
        menuItems: {
          imageUpload: true,
          fileUpload: true,
          shareLocation: true,
        },
        buttonIconUrl: process.env.REACT_APP_ZENDESK_BUTTON_ICON_URL,
      });

      Smooch.on("widget:opened", () => {
        Smooch.createConversation({
          messages: [
            {
              text: "Hola...",
              type: "text",
              author: { type: "business" },
            },
          ],
        }).then(() => {
          console.debug("Conversación iniciada");
        });
      });
    } else {
      Smooch.destroy();
    }

    return () => {
      Smooch.destroy();
    };
  }, [userData]);

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route exact path={ROUTES.LOGIN.path} component={Login} />
        <Route exact path={ROUTES.REGISTER.path} component={CentralRegister} />
        <Route
          exact
          path={ROUTES.PHYSICAL_PERSON_REGISTRATION.path}
          component={Register}
        />
        <Route
          exact
          path={ROUTES.LEGAL_ENTITY_REGISTRATION.path}
          component={LegalEntityRegistration}
        />
        <Route exact path={ROUTES.HOME.path} component={Home} />
        <Route
          exact
          path={ROUTES.PAYMENT_DETAILS_BY_DAY.path}
          component={PaymentDetailsByDay}
        />
        {process.env.REACT_APP_ENABLE_SECTION_YOUR_MONEY === "YES" ? (
          <Route exact path={ROUTES.YOUR_MONEY.path} component={YourMoney} />
        ) : null}
        <Route
          exact
          path={ROUTES.OPERATION_DETAILS.path}
          component={OperationDetails}
        />
        {process.env.REACT_APP_ENABLE_SECTION_ACTIVITY === "YES" ? (
          <Route exact path={ROUTES.ACTIVITY.path} component={Activity} />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_ACCOUNT === "YES" ? (
          <Route exact path={ROUTES.ACCOUNT.path} component={Account} />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_ACCOUNT === "YES" ? (
          <Route
            exact
            path={ROUTES.ACCOUNT_DETAILS.path}
            component={AccountDetails}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_REVERSALS === "YES" ? (
          <Route exact path={ROUTES.REVERSALS.path} component={Reversals} />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_VOUCHERS === "YES" ? (
          <Route exact path={ROUTES.VOUCHERS.path} component={Vouchers} />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_LOANS === "YES" ? (
          <Route exact path={ROUTES.LOANS.path} component={Loans} />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_LOAN_DETAILS === "YES" ? (
          <Route
            exact
            path={ROUTES.LOAN_DETAILS.path}
            component={LoanDetails}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_MOBILE_BALANCE === "YES" ? (
          <Route
            exact
            path={ROUTES.MOBILE_BALANCE.path}
            component={MobileBalance}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_HISTORY === "YES" ? (
          <Route exact path={ROUTES.HISTORY.path} component={History} />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PAYMENT_LINK === "YES" ? (
          <Route
            exact
            path={ROUTES.PAYMENT_LINK.path}
            component={PaymentLink}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PAYMENT_LINK === "YES" ? (
          <Route
            exact
            path={ROUTES.PAYMENT_LINK_NEW.path}
            component={AddPaymentLink}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PAYMENT_LINK === "YES" ? (
          <Route
            exact
            path={ROUTES.PAYMENT_LINK_VIEW.path}
            component={ViewPaymentLink}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PAYMENT_LINK === "YES" ? (
          <Route
            exact
            path={ROUTES.PAYMENT_LINK_SHARE.path}
            component={SharePaymentLink}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PAYMENT_LINK === "YES" ? (
          <Route
            exact
            path={ROUTES.PAYMENT_LINK_MODIFY.path}
            component={AddPaymentLink}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PAYMENT_LINK === "YES" ? (
          <Route exact path={ROUTES.HANDY_QR.path} component={HandyQr} />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_TAP_TO_PHONE === "YES" ? (
          <Route exact path={ROUTES.HANDY_TAP.path} component={HandyTap} />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROFILE === "YES" ? (
          <Route exact path={ROUTES.PROFILE.path} component={Profile} />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_MANAGE_PAYMENT_METHODS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PAYMENT_METHODS.path}
            component={ManagePaymentMethods}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_MANAGE_PAYMENT_METHODS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PAYMENT_METHODS_ADD.path}
            component={ManagePaymentMethodsAdd}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_MANAGE_POS_REQUESTS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_POS_REQUESTS.path}
            component={ManagePosRequests}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_MANAGE_POS_REQUESTS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_POS_REQUESTS_ADD.path}
            component={AddPosRequest}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_TAP_TO_PHONE === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_HANDY_TAP.path}
            component={ManageHandyTap}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_TAP_TO_PHONE === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_HANDY_TAP_ADD.path}
            component={AddHandyTap}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_ITAU === "YES" ? (
          <Route exact path={ROUTES.ITAU.path} component={Itau} />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_ITAU === "YES" ? (
          <Route exact path={ROUTES.ITAU_SALE.path} component={ItauSale} />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_MANAGE_USERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_USERS.path}
            component={ManageUsers}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_MANAGE_USERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_USERS_ADD.path}
            component={ManageUsersAdd}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_MANAGE_USERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_USERS_EDIT.path}
            component={ManageUsersEdit}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_MANAGE_USERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_USERS_CHANGE_PASSWORD.path}
            component={ManageUsersChangePassword}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_PAYMENTS.path}
            component={ManageSubscriptions}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_SUBSCRIPTIONS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS.path}
            component={ManageSubscriptions}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_SUBSCRIPTIONS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS_ADD.path}
            component={ManageSubscriptionsAdd}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_SUBSCRIPTIONS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS_EDIT.path}
            component={ManageSubscriptionsAdd}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_SUBSCRIPTIONS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS_VIEW.path}
            component={ManageSubscriptionsView}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_SUBSCRIPTIONS === "YES" ? (
          <Route
            exact
            path={
              ROUTES.MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS_PAYMENT_DETAILS
                .path
            }
            component={ManageSubscriptionsPaymentDetails}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_SUBSCRIPTIONS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_PAYMENTS_PAYMENTS.path}
            component={ManagePayments}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS.path}
            component={ManageProvidersServices}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SERVICES.path}
            component={ManageProvidersServices}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SERVICES_EDIT.path}
            component={ManageProvidersServicesEdit}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SERVICES_VIEW.path}
            component={ManageProvidersServicesEdit}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIBERS.path}
            component={ManageProvidersSubscribers}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIBERS_VIEW.path}
            component={ManageProvidersViewSubscriber}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIBERS_NEW_CHARGE.path}
            component={ManageProvidersRequestNewCharge}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIBERS_CHARGE_DETAILS.path}
            component={ManageProvidersChargeDetails}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS === "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_CHARGES.path}
            component={ManageProvidersCharges}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.path}
            component={ManageProvidersSubscriptionsPlans}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS_ADD.path}
            component={ProvidersSubscriptionsAddPlan}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLAN_EDIT.path}
            component={ProvidersSubscriptionsAddPlan}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLAN_VIEW.path}
            component={ProvidersSubscriptionsPlanDetails}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS.path}
            component={ManageProvidersSubscriptionsSubscriptions}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS_VIEW.path}
            component={ManageProvidersSubscriptionsSubscriptionDetails}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS_EDIT.path}
            component={ManageProvidersSubscriptionsEditSubscription}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS.path}
            component={ManageProvidersSubscriptionsTransactions}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS_VIEW.path}
            component={ManageProvidersSubscriptionsTransactionDetails}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS.path}
            component={ManageProvidersSubscriptionsPromotions}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS_ADD.path}
            component={ManageProvidersSubscriptionsAddPromotion}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS_VIEW.path}
            component={ManageProvidersSubscriptionsPromotionDetails}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={
              ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS.path
            }
            component={ManageProvidersSubscriptionsTermsAndConditions}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={
              ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS_ADD
                .path
            }
            component={ManageProvidersSubscriptionsAddTermsAndConditions}
          />
        ) : null}
        {process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS ===
        "YES" ? (
          <Route
            exact
            path={
              ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS_EDIT
                .path
            }
            component={ManageProvidersSubscriptionsAddTermsAndConditions}
          />
        ) : null}
      </Switch>
    </React.Fragment>
  );
};

export default App;
