import React from 'react';
import makeClasses from './styles';

const OperationsTableMoreInformation = ({ children }) => {
  const classes = makeClasses();

  return <div className={classes.container}>{children}</div>;
};

export default OperationsTableMoreInformation;
