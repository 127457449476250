import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { goBack, replace } from "connected-react-router";
import moment from "moment";
import qs from "query-string";

import ROUTES from "../../../../routes";
import {
  BoxSeparator,
  ContentBox,
  ForbiddenSection,
  OperationsTable,
  Page,
  TitleBox,
} from "../../../../components";
import { business as businessActions } from "../../../../actions";
import { Language } from "../../../../utils";

import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
  InsertPhotoOutlined as InsertPhotoOutlinedIcon,
} from "@material-ui/icons";

import makeClasses from "./styles";
import { Policies } from "../../../../utils/Policies";
import { toISOString } from "../../../../utils/Utils";

const ViewPaymentLinkScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);

  const [paymentLink, setPaymentLink] = useState({});
  const [paymentLinkTransactions, setPaymentLinkTransactions] = useState([]);
  const [paginationPage, setPaginationPage] = useState(
    searchParams.page ? parseInt(searchParams.page) : 1
  );
  const [paginationPageSize] = useState(10);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const { policies, branches, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    branches: state.business.branches || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (policies && policies.includes(Policies.types.HANDY_LINK)) {
      dispatch(
        businessActions.getSecrets(true, (secrets) => {
          dispatch(
            businessActions.getPaymentLink(
              match.params.traceId,
              (paymentLink) => {
                const secret = secrets.find(
                  (s) => s.id === paymentLink.ecommerceSecretId
                );
                const branch = branches.find(
                  (b) => secret.branchTraceId === b.traceId
                );
                setPaymentLink({ ...paymentLink, branchName: branch.name });
              }
            )
          );
        })
      );
    } else {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (paymentLink.traceId) {
      const startDate = toISOString(
        moment(paymentLink.creationDate).startOf("day")
      );
      const endDate = toISOString(moment().endOf("day"));

      dispatch(
        businessActions.getPaymentLinkTransactions(
          paymentLink.traceId,
          startDate,
          endDate,
          paginationPage,
          paginationPageSize,
          (transactions) => setPaymentLinkTransactions(transactions)
        )
      );
    }
  }, [paymentLink, paginationPage]);

  const _goBack = (e) => {
    e.preventDefault();
    dispatch(goBack());
  };

  const _handleOnPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const renderInlineDetails = (details) => {
    if (!details) {
      return null;
    }

    return (
      <div className={classes.detailsWrapper}>
        {details.userData && details.userData.document ? (
          <div className={classes.detailsItem}>
            <div className={classes.detailsItemLabel}>
              {i18n.get("ViewPaymentLink.TransactionDetailsClient")}
            </div>
            <div className={classes.detailsItemValue}>
              {details.userData.document}
            </div>
          </div>
        ) : null}
        {details.transactionData && details.transactionData.paymentCode ? (
          <div className={classes.detailsItem}>
            <div className={classes.detailsItemLabel}>
              {i18n.get("ViewPaymentLink.TransactionDetails")}
            </div>
            <div className={classes.detailsItemValue}>
              {details.transactionData.paymentCode}
            </div>
          </div>
        ) : null}
        {details.otherData && details.otherData.authorizationNumber ? (
          <div className={classes.detailsItem}>
            <div className={classes.detailsItemLabel}>
              {i18n.get("ViewPaymentLink.TransactionAuthorizationNumber")}
            </div>
            <div className={classes.detailsItemValue}>
              {details.otherData.authorizationNumber}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const renderTable = () => {
    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get("ViewPaymentLink.TransactionsGrid.Column1"),
        sortable: false,
        type: "date-time",
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get("ViewPaymentLink.TransactionsGrid.Column2"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get("ViewPaymentLink.TransactionsGrid.Column3"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get("ViewPaymentLink.TransactionsGrid.Column4"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get("ViewPaymentLink.TransactionsGrid.Column5"),
        sortable: false,
        type: "date",
      },
    ];
    const body = (paymentLinkTransactions.elements || []).map((item) => ({
      rowId: item.id,
      columns: [
        {
          columnNumber: 1,
          data: item.creationDateTime,
        },
        {
          columnNumber: 2,
          data: item.digitalIdentifier,
        },
        {
          columnNumber: 3,
          data: item.acquirer,
        },
        {
          columnNumber: 4,
          data: item.cardType,
        },
        {
          columnNumber: 5,
          data: item.executionDate,
        },
      ],
      inlineDetails: {
        userData: item.userData,
        transactionData: item.transactionData,
        otherData: {
          authorizationNumber: item.authorizationNumber,
        },
      },
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={
          (paymentLinkTransactions.elements || []).length === paginationPageSize
        }
        onPaginationChange={_handleOnPaginationChange}
        emptyMessage={i18n.get("ViewPaymentLink.GridEmptyMessage")}
        showDetails={false}
        showInlineDetails
        renderInlineDetails={renderInlineDetails}
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get("ViewPaymentLink.Title")}
        withActivePage={ROUTES.PAYMENT_LINK.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get("ViewPaymentLink.Title")}
      withActivePage={ROUTES.PAYMENT_LINK.id}
    >
      <TitleBox
        title={i18n.get("ViewPaymentLink.BoxTitle")}
        back
        backTitle={i18n.get("ViewPaymentLink.BoxBackButton")}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator size="small" />
      <ContentBox title={i18n.get("ViewPaymentLink.BoxContentTitle")} titleBold>
        <div className={classes.contentTile}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={10}
              md={10}
              lg={10}
              className={classes.productInformationWrapper}
            >
              <div className={classes.productInformationTitle}>
                <div className={classes.productInformationLabel}>
                  {i18n.get("ViewPaymentLink.ProductTitle")}
                </div>
                <div className={classes.productInformationValue}>
                  {paymentLink.productName}
                </div>
              </div>
              <div className={classes.productInformationBranchWrapper}>
                <div className={classes.productInformationTitle}>
                  <div className={classes.productInformationLabel}>
                    {i18n.get("ViewPaymentLink.ProductInvoiceNumber")}
                  </div>
                  <div className={classes.productInformationValue}>
                    {paymentLink.invoiceNumber}
                  </div>
                </div>
                <div className={classes.productInformationTitle}>
                  <div className={classes.productInformationLabel}>
                    {i18n.get("ViewPaymentLink.ProductBranch")}
                  </div>
                  <div className={classes.productInformationValue}>
                    {paymentLink.branchName}
                  </div>
                </div>
              </div>
              <div className={classes.productInformationPriceWrapper}>
                <div className={classes.productInformationPriceCurrencyWrapper}>
                  <div className={classes.productInformationLabel}>
                    {i18n.get("ViewPaymentLink.MoreCurrencyTitle")}
                  </div>
                  {paymentLink.currency ? (
                    <div className={classes.productInformationValue}>
                      {paymentLink.currency.alpha}
                    </div>
                  ) : null}
                </div>
                <div>
                  <div className={classes.productInformationLabel}>
                    {i18n.get("ViewPaymentLink.ProductPriceTitle")}
                  </div>
                  <div className={classes.productInformationValue}>
                    {paymentLink.amount}
                  </div>
                </div>
                <div>
                  <div className={classes.productInformationLabel}>
                    {i18n.get("ViewPaymentLink.ProductTaxedPriceTitle")}
                  </div>
                  <div className={classes.productInformationValue}>
                    {paymentLink.taxedAmount}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}>
              <div className={classes.productImagePreview}>
                {paymentLink.bundleImageUrl ? (
                  <img
                    src={paymentLink.bundleImageUrl}
                    className={classes.productImage}
                    alt="payment"
                  />
                ) : (
                  <div className={classes.productImagePlaceholder}>
                    <InsertPhotoOutlinedIcon
                      color="disabled"
                      fontSize="large"
                    />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <Accordion className={classes.contentMoreDetails}>
          <AccordionSummary
            className={classes.moreDetailsAction}
            expandIcon={<ExpandMoreIcon />}
          >
            <div>{i18n.get("ViewPaymentLink.MoreOptions")}</div>
          </AccordionSummary>
          <AccordionDetails className={classes.moreDetailsContainer}>
            <Grid container>
              {paymentLink.description ? (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className={classes.moreDetailsSectionSingle}>
                    <div className={classes.productInformationLabel}>
                      {i18n.get("ViewPaymentLink.MoreDescriptionTitle")}
                    </div>
                    <div className={classes.productInformationValue}>
                      {paymentLink.description}
                    </div>
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className={classes.moreDetailsSection}>
                  <div className={classes.productInformationLabel}>
                    {i18n.get("ViewPaymentLink.MoreCreationLinkDateTitle")}
                  </div>
                  <div className={classes.productInformationValue}>
                    {moment(paymentLink.creationDate).format("LL")} -{" "}
                    {moment(paymentLink.expirationDate).format(
                      i18n.get("TimeFormat")
                    )}
                  </div>
                </div>
                <div className={classes.moreDetailsSection}>
                  <div className={classes.productInformationLabel}>
                    {i18n.get("ViewPaymentLink.MoreStatusTitle")}
                  </div>
                  <div className={classes.productInformationValue}>
                    {i18n.get(
                      `ViewPaymentLink.MoreStatus_${paymentLink.status}`
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className={classes.moreDetailsSection}>
                  <div className={classes.productInformationLabel}>
                    {i18n.get("ViewPaymentLink.MoreExpirationLinkDateTitle")}
                  </div>
                  <div className={classes.productInformationValue}>
                    {moment(paymentLink.expirationDate).format("LL")} -{" "}
                    {moment(paymentLink.expirationDate).format(
                      i18n.get("TimeFormat")
                    )}
                  </div>
                </div>
                <div className={classes.moreDetailsSection}>
                  <div className={classes.productInformationLabel}>
                    {i18n.get("ViewPaymentLink.MoreUsageTitle")}
                  </div>
                  <div className={classes.productInformationValue}>
                    {paymentLink.timesUsed === 0
                      ? i18n.get("ViewPaymentLink.MoreUsageMessage_noUsage")
                      : paymentLink.timesUsed === 1
                      ? i18n.get("ViewPaymentLink.MoreUsageMessage_singular")
                      : i18n
                          .get("ViewPaymentLink.MoreUsageMessage")
                          .replace("{timesUsed}", paymentLink.timesUsed)}
                  </div>
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </ContentBox>
      <BoxSeparator size="small" />
      <ContentBox title={i18n.get("ViewPaymentLink.GridTitle")} titleBold>
        {renderTable()}
      </ContentBox>
    </Page>
  );
};

ViewPaymentLinkScreen.id = "com.Handy.ViewPaymentLink";

export default ViewPaymentLinkScreen;
