import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  boxContainer: {
    
  },
  boxTitle: {
    fontFamily: theme.fontFamily.bold,
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentTextHighlighted,
  },
  boxText: {
    fontSize: theme.fontSize.M,
    color: Colors.rgb.contentTextSoft,
    marginTop: theme.spacing(2)
  },
  boxViewsContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${Colors.rgb.contentLineSoft}`
  },
  contentBoxTitle: {
    fontFamily: theme.fontFamily.bold,
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentTextHighlighted,
  },
  contentBoxText: {
    fontSize: theme.fontSize.M,
    color: Colors.rgb.contentTextSoft,
    marginTop: theme.spacing(2),

    '& a': {
      color: Colors.rgb.primary,
    }
  },
  contentBoxTextWithAction: {
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      marginRight: theme.spacing(2),
    }
  },
  contentBoxTextTitle: {
    marginBottom: theme.spacing(3),
  },
  contentBoxTextContent: {
    '& > div': {
      marginBottom: theme.spacing(2),
    }
  }
}));

export default makeClasses;
