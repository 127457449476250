import { makeStyles } from "@material-ui/core";
import { Colors } from "../../../../utils";

const makeClasses = makeStyles((theme) => ({
  //
  // Content
  contentTile: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  productInformationTitle: {
    marginBottom: theme.spacing(4),
  },
  productInformationPriceWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    "& > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      flex: 1,
    },

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
    },
  },
  productInformationBranchWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    "& > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",

      "&:nth-child(1)": {
        flex: 3,
      },

      "&:nth-child(2)": {
        flex: 2,
      },
    },
  },
  productInformationPriceCurrencyWrapper: {
    flex: `3 !important`,

    [theme.breakpoints.down("sm")]: {
      flex: `1 !important`,
    },
  },
  productInformationLabel: {
    color: Colors.rgb.contentTextSoft,
    fontSize: theme.fontSize.S,
    fontFamily: theme.fontFamily.regular,
  },
  productInformationValue: {
    color: Colors.rgb.contentText,
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.regular,
  },
  productImagePreview: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  productImage: {
    width: 168,
    height: 168,
    objectFit: "cover",
    borderRadius: 5,
    border: `2px solid ${Colors.rgba(Colors.rgb.black, 10)}`,
    boxSizing: "border-box",
  },
  productImagePlaceholder: {
    width: 168,
    height: 168,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${Colors.rgb.contentLine}`,
    borderRadius: 5,
  },
  contentMoreDetails: {
    padding: 0,
    border: "none",
    borderRadius: "0 !important",
    boxShadow: "none",

    "&:before": {
      height: 0,
    },
  },
  moreDetailsAction: {
    height: `31px !important`,
    minHeight: `31px !important`,
    color: Colors.rgb.contentTextHighlighted,
    fontSize: theme.fontSize.XL,
    fontFamily: theme.fontFamily.bold,
    borderBottom: `1px solid ${Colors.rgb.contentLine}`,
    paddingBottom: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,

    "& > div": {
      margin: `0 !important`,
    },
  },
  moreDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  moreDetailsSection: {
    position: "relative",
    marginBottom: theme.spacing(4),

    "&:last-child": {
      marginBottom: 0,
    },

    [theme.breakpoints.down("sm")]: {
      "&:last-child": {
        marginBottom: theme.spacing(4),
      },
    },
  },
  moreDetailsSectionSingle: {
    position: "relative",
    marginBottom: theme.spacing(4),
  },
  moreDetailsWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  moreDetailsItem: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  moreDetailsLinkEpiration: {
    fontWeight: "bold",
    marginTop: 10,
  },
  moreDetailsLinkExpired: {
    fontWeight: "bold",
    color: Colors.rgb.red,
  },
  detailsWrapper: {
    padding: theme.spacing(1),
  },
  detailsItem: {
    marginBottom: theme.spacing(1),
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.M,

    "&:last-child": {
      marginBottom: 0,
    },
  },
  detailsItemLabel: {
    color: Colors.rgb.contentTextSoft,
  },
  detailsItemValue: {
    color: Colors.rgb.contentText,
  },
}));

export default makeClasses;
