import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  status: {
    display: 'inline-block',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: 5,
    fontSize: `${theme.fontSize.S} !important`,
  },
  statusSmall: {
    padding: `3px ${theme.spacing(1)}`,
  },
  statusActive: {
    background: Colors.rgb.alertSuccessBackground,
    border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
    color: `${Colors.rgb.alertSuccessText} !important`,
  },
  statusInactive: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
  statusGreen: {
    background: Colors.rgb.alertSuccessBackground,
    border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
    color: `${Colors.rgb.alertSuccessText} !important`,
  },
  statusRed: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
  statusYellow: {
    background: Colors.rgb.alertWarningBackground,
    border: `1px solid ${Colors.rgb.alertWarningBorder}`,
    color: `${Colors.rgb.alertWarningText} !important`,
  },
  statusGrey: {
    background: Colors.rgb.alertIdleBackground,
    border: `1px solid ${Colors.rgb.alertIdleBorder}`,
    color: `${Colors.rgb.alertIdleText} !important`,
  },
}));

export default makeClasses;
