import { makeStyles } from '@material-ui/core';
import { THEME_LAYOUT, THEME_SIDEMENU_WIDTH } from '../../theme';

const makeClasses = makeStyles(theme => ({
  root: {
    height: '100vh',
    overflow: 'hidden',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  contentContainer: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
    boxSizing: 'border-box',
  },
  contentContainerWithHeader: {
    marginTop: 66,
    paddingTop: theme.spacing(THEME_LAYOUT.VERTICAL.INNER.L),
    paddingBottom: 95,
    paddingRight: theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.L),
    paddingLeft: theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.L),
    height: 'calc(100% - 66px)',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
      margin: '56px auto 0',
      height: 'calc(100% - 66px)',
      paddingBottom: '95px !important',
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  contentContainerWithHeaderWithBanner: {
    marginTop: '121px !important',
    height: 'calc(100% - 121px)',
  },
  contentContainerWithHeaderWithBoxBanner: {
    marginTop: '127px !important',
    height: 'calc(100% - 127px)',
  },
  contentContainerWithHeaderWithBoxBannerMd: {
    marginTop: '176px !important',
    height: 'calc(100% - 176px)',

    [theme.breakpoints.down('md')]: {
      marginTop: '127px !important',
      height: 'calc(100% - 127px)',
    },
  },
  contentContainerWithSideMenu: {
    marginLeft: THEME_SIDEMENU_WIDTH,

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
      margin: '66px auto 0',
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  toast: {
    '& > div': {
      borderRadius: 3,
    },
  },
  toastBody: {
    display: 'flex',
    fontSize: '0.9rem',
    padding: '0 10px',
  },
  notificationsWrapper: {
    marginBottom: theme.spacing(2),
  },
}));

export default makeClasses;
