import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  BoxMenu,
  BoxSeparator,
  Button,
  Confirm,
  ContentBox,
  EmptyMessage,
  FiltersContainer,
  FiltersDateSelector,
  FiltersSelector,
  FiltersSeparator,
  FiltersText,
  FiltersToggle,
  ForbiddenSection,
  OperationsTable,
  Page,
  PaginationSimple,
  TitleBox,
} from '../../../components';
import ROUTES from '../../../routes';
import { Language, Utils } from '../../../utils';
import { business as businessActions } from '../../../actions';
import { push, replace } from 'connected-react-router';
import qs from 'query-string';
import { CUSTOM_FILTER, getDateRangeForFilter } from '../../../components/FiltersDateSelector';
import makeClasses from './styles';
import moment from 'moment';
import {
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { Visibility as VisisbilityIcon, Replay as ReplayIcon } from '@material-ui/icons';
import MoreVerticalIcon from '../../../static/images/icons/more_vertical.svg';
import clsx from 'clsx';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { Policies } from '../../../utils/Policies';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { Alert, AlertTitle } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { toISOString } from '../../../utils/Utils';

const STATUS_OPTIONS = [
  { id: 'CANCELED', label: 'Cancelado' },
  { id: 'COMPLETED', label: 'Cobrado' },
  { id: 'ERROR', label: 'Error' },
  { id: 'PARTIAL_EXECUTED', label: 'Parcialmente cobrado' },
  { id: 'IN_PROGRESS', label: 'Procesando cobro' },
  { id: 'MANUAL_REJECTED', label: 'Rechazado por usuario' },
];

const STATUS_FILTER_OPTIONS = [
  { id: 'CANCELED', name: 'ProvidersManageCharges.StatusFilter_CANCELED' },
  { id: 'COMPLETED', name: 'ProvidersManageCharges.StatusFilter_COMPLETED' },
  { id: 'ERROR', name: 'ProvidersManageCharges.StatusFilter_ERROR' },
  { id: 'PARTIAL_EXECUTED', name: 'ProvidersManageCharges.StatusFilter_PARTIAL_EXECUTED' },
  { id: 'IN_PROGRESS|APPROVED', name: 'ProvidersManageCharges.StatusFilter_IN_PROGRESS' },
  { id: 'MANUAL_REJECTED', name: 'ProvidersManageCharges.StatusFilter_MANUAL_REJECTED' },
];

const VIEW_FILTER_OPTIONS = [
  { id: 0, label: 'ProvidersManageCharges.ViewFilter.PENDING_CHARGES' },
  { id: 1, label: 'ProvidersManageCharges.ViewFilter.MANAGE_CHARGES' },
];

const ProviderManageChargesScreen = ({ history }) => {
  const dispatch = useDispatch();
  const searchParams = qs.parse(history.location.search);
  const classes = makeClasses();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const [showCancelChargeDialog, setShowCancelChargeDialog] = useState(false);
  const [showRejectChargeDialog, setShowRejectChargeDialog] = useState(false);
  const [showRetryChargeDialog, setShowRetryChargeDialog] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState(null);
  const [pendingCharges, setPendingCharges] = useState({});
  const [chargesHistory, setChargesHistory] = useState({});
  const [paginationPage, setPaginationPage] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [orderByField, setOrderByField] = useState(searchParams.orderByField ? searchParams.orderByField : 'CreatedAt');
  const [orderByDesc, setOrderByDesc] = useState(searchParams.orderByDesc ? searchParams.orderByDesc === 'desc' : true);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [selectedService, setSelectedService] = useState(searchParams.sid || 'all');
  const [providersServicesOptions, setProvidersServicesOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(searchParams.u ? searchParams.u : '');
  const [selectedStatus, setSelectedStatus] = useState(searchParams.tid ? searchParams.tid : 'all');
  const [selectedFilter, setSelectedFilter] = useState(searchParams.fid ? parseInt(searchParams.fid) : 0);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState(
    parseInt(searchParams.fid) === CUSTOM_FILTER ? { startDate: searchParams.fsd, endDate: searchParams.fed } : {}
  );
  const [selectedView, setSelectedView] = useState(searchParams.view ? parseInt(searchParams.view) : 0);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const canExport = policies.includes(Policies.types.PROVIDER) && policies.includes(Policies.types.SUPER_READ);
  const canManage = policies.includes(Policies.types.PROVIDER_WRITE);

  useEffect(() => {
    if (!policies || !policies.includes(Policies.types.PROVIDER)) {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (!forbiddenSection) {
      loadFiltersData();

      if (selectedView === 0) {
        loadPendingPayments();
      } else {
        loadPaymentsHistory();
      }
    }
  }, [
    forbiddenSection,
    selectedView,
    paginationPage,
    orderByField,
    orderByDesc,
    selectedFilter,
    selectedCustomFilter,
    selectedUser,
    selectedService,
    selectedStatus,
  ]);

  const loadFiltersData = () => {
    if (filtersLoaded) {
      return;
    }
    dispatch(
      businessActions.getProviderServices((data) => {
        const options = data.elements
          ? data.elements.filter((e) => e).map((e) => ({ id: e.traceId, name: e.name }))
          : [];
        setProvidersServicesOptions(options);
      })
    );
    setFiltersLoaded(true);
  };

  const loadPendingPayments = () => {
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.getPendingCharges(
        null,
        selectedService !== 'all' ? selectedService : null,
        orderByField,
        orderByDesc,
        paginationPage,
        startDate,
        endDate,
        selectedUser,
        (data) => {
          setPendingCharges(data);
        }
      )
    );
  };

  const loadPaymentsHistory = () => {
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.getChargesHistory(
        null,
        selectedService !== 'all' ? selectedService : null,
        orderByField,
        orderByDesc,
        paginationPage,
        startDate,
        endDate,
        selectedUser,
        selectedStatus !== 'all' ? selectedStatus : null,
        (data) => {
          setChargesHistory(data);
        }
      )
    );
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const handleOnPaymentsHistoryPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnPaginationPressNext = () => {
    setPaginationPage(paginationPage + 1);
    handleOnPaymentsHistoryPaginationChange(paginationPage + 1);
  };

  const handleOnPaginationPressPrevious = () => {
    setPaginationPage(paginationPage - 1);
    handleOnPaymentsHistoryPaginationChange(paginationPage - 1);
  };

  const handleOnPaymentsHistorySortChange = (column, direction) => {
    let orderByField = '';
    let orderByDesc = false;

    if (column === 1) {
      orderByField = 'CreatedAt';
      orderByDesc = direction === 'desc';
    }

    setOrderByField(orderByField);
    setOrderByDesc(orderByDesc);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.orderByField = orderByField;
    searchParams.orderByDesc = direction;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnFilterSelected = (filter, startDate, endDate) => {
    setSelectedFilter(filter);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnServiceSelected = (service) => {
    setSelectedService(service);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.sid = service;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnStatusSelected = (status) => {
    setSelectedStatus(status);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.tid = status;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnChangeUser = (user) => {
    setSelectedUser(user);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.u = user;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnViewChange = (view) => {
    setSelectedView(view);
    setSelectedFilter(0);
    setSelectedCustomFilter({});
    setSelectedUser('');
    setSelectedService('all');

    const searchParams = {};
    searchParams.view = view;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnRetryCharge = (charge) => () => {
    toggleMenu()();
    toggleRetryChargeDialog();
    setSelectedCharge(charge);
  };

  const toggleDialog = () => {
    if (showRetryChargeDialog) {
      toggleRetryChargeDialog();
    } else if (showCancelChargeDialog) {
      toggleCancelChargeDialog();
    } else if (showRejectChargeDialog) {
      toggleRejectChargeDialog();
    }
  };

  const toggleRetryChargeDialog = () => {
    setShowRetryChargeDialog(!showRetryChargeDialog);
  };

  const toggleCancelChargeDialog = () => {
    setShowCancelChargeDialog(!showCancelChargeDialog);
  };

  const toggleRejectChargeDialog = () => {
    setShowRejectChargeDialog(!showRejectChargeDialog);
  };

  const handleOnConfirm = () => {
    if (showRetryChargeDialog) {
      confirmRetryCharge();
    } else if (showCancelChargeDialog || showRejectChargeDialog) {
      confirmCancelCharge();
    }
  };

  const confirmRetryCharge = () => {
    toggleDialog();
    dispatch(
      businessActions.retryCharge(selectedCharge.messageId, (result) => {
        if (result) {
          toast.success(i18n.get('ProvidersManageCharges.SuccessRetryMessage'));
          if (selectedView === 0) {
            loadPendingPayments();
          } else {
            loadPaymentsHistory();
          }
        } else {
          toast.error(i18n.get('ProvidersManageCharges.ErrorRetryMessage'));
        }
      })
    );
  };

  const confirmCancelCharge = () => {
    toggleDialog();
    dispatch(
      businessActions.cancelCharge(selectedCharge.traceId, (result) => {
        if (result) {
          toast.success(i18n.get('ProvidersManageCharges.SuccessCancelMessage'));
          if (selectedView === 0) {
            loadPendingPayments();
          } else {
            loadPaymentsHistory();
          }
        } else {
          toast.error(i18n.get('ProvidersManageCharges.ErrorCancelMessage'));
        }
      })
    );
  };

  const handleOnMenuItemClick = (item) => {
    dispatch(push(item.path));
  };

  const goToChargeHistoryDetails = (traceId) => (e) => {
    const charge = chargesHistory.elements.find((c) => c.traceId === traceId);
    goToChargeDetails(charge.subscriptionTraceId, charge.traceId)();
  };

  const goToChargeDetails = (subscriptionTraceId, traceId) => (e) => {
    e && e.preventDefault();

    dispatch(
      push(
        ROUTES.MANAGE_PROVIDERS_SUBSCRIBERS_CHARGE_DETAILS.path
          .replace(':traceId', subscriptionTraceId)
          .replace(':chargeTraceId', traceId)
      )
    );
  };

  const handleOnViewCharge = (charge) => () => {
    toggleMenu()();
    goToChargeDetails(charge.subscriptionTraceId, charge.traceId)();
  };

  const handleOnGridExport = () => {
    if (selectedFilter === 0) {
      toast.error(i18n.get('ProvidersManageCharges.ExportErrorNoDateFilterSelected'));
      return;
    }

    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    const fileName = selectedView === 0 ? 'Cobranzas_CobrosPendientes_' : 'Cobranzas_AdministracionCobros_';

    dispatch(
      businessActions.exportProviderCharges(
        selectedService !== 'all' ? selectedService : null,
        orderByField,
        orderByDesc,
        paginationPage,
        startDate,
        endDate,
        selectedUser,
        selectedStatus !== 'all' ? selectedStatus : null,
        selectedView === 0,
        (file) =>
          Utils.downloadFile(
            file,
            fileName +
              dateFilter.startDate.format(i18n.get('ExportDateFormat')) +
              '_' +
              dateFilter.endDate.format(i18n.get('ExportDateFormat')) +
              '.xlsx'
          )
      )
    );
  };

  const renderServicesSelector = () => {
    return (
      <FiltersSelector
        options={providersServicesOptions}
        selected={selectedService}
        onSelected={handleOnServiceSelected}
        defaultOption={i18n.get('ProvidersManageSubscribers.FilterServicesDefaultOption')}
      />
    );
  };

  const renderStatusesSelector = () => {
    return (
      <FiltersSelector
        options={STATUS_FILTER_OPTIONS.map((s) => ({ ...s, name: i18n.get(s.name) }))}
        selected={selectedStatus}
        onSelected={handleOnStatusSelected}
        defaultOption={i18n.get('ProvidersManageSubscribers.FilterStatusesDefaultOption')}
      />
    );
  };

  const renderUserFilter = () => {
    return (
      <FiltersText
        value={selectedUser}
        placeholder={i18n.get('ProvidersManageSubscribers.FiltersUserPlaceholder')}
        onChange={handleOnChangeUser}
      />
    );
  };

  const renderFiltersDateSelector = () => (
    <FiltersDateSelector
      selectedFilter={selectedFilter}
      startDate={selectedCustomFilter.startDate}
      endDate={selectedCustomFilter.endDate}
      onFilterSelected={handleOnFilterSelected}
      align="right"
      dateFormat={i18n.get('DateFormat', false)}
      showNoFilterOption
    />
  );

  const renderViewToggle = () => (
    <FiltersToggle
      showLabel={false}
      showDefault={false}
      options={VIEW_FILTER_OPTIONS}
      onChange={handleOnViewChange}
      selected={selectedView}
    />
  );

  const renderPendingCharges = () => {
    if (!pendingCharges || !pendingCharges.elements || pendingCharges.elements.length === 0) {
      return <EmptyMessage textOnly>{i18n.get('ProvidersManageCharges.GridEmptyMessage')}</EmptyMessage>;
    }

    if (isSmallScreen) {
      return (
        <div>
          {pendingCharges.elements.map((charge, index) => (
            <React.Fragment key={index}>
              {index === 0 ? null : <div className={classes.boxItemSeparator} />}
              <div className={clsx(classes.boxContainer, index % 2 === 0 ? classes.boxContainerEven : null)}>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ProvidersManageCharges.LabelService')}:</span>
                  <span>{charge.productName}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ProvidersManageCharges.LabelUser')}:</span>
                  <span>
                    {charge.merchantName}
                    {' - '}
                    {charge.branchName}
                  </span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ProvidersManageCharges.LabelDate')}:</span>
                  <span>{moment(charge.createdAt).format(i18n.get('DateFormat'))}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ProvidersManageCharges.LabelLimitDate')}:</span>
                  <span>{moment(charge.limitDate).format(i18n.get('DateFormat'))}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ProvidersManageCharges.LabelAmount')}:</span>
                  <span>
                    {charge.currency} {Utils.formatCurrency(charge.amount)}
                  </span>
                </div>
                <div className={classes.listItemActions}>
                  <IconButton onClick={toggleMenu(index)}>
                    <img src={MoreVerticalIcon} alt="More" />
                  </IconButton>
                </div>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={menuOpenIndex === index}
                  onClose={toggleMenu()}
                  className={classes.listItemMenu}
                >
                  <MenuItem onClick={handleOnViewCharge(charge)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ProvidersManageCharges.ActionViewPaymentDetails')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                </Menu>
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }

    return pendingCharges.elements.map((charge, index) => (
      <React.Fragment key={charge.traceId}>
        {index !== 0 ? <div className={classes.subscriberItemSeparator} /> : null}
        <div className={classes.subscriberItemWrapper}>
          <div className={classes.subscriberItemDetails}>
            <div>
              <span>{i18n.get('ProvidersManageCharges.LabelService')}:</span> {charge.productName}
            </div>
            <div className={classes.subscriberItemExtraDetails}>
              <div>
                <span>{i18n.get('ProvidersManageCharges.LabelUser')}:</span> {charge.merchantName} - {charge.branchName}
              </div>
              <div>|</div>
              <div>
                <span>{i18n.get('ProvidersManageCharges.LabelDate')}:</span>
                {moment(charge.createdAt).format(i18n.get('DateFormat'))}
              </div>
              <div>|</div>
              <div>
                <span>{i18n.get('ProvidersManageCharges.LabelLimitDate')}:</span>
                {moment(charge.limitDate).format(i18n.get('DateFormat'))}
              </div>
              <div>|</div>
              <div>
                <span>{i18n.get('ProvidersManageCharges.LabelAmount')}:</span>
                {charge.currency} {Utils.formatCurrency(charge.amount)}
              </div>
            </div>
          </div>
          <div>
            <div className={classes.listItemActions}>
              <IconButton onClick={toggleMenu(index)}>
                <img src={MoreVerticalIcon} alt="More" />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={menuOpenIndex === index}
                onClose={toggleMenu()}
                className={classes.listItemMenu}
              >
                <MenuItem onClick={handleOnViewCharge(charge)} className={classes.listItemMenuItem}>
                  <ListItemIcon className={classes.menuItemIcon}>
                    <VisisbilityIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={i18n.get('ProvidersManageCharges.ActionViewChargeDetails')}
                    className={classes.menuItemText}
                  />
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </React.Fragment>
    ));
  };

  const renderChargesHistory = () => {
    if (!chargesHistory || !chargesHistory.elements) {
      return null;
    }

    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('ProvidersManageCharges.ChargesHistoryGrid.Column1'),
        sortable: true,
        type: 'date-time',
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('ProvidersManageCharges.ChargesHistoryGrid.Column2'),
        sortable: false,
        type: 'status',
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get('ProvidersManageCharges.ChargesHistoryGrid.Column3'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get('ProvidersManageCharges.ChargesHistoryGrid.Column4'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get('ProvidersManageCharges.ChargesHistoryGrid.Column5'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 6,
        name: i18n.get('ProvidersManageCharges.ChargesHistoryGrid.Column6'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 7,
        name: i18n.get('ProvidersManageCharges.ChargesHistoryGrid.Column7'),
        sortable: false,
        type: 'custom',
        align: 'right',
        show: !isSmallScreen,
      },
    ];
    const body = (chargesHistory.elements || []).map((item, index) => ({
      rowId: item.traceId,
      columns: [
        {
          columnNumber: 1,
          data: item.createdAt,
        },
        {
          columnNumber: 2,
          data: item.status === 'APPROVED' ? 'IN_PROGRESS' : item.status,
          customData: STATUS_OPTIONS,
        },
        {
          columnNumber: 3,
          data: item.productName,
        },
        {
          columnNumber: 4,
          data: item.branchName ? `${item.merchantName} - ${item.branchName}` : item.merchantName,
        },
        {
          columnNumber: 5,
          data: `${item.currency} ${Utils.formatCurrency(item.amount)}`,
        },
        {
          columnNumber: 6,
          data: `${item.currency} ${Utils.formatCurrency(item.amount - item.chargedAmount)}`,
        },
        {
          columnNumber: 7,
          data: () => {
            return (
              <div className={classes.listItemActions}>
                <IconButton onClick={toggleMenu(index)}>
                  <img src={MoreVerticalIcon} alt="More" />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  onClose={toggleMenu()}
                  open={menuOpenIndex === index}
                  className={classes.listItemMenu}
                >
                  <MenuItem onClick={handleOnViewCharge(item)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ProvidersManageCharges.ActionViewChargeDetails')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  {canManage && item.status === 'ERROR' ? (
                    <MenuItem onClick={handleOnRetryCharge(item)} className={classes.listItemMenuItem}>
                      <ListItemIcon className={classes.menuItemIcon}>
                        <ReplayIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.get('ProvidersManageCharges.ActionRetry')}
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                </Menu>
              </div>
            );
          },
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={chargesHistory.hasNext}
        onPaginationChange={handleOnPaymentsHistoryPaginationChange}
        emptyMessage={i18n.get('ProvidersManageCharges.GridEmptyMessage')}
        showDetails={false}
        onClick={goToChargeHistoryDetails}
        onTableSortChange={handleOnPaymentsHistorySortChange}
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ProvidersManageCharges.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ProvidersManageCharges.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS.id}
    >
      <BoxMenu
        items={[
          { ...ROUTES.MANAGE_PROVIDERS_SERVICES, label: i18n.get('ProvidersManageCharges.BoxMenu.Item1') },
          { ...ROUTES.MANAGE_PROVIDERS_SUBSCRIBERS, label: i18n.get('ProvidersManageCharges.BoxMenu.Item2') },
          { ...ROUTES.MANAGE_PROVIDERS_CHARGES, label: i18n.get('ProvidersManageCharges.BoxMenu.Item3') },
        ]}
        selectedItem={ROUTES.MANAGE_PROVIDERS_CHARGES.id}
        onClickItem={handleOnMenuItemClick}
      />
      <TitleBox title={i18n.get('ProvidersManageCharges.BoxTitle')} />
      <BoxSeparator size="small" />
      <Hidden xsDown>
        <Box>
          <div className={classes.viewFilterWrapper}>
            <div>{i18n.get('ProvidersManageCharges.BoxViewTitle')}</div>
            {renderViewToggle()}
          </div>
        </Box>
      </Hidden>
      <Hidden smUp>
        <ContentBox title={i18n.get('ProvidersManageCharges.BoxViewTitle')} titleBold>
          <FiltersContainer>{renderViewToggle()}</FiltersContainer>
        </ContentBox>
      </Hidden>
      <BoxSeparator size="small" />
      <ContentBox title={i18n.get('ProvidersManageCharges.BoxFiltersTitle')} titleBold>
        <FiltersContainer showLabel={!isSmallScreen}>
          {selectedView === 0 ? (
            <React.Fragment>
              {renderServicesSelector()}
              <FiltersSeparator />
              {renderUserFilter()}
              <FiltersSeparator />
              {renderFiltersDateSelector()}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {renderServicesSelector()}
              <FiltersSeparator />
              {renderStatusesSelector()}
              <FiltersSeparator />
              {renderUserFilter()}
              <FiltersSeparator />
              {renderFiltersDateSelector()}
            </React.Fragment>
          )}
        </FiltersContainer>
      </ContentBox>
      <BoxSeparator size="small" />
      {selectedView === 0 ? (
        <Box direction="column">
          <div className={classes.gridTitleWrapper}>
            <div>
              <div>{i18n.get('ProvidersManageCharges.GridTitle')}</div>
            </div>
            {canExport ? (
              <Button
                onClick={handleOnGridExport}
                rightIcon={() => <SaveAltIcon />}
                disabled={!pendingCharges || !pendingCharges.elements || pendingCharges.elements.length === 0}
              >
                {isSmallScreen ? null : i18n.get('ProvidersManageCharges.GridExportButton')}
              </Button>
            ) : null}
          </div>
          <div className={classes.gridWrapper}>
            <div className={classes.gridSeparator} />
            {renderPendingCharges()}
          </div>
          {pendingCharges && pendingCharges.elements && pendingCharges.elements.length > 0 ? (
            <PaginationSimple
              currentPage={paginationPage}
              hasMorePages={pendingCharges.hasNext}
              onPressNext={handleOnPaginationPressNext}
              onPressPrevious={handleOnPaginationPressPrevious}
            />
          ) : null}
        </Box>
      ) : (
        <Box direction="column">
          <div className={classes.gridTitleWrapper}>
            <div>
              <div>{i18n.get('ProvidersManageCharges.GridTitle')}</div>
            </div>
            {canExport ? (
              <Button
                onClick={handleOnGridExport}
                rightIcon={() => <SaveAltIcon />}
                disabled={!chargesHistory || !chargesHistory.elements || chargesHistory.elements.length === 0}
              >
                {isSmallScreen ? null : i18n.get('ProvidersManageCharges.GridExportButton')}
              </Button>
            ) : null}
          </div>
          <div className={classes.gridWrapper}>
            <div className={classes.gridSeparator} />
            {renderChargesHistory()}
          </div>
        </Box>
      )}
      <Confirm
        open={showRetryChargeDialog || showCancelChargeDialog || showRejectChargeDialog}
        onClose={toggleDialog}
        title={i18n.get(
          showRejectChargeDialog
            ? 'ProvidersManageCharges.RejectChargeDialogTitle'
            : showCancelChargeDialog
            ? 'ProvidersManageCharges.CancelChargeDialogTitle'
            : 'ProvidersManageCharges.RetryChargeDialogTitle'
        )}
        confirmText={i18n.get('ProvidersManageCharges.DialogActionConfirm')}
        onConfirm={handleOnConfirm}
        cancelText={i18n.get('ProvidersManageCharges.DialogActionCancel')}
        onCancel={toggleDialog}
      >
        <Alert severity="error" icon={false} className={clsx(classes.alert, classes.alertDanger)}>
          <div>
            <ErrorOutlineOutlinedIcon className={classes.alertIcon} />
          </div>
          <div>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get(
                showRejectChargeDialog
                  ? 'ProvidersManageCharges.RejectChargeDialogContentTitle'
                  : showCancelChargeDialog
                  ? 'ProvidersManageCharges.CancelChargeDialogContentTitle'
                  : 'ProvidersManageCharges.RetryChargeDialogContentTitle'
              )}
            </AlertTitle>
            {i18n.get(
              showRejectChargeDialog
                ? 'ProvidersManageCharges.RejectChargeDialogContentText'
                : showCancelChargeDialog
                ? 'ProvidersManageCharges.RetryChargeDialogContentText'
                : 'ProvidersManageCharges.CancelChargeDialogContentText'
            )}
          </div>
        </Alert>
      </Confirm>
    </Page>
  );
};

ProviderManageChargesScreen.id = 'com.Handy.ProviderManageCharges';

export default ProviderManageChargesScreen;
