import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import qs from "query-string";
import _ from "lodash";

import ROUTES from "../../routes";
import {
  AssignPaymentMethodModal,
  BoxSeparator,
  Confirm,
  ContentBox,
  FiltersToggle,
  ForbiddenSection,
  Page,
  PaginationSimple,
  TitleBox,
} from "../../components";
import {
  user as userActions,
  business as businessActions,
} from "../../actions";
import { Colors, Language, Utils } from "../../utils";

import ListItemText from "@material-ui/core/ListItemText";
import Table from "@material-ui/core/Table";
import {
  Add as AddIcon,
  Visibility as VisisbilityIcon,
} from "@material-ui/icons";
import MoreVerticalIcon from "../../static/images/icons/more_vertical.svg";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

import makeClasses from "./styles";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Policies } from "../../utils/Policies";
import moment from "moment";

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: Colors.rgb.grey,
    },
    "&:nth-of-type(even)": {
      backgroundColor: Colors.rgb.white,
    },
  },
}))(TableRow);

const statusFilterOptions = [
  {
    id: 0,
    label: "ManagePaymentMethods.PaymentMethodsModesFilter.APPROVED",
  },
  {
    id: 1,
    label: "ManagePaymentMethods.PaymentMethodsModesFilter.REJECTED",
  },
  {
    id: 2,
    label: "ManagePaymentMethods.PaymentMethodsModesFilter.PENDING",
  },
];

const ManagePaymentMethodsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedView, setselectedView] = useState(
    searchParams.mode ? parseInt(searchParams.mode) : 0
  );
  const [paymentMethods, setPaymentMethods] = useState({});
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [paginationPage, setPaginationPage] = useState(
    searchParams.page ? parseInt(searchParams.page) : 1
  );
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [showAssignPaymentMethodModal, setShowAssignPaymentMethodModal] =
    useState(false);
  const [paymentMethodToAssign, setPaymentMethodToAssign] = useState(null);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
  const { policies, authTime, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    authTime: state.user.userData.authTime || Date.now(),
    languageTexts: state.language.texts || {},
  }));

  const i18n = Language(languageTexts);
  const hasPaymentMethods =
    paymentMethods.elements && paymentMethods.elements.length > 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!policies || !policies.includes(Policies.types.PROFILE)) {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (!forbiddenSection) {
      loadPaymentMethods();
    }
  }, [forbiddenSection, selectedView, paginationPage]);

  const loadPaymentMethods = () => {
    dispatch(
      userActions.getPaymentMethods(selectedView, paginationPage, (data) => {
        if (data) {
          data.elements.forEach((item) => {
            item.accountNumberVisible = false;
          });
          setPaymentMethods(data);
        }
      })
    );
  };

  const _handleOnClickNewPaymentMethod = () => {
    const exiredSession =
      Math.floor((new Date() - new Date(authTime * 1000)) / (1000 * 60)) >= 15;

    if (exiredSession) {
      return _toggleSessionExpiredModal();
    }

    dispatch(push(ROUTES.MANAGE_PAYMENT_METHODS_ADD.path));
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const _handleOnAssignBranch = (paymentMethod) => () => {
    toggleMenu()();

    const exiredSession =
      Math.floor((new Date() - new Date(authTime * 1000)) / (1000 * 60)) >= 15;

    if (exiredSession) {
      return _toggleSessionExpiredModal();
    }

    _toggleAssignPaymentMethodModal(false, paymentMethod);
  };

  const _toggleAssignPaymentMethodModal = (result, paymentMethod) => {
    setPaymentMethodToAssign(
      !showAssignPaymentMethodModal ? paymentMethod : null
    );
    setShowAssignPaymentMethodModal(!showAssignPaymentMethodModal);
    if (result) {
      loadPaymentMethods();
      dispatch(businessActions.getBranchesWithNoPaymentMethod());
    }
  };

  const handleOnPaginationChange = (e, nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _getAccountTypeName = (accountType) => {
    if (accountType === "CUENTA_CORRIENTE") {
      return i18n.get("ManagePaymentMethods.AccountTypes.CUENTA_CORRIENTE");
    }
    if (accountType === "CAJA_DE_AHORRO") {
      return i18n.get("ManagePaymentMethods.AccountTypes.CAJA_DE_AHORRO");
    }
    return "";
  };

  const _handleOnselectedViewChange = (mode) => {
    setselectedView(mode);
    setPaymentMethods({});
    setPaginationPage(1);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.mode = mode;
    searchParams.page = 1;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _toggleSessionExpiredModal = () => {
    setShowSessionExpiredModal(!showSessionExpiredModal);
  };

  const _logOut = () => {
    dispatch(userActions.logOut());
  };

  const renderPaymentMethodsGrid = () => {
    if (!hasPaymentMethods) {
      return (
        <div className={classes.noDataMessage}>
          {i18n.get("ManagePaymentMethods.NoUsersMessage")}
        </div>
      );
    }

    if (selectedView === 0 || selectedView === 1) {
      return renderPaymentMethods();
    } else if (selectedView === 2) {
      return renderPendingPaymentMethods();
    }
  };

  const toggleAccountNumber = (paymentMethodIndex) => async () => {
    const paymentMethod = paymentMethods.elements[paymentMethodIndex];

    paymentMethod.accountNumberVisible = !paymentMethod.accountNumberVisible;

    if (
      paymentMethod.accountNumberVisible &&
      !paymentMethod.fullAccountNumber
    ) {
      dispatch(
        userActions.getPaymentMethodFullAccountNumber(
          paymentMethods.elements[paymentMethodIndex].traceId,
          (data) => {
            if (data) {
              paymentMethod.fullAccountNumber = data.accountNumber;
              setPaymentMethods({ ...paymentMethods });
            }
          }
        )
      );
    } else {
      setPaymentMethods({ ...paymentMethods });
    }
  };

  const renderPaymentMethods = () => {
    if (isSmallScreen) {
      return (
        <div>
          {paymentMethods.elements.map((paymentMethod, index) => (
            <React.Fragment key={index}>
              {index === 0 ? null : (
                <div className={classes.boxItemSeparator} />
              )}
              <div
                className={clsx(
                  classes.boxContainer,
                  index % 2 === 0 ? classes.boxContainerEven : null
                )}
              >
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableHeaderPaymentEntity"
                    )}
                    :
                  </span>
                  <span>
                    {paymentMethod.paymentEntity ||
                      paymentMethod.paymentEntityKey}
                  </span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableHeaderCurrency"
                    )}
                    :
                  </span>
                  <span>{paymentMethod.currencyISO}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableHeaderAccountNumber"
                    )}
                    :
                  </span>
                  <span className={classes.tableBodyCellAccountNumber}>
                    {selectedView === 0 ? (
                      <React.Fragment>
                        {paymentMethod.accountNumberVisible &&
                        paymentMethod.fullAccountNumber
                          ? paymentMethod.fullAccountNumber
                          : paymentMethod.accountNumber}
                        <IconButton
                          onClick={toggleAccountNumber(index)}
                          style={{ marginLeft: 5 }}
                        >
                          {paymentMethod.accountNumberVisible ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </React.Fragment>
                    ) : (
                      paymentMethod.accountNumber
                    )}
                  </span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableHeaderAccountType"
                    )}
                    :
                  </span>
                  <span>{_getAccountTypeName(paymentMethod.accountType)}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableHeaderOwner"
                    )}
                    :
                  </span>
                  <span>{paymentMethod.ownerName}</span>
                </div>
                {selectedView === 0 ? (
                  <div
                    className={clsx(classes.boxItem, classes.boxItemCompact)}
                  >
                    <span>
                      {i18n.get(
                        "ManagePaymentMethods.PaymentMethodsTableHeaderBranches"
                      )}
                      :
                    </span>
                    <span>
                      {paymentMethod.branches
                        .map((branch) => branch.name)
                        .join(", ")}
                    </span>
                  </div>
                ) : null}
                {selectedView === 0 ? (
                  <React.Fragment>
                    <div className={classes.listItemActions}>
                      <IconButton onClick={toggleMenu(index)}>
                        <img src={MoreVerticalIcon} alt="More" />
                      </IconButton>
                    </div>
                    <Menu
                      anchorEl={menuAnchorEl}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      keepMounted
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                      open={menuOpenIndex === index}
                      onClose={toggleMenu()}
                      className={classes.listItemMenu}
                    >
                      <MenuItem
                        onClick={_handleOnAssignBranch(paymentMethod)}
                        className={classes.listItemMenuItem}
                      >
                        <ListItemIcon className={classes.menuItemIcon}>
                          <VisisbilityIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            paymentMethod.branches.length === 0
                              ? i18n.get(
                                  "ManagePaymentMethods.PaymentMethodsTableBodyActionsAddBranch"
                                )
                              : i18n.get(
                                  "ManagePaymentMethods.PaymentMethodsTableBodyActionsAddRemoveBranch"
                                )
                          }
                          className={classes.menuItemText}
                        />
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                ) : null}
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }

    return (
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get(
                  "ManagePaymentMethods.PaymentMethodsTableHeaderPaymentEntity"
                )}
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get(
                  "ManagePaymentMethods.PaymentMethodsTableHeaderCurrency"
                )}
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get(
                  "ManagePaymentMethods.PaymentMethodsTableHeaderAccountNumber"
                )}
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get(
                  "ManagePaymentMethods.PaymentMethodsTableHeaderAccountType"
                )}
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get(
                  "ManagePaymentMethods.PaymentMethodsTableHeaderOwner"
                )}
              </TableCell>
              {selectedView === 0 ? (
                <TableCell className={classes.tableHeaderCell}>
                  {i18n.get(
                    "ManagePaymentMethods.PaymentMethodsTableHeaderBranches"
                  )}
                </TableCell>
              ) : null}
              {selectedView === 0 ? (
                <TableCell align="right" className={classes.tableHeaderCell}>
                  {i18n.get(
                    "ManagePaymentMethods.PaymentMethodsTableHeaderActions"
                  )}
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentMethods.elements.map((paymentMethod, index) => (
              <StyledTableRow key={paymentMethod.traceId}>
                <TableCell className={classes.tableBodyCell}>
                  {paymentMethod.paymentEntity ||
                    paymentMethod.paymentEntityKey}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {paymentMethod.currencyISO}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  <div className={classes.tableBodyCellAccountNumber}>
                    {selectedView === 0 ? (
                      <React.Fragment>
                        {paymentMethod.accountNumberVisible &&
                        paymentMethod.fullAccountNumber
                          ? paymentMethod.fullAccountNumber
                          : paymentMethod.accountNumber}
                        <IconButton
                          onClick={toggleAccountNumber(index)}
                          style={{ marginLeft: 5 }}
                        >
                          {paymentMethod.accountNumberVisible ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </React.Fragment>
                    ) : (
                      paymentMethod.accountNumber
                    )}
                  </div>
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {_getAccountTypeName(paymentMethod.accountType)}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {paymentMethod.ownerName}
                </TableCell>
                {selectedView === 0 ? (
                  <TableCell className={classes.tableBodyCell}>
                    {paymentMethod.branches
                      .map((branch) => branch.name)
                      .join(", ")}
                  </TableCell>
                ) : null}
                {selectedView === 0 ? (
                  <React.Fragment>
                    <TableCell className={classes.tableBodyCell} align="right">
                      <div className={classes.listItemActions}>
                        <IconButton onClick={toggleMenu(index)}>
                          <img src={MoreVerticalIcon} alt="More" />
                        </IconButton>
                      </div>
                      <Menu
                        anchorEl={menuAnchorEl}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={menuOpenIndex === index}
                        onClose={toggleMenu()}
                        className={classes.listItemMenu}
                      >
                        <MenuItem
                          onClick={_handleOnAssignBranch(paymentMethod)}
                          className={classes.listItemMenuItem}
                        >
                          <ListItemIcon className={classes.menuItemIcon}>
                            <VisisbilityIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              paymentMethod.branches.length === 0
                                ? i18n.get(
                                    "ManagePaymentMethods.PaymentMethodsTableBodyActionsAddBranch"
                                  )
                                : i18n.get(
                                    "ManagePaymentMethods.PaymentMethodsTableBodyActionsAddRemoveBranch"
                                  )
                            }
                            className={classes.menuItemText}
                          />
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </React.Fragment>
                ) : null}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderPendingPaymentMethods = () => {
    if (isSmallScreen) {
      return (
        <div>
          {paymentMethods.elements.map((paymentMethod, index) => (
            <React.Fragment key={index}>
              {index === 0 ? null : (
                <div className={classes.boxItemSeparator} />
              )}
              <div
                className={clsx(
                  classes.boxContainer,
                  index % 2 === 0 ? classes.boxContainerEven : null
                )}
              >
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableHeaderDate"
                    )}
                    :
                  </span>
                  <span>
                    {moment(paymentMethod.initializeRequestDateTime).format(
                      i18n.get("DateFormat", false) || Utils.DEFAULT_DATE_FORMAT
                    )}
                  </span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableHeaderPaymentEntity"
                    )}
                    :
                  </span>
                  <span>
                    {paymentMethod.paymentEntity ||
                      paymentMethod.paymentEntityKey}
                  </span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableHeaderCurrency"
                    )}
                    :
                  </span>
                  <span>{paymentMethod.currencyISO}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableHeaderAccountNumber"
                    )}
                    :
                  </span>
                  <span>{paymentMethod.accountNumber}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableHeaderAccountType"
                    )}
                    :
                  </span>
                  <span>{_getAccountTypeName(paymentMethod.accountType)}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableHeaderOwner"
                    )}
                    :
                  </span>
                  <span>{paymentMethod.ownerName}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableHeaderStatus"
                    )}
                    :
                  </span>
                  <span
                    className={clsx(
                      classes.tableBodyCellStatus,
                      classes.tableBodyCellStatusPending
                    )}
                  >
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableBodyStatusPending"
                    )}
                  </span>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }

    return (
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get("ManagePaymentMethods.PaymentMethodsTableHeaderDate")}
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get(
                  "ManagePaymentMethods.PaymentMethodsTableHeaderPaymentEntity"
                )}
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get(
                  "ManagePaymentMethods.PaymentMethodsTableHeaderCurrency"
                )}
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get(
                  "ManagePaymentMethods.PaymentMethodsTableHeaderAccountNumber"
                )}
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get(
                  "ManagePaymentMethods.PaymentMethodsTableHeaderAccountType"
                )}
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get(
                  "ManagePaymentMethods.PaymentMethodsTableHeaderOwner"
                )}
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get(
                  "ManagePaymentMethods.PaymentMethodsTableHeaderStatus"
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentMethods.elements.map((paymentMethod) => (
              <StyledTableRow key={paymentMethod.traceId}>
                <TableCell className={classes.tableBodyCell}>
                  {moment(paymentMethod.initializeRequestDateTime).format(
                    i18n.get("DateFormat", false) || Utils.DEFAULT_DATE_FORMAT
                  )}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {paymentMethod.paymentEntity ||
                    paymentMethod.paymentEntityKey}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {paymentMethod.currencyISO}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {paymentMethod.accountNumberVisible
                    ? paymentMethod.fullAccountNumber
                    : paymentMethod.accountNumber}{" "}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {_getAccountTypeName(paymentMethod.accountType)}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {paymentMethod.ownerName}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  <span
                    className={clsx(
                      classes.tableBodyCellStatus,
                      classes.tableBodyCellStatusPending
                    )}
                  >
                    {i18n.get(
                      "ManagePaymentMethods.PaymentMethodsTableBodyStatusPending"
                    )}
                  </span>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderGridTitle = () => {
    return (
      <div className={classes.gridBoxTitleContainer}>
        <div className={classes.gridBoxTitleWrapper}>
          {i18n.get("ManagePaymentMethods.BoxUsersTitle")}
        </div>
        <div className={classes.gridBoxTitleFilterWrapper}>
          <FiltersToggle
            showLabel={false}
            showDefault={false}
            selected={selectedView}
            options={statusFilterOptions}
            onChange={_handleOnselectedViewChange}
            showAsColumnSm
          />
        </div>
      </div>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get("ManagePaymentMethods.Title")}
        withActivePage={ROUTES.MANAGE_PAYMENT_METHODS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get("ManagePaymentMethods.Title")}
      withActivePage={ROUTES.MANAGE_PAYMENT_METHODS.id}
    >
      <TitleBox
        title={i18n.get("ManagePaymentMethods.BoxTitle")}
        buttonsRight={_.compact([
          {
            text: isSmallScreen
              ? null
              : i18n.get("ManagePaymentMethods.BoxTitleButtonText"),
            icon: () => <AddIcon fontSize="small" />,
            onClick: _handleOnClickNewPaymentMethod,
          },
        ])}
      />
      <BoxSeparator />
      <ContentBox
        title={renderGridTitle}
        titleBold
        headerVertical={isSmallScreen}
      >
        {renderPaymentMethodsGrid()}
        {paymentMethods.elements && paymentMethods.elements.length > 0 ? (
          <PaginationSimple
            currentPage={paginationPage}
            hasMorePages={paymentMethods.hasNext}
            onPressNext={() =>
              handleOnPaginationChange(null, paginationPage + 1)
            }
            onPressPrevious={() =>
              handleOnPaginationChange(null, paginationPage - 1)
            }
          />
        ) : null}
      </ContentBox>
      {showAssignPaymentMethodModal ? (
        <AssignPaymentMethodModal
          open={showAssignPaymentMethodModal}
          paymentMethod={paymentMethodToAssign}
          onClose={(result) => _toggleAssignPaymentMethodModal(result)}
        />
      ) : null}
      <Confirm
        open={showSessionExpiredModal}
        onClose={_toggleSessionExpiredModal}
        title={i18n.get("ManagePaymentMethods.ExpiredSessionDialogTitle")}
        onConfirm={_logOut}
        confirmText={i18n.get(
          "ManagePaymentMethods.ExpiredSessionDialogActionConfirm"
        )}
        cancelText={i18n.get(
          "ManagePaymentMethods.ExpiredSessionDialogActionCancel"
        )}
        onCancel={_toggleSessionExpiredModal}
      >
        <Alert
          severity="info"
          icon={false}
          className={clsx(classes.alert, classes.alertInfo)}
        >
          <div>
            <InfoOutlinedIcon className={classes.alertIcon} />
          </div>
          <div className={classes.alertMessage}>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get(
                "ManagePaymentMethods.ExpiredSessionDialogContentTitle"
              )}
            </AlertTitle>
            {i18n.get(
              "ManagePaymentMethods.ExpiredSessionDialogContentMessage"
            )}
          </div>
        </Alert>
      </Confirm>
    </Page>
  );
};

ManagePaymentMethodsScreen.id = "com.Handy.ManagePaymentMethods";

export default ManagePaymentMethodsScreen;
