import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../utils';

const makeClasses = makeStyles((theme) => ({
  listItemMenu: {
    padding: theme.spacing(1),
  },
  listItemMenuItem: {
    color: Colors.rgb.contentText,
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.S,
  },
  listItemActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '& > button': {
      width: 40,
      padding: 6
    },

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  noDataMessage: {
    fontFamily: theme.fontFamily.semiBold,
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentTextSoft,
    textAlign: 'center',
    width: '100%',
  },
  menuItemText: {
    minWidth: 0,
    marginTop: 0,
  },
  menuItemIcon: {
    minWidth: 35,
  },
  alert: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    width: '100%',
    boxSizing: 'border-box',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  alertTitle: {
    fontFamily: theme.fontFamily.bold,
  },
  alertIcon: {
    fontSize: 50,
    marginRight: theme.spacing(2),
  },
  alertDanger: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
}));

export default makeClasses;
