import React, { useState, useEffect, useRef } from 'react';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import ROUTES from '../../routes';
import {
  Page,
  FiltersDateSelector,
  VouchersTypeSelector,
  OperationsTable,
  TitleBox,
  BoxSeparator,
  ContentBox,
  FiltersContainer,
  FiltersSeparator,
  EmptyMessage,
  ForbiddenSection,
} from '../../components';
import { CUSTOM_FILTER, getDateRangeForFilter } from '../../components/FiltersDateSelector';
import { business as businessActions } from '../../actions';
import { Language, Utils } from '../../utils';

import SaveAltIcon from '@material-ui/icons/SaveAlt';
import moment from 'moment';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Policies } from '../../utils/Policies';
import { toISOString } from '../../utils/Utils';

const VouchersScreen = ({ history }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const searchParams = qs.parse(history.location.search);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [ vouchers, setVouchers ] = useState({});
  const [ selectedBranch ] = useState(searchParams.bid || 'all');
  const [ selectedFilter, setSelectedFilter ] = useState(searchParams.fid ? parseInt(searchParams.fid) : 3);
  const [ selectedCustomFilter, setSelectedCustomFilter ] = useState(
    parseInt(searchParams.fid) === CUSTOM_FILTER ? { startDate: searchParams.fsd, endDate: searchParams.fed } : {}
  );
  const [ selectedVoucherType, setSelectedVoucherType ] = useState(searchParams.vid || 'all');
  const [ paginationPage, setPaginationPage ] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [ columnOrder, setColumnOrder ] = useState(
    searchParams.columnNumber ? parseInt(searchParams.columnNumber) : ''
  );
  const [ columnOrderType, setColumnOrderType ] = useState(searchParams.sortType ? searchParams.sortType : '');
  const [ paginationCount ] = useState(searchParams.count ? parseInt(searchParams.count) : 10);
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const { policies, languageTexts, branches } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
    branches: state.business.branches || [],
  }));
  const prevPaginationPage = useRef(null);
  const i18n = Language(languageTexts);

  const hasVouchers = vouchers && vouchers.elements && vouchers.elements.length > 0;
  const canExportVouchers =
    policies && policies.includes(Policies.types.VOUCHERS) && policies.includes(Policies.types.SUPER_READ);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(
    () => {
      if (!policies || !policies.includes(Policies.types.VOUCHERS)) {
        setForbbidenSection(true);
      }
    },
    [ policies ]
  );

  useEffect(
    () => {
      if (!forbiddenSection) {
        if (paginationPage > 1 && prevPaginationPage.current === paginationPage) {
          _handleOnPaginationChange(1);
          prevPaginationPage.current = null;
        } else {
          loadData();
          prevPaginationPage.current = paginationPage;
        }
      }
    },
    [
      forbiddenSection,
      paginationPage,
      columnOrder,
      columnOrderType,
      selectedFilter,
      selectedCustomFilter,
      selectedVoucherType,
    ]
  );

  const loadData = () => {
    const branchId = selectedBranch !== 'all' ? selectedBranch : null;
    const voucherTypeId = selectedVoucherType !== 'all' ? selectedVoucherType : null;
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.getVouchers(
        branchId,
        startDate,
        endDate,
        voucherTypeId,
        paginationPage,
        paginationCount,
        columnOrder,
        columnOrderType,
        vouchers => {
          if (vouchers && vouchers.rowsColumnAndSale && vouchers.rowsColumnAndSale.length > 0) {
            vouchers.rowsColumnAndSale.forEach(item => {
              item.columns[3].data = i18n.get(`Tickets.GridColumnType_${item.columns[3].data}`);
            });
          }
          setVouchers(vouchers);
        }
      )
    );
  };

  const _handleOnFilterSelected = (filter, startDate, endDate) => {
    setSelectedFilter(filter);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnVoucherTypeSelected = type => {
    setSelectedVoucherType(type);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.vid = type;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnPaginationChange = nextPage => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnTableSortChange = (columnNumber, sortType) => {
    setColumnOrder(columnNumber);
    setColumnOrderType(sortType);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.columnNumber = columnNumber;
    searchParams.sortType = sortType;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnTableExport = () => {
    const branchId = selectedBranch !== 'all' ? selectedBranch : null;
    const voucherTypeId = selectedVoucherType !== 'all' ? selectedVoucherType : null;
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.exportTable(
        'vouchers',
        {
          branchId,
          begin: startDate,
          end: endDate,
          type: voucherTypeId,
          columnOrder,
          columnOrderType,
        },
        file =>
          Utils.downloadFile(
            file,
            'ComprobantesFiscales_' +
              dateFilter.startDate.format(i18n.get('ExportDateFormat')) +
              '_' +
              dateFilter.endDate.format(i18n.get('ExportDateFormat')) +
              '.xlsx'
          )
      )
    );
  };

  const renderFiltersDateSelector = () => (
    <FiltersDateSelector
      selectedFilter={selectedFilter}
      onFilterSelected={_handleOnFilterSelected}
      align="right"
      dateFormat={i18n.get('DateFormat', false)}
    />
  );

  const renderVouchersTypesSelector = () => (
    <VouchersTypeSelector
      selectedVoucherType={selectedVoucherType}
      onVoucherTypeSelected={_handleOnVoucherTypeSelected}
      align="right"
    />
  );

  const renderOperationsTable = () => {
    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('Tickets.Grid.Column1'),
        sortable: true,
        type: 'date',
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('Tickets.Grid.Column2'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get('Tickets.Grid.Column3'),
        sortable: true,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get('Tickets.Grid.Column4'),
        sortable: true,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get('Tickets.Grid.Column5'),
        sortable: true,
        type: 'number-inline',
      },
      {
        canHide: false,
        columnNumber: 6,
        name: i18n.get('Tickets.Grid.Column6'),
        sortable: false,
        type: 'download',
        align: 'right',
        buttonTextOnly: true,
        buttonSize: 'md',
        buttonLabel: i18n.get('Tickets.Grid.Column6ButtonLabel'),
      },
    ];
    const body = (vouchers.elements || []).map(item => ({
      rowId: item.id,
      columns: [
        {
          columnNumber: 1,
          data: item.dateTime,
        },
        {
          columnNumber: 2,
          data: branches.find(b => b.traceId === item.branchTraceId).name,
        },
        {
          columnNumber: 3,
          data: i18n.get(`Tickets.Types.${item.type}`),
        },
        {
          columnNumber: 4,
          data: item.serial && item.number ? `${item.serial} ${item.number}` : '',
        },
        {
          columnNumber: 5,
          data: item.total,
        },
        {
          columnNumber: 6,
          data: item.pdfLink,
          buttonTextOnly: true,
          buttonSize: 'md',
          buttonLabel: i18n.get('Tickets.Grid.Column6ButtonLabel'),
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={(vouchers.elements || []).length === paginationCount}
        onPaginationChange={_handleOnPaginationChange}
        emptyMessage={i18n.get('MobileBalance.GridEmptyMessage')}
        showDetails={false}
        onTableSortChange={_handleOnTableSortChange}
        showDates={false}
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page withHeader withSidebar withHeaderTitle={i18n.get('Tickets.Title')} withActivePage={ROUTES.VOUCHERS.id}>
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('Tickets.Title')} withActivePage={ROUTES.VOUCHERS.id}>
      <TitleBox title={i18n.get('Tickets.BoxTitle')} />
      <BoxSeparator />
      <ContentBox title={i18n.get('Tickets.BoxFiltersTitle')} titleBold>
        <FiltersContainer>
          {renderVouchersTypesSelector()}
          <FiltersSeparator />
          {renderFiltersDateSelector()}
        </FiltersContainer>
      </ContentBox>
      <BoxSeparator size="small" />
      <ContentBox
        title={i18n.get('Tickets.GridTitle')}
        titleBold
        button={hasVouchers && canExportVouchers ? true : false}
        buttonText={isSmallScreen ? null : i18n.get('Tickets.GridExportButton')}
        buttonOnClick={_handleOnTableExport}
        buttonRightIcon={() => <SaveAltIcon fontSize="small" />}
      >
        {hasVouchers ? (
          renderOperationsTable()
        ) : (
          <EmptyMessage textOnly>{i18n.get('Tickets.GridEmptyMessage')}</EmptyMessage>
        )}
      </ContentBox>
    </Page>
  );
};

VouchersScreen.id = 'com.Handy.Vouchers';

export default VouchersScreen;
