import React from 'react';
import makeClasses from './styles';

const OperationsTableMoreInformationItem = ({ label, value }) => {
  const classes = makeClasses();

  return (
    <div className={classes.detailsItem}>
      <div className={classes.detailsItemLabel}>{label}</div>
      <div className={classes.detailsItemValue}>{value}</div>
    </div>
  );
};

export default OperationsTableMoreInformationItem;
