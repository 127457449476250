export const rgba = (color, alpha) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  return result ? `rgba(${r}, ${g}, ${b}, ${alpha / 100})` : null;
};

export const rgb = {
  // Global
  primary: '#6E28FA',
  secondary: '#5920C9',
  text: '#E5E5E5',
  textHighlighted: '#FFFFFF',
  textSoft: '#E5E5E5',
  // Header
  headerBackground: '#6E28FA',
  headerBackgroundSecondary: '#5920C9',
  headerText: '#E5E5E5',
  headerTextHighlighted: '#FFFFFF',
  headerTextSoft: '#E5E5E5',
  // Menu
  menuBackgroundPrimary: '#FFFFFF',
  menuBackgroundSecondary: '#EFEFEF',
  menuText: '#8D97AD',
  menuTextHighlighted: '#6E28FA',
  menuTextSoft: '#D1D5DE',
  // Content
  contentBackgroundPrimary: '#EDF1F5',
  contentBackgroundSecondary: '#FFFFFF',
  contentBackgroundComplementary: '#6E28FA',
  contentText: '#666666',
  contentTextHighlighted: '#333333',
  contentTextSuperHighlighted: '#1A1A1A',
  contentTextSoft: '#999999',
  contentTextSuperSoft: '#FFFFFF',
  contentLine: '#D1D5DE',
  contentLineHighlighted: '#D1D5DE',
  contentLineSoft: '#E6E6E6',
  // Click Element
  clickElement: '#F2F2F2',
  clickElementHighlighted: '#5920C9',
  clickElementSoft: '#F8F9FA',
  // Alerts
  alertSuccessBackground: '#D4EDDA',
  alertSuccessBorder: '#C3E6CB',
  alertSuccessText: '#155724',
  alertWarningBackground: '#FFF3CD',
  alertWarningBorder: '#FFEEBA',
  alertWarningText: '#856404',
  alertDangerBackground: '#F8D7DA',
  alertDangerBorder: '#F5C6CB',
  alertDangerText: '#721C24',
  alertInfoBackground: '#cce5ff',
  alertInfoBorder: '#b8daff',
  alertInfoText: '#004085',
  alertIdleBackground: '#e2e3e5',
  alertIdleBorder: '#d3d6d8',
  alertIdleText: '#41464b',
  //
  white: '#ffffff',
  black: '#000000',
  black_light: '#222222',
  grey: '#F8F9FA',
  grey_dark: '#cccccc',
  primaryGreen: '#3efcab',
  number_red: '#D91123',
  number_green: '#44D92E',
  boxGreen: '#44d92e',
  boxYellow: '#e3d70e',
  primaryHover: '#a1a1a1',
  //
  contentBackgroundLight: 'rgba(0, 0, 0, 0.03)',
  gold: '#e3b73c',
  light_black: '#2D2D2D',
  blue: '#6E28FA',
  dark_blue: '#5717D8',
  light_blue: '#2BB9F5',
  red: '#D0021B',
  red_dark: '#c62828',
  soft_red: '#FFF5F6',
  green: '#82C43A',
  green_dark: '#417107',
  yellow: '#ffee58',
  yellow_dark: '#f9a825',
  gray: '#e8e8e8',
  dark_gray: '#333333',
  medium_gray: '#444444',
  light_gray: '#f9f9f9',
  transparent: 'transparent',
  page_background: '#EDEDED',
  notification_background: 'rgba(110,40,250,0.65)',
  purple: '#8224FF',
  purple_dark: '#5d02cd',
  light_green: '#1BFABC',
};
