import * as types from './User.actionTypes';

export const initialize = (callback) => {
  return {
    type: types.INITIALIZE,
    callback,
  };
};

export const userLoggedIn = () => {
  return {
    type: types.USER_LOGGED_IN,
  };
};

export const setLoading = (value) => {
  return {
    type: types.SET_LOADING,
    value,
  };
};

export const setUserData = (data) => {
  return {
    type: types.SET_USER_DATA,
    data,
  };
};

export const login = (rut, callback) => {
  return {
    type: types.LOGIN,
    rut,
    callback,
  };
};

export const loginAdditional = (rut, username, password, callback) => {
  return {
    type: types.LOGIN_ADDITIONAL,
    rut,
    username,
    password,
    callback,
  };
};

export const clear = () => {
  return {
    type: types.CLEAR,
  };
};

export const logOut = () => {
  return {
    type: types.LOGOUT,
  };
};

export const userLoggedOut = () => {
  return {
    type: types.USER_LOGGED_OUT,
  };
};

export const verifyAccount = (cognitoUser, verificationCode, callbackSuccess) => {
  return {
    type: types.VERIFY_ACCOUNT,
    cognitoUser,
    verificationCode,
    callbackSuccess,
  };
};

export const verifyAccountAdditional = (cognitoUser, callbackSuccess, callbackError) => {
  return {
    type: types.VERIFY_ACCOUNT_ADDITIONAL,
    cognitoUser,
    callbackSuccess,
    callbackError,
  };
};

export const getProfileData = (callback) => {
  return {
    type: types.GET_PROFILE_DATA,
    callback,
  };
};

export const getUserRoles = (callback) => {
  return {
    type: types.GET_USER_ROLES,
    callback,
  };
};

export const getUser = (userTraceId, callback) => {
  return {
    type: types.GET_USER,
    userTraceId,
    callback,
  };
};

export const getUsers = (page, callback) => {
  return {
    type: types.GET_USERS,
    page,
    callback,
  };
};

export const createUser = (params, callback) => {
  return {
    type: types.CREATE_USER,
    params,
    callback,
  };
};

export const updateUser = (userTraceId, params, callback) => {
  return {
    type: types.UPDATE_USER,
    userTraceId,
    params,
    callback,
  };
};

export const updatePassword = (userTraceId, password, callback) => {
  return {
    type: types.UPDATE_PASSWORD,
    userTraceId,
    password,
    callback,
  };
};

export const disableUser = (userTraceId, callback) => {
  return {
    type: types.DISABLE_USER,
    userTraceId,
    callback,
  };
};

export const enableUser = (userTraceId, callback) => {
  return {
    type: types.ENABLE_USER,
    userTraceId,
    callback,
  };
};

export const deleteUser = (userTraceId, callback) => {
  return {
    type: types.DELETE_USER,
    userTraceId,
    callback,
  };
};

export const getUsersCount = (callback) => {
  return {
    type: types.GET_USERS_COUNT,
    callback,
  };
};

export const requestChangeEmail = (newEmail, callbackSuccess, callbackError) => {
  return {
    type: types.REQUEST_CHANGE_EMAIL,
    newEmail,
    callbackSuccess,
    callbackError,
  };
};

export const requestChangePhoneNumber = (countryId, dialingCode, newPhoneNumber, callbackSuccess, callbackError) => {
  return {
    type: types.REQUEST_CHANGE_PHONE_NUMBER,
    countryId,
    dialingCode,
    newPhoneNumber,
    callbackSuccess,
    callbackError,
  };
};

export const verifyDataChange = (accessCode, requestTraceId, callbackSuccess, callbackError) => {
  return {
    type: types.VERIFY_DATA_CHANGE,
    accessCode,
    requestTraceId,
    callbackSuccess,
    callbackError,
  };
};

export const getCategories = (categoryType, callback) => {
  return {
    type: types.GET_CATEGORIES,
    categoryType,
    callback,
  };
};

export const getCountries = (callback) => {
  return {
    type: types.GET_COUNTRIES,
    callback,
  };
};

export const getDepartmentsByCountry = (countryId, callback) => {
  return {
    type: types.GET_DEPARTMENTS_BY_COUNTRY,
    countryId,
    callback,
  };
};

export const getCitiesByDepartments = (departmentId, callback) => {
  return {
    type: types.GET_CITIES_BY_DEPARTMENTS,
    departmentId,
    callback,
  };
};

export const startAffiliation = (input, callback) => {
  return {
    type: types.START_AFFILIATION,
    input,
    callback,
  };
};

export const affiliationRequestEmailVerificationCode = (traceId, callback) => {
  return {
    type: types.AFFILIATION_REQUEST_EMAIL_VERIFICATION_CODE,
    traceId,
    callback,
  };
};

export const affiliationRequestPhoneVerificationCode = (traceId, callback) => {
  return {
    type: types.AFFILIATION_REQUEST_PHONE_VERIFICATION_CODE,
    traceId,
    callback,
  };
};

export const affiliationVerifyVerificationCode = (traceId, requestTraceId, verificationCode, callback) => {
  return {
    type: types.AFFILIATION_VERIFY_VERIFICATION_CODE,
    traceId,
    requestTraceId,
    verificationCode,
    callback,
  };
};

export const affiliationGetIdentityVerificationLink = (traceId, callback) => {
  return {
    type: types.AFFILIATION_GET_IDENTITY_VERIFICATION_LINK,
    traceId,
    callback,
  };
};

export const affiliationGetIdentityVerificationStatus = (traceId, callback) => {
  return {
    type: types.AFFILIATION_GET_IDENTITY_VERIFICATION_STATUS,
    traceId,
    callback,
  };
};

export const getPaymentMethods = (selectedView, page, callback) => {
  return {
    type: types.GET_PAYMENT_METHODS,
    selectedView,
    page,
    callback,
  };
};

export const getPaymentMethodsByBranches = (branchTraceIds, callback) => {
  return {
    type: types.GET_PAYMENT_METHODS_BY_BRANCHES,
    branchTraceIds,
    callback,
  };
};

export const getPaymentMethodFullAccountNumber = (paymentMethodTraceId, callback) => {
  return {
    type: types.GET_PAYMENT_METHOD_FULL_ACCOUNT_NUMBER,
    paymentMethodTraceId,
    callback,
  };
};

export const getPosRequests = (states, page, callback) => {
  return {
    type: types.GET_POS_REQUESTS,
    states,
    page,
    callback,
  };
};

export const getPosRequestsHistory = (page, callback) => {
  return {
    type: types.GET_POS_REQUESTS_HISTORY,
    page,
    callback,
  };
};

export const getPosTypes = (personType, callback) => {
  return {
    type: types.GET_POS_TYPES,
    personType,
    callback,
  };
};

export const addPosRequest = (params, callback) => {
  return {
    type: types.ADD_POS_REQUEST,
    params,
    callback,
  };
};

export const cancelPosRequest = (posTraceId, callback) => {
  return {
    type: types.CANCEL_POS_REQUEST,
    posTraceId,
    callback,
  };
};

export const getPOSByBranches = (branchTraceIds, callback) => {
  return {
    type: types.GET_POS_BY_BRANCHES,
    branchTraceIds,
    callback,
  };
};

export const getHanyTaps = (page, callback) => {
  return {
    type: types.GET_HANDY_TAPS,
    page,
    callback,
  };
};

export const addHandyTap = (branchId, params, callback) => {
  return {
    type: types.ADD_HANDY_TAP,
    branchId,
    params,
    callback,
  };
};

export const getPaymentEntities = (callback) => {
  return {
    type: types.GET_PAYMENT_ENTITIES,
    callback,
  };
};

export const getPaymentCurrencies = (callback) => {
  return {
    type: types.GET_PAYMENT_CURRENCIES,
    callback,
  };
};

export const getPaymentEntityRegex = (entityId, callback) => {
  return {
    type: types.GET_PAYMENT_ENTITY_REGEX,
    entityId,
    callback,
  };
};

export const addPaymentMethod = (data, callback) => {
  return {
    type: types.ADD_PAYMENT_METHOD,
    data,
    callback,
  };
};

export const verifyChangePaymentMethod = (accessCode, requestTraceId, callbackSuccess, callbackError) => {
  return {
    type: types.VERIFY_CHANGE_PAYMENT_METHOD,
    accessCode,
    requestTraceId,
    callbackSuccess,
    callbackError,
  };
};

export const assignBranchesToPaymentMethod = (paymentMethodTraceId, branchesToAssign, branchesToRemove, callback) => {
  return {
    type: types.ASSIGN_BRANCHES_TO_PAYMENT_METHOD,
    paymentMethodTraceId,
    branchesToAssign,
    branchesToRemove,
    callback,
  };
};

export const merchantStartAffiliation = (input, callback) => {
  return {
    type: types.MERCHANT_START_AFFILIATION,
    input,
    callback,
  };
};

export const merchantAffiliationRequestEmailVerificationCode = (traceId, callback) => {
  return {
    type: types.MERCHANT_AFFILIATION_REQUEST_EMAIL_VERIFICATION_CODE,
    traceId,
    callback,
  };
};

export const merchantAffiliationRequestPhoneVerificationCode = (traceId, callback) => {
  return {
    type: types.MERCHANT_AFFILIATION_REQUEST_PHONE_VERIFICATION_CODE,
    traceId,
    callback,
  };
};

export const merchantAffiliationVerifyVerificationCode = (traceId, requestTraceId, verificationCode, callback) => {
  return {
    type: types.MERCHANT_AFFILIATION_VERIFY_VERIFICATION_CODE,
    traceId,
    requestTraceId,
    verificationCode,
    callback,
  };
};

export const getMccs = (callback) => {
  return {
    type: types.GET_MCCS,
    callback,
  };
};

export const getPaymentPlans = (callback) => {
  return {
    type: types.GET_PAYMENT_PLANS,
    callback,
  };
};

export const merchantAffiliationData = (traceId, merchantData, dgiFiles, exemptionFiles, callback) => {
  return {
    type: types.MERCHANT_AFFILIATION_DATA,
    traceId,
    merchantData,
    dgiFiles,
    exemptionFiles,
    callback,
  };
};

export const getPositions = (callback) => {
  return {
    type: types.GET_POSITIONS,
    callback,
  };
};

export const getBusinessNameTypes = (callback) => {
  return {
    type: types.BUSINESS_NAME_TYPES,
    callback,
  };
};

export const merchantRepresentativeData = (traceId, params, pepFiles, callback) => {
  return {
    type: types.MERCHANT_REPRESENTATIVE_DATA,
    traceId,
    params,
    pepFiles,
    callback,
  };
};

export const merchantAffiliationIdentityVerification = (traceId, callback) => {
  return {
    type: types.MERCHANT_IDENTITY_VERIFICATION,
    traceId,
    callback,
  };
};

export const merchantAffiliationValidateEmail = (traceId, input, callback) => {
  return {
    type: types.MERCHANT_AFFILIATION_VALIDATE_EMAIL,
    traceId,
    input,
    callback,
  };
};

export const merchantAffiliationValidatePhone = (traceId, input, callback) => {
  return {
    type: types.MERCHANT_AFFILIATION_VALIDATE_PHONE,
    traceId,
    input,
    callback,
  };
};

export const getToken = (callback) => {
  return {
    type: types.GET_TOKEN,
    callback,
  };
};
