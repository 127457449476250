import React from 'react';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import makeClasses from './styles';
import clsx from 'clsx';
import { useMediaQuery, useTheme } from '@material-ui/core';

const NewFeatureBanner = ({
  data,
  onClose,
  box = false,
  boxSize = 'sm',
  boxOnly = false,
  theme = 'default',
  link = null,
  boxImages = null,
}) => {
  const { id, message, canBeDeleted = false, html = false } = data || {};
  const classes = makeClasses();
  const muiTheme = useTheme();
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down('md'));

  const handleOnClose = () => {
    onClose(id);
  };

  const handleOnClick = () => {
    if (typeof link === 'string') {
      window.open(link, '_blank');
    }
  };

  return (
    <div
      className={clsx(
        classes.root,
        theme === 'light' ? classes.rootLight : null,
        box ? classes.rootWithBox : null,
        box && boxSize === 'md' ? classes.rootWithBoxMd : null
      )}
    >
      {!boxOnly ? (
        <div className={clsx(classes.textWrapper, !box ? classes.textWrapperWithoutBox : null)}>
          {html ? <div className={classes.text} dangerouslySetInnerHTML={{ __html: message }} /> : <div>{message}</div>}
        </div>
      ) : null}
      {box ? (
        <div
          className={clsx(
            classes.box,
            boxSize === 'md' ? classes.boxMd : classes.boxSm,
            html ? classes.boxWithText : null,
            typeof link === 'string' ? classes.boxWithLink : null
          )}
          onClick={handleOnClick}
        >
          <img src={boxSize === 'md' && !isSmallScreen ? boxImages.md : boxImages.sm} alt="banner" />
        </div>
      ) : null}
      {canBeDeleted ? (
        <div onClick={handleOnClose} className={classes.closeButton}>
          <CloseOutlinedIcon color="inherit" className={classes.closeIcon} />
        </div>
      ) : null}
    </div>
  );
};

export default NewFeatureBanner;
