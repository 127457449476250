import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  dialog: {
    boxShadow: 'none',
    width: 600,
    maxWidth: 'unset',

    [theme.breakpoints.down('sm')]: {
      width: '95%',
      margin: 0,
    },
  },
  dialogTitleWrapper: {
    background: Colors.rgb.black_light,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,

    '& > h2': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
  },
  dialogTitle: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.XL,
    color: Colors.rgb.white,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: theme.fontSize.M,
    },
  },
  dialogContentWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  dialogActionsWrapper: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing(2),
    borderTop: `1px solid ${Colors.rgb.contentLineSoft}`,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  contentWrapper: {
    display: 'flex',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  contentDetails: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  shareableContentWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  shareableContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: theme.spacing(8),

    '&:last-child': {
      marginRight: 0,
    },

    '& > div': {
      '&:nth-child(1)': {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(4),
      },
      '&:nth-child(2)': {
        display: 'flex',
        alignItems: 'center',
      },
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginRight: 0,
      marginBottom: theme.spacing(4),

      '& > div': {
        '&:nth-child(1)': {
          marginRight: `0 !important`,
          marginBottom: `${theme.spacing(2)} !important`,
        },
      },
    },
  },
  shareableLinkWrapper: {
    width: '100%',
    display: 'flex',
    marginLeft: 0,

    [theme.breakpoints.down('sm')]: {},
  },
  socialActions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  socialAction: {
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.2s',
    cursor: 'pointer',

    '&:hover': {
      transform: 'translateY(-2px)',
    },

    '& > img': {
      width: '70%',
      height: '70%',
    },
  },
  shareAction: {
    '& > img': {
      height: 100,
      width: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      '& > img': {
        height: 40,
        width: 'auto',
      },
    },
  },
  shareableLinkInput: {
    '& > div': {
      borderRadius: 0,

      '& > input': {
        height: 40,
        boxSizing: 'border-box',
        fontFamily: theme.fontFamily.regular,
        fontSize: theme.fontSize.M,
        color: Colors.rgb.contentText,
      },
    },
  },
  shareableLinkButton: {
    marginTop: 0,
    textTransform: 'none',
    height: 40,
    border: 'none',
    padding: '0 15px',
    borderRadius: 0,
  },
}));

export default makeClasses;
