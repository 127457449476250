import React from 'react';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import makeClasses from './styles';
import clsx from 'clsx';
import DownloadApp from './notifications/DownloadApp';

const Notification = ({ data: { id, message, canBeDeleted = false, html = false, alertType }, onClose, canClose }) => {
  const classes = makeClasses();

  const handleOnClose = () => {
    onClose(id);
  };

  const renderContent = () => {
    if (message === 'download-app') {
      return <DownloadApp />;
    }
    if (html) {
      return <div dangerouslySetInnerHTML={{ __html: message }} />;
    }
    return <div>{message}</div>;
  }

  return (
    <div className={clsx(classes.root, alertType === 'info' ? classes.rootInfo : null)}>
      <InfoOutlinedIcon solor="inherit" className={clsx(classes.infoIcon, alertType === 'info' ? classes.infoIconFull : null)} />
      {renderContent()}
      {canBeDeleted && canClose ? (
        <div onClick={handleOnClose} className={classes.closeButton}>
          <CloseOutlinedIcon color="inherit" className={classes.closeIcon} />
        </div>
      ) : null}
    </div>
  );
};

export default Notification;
