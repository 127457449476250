import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 55,
    padding: theme.spacing(2),
    background: Colors.rgb.primaryGreen,
    boxSizing: 'border-box',
    color: Colors.rgb.contentTextHighlighted,
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.light,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.S,
    },
  },
  rootLight: {
    background: Colors.rgb.white,
  },
  rootWithBox: {
    height: 60,
  },
  rootWithBoxMd: {
    height: 110,

    [theme.breakpoints.down('sm')]: {
      height: 60,
    },
  },
  textWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  textWrapperWithoutBox: {
    [theme.breakpoints.down('sm')]: {
      display: 'block !important',
    },
  },
  text: {
    marginTop: -3,
    textAlign: 'center',
  },
  infoIcon: {
    alignSelf: 'flex-start',
    fontSize: '1rem',
    marginRight: 10,
  },
  box: {
    background: Colors.rgb.black_light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxWithText: {
    marginLeft: theme.spacing(10),

    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  boxWithLink: {
    cursor: 'pointer',
    transition: 'opacity 0.3s',

    '&:hover': {
      opacity: 0.95,
    },
  },
  boxSm: {
    width: 320,
    height: 50,
  },
  boxMd: {
    width: 728,
    height: 90,

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      width: 320,
      height: 50,
    },
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(4),
    right: 7,
    cursor: 'pointer',
    zIndex: 10,
    color: '#fff',

    '&:hover': {
      opacity: 0.9,
    },
  },
  closeIcon: {
    fontSize: '1rem',
  },
}));

export default makeClasses;
