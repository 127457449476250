import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../routes";
import {
  BoxSeparator,
  Button,
  ChangeEmailModal,
  ChangePhoneNumberModal,
  Confirm,
  ContentBox,
  ForbiddenSection,
  Page,
  PlansModal,
  StatusPill,
  TitleBox,
} from "../../components";
import { user as userActions } from "../../actions";
import { Language } from "../../utils";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import makeClasses from "./styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Hidden,
} from "@material-ui/core";
import clsx from "clsx";
import { Policies } from "../../utils/Policies";
import { Alert, AlertTitle } from "@material-ui/lab";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { checkForExpiredSession } from "../../utils/Utils";
import _ from "lodash";

const ProfileScreen = () => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const [profile, setProfile] = useState({});
  const [hasRUT, setHasRUT] = useState(false);
  const [branches, setBranches] = useState([]);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [leftBoxes, setLeftBoxes] = useState([]);
  const [rightBoxes, setRightBoxes] = useState([]);
  const [showPlansModal, setShowPlansModal] = useState({
    show: false,
    branch: null,
  });
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showChangePhoneNumberModal, setShowChangePhoneNumberModal] =
    useState(false);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
  const [sessionExpiredModalTitle, setSessionExpiredModalTitle] = useState("");
  const [sessionExpiredModalMessage, setSessionExpiredModalMessage] =
    useState("");

  const { policies, authTime, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    authTime: state.user.userData.authTime || Date.now(),
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const hasBranches = branches.length > 0;
  const canChangeSensitiveData =
    policies && policies.includes(Policies.types.SENSITIVE_DATA);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (policies && policies.includes(Policies.types.PROFILE)) {
      _loadProfile();
    } else {
      setForbbidenSection(true);
    }
  }, [policies]);

  const _loadProfile = () => {
    dispatch(
      userActions.getProfileData((profile, branches) => {
        setProfile(profile);
        setHasRUT(
          profile.merchantIdentifier &&
            profile.merchantIdentifier.merchantDocumentType === "RUT"
        );

        const _leftBoxes = [];
        const _rightBoxes = [];
        branches.forEach((branch, index) => {
          if (index % 2 === 0) {
            _rightBoxes.push(branch);
          } else {
            _leftBoxes.push(branch);
          }
        });
        setLeftBoxes(_leftBoxes);
        setRightBoxes(_rightBoxes);

        dispatch(
          userActions.getPaymentMethodsByBranches(
            branches.map((b) => b.traceId),
            (paymentMethods) => {
              branches.forEach((branch, index) => {
                branch.paymentMethods = paymentMethods[index];
              });

              dispatch(
                userActions.getPOSByBranches(
                  branches.map((b) => b.traceId),
                  (pos) => {
                    branches.forEach((branch, index) => {
                      branch.pos = pos[index];
                    });
                    setBranches(branches);
                  }
                )
              );
            }
          )
        );
      })
    );
  };

  const _togglePlansModal = (branch) => () => {
    const _showPlansModal = !showPlansModal.show;
    setShowPlansModal({
      show: _showPlansModal,
      branch: _showPlansModal ? branch : null,
    });
  };

  const _toggleSessionExpiredModal = (title, message) => {
    setSessionExpiredModalTitle(!showSessionExpiredModal ? title : "");
    setSessionExpiredModalMessage(!showSessionExpiredModal ? message : "");
    setShowSessionExpiredModal(!showSessionExpiredModal);
  };

  const _toggleChangeEmailModal = (success = false) => {
    if (!showChangeEmailModal) {
      const exiredSession = checkForExpiredSession(authTime);

      if (exiredSession) {
        return _toggleSessionExpiredModal(
          i18n.get("Profile.ChangeEmailExpiredSessionDialogTitle"),
          i18n.get("Profile.ChangeEmailExpiredSessionDialogContentText")
        );
      }
    }

    setShowChangeEmailModal(!showChangeEmailModal);

    if (success) {
      _loadProfile();
    }
  };

  const _toggleChangePhoneNumberModal = (success = false) => {
    if (!showChangePhoneNumberModal) {
      const exiredSession = checkForExpiredSession(authTime);

      if (exiredSession) {
        return _toggleSessionExpiredModal(
          i18n.get("Profile.ChangePhoneNumberExpiredSessionDialogTitle"),
          i18n.get("Profile.ChangePhoneNumberExpiredSessionDialogContentText")
        );
      }
    }

    setShowChangePhoneNumberModal(!showChangePhoneNumberModal);

    if (success) {
      _loadProfile();
    }
  };

  const _logOut = () => {
    dispatch(userActions.logOut());
  };

  const renderUserDetails = () => {
    const merchantIdentifier = profile.merchantIdentifier || {};

    const items = _.compact([
      {
        label: i18n.get("Profile.UserDetailsName"),
        value: profile.businessName,
        actionable: false,
      },
      {
        label: merchantIdentifier.merchantDocumentType,
        value: merchantIdentifier.merchantDocument,
        actionable: false,
      },
      {
        label: i18n.get("Profile.UserDetailsName"),
        value: profile.businessName,
        actionable: false,
      },
      {
        label: i18n.get("Profile.UserDetailsEmail"),
        value: profile.contactEmail,
        actionable: true,
        action: () =>
          canChangeSensitiveData ? (
            <Button
              className={classes.contentItemButton}
              onClick={() => _toggleChangeEmailModal()}
            >
              <EditOutlinedIcon className={classes.contentItemButtonIcon} />
            </Button>
          ) : null,
      },
      {
        label: i18n.get("Profile.UserDetailsPhone"),
        value: profile.phone
          ? `(${profile.phone.dialingCode}) ${profile.phone.phoneNumber}`
          : "",
        actionable: true,
        action: () =>
          canChangeSensitiveData ? (
            <Button
              className={classes.contentItemButton}
              onClick={() => _toggleChangePhoneNumberModal()}
            >
              <EditOutlinedIcon className={classes.contentItemButtonIcon} />
            </Button>
          ) : null,
      },
      {
        label: i18n.get("Profile.TermsAndConditions"),
        actionable: true,
        action: () => (
          <a
            className={classes.contentItemLink}
            href={process.env.REACT_APP_TERMS_AND_CONDITIONS}
            target="_blank"
            rel="noreferrer"
          >
            {i18n.get("Profile.TermsAndConditionsLink")}
          </a>
        ),
      },
      hasRUT ? {
        label: i18n.get('Profile.BenefitsPRO'),
        actionable: true,
        action: () => (
          <a
            className={classes.contentItemLink}
            href={process.env.REACT_APP_BENEFITS_PRO}
            target="_blank"
            rel="noreferrer"
          >
            {i18n.get('Profile.BenefitsPROLink')}
          </a>
        ),
      } : null,
    ]);

    return (
      <List className={classes.contentTile}>
        {items.map((item, index) => (
          <ListItem
            key={index}
            className={clsx(
              classes.contentItem,
              classes.contentItemColumn,
              index % 2 === 0 ? classes.contentItemEven : null
            )}
          >
            <div>{item.label}</div>
            <div
              className={clsx(
                classes.contentItemText,
                classes.contentItemColumnText
              )}
            >
              <div>{item.value}</div>
              {item.actionable ? item?.action() : null}
            </div>
          </ListItem>
        ))}
      </List>
    );
  };

  const renderPos = (pos, key, showSerialNumberInline = false) => {
    return (
      <Grid
        container
        key={key}
        className={clsx(
          classes.contentPos,
          key % 2 !== 0 ? classes.contentItemEven : null
        )}
      >
        {!showSerialNumberInline ? <Grid
          item
          xs={12}
          sm={12}
          md={showSerialNumberInline ? 6 : 12}
          className={clsx(
            classes.contentPosItem,
            !showSerialNumberInline ? classes.contentPosItemFull : null
          )}
        >
          <div>
            {i18n.get("Profile.BranchesPosItemSerialNumber")}
            <span>{pos.serialNumber}</span>
          </div>
        </Grid> : null}
        <Grid item xs={12} sm={12} md={6} className={classes.contentPosItem}>
          <div>
            {i18n.get("Profile.BranchesPosItemNumberOfInstallments")}
            <span>{pos.numberOfInstallments}</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.contentPosItem}>
          <div>
            {i18n.get("Profile.BranchesPosItemCurrency")}
            <span>
              {pos.acceptUYU && pos.acceptUSD
                ? "UYU/USD"
                : pos.acceptUYU
                ? "UYU"
                : pos.acceptUSD
                ? "USD"
                : "-"}
            </span>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.contentPosItem}>
          <div>
            {i18n.get("Profile.BranchesPosTip")}
            <span>{pos.hasTip ? "SI" : "NO"}</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.contentPosItem}>
          <div>
            {i18n.get("Profile.BranchesPosItemVat")}
            <span>{pos.vat}%</span>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={showSerialNumberInline ? 6 : 12}
          className={clsx(
            classes.contentPosItem,
            showSerialNumberInline ? classes.contentPosLastItem : null
          )}
        >
          <div>
            {i18n.get("Profile.BranchesPosItemSynchronizationState")}
            <span>
              <StatusPill small data={pos.synchronizationState} />
            </span>
          </div>
        </Grid>
      </Grid>
    );
  };

  const renderAllPos = (branch) => {
    const hasMultiplePos = branch.pos?.length > 1;
  
    return (
      <Accordion
        className={classes.accordionContainer}
        classes={{ expanded: classes.accordionContainerExpanded }}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          classes={{
            content: classes.accordionSummaryContent,
            expanded: classes.accordionSummaryExpanded,
            expandIcon: classes.accordionSummaryExpandIcon,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          {hasMultiplePos
            ? i18n.get("Profile.BranchesMultiplePosTitle")
            : i18n
                .get("Profile.BranchesPosTitle")
                .replace("{POS_ID}", branch.pos[0].serialNumber)}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsContainer}>
          {branch.pos?.map((item, index) =>
            renderPos(item, index, !hasMultiplePos)
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderBoxes = (boxes) => {
    return boxes.map((branch, index) => {
      const hasPaymentMethods =
        branch.paymentMethods && branch.paymentMethods.length > 0;
      const hasPos = branch.pos && branch.pos.length > 0;

      return (
        <React.Fragment key={index}>
          {index !== 0 ? <BoxSeparator /> : null}
          <ContentBox title={i18n.get("Profile.BoxBranchTitle")} titleBold>
            <List className={classes.contentTile}>
              <ListItem
                className={clsx(
                  classes.contentItem,
                  classes.contentItemColumn,
                  classes.contentItemEven
                )}
              >
                <div>{branch.name}</div>
                <div
                  className={clsx(
                    classes.contentItemText,
                    classes.contentItemColumnText
                  )}
                >
                  {branch.address}
                </div>
              </ListItem>
              <ListItem
                className={clsx(classes.contentItem, classes.contentItemColumn)}
              >
                <div>{i18n.get("Profile.BranchesSectionPlanTitle")}</div>
                <div
                  className={clsx(
                    classes.contentItemText,
                    classes.contentItemColumnText
                  )}
                >
                  <Button
                    className={classes.contentItemButton}
                    onClick={_togglePlansModal(branch)}
                  >
                    <VisibilityOutlinedIcon
                      className={classes.contentItemButtonIcon}
                    />
                  </Button>
                </div>
              </ListItem>
              <ListItem
                className={clsx(
                  classes.contentItem,
                  classes.contentItemColumn,
                  classes.contentItemEven,
                  classes.contentItemWithSubItems,
                  !hasPaymentMethods ? classes.contentItemWarning : null
                )}
              >
                <div>
                  {hasPaymentMethods
                    ? i18n.get("Profile.BranchesSectionPaymentMethods")
                    : i18n.get("Profile.BranchesSectionPaymentMethodsEmpty")}
                </div>
                <div
                  className={clsx(
                    classes.contentItemText,
                    classes.contentItemColumnText,
                    classes.contentItemSubItem
                  )}
                >
                  {hasPaymentMethods
                    ? branch.paymentMethods.map((paymentMethod, index) => (
                        <div key={index}>
                          {paymentMethod.currencyISO} |{" "}
                          {paymentMethod.paymentEntity} |{" "}
                          {paymentMethod.accountNumber}
                        </div>
                      ))
                    : null}
                </div>
              </ListItem>
            </List>
            {hasPos ? renderAllPos(branch) : null}
          </ContentBox>
        </React.Fragment>
      );
    });
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get("Profile.Title")}
        withActivePage={ROUTES.PROFILE.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get("Profile.Title")}
      withActivePage={ROUTES.PROFILE.id}
    >
      <TitleBox title={i18n.get("Profile.BoxTitle")} />
      <BoxSeparator />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <ContentBox title={i18n.get("Profile.BoxUserTitle")} titleBold>
            {renderUserDetails()}
          </ContentBox>
          <BoxSeparator />
          <Hidden mdDown>{hasBranches ? renderBoxes(leftBoxes) : null}</Hidden>
          <Hidden mdUp>{hasBranches ? renderBoxes(branches) : null}</Hidden>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12} sm={12} md={6}>
            {hasBranches ? renderBoxes(rightBoxes) : null}
          </Grid>
        </Hidden>
      </Grid>
      <PlansModal
        open={showPlansModal.show}
        branch={showPlansModal.branch}
        onClose={_togglePlansModal()}
        hasRUT={hasRUT}
      />
      <ChangeEmailModal
        open={showChangeEmailModal}
        onClose={_toggleChangeEmailModal}
      />
      <ChangePhoneNumberModal
        open={showChangePhoneNumberModal}
        onClose={_toggleChangePhoneNumberModal}
      />
      <Confirm
        open={showSessionExpiredModal}
        onClose={_toggleSessionExpiredModal}
        title={sessionExpiredModalTitle}
        onConfirm={_logOut}
        confirmText={i18n.get("Profile.ExpiredSessionDialogActionConfirm")}
        cancelText={i18n.get("Profile.ExpiredSessionDialogActionCancel")}
        onCancel={_toggleSessionExpiredModal}
      >
        <Alert
          severity="info"
          icon={false}
          className={clsx(classes.alert, classes.alertInfo)}
        >
          <div>
            <InfoOutlinedIcon className={classes.alertIcon} />
          </div>
          <div className={classes.alertMessage}>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get("Profile.ExpiredSessionDialogContentTitle")}
            </AlertTitle>
            {sessionExpiredModalMessage}
          </div>
        </Alert>
      </Confirm>
    </Page>
  );
};

ProfileScreen.id = "com.Handy.Profile";

export default ProfileScreen;
