import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Button } from '..';
import makeClasses from './styles';

const Confirm = ({ open, title, text, htmlText, onClose, onCancel, cancelText, onConfirm, confirmText, children }) => {
  const classes = makeClasses();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitleWrapper}>
        <div className={classes.dialogTitle}>{title}</div>
      </DialogTitle>
      <DialogContent className={classes.dialogContentWrapper}>
        {children ? (
          children
        ) : htmlText ? (
          <div className={classes.dialogContent} dangerouslySetInnerHTML={{ __html: htmlText }} />
        ) : (
          <div className={classes.dialogContent}>{text}</div>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActionsWrapper}>
        {onCancel ? (
          <Button onClick={onCancel} variant="outlined">
            {cancelText}
          </Button>
        ) : null}
        {onConfirm ? <Button onClick={onConfirm}>{confirmText}</Button> : null}
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
