import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../utils';

const makeClasses = makeStyles((theme) => ({
  table: {},
  tableHeaderCell: {
    fontFamily: theme.fontFamily.bold,
  },
  tableBodyCellStatus: {
    padding: theme.spacing(2),
    borderRadius: 5,

    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(1)}`,
      fontSize: `${theme.fontSize.S} !important`,
    },
  },
  tableBodyCellStatusActive: {
    background: '#d4edda',
    border: '1px solid #cfebd5',
    color: '#1a5927',
  },
  tableBodyCellStatusDisabled: {
    background: '#e2e3e5',
    border: '1px solid #e2e3e5',
    color: '#3d4245',
  },
  tableBodyCell: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    borderBottomColor: Colors.rgba(Colors.rgb.contentLineSoft, 60),
  },
  listItemMenu: {
    padding: theme.spacing(1),
  },
  listItemMenuItem: {
    color: Colors.rgb.contentText,
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.S,
  },
  listItemActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '& > button': {
      width: 40,
      padding: 6
    },

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      top: 20,
    },
  },
  pagination: {
    marginTop: 20,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
  },
  paginationList: {
    '& li > button': {
      background: Colors.rgb.white,
    },
  },
  noDataMessage: {
    fontFamily: theme.fontFamily.semiBold,
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentTextSoft,
    textAlign: 'center',
    width: '100%',
  },
  menuItemText: {
    minWidth: 0,
    marginTop: 0,
  },
  menuItemIcon: {
    minWidth: 35,
  },
  boxContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginLeft: `-${theme.spacing(2)}`,
    marginRight: `-${theme.spacing(2)}`,
    position: 'relative',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: Colors.rgb.grey,

    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${Colors.rgb.contentLineSoft}`,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),

      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  boxContainerEven: {
    background: Colors.rgb.white,
  },
  boxItemSeparator: {
    height: 1,
    width: '100%',
    background: Colors.rgb.contentLineSoft,
    margin: `${theme.spacing(3)} 0`,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  boxItem: {
    display: 'flex',
    marginBottom: theme.spacing(1),

    '& > span': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentTextSoft,

      '&:last-child': {
        marginLeft: theme.spacing(2),
        color: Colors.rgb.contentText,
      },
    },

    '&:last-child': {
      marginBottom: 0,
    },

    [theme.breakpoints.down('sm')]: {
      '& > span': {
        fontSize: theme.fontSize.M,
      },
    },
  },
  boxItemTitle: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentText,
    fontSize: theme.fontSize.M,
    marginBottom: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '90%',
  },
  boxItemDescription: {
    margin: `${theme.spacing(1)} 0 ${theme.spacing(3)}`,
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentText,
    fontSize: theme.fontSize.M,
  },
  boxItemCompact: {
    fontSize: theme.fontSize.M,
  },
  usersCountContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '60%',
    justifyContent: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
    },
  },
  usersCountTitle: {
    background: Colors.rgb.dark_gray,
    color: Colors.rgb.white,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
    fontSize: theme.fontSize.L,
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      fontSize: theme.fontSize.M,
      padding: theme.spacing(1),
      width: '100%',
      textAlign: 'center',
    },
  },
  usersCountItemsWrapper: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  usersCountItem: {
    background: Colors.rgb.clickElement,
    color: '#3d4245',
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    fontSize: theme.fontSize.L,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: theme.fontSize.M,
      padding: theme.spacing(1),
      justifyContent: 'center',
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: theme.spacing(1),
    },
  },
  usersCountItemCount: {
    marginLeft: theme.spacing(1),
    background: Colors.rgb.primaryGreen,
    width: 20,
    height: 20,
    borderRadius: 20,
    fontSize: theme.fontSize.S,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  usersCountItemLast: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderLeft: `2px solid ${Colors.rgb.white}`,

    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: theme.spacing(1),
      borderBottomLeftRadius: 0,
    },
  },
  alert: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    width: '100%',
    boxSizing: 'border-box',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  alertTitle: {
    fontFamily: theme.fontFamily.bold,
  },
  alertIcon: {
    fontSize: 50,
    marginRight: theme.spacing(2),
  },
  alertDanger: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },

  subscriptionItemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  subscriptionItemDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentTextHighlighted,
      fontSize: theme.fontSize.L,

      '& > span': {
        margin: `0 ${theme.spacing(2)}`,
      },
    },
  },
  subscriptionItemExtraDetails: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentText,
    fontSize: `${theme.fontSize.M} !important`,
    marginTop: theme.spacing(1),

    '& span': {
      color: Colors.rgb.contentTextSoft,
      marginRight: theme.spacing(1),
    },

    '& > div:nth-child(even)': {
      color: Colors.rgb.contentTextSoft,
      margin: `0 ${theme.spacing(2)}`,
    },
  },
  subscriptionItemStatus: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: 5,
    fontSize: theme.fontSize.S,
    marginLeft: theme.spacing(1),
    background: '#e2e3e5',
    border: '1px solid #e2e3e5',
    color: '#3d4245',
  },
  subscriptionItemStatusActive: {
    background: Colors.rgb.alertSuccessBackground,
    border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
    color: `${Colors.rgb.alertSuccessText} !important`,
  },
  viewFilterWrapper: {
    display: 'flex',
    alignItems: 'center',

    '& > div:nth-child(1)': {
      color: Colors.rgb.contentTextHighlighted,
      fontSize: theme.fontSize.XL,
      fontFamily: theme.fontFamily.bold,
      marginRight: theme.spacing(4),
    },
  },
  gridTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '& > div': {
      display: 'flex',
      alignItems: 'center',

      '&:nth-child(1)': {
        '& > div:nth-child(1)': {
          color: Colors.rgb.contentTextHighlighted,
          fontSize: theme.fontSize.XL,
          fontFamily: theme.fontFamily.bold,
          marginRight: theme.spacing(4),

          [theme.breakpoints.down('sm')]: {
            fontSize: theme.fontSize.M,
            marginRight: theme.spacing(2),
          },
        },
      },
    },
  },
  gridWrapper: {
    marginTop: theme.spacing(3),
  },
  gridSeparator: {
    background: Colors.rgb.contentLine,
    height: 1,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  accordionTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSize.L,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.M,
    },
  },
  subscriptionItemSeparator: {
    height: 1,
    width: '100%',
    background: Colors.rgb.contentLine,
    margin: `${theme.spacing(3)} 0`,
  },
}));

export default makeClasses;
