import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ROUTES from '../../routes';
import { Box, BoxSeparator, Button, FiltersToggle, Page, TitleBox } from '../../components';
import { Language } from '../../utils';
import makeClasses from './styles';
import { push } from 'connected-react-router';
import { useMediaQuery, useTheme } from '@material-ui/core';

function HandyTap() {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedView, setSelectedView] = useState('View1');
  const [viewContent, setViewContent] = useState(null);
  
  const viewsOptions = ([{
    label: 'HandyTap.ContentBoxViews.View1',
    id: 'View1',
  },
  {
    label: 'HandyTap.ContentBoxViews.View2',
    id: 'View2',
  },
  {
    label: 'HandyTap.ContentBoxViews.View3',
    id: 'View3',
  }])
  
  const { languageTexts } = useSelector(state => ({
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    setViewContent(`HandyTap.ContentBoxContent.${selectedView}`);
  }, [selectedView]);

  const handleOnSelectedView = (view) => {
    setSelectedView(view);
  }

  const goToAddNew = () => {
    dispatch(push(ROUTES.MANAGE_HANDY_TAP_ADD.path));
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('HandyTap.Title')} withActivePage={ROUTES.HANDY_TAP.id}>
      <TitleBox title={i18n.get('HandyTap.BoxTitle')} />
      <BoxSeparator />
      <Box>
        <div className={classes.boxContainer}>
          <div className={classes.boxTitle}>{i18n.get('HandyTap.ContentBoxTitle')}</div>
          <div className={classes.boxText}>{i18n.get('HandyTap.ContentBoxText')}</div>
          <div className={classes.boxViewsContainer}>
            <FiltersToggle
              selected={selectedView}
              options={viewsOptions}
              onChange={handleOnSelectedView}
              showDefault={false}
              showLabel={false}
              showAsColumnSm
            />
          </div>
          {viewContent ? (
            <div>
              <div className={classes.contentBoxTitle}>{i18n.get(`HandyTap.ContentBoxViews.${selectedView}`)}</div>
              <div className={classes.contentBoxText}>
                {Array.isArray(i18n.get(viewContent)) ? (
                  <div className={classes.contentBoxTextContent}>
                    {selectedView === 'View1' ? (
                      <div className={classes.contentBoxTextWithAction}>
                        {isSmallScreen ? (
                          <span>- Descarga nuestra aplicación <a href="/administracion-handy-tap/nuevo">haciendo click aquí</a></span>
                        ) : (
                          <>
                            <span>- Descarga nuestra aplicación presionando el siguiente botón</span><Button width={90}>Ir ahora</Button>
                          </>
                        )}
                      </div>
                    ) : null}
                    {i18n.get(viewContent)?.map((content, index) => (
                      <div key={index}>- {content}</div>
                    ))}
                    {selectedView === 'View2' ? (
                      <div className={classes.contentBoxTextWithAction}>
                        {isSmallScreen ? (
                          <span>- Haz clic en <a href="/administracion-handy-tap/nuevo">"Agregar nuevo"</a></span>
                        ) : (
                          <>
                            <span>- Haz clic en <a href="/administracion-handy-tap/nuevo">"Agregar nuevo"</a> o en el siguiente botón</span><Button width={90} onClick={goToAddNew}>Ir ahora</Button>
                          </>
                        )}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <>
                    <div className={classes.contentBoxTextTitle}>{i18n.get(viewContent)?.title}</div>
                    <div className={classes.contentBoxTextContent}>
                      {i18n.get(viewContent)?.content?.map((content, index) => (
                        <div key={index}>- {content}</div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </Box>
    </Page>
  );
}

HandyTap.id = 'com.Handy.HandyTap';

export default HandyTap;
