import clsx from 'clsx';
import React from 'react';
import { Box } from '..';
import { Colors } from '../../utils';
import makeClasses from './styles';

const Title = ({ bold = false, semibold = false, light = false, iconRight = null, children }) => {
  const classes = makeClasses();

  const renderTitle = () => {
    let titleClasses = classes.title;

    if (bold) {
      titleClasses = clsx(titleClasses, classes.titleBold);
    } else if (semibold) {
      titleClasses = clsx(titleClasses, classes.titleSemibold);
    } else if (light) {
      titleClasses = clsx(titleClasses, classes.titleLight);
    }

    return <div className={titleClasses}>{children}</div>;
  };

  return (
    <Box background={Colors.rgb.light_black} top={false} bottom={false} align={'center'} customClass={classes.box}>
      {renderTitle()}
      {iconRight ? <img className={classes.iconRight} src={iconRight} alt="title_icon_right" /> : null}
    </Box>
  );
};

export default Title;
