import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  dialog: {
    boxShadow: 'none',
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  dialogTitleWrapper: {
    background: Colors.rgb.black_light,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,

    '& > h2': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
  },
  dialogTitle: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.XL,
    color: Colors.rgb.white,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: theme.fontSize.M,
    },
  },
  dialogTitleWithCloseButton: {
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
  },
  dialogTitleClose: {
    position: 'absolute',
    right: 0,
    height: 23,
    cursor: 'pointer',
  },
  dialogContentTitle: {
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentText,
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.M,
    },
  },
  dialogTitleCloseIcon: {
    color: Colors.rgb.white,
  },
  dialogContentWrapper: {
    padding: theme.spacing(4),
  },
  dialogContentFiltersWrapper: {
    margin: `${theme.spacing(4)} 0 0 0`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  dialogContentInputWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    width: '100%',
  },
  dialogContentInput: {
    fontSize: theme.fontSize.M,
    color: Colors.rgb.contentText,
    width: '100%',
  },
  dialogContentInputEndAdornment: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentTextSoft,
    fontSize: theme.fontSize.S,
  },
  dialogContent: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentText,
  },
  dialogActionsWrapper: {
    padding: `${theme.spacing(2)} 0`,
    margin: `0 ${theme.spacing(4)}`,
    borderTop: `1px solid ${Colors.rgb.contentLineSoft}`,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  modalBodyCentered: {
    alignItems: 'center',
  },
  modalContentFooter: {
    fontSize: theme.fontSize.S,
    color: Colors.rgb.contentText,
    marginTop: theme.spacing(6),
  },
  alert: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    width: '100%',
    boxSizing: 'border-box',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)} !important`,
      '& > div': {
        alignItems: 'flex-start',
      },
    },
  },
  alertTitle: {
    fontFamily: theme.fontFamily.bold,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.M,
    },
  },
  alertMessage: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.S,
    },
  },
  alertTimer: {
    fontSize: theme.fontSize.XXL,
    color: Colors.rgb.alertWarningText,
    marginTop: theme.spacing(6),
    margin: `0 ${theme.spacing(1)}`,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.XL,
    },
  },
  alertIcon: {
    fontSize: 50,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
  },
  alertSuccess: {
    background: Colors.rgb.alertSuccessBackground,
    border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
    color: `${Colors.rgb.alertSuccessText} !important`,
  },
  alertWarning: {
    background: Colors.rgb.alertWarningBackground,
    border: `1px solid ${Colors.rgb.alertWarningBorder}`,
    color: `${Colors.rgb.alertWarningText} !important`,
  },
  alertError: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
  verificationCodeForm: {
    width: '70%',
    margin: '30px auto 10px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  verificationCodeInput: {
    textAlign: 'center',
    fontSize: '1.3rem',
    height: 43,
    background: Colors.rgb.white,
    borderRadius: 4,
    border: `1px solid ${Colors.rgb.contentLine}`,
    margin: '0 3px',

    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },
}));

export default makeClasses;
