import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { toast } from 'react-toastify';

import ROUTES from '../../../../../routes';
import { BoxSeparator, ContentBox, Page, TitleBox, Button, ForbiddenSection } from '../../../../../components';
import { business as businessActions } from '../../../../../actions';
import { Colors, Language } from '../../../../../utils';

import { Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';

import makeClasses from './styles';
import { Policies } from '../../../../../utils/Policies';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { toISOString } from '../../../../../utils/Utils';

const TYPE_FILTER_OPTIONS = [
  { id: 'POSTPONE', name: 'ProvidersSubscriptionsManageAddPromotion.Options_Types_Postpone' },
  { id: 'PERIOD', name: 'ProvidersSubscriptionsManageAddPromotion.Options_Types_Period' },
];

const TYPE_FILTER_APPLIES_TO = [
  { id: 'ALL', name: 'ProvidersSubscriptionsManageAddPromotion.Options_AppliesTo_All' },
  { id: 'NEW', name: 'ProvidersSubscriptionsManageAddPromotion.Options_AppliesTo_New' },
  { id: 'ALREADY_SUBSCRIBED', name: 'ProvidersSubscriptionsManageAddPromotion.Options_AppliesTo_Subscribers' },
];

const ProvidersSubscriptionsManageAddPromotionScreen = () => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const [inputName, setInputName] = useState('');
  const [inputPlan, setInputPlan] = useState('');
  const [inputType, setInputType] = useState('');
  const [inputAmountDays, setInputAmountDays] = useState('');
  const [inputPercentage, setInputPercentage] = useState('');
  const [inputAppliesTo, setInputAppliesTo] = useState('');
  const [inputStartDate, setInputStartDate] = useState(null);
  const [inputEndDate, setInputEndDate] = useState(null);

  const [plansOptions, setPlansOptions] = useState([]);
  const [forbiddenSection, setForbbidenSection] = useState(false);

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const validFields =
    inputName.length > 0 &&
    inputPlan.length > 0 &&
    inputType.length > 0 &&
    inputAppliesTo.length > 0 &&
    inputStartDate !== null &&
    inputEndDate !== null;

  const canSubmitForm = validFields
    ? inputType === 'POSTPONE'
      ? inputAmountDays && parseInt(inputAmountDays) > 0
      : inputType === 'PERIOD'
      ? inputPercentage && parseInt(inputPercentage) > 0
      : false
    : false;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (policies && policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_PROMOTIONS_WRITE)) {
      getPlansOptions();
    } else {
      setForbbidenSection(true);
    }
  }, [policies]);

  const getPlansOptions = () => {
    dispatch(
      businessActions.getProvidersSubscriptionsPlans(null, null, null, null, null, 100, 1, (plans) => {
        if (plans?.elements?.length > 0) {
          setPlansOptions(
            plans.elements.filter(p => p.subscriptionPlanStatus === 'ACTIVE').map((p) => ({
              id: p.subscriptionPlanTraceId,
              name: `${p.name}` + (p.code ? ` - ${p.code}` : '').trimRight(),
              branchTraceId: p.branchTraceId || null,
            }))
          );
        }
      })
    );
  };

  const _goBack = (e) => {
    e.preventDefault();
    dispatch(goBack());
  };

  const _submitForm = () => {
    if (!canSubmitForm) {
      return false;
    }

    const payload = {
      name: inputName,
      startDate: toISOString(inputStartDate),
      endDate: toISOString(inputEndDate),
      promotionType: inputType,
      promotionStatus: 'ACTIVE',
      percentage: inputType === 'PERIOD' ? parseInt(inputPercentage) : null,
      amountDays: inputType === 'POSTPONE' ? parseInt(inputAmountDays) : null,
      appliesTo: inputAppliesTo || 'ALL',
    };
    const plan = plansOptions.find((o) => o.id === inputPlan);
    dispatch(
      businessActions.addProvidersSubscriptionsPromotion(plan.id, plan.branchTraceId, payload, (result) => {
        if (result) {
          toast.success(i18n.get('ProvidersSubscriptionsManageAddPromotion.SuccessMessage'));
          setTimeout(() => dispatch(push(ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS.path)), 2000);
        } else {
          toast.error(i18n.get('ProvidersSubscriptionsManageAddPromotion.ErrorMessage'));
        }
      })
    );
  };

  const renderForm = () => {
    return (
      <Grid container justify="center" alignItems="center" className={classes.formWrapper}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form layout="vertical" onSubmit={_submitForm} className={classes.form}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get('ProvidersSubscriptionsManageAddPromotion.InputNameLabel')}
                  placeholder={i18n.get('ProvidersSubscriptionsManageAddPromotion.InputNamePlaceholder')}
                  onChange={(e) => setInputName(e.target.value)}
                  value={inputName}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputLabel className={classes.productFormSelectLabel} shrink>
                  {i18n.get('ProvidersSubscriptionsManageAddPromotion.InputPlanLabel')}
                </InputLabel>
                <Select
                  className={classes.formSelect}
                  onChange={(e) => setInputPlan(e.target.value)}
                  value={inputPlan}
                  displayEmpty
                  renderValue={(value) => (
                    <div style={{ color: !value ? Colors.rgba(Colors.rgb.black, 30) : null }}>
                      {value
                        ? plansOptions.find((o) => o.id === value)?.name
                        : i18n.get('ProvidersSubscriptionsManageAddPromotion.Options_Plans_Default')}
                    </div>
                  )}
                >
                  <MenuItem value={''} disabled className={classes.formSelectOption}>
                    {i18n.get('ProvidersSubscriptionsManageAddPromotion.Options_Plans_Default')}
                  </MenuItem>
                  {plansOptions.map((item, index) => (
                    <MenuItem key={index} value={item.id} className={classes.formSelectOption}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputLabel className={classes.productFormSelectLabel} shrink>
                  {i18n.get('ProvidersSubscriptionsManageAddPromotion.InputTypeLabel')}
                </InputLabel>
                <Select
                  className={classes.formSelect}
                  onChange={(e) => setInputType(e.target.value)}
                  value={inputType}
                  displayEmpty
                  renderValue={(value) => (
                    <div style={{ color: !value ? Colors.rgba(Colors.rgb.black, 30) : null }}>
                      {value
                        ? i18n.get(TYPE_FILTER_OPTIONS.find((o) => o.id === value)?.name)
                        : i18n.get('ProvidersSubscriptionsManageAddPromotion.Options_Types_Default')}
                    </div>
                  )}
                >
                  <MenuItem value={''} disabled className={classes.formSelectOption}>
                    {i18n.get('ProvidersSubscriptionsManageAddPromotion.Options_Types_Default')}
                  </MenuItem>
                  {TYPE_FILTER_OPTIONS.map((item, index) => (
                    <MenuItem key={index} value={item.id} className={classes.formSelectOption}>
                      {i18n.get(item.name)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {inputType === 'POSTPONE' ? (
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.formInput}
                    label={i18n.get('ProvidersSubscriptionsManageAddPromotion.InputAmountDaysLabel')}
                    placeholder={i18n.get('ProvidersSubscriptionsManageAddPromotion.InputAmountDaysPlaceholder')}
                    onChange={(e) => setInputAmountDays(e.target.value)}
                    value={inputAmountDays}
                    type="number"
                  />
                </Grid>
              ) : inputType === 'PERIOD' ? (
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.formInput}
                    label={i18n.get('ProvidersSubscriptionsManageAddPromotion.InputPercentageLabel')}
                    placeholder={i18n.get('ProvidersSubscriptionsManageAddPromotion.InputPercentagePlaceholder')}
                    onChange={(e) => {
                      let value = parseInt(e.target.value);
                      if (value > 100) {
                        value = 100;
                      } else if (value < 0) {
                        value = 0;
                      }
                      setInputPercentage(value);
                    }}
                    value={inputPercentage}
                    type="number"
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={12} md={4}>
                <InputLabel className={classes.productFormSelectLabel} shrink>
                  {i18n.get('ProvidersSubscriptionsManageAddPromotion.InputAppliesToLabel')}
                </InputLabel>
                <Select
                  className={classes.formSelect}
                  onChange={(e) => setInputAppliesTo(e.target.value)}
                  value={inputAppliesTo}
                  displayEmpty
                  renderValue={(value) => (
                    <div style={{ color: !value ? Colors.rgba(Colors.rgb.black, 30) : null }}>
                      {value
                        ? i18n.get(TYPE_FILTER_APPLIES_TO.find((o) => o.id === value)?.name)
                        : i18n.get('ProvidersSubscriptionsManageAddPromotion.Options_AppliesTo_Default')}
                    </div>
                  )}
                >
                  <MenuItem value={''} disabled className={classes.formSelectOption}>
                    {i18n.get('ProvidersSubscriptionsManageAddPromotion.Options_AppliesTo_Default')}
                  </MenuItem>
                  {TYPE_FILTER_APPLIES_TO.map((item, index) => (
                    <MenuItem key={index} value={item.id} className={classes.formSelectOption}>
                      {i18n.get(item.name)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><CalendarIcon color="disabled" /></InputAdornment>,
                    }}
                    label={i18n.get('ProvidersSubscriptionsManageAddPromotion.InputStartDateLabel')}
                    placeholder={i18n.get('ProvidersSubscriptionsManageAddPromotion.InputStartDatePlaceholder')}
                    onChange={(date) => setInputStartDate(date)}
                    className={classes.formInput}
                    variant="inline"
                    value={inputStartDate}
                    InputLabelProps={{ shrink: true }}
                    format={i18n.get('DateFormat')}
                    autoOk
                    disablePast
                    disableToolbar
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><CalendarIcon color="disabled" /></InputAdornment>,
                    }}
                    label={i18n.get('ProvidersSubscriptionsManageAddPromotion.InputEndDateLabel')}
                    placeholder={i18n.get('ProvidersSubscriptionsManageAddPromotion.InputEndDatePlaceholder')}
                    onChange={(date) => setInputEndDate(date)}
                    className={classes.formInput}
                    variant="inline"
                    value={inputEndDate}
                    InputLabelProps={{ shrink: true }}
                    format={i18n.get('DateFormat')}
                    autoOk
                    disablePast
                    disableToolbar
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ProvidersSubscriptionsManageAddPromotion.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ProvidersSubscriptionsManageAddPromotion.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
    >
      <TitleBox
        title={i18n.get('ProvidersSubscriptionsManageAddPromotion.BoxTitle')}
        back
        backTitle={i18n.get('ProvidersSubscriptionsManageAddPromotion.BackButton')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <ContentBox title={i18n.get('ProvidersSubscriptionsManageAddPromotion.BoxContentTitle')} titleBold>
        {renderForm()}
        <div>
          <div className={classes.contentActionsWrapper}>
            <Button variant="outlined" onClick={_goBack}>
              {i18n.get('Buttons.CANCEL')}
            </Button>
            <div className={classes.contentActionSeparator} />
            <Button disabled={!canSubmitForm} onClick={_submitForm}>
              {i18n.get('Buttons.SAVE')}
            </Button>
          </div>
        </div>
      </ContentBox>
    </Page>
  );
};

ProvidersSubscriptionsManageAddPromotionScreen.id = 'com.Handy.ProvidersSubscriptionsManageAddPromotion';

export default ProvidersSubscriptionsManageAddPromotionScreen;
