import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';
import { toast } from 'react-toastify';

import ROUTES from '../../../../../routes';
import { BoxSeparator, ContentBox, Page, TitleBox, Button, ForbiddenSection, Box } from '../../../../../components';
import { business as businessActions } from '../../../../../actions';
import { Language } from '../../../../../utils';

import { Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import makeClasses from './styles';
import { Policies } from '../../../../../utils/Policies';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { toISOString } from '../../../../../utils/Utils';

const RequestNewChargeScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const subscriberTraceId = match && match.params ? match.params.traceId : null;

  const [subscriber, setSubscriber] = useState(null);
  const [inputAmount, setInputAmount] = useState('');
  const [inputMessage, setInputMessage] = useState('');
  const [inputRef, setInputRef] = useState('');
  const [inputInvoiceNumber, setInputInvoiceNumber] = useState('');
  const [additionalData, setAdditionalData] = useState([]);
  const [forbiddenSection, setForbbidenSection] = useState(false);

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
    branches: state.business.branches || [],
  }));
  const i18n = Language(languageTexts);

  const canSubmitForm = inputAmount && parseInt(inputAmount) !== 0 && inputMessage.length > 0;

  const fieldTypesOptions = [
    {
      id: 'text',
      name: i18n.get('ProvidersManageSubscribersRequestNewCharge.AdditionalFieldTypeOption1'),
    },
    {
      id: 'number',
      name: i18n.get('ProvidersManageSubscribersRequestNewCharge.AdditionalFieldTypeOption2'),
    },
    {
      id: 'date',
      name: i18n.get('ProvidersManageSubscribersRequestNewCharge.AdditionalFieldTypeOption3'),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);

    if (policies && policies.includes(Policies.types.PROVIDER_CREATE_PAYMENT)) {
      dispatch(
        businessActions.getSubscriber(subscriberTraceId, (data) => {
          setSubscriber(data);
        })
      );
    } else {
      setForbbidenSection(true);
    }
  }, [policies]);

  const _goBack = (e) => {
    e && e.preventDefault();
    dispatch(goBack());
  };

  const handleOnAddNewField = () => {
    setAdditionalData([...additionalData, { id: uniqueId(), name: '', fieldName: '', type: 'text', value: '' }]);
  };

  const handleOnClickRemove = (index) => () => {
    setAdditionalData([...additionalData.slice(0, index), ...additionalData.slice(index + 1)]);
  };

  const _submitForm = () => {
    if (!canSubmitForm) {
      return false;
    }

    if (!inputAmount) {
      return toast.error(i18n.get('ProvidersManageSubscribersRequestNewCharge.ValidationAmountError'));
    }
    if (!inputMessage) {
      return toast.error(i18n.get('ProvidersManageSubscribersRequestNewCharge.ValidationMessageError'));
    }

    const additionalDataObj = {};
    for (let i = 0, l = additionalData.length; i < l; i += 1) {
      const name = additionalData[i].name;
      delete additionalData[i].id;

      for (const key in additionalData[i]) {
        if (additionalData[i].hasOwnProperty(key) && key !== 'id') {
          if (!additionalDataObj[name]) {
            additionalDataObj[name] = {};
          }

          additionalDataObj[name][key] = additionalData[i][key];

          if (key === 'value' && additionalData[i].type === 'date') {
            additionalDataObj[name][key] = toISOString(moment(additionalData[i][key]));
          }
        }
      }
    }

    dispatch(
      businessActions.addCharge(
        {
          SubscriptionTraceId: subscriber.traceId,
          Reference: inputRef,
          BillIdentifier: inputInvoiceNumber,
          Amount: parseFloat(inputAmount),
          Message: inputMessage,
        },
        additionalDataObj,
        (result) => {
          if (result) {
            toast.success(i18n.get('ProvidersManageSubscribersRequestNewCharge.SuccessAddMessage'));
            setTimeout(() => _goBack(), 1000);
          }
        }
      )
    );
  };

  const handleOnAdditionalFieldChange = (index, field) => (e) => {
    const updatedField = {
      ...additionalData[index],
      [field]: e.target.value,
    };

    if (field === 'type') {
      updatedField.value = '';
    }

    setAdditionalData([...additionalData.slice(0, index), updatedField, ...additionalData.slice(index + 1)]);
  };

  const renderForm = () => {
    return (
      <Grid container justify="center" alignItems="center" className={classes.formWrapper}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form layout="vertical" onSubmit={_submitForm} className={classes.form}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get('ProvidersManageSubscribersRequestNewCharge.CurrencyInputLabel')}
                  value={subscriber && subscriber.product.currency}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get('ProvidersManageSubscribersRequestNewCharge.AmountInputLabel')}
                  placeholder={i18n.get('ProvidersManageSubscribersRequestNewCharge.AmountInputPlaceholder')}
                  onChange={(e) => setInputAmount(e.target.value)}
                  value={inputAmount}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get('ProvidersManageSubscribersRequestNewCharge.MessageInputLabel')}
                  placeholder={i18n.get('ProvidersManageSubscribersRequestNewCharge.MessageInputPlaceholder')}
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                />
              </Grid>
            </Grid>
            <div className={classes.formInputSeparator} />
            <div className={classes.formInputSeparator} />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get('ProvidersManageSubscribersRequestNewCharge.RefInputLabel')}
                  placeholder={i18n.get('ProvidersManageSubscribersRequestNewCharge.RefInputPlaceholder')}
                  value={inputRef}
                  onChange={(e) => setInputRef(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get('ProvidersManageSubscribersRequestNewCharge.InvoiceNumberInputLabel')}
                  placeholder={i18n.get('ProvidersManageSubscribersRequestNewCharge.InvoiceNumberInputPlaceholder')}
                  value={inputInvoiceNumber}
                  onChange={(e) => setInputInvoiceNumber(e.target.value)}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ProvidersManageSubscribersRequestNewCharge.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ProvidersManageSubscribersRequestNewCharge.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS.id}
    >
      <TitleBox
        title={i18n.get('ProvidersManageSubscribersRequestNewCharge.BoxTitle')}
        back
        backTitle={i18n.get('ProvidersManageSubscribersRequestNewCharge.BoxBackButton')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <ContentBox title={i18n.get('ProvidersManageSubscribersRequestNewCharge.BoxContentTitle')} titleBold>
        {renderForm()}
      </ContentBox>
      <BoxSeparator size="small" />
      <ContentBox
        title={i18n.get('ProvidersManageSubscribersRequestNewCharge.AdditionalDataTitle')}
        titleBold
        button
        buttonText={i18n.get('ProvidersManageSubscribersRequestNewCharge.AdditionalDataButton')}
        buttonOnClick={handleOnAddNewField}
      >
        <div>
          {additionalData.length > 0
            ? additionalData.map((field, index) => (
                <ContentBox
                  key={field.id}
                  title={`${i18n.get('ProvidersManageSubscribersRequestNewCharge.AdditionalFieldTitle')} ${index + 1}`}
                  button
                  buttonRightIcon={() => <DeleteIcon />}
                  buttonOnClick={handleOnClickRemove(index)}
                >
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.formInput}
                        label={i18n.get('ProvidersManageSubscribersRequestNewCharge.AdditionalFieldNameInputLabel')}
                        placeholder={i18n.get(
                          'ProvidersManageSubscribersRequestNewCharge.AdditionalFieldNameInputPlaceholder'
                        )}
                        value={field.name}
                        onChange={handleOnAdditionalFieldChange(index, 'name')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.formInput}
                        label={i18n.get(
                          'ProvidersManageSubscribersRequestNewCharge.AdditionalFieldInternalNameInputLabel'
                        )}
                        placeholder={i18n.get(
                          'ProvidersManageSubscribersRequestNewCharge.AdditionalFieldInternalNameInputPlaceholder'
                        )}
                        value={field.fieldName}
                        onChange={handleOnAdditionalFieldChange(index, 'fieldName')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <InputLabel className={classes.formInputSelectLabel} shrink>
                        {i18n.get('ProvidersManageSubscribersRequestNewCharge.AdditionalFieldTypeInputLabel')}
                      </InputLabel>
                      <Select
                        className={classes.formInputSelect}
                        onChange={handleOnAdditionalFieldChange(index, 'type')}
                        value={field.type}
                      >
                        {fieldTypesOptions.map((fieldType, index) => (
                          <MenuItem key={index} value={fieldType.id} className={classes.formInputSelectOption}>
                            {fieldType.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.formInput}
                        label={i18n.get('ProvidersManageSubscribersRequestNewCharge.AdditionalFieldValueInputLabel')}
                        placeholder={i18n.get(
                          'ProvidersManageSubscribersRequestNewCharge.AdditionalFieldValueInputPlaceholder'
                        )}
                        value={field.value}
                        onChange={handleOnAdditionalFieldChange(index, 'value')}
                        type={field.type}
                      />
                    </Grid>
                  </Grid>
                </ContentBox>
              ))
            : null}
        </div>
      </ContentBox>
      <BoxSeparator size="small" />
      <Box>
        <div className={classes.contentActionsWrapper}>
          <Button variant="outlined" onClick={_goBack}>
            {i18n.get('ProvidersManageSubscribersRequestNewCharge.CancelButton')}
          </Button>
          <div className={classes.contentActionSeparator} />
          <Button disabled={!canSubmitForm} onClick={_submitForm}>
            {i18n.get('ProvidersManageSubscribersRequestNewCharge.CreateButton')}
          </Button>
        </div>
      </Box>
    </Page>
  );
};

RequestNewChargeScreen.id = 'com.Handy.RequestNewCharge';

export default RequestNewChargeScreen;
