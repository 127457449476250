import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../../utils';

const makeClasses = makeStyles((theme) => ({
  formWrapper: {
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${Colors.rgb.contentLineSoft}`,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  formInput: {
    width: '100%',

    '& > label': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentTextSoft,
      fontSize: theme.fontSize.L,
    },

    '& > div:before': {
      borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,
    },
    '& > div:after': {
      borderBottom: `1px solid ${Colors.rgb.primary}`,
    },
    '& > div:hover': {
      '&:before': {
        borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted} !important`,
      },
    },

    '& input': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.L,
    },
  },
  formInputSelect: {
    padding: '0 !important',
  },
  formInputSeparator: {
    height: theme.spacing(2),
  },
  contentActionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing(4),

    '& > button:last-child': {
      marginRight: 0,
    },
  },
  contentActionSeparator: {
    width: theme.spacing(3),
  },
  serviceDetailsItem: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentTextHighlighted,
    fontSize: theme.fontSize.L,

    '& > div': {
      color: Colors.rgb.contentTextSoft,
      fontSize: theme.fontSize.M,
    },
  },
  serviceDetailsItemDisabled: {
    opacity: 0.5,
  },
  serviceDetailsStatus: {
    display: 'inline-block',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: 5,
    fontSize: theme.fontSize.S,
  },
  serviceDetailsStatusActive: {
    background: Colors.rgb.alertSuccessBackground,
    border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
    color: `${Colors.rgb.alertSuccessText} !important`,
  },
  serviceDetailsStatusInactive: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
  formSelect: {
    width: '100%',

    '&:before': {
      borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,
    },
    '&:after': {
      borderBottom: `1px solid ${Colors.rgb.primary}`,
    },
    '&:hover': {
      '&:before': {
        borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted} !important`,
      },
    },

    '& > div': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.L,
    },
  },
  formSelectOption: {
    color: Colors.rgb.contentText,
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.regular,
  },
}));

export default makeClasses;
