import React from 'react';

import OutlinedFlagIcon from '@material-ui/icons/OutlinedFlag';
import { Alert, AlertTitle } from '@material-ui/lab';
import makeClasses from './styles';
import { useSelector } from 'react-redux';
import { Language } from '../../utils';
import clsx from 'clsx';

const AlertBranchesWithNoPaymentMethod = ({ title = null, message = null, showIcon = true, onToggleHelp }) => {
  const classes = makeClasses();

  const { languageTexts, branchesWithNoPaymentMethod } = useSelector(({ language, business }) => ({
    languageTexts: language.texts || {},
    branchesWithNoPaymentMethod: business.branchesWithNoPaymentMethod || [],
  }));
  const i18n = Language(languageTexts);

  return (
    <Alert
      severity="warning"
      icon={false}
      className={clsx(classes.alert, classes.alertWarning, !showIcon ? classes.alertWithNoIcon : null)}
    >
      {showIcon ? (
        <div>
          <OutlinedFlagIcon className={classes.alertIcon} />
        </div>
      ) : null}
      <div>
        <AlertTitle className={classes.alertTitle}>
          {title || (
            <div
              dangerouslySetInnerHTML={{
                __html: i18n
                  .get('Components.AlertBranchesWithNoPaymentMethod.title')
                  .replace('{branches}', branchesWithNoPaymentMethod.map(b => b.branchName).join(', ')),
              }}
            />
          )}
        </AlertTitle>
        {message ? <div className={classes.alertMessage}>{message}</div> : null}
        <button className={classes.alertButton} onClick={onToggleHelp}>
          {i18n.get('Components.AlertBranchesWithNoPaymentMethod.message')}
        </button>
      </div>
    </Alert>
  );
};

export default AlertBranchesWithNoPaymentMethod;
