import React, { useEffect, useState } from 'react';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import makeClasses from './styles';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { Language } from '../../utils';
import { user as userActions } from '../../actions';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import clsx from 'clsx';
import _ from 'lodash';

const AssingPaymentMethodModal = ({ open, paymentMethod, onClose }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();

  const { languageTexts, branches } = useSelector(({ language, business }) => ({
    languageTexts: language.texts || {},
    branches: business.branches || [],
  }));
  const [ step, setStep ] = useState(1);
  const [ assigningPaymentMethod, setAssigningPaymentMethod ] = useState(false);
  const [ selectedBranches, setSelectedBranches ] = useState([]);
  const [ initialSelectedBranches, setInitialSelectedBranched ] = useState(null);
  const [ allBranches, setAllBranched ] = useState(false);

  const i18n = Language(languageTexts);

  useEffect(
    () => {
      if (paymentMethod && paymentMethod.branches.length > 0) {
        const _selectedBranches = paymentMethod.branches.map(b => b.traceId);
        setInitialSelectedBranched(_selectedBranches);
        setSelectedBranches(_selectedBranches);
      } else {
        setInitialSelectedBranched([]);
        setSelectedBranches([]);
      }
    },
    [ paymentMethod ]
  );

  useEffect(
    () => {
      if (allBranches) {
        setSelectedBranches([
          ...branches.filter(b => !initialSelectedBranches.includes(b.traceId)).map(b => b.traceId),
          ...initialSelectedBranches,
        ]);
      } else if (initialSelectedBranches) {
        setSelectedBranches(initialSelectedBranches.slice());
      }
    },
    [ allBranches, branches, initialSelectedBranches ]
  );

  const _onClose = (success = false) => {
    setStep(1);
    setSelectedBranches([]);
    setAllBranched(false);
    onClose(success);
  };

  const _assignBranchesToPaymentMethod = () => {
    setAssigningPaymentMethod(true);

    const branchesToAssign = selectedBranches.slice();
    const branchesToRemove = _.difference(paymentMethod.branches.map(b => b.traceId), selectedBranches);

    dispatch(
      userActions.assignBranchesToPaymentMethod(paymentMethod.traceId, branchesToAssign, branchesToRemove, result => {
        setAssigningPaymentMethod(false);
        if (result) {
          setStep(2);
        }
      })
    );
  };

  const _toggleBranch = branch => {
    const localSelectedBranches = selectedBranches.slice();
    const branchIndex = localSelectedBranches.findIndex(branchTraceId => branchTraceId === branch.traceId);
    if (selectedBranches.includes(branch.traceId)) {
      localSelectedBranches.splice(branchIndex, 1);
    } else {
      localSelectedBranches.push(branch.traceId);
    }
    setSelectedBranches(localSelectedBranches);
  };

  const _toggleAllBranches = () => {
    setAllBranched(!allBranches);
  };

  const renderSteps = () => {
    if (step === 1) {
      return (
        <div className={classes.modalBody}>
          {branches.length > 1 ? (
            <div>
              <FormControlLabel
                control={<Checkbox checked={allBranches} onChange={() => _toggleAllBranches()} />}
                label={i18n.get('Components.AssingPaymentMethodModal.AllBranchesTitle')}
              />
            </div>
          ) : null}
          {branches.map((branch, index) => (
            <div key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={initialSelectedBranches && initialSelectedBranches.includes(branch.traceId)}
                    checked={selectedBranches.includes(branch.traceId)}
                    onChange={() => _toggleBranch(branch)}
                  />
                }
                label={branch.name}
              />
            </div>
          ))}
        </div>
      );
    }
    if (step === 2) {
      return (
        <div className={classes.modalBody}>
          <Alert severity="success" icon={false} className={clsx(classes.alert, classes.alertSuccess)}>
            <div>
              <CheckCircleOutlineOutlinedIcon className={classes.alertIcon} />
            </div>
            <div>
              <AlertTitle className={classes.alertTitle}>
                {i18n.get('Components.AssingPaymentMethodModal.AlertSuccessTitle')}
              </AlertTitle>
              {i18n.get('Components.AssingPaymentMethodModal.AlertSuccessMessage')}
            </div>
          </Alert>
        </div>
      );
    }

    return null;
  };

  return (
    <Dialog open={open} onClose={() => _onClose()} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitleWrapper}>
        <div className={clsx(classes.dialogTitle, classes.dialogTitleWithCloseButton)}>
          {i18n.get('Components.AssingPaymentMethodModal.HeaderTitle')}
        </div>
        <div className={classes.dialogTitleClose} onClick={() => _onClose()}>
          <CancelOutlinedIcon className={classes.dialogTitleCloseIcon} color="inherit" />
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContentWrapper}>{renderSteps()}</DialogContent>
      <DialogActions className={classes.dialogActionsWrapper}>
        {step === 1 ? (
          <React.Fragment>
            <Button variant="outlined" onClick={() => _onClose(false)}>
              {i18n.get('Components.AssingPaymentMethodModal.ButtonCancel')}
            </Button>
            <Button loading={assigningPaymentMethod} onClick={_assignBranchesToPaymentMethod}>
              {i18n.get('Components.AssingPaymentMethodModal.ButtonNext')}
            </Button>
          </React.Fragment>
        ) : step === 2 ? (
          <Button onClick={() => _onClose(true)}>{i18n.get('Components.AssingPaymentMethodModal.ButtonClose')}</Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default AssingPaymentMethodModal;
