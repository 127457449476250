import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';
import BackgroundImage from '../../static/images/login-bg.png';

const makeClasses = makeStyles((theme) => ({
  container: {
    height: '100%',
    background: Colors.rgb.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: '100% 100%',

    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'auto 100%',
      backgroundPosition: '50% 50%',
      flexDirection: 'column',
    },
  },
  contentWrapper: {
    background: Colors.rgb.contentTextSuperHighlighted,
    padding: '80px 40px',
    maxWidth: 330,
    borderRadius: 5,

    [theme.breakpoints.down('md')]: {
      padding: '80px 20px',
      maxWidth: 320,
    },

    [theme.breakpoints.down('sm')]: {
      padding: 20,
      width: '90%',
      maxWidth: 'unset',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      boxSizing: 'border-box',
    },

    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  brandContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  brandLogo: {
    height: 50,

    [theme.breakpoints.down('md')]: {
      height: 40,
    },

    [theme.breakpoints.down('xs')]: {
      height: 30,
    },
  },
  brandSeparator: {
    height: 55,
    width: 1,
    margin: '0 15px',
    background: Colors.rgb.white,

    [theme.breakpoints.down('xs')]: {
      height: 35,
    },
  },
  brandTitle: {
    fontFamily: theme.fontFamily.regular,
    fontSize: 24,
    color: Colors.rgb.white,

    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  loginOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 50,

    [theme.breakpoints.down('md')]: {
      marginTop: 20,
    },
  },
  loginOption: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: `${theme.spacing(3)} ${theme.spacing(7)} ${theme.spacing(4)}`,
    borderRadius: 5,
    background: Colors.rgb.black_light,
    boxSizing: 'border-box',
    position: 'relative',
    cursor: 'pointer',
    transition: 'box-shadow 0.4s ease-out',
    boxShadow: `inset 0 0 0 0 ${Colors.rgb.purple_dark}`,

    '&:hover': {
      boxShadow: `inset 0 200px 0 0 ${Colors.rgb.purple_dark}`,
    },

    '&:last-child': {
      marginTop: 20,
    },
  },
  loginOptionSelected: {
    background: Colors.rgb.purple,
    width: '100%',
  },
  loginOptionCheckbox: {
    width: 18,
    height: 18,
    borderRadius: 18,
    background: Colors.rgb.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(2),
    boxSizing: 'border-box',
    border: `1px solid ${Colors.rgb.white}`,
  },
  loginOptionCheckboxSelected: {
    border: `1px solid ${Colors.rgb.light_green}`,
  },
  loginOptionCheckboxSelectedIndicator: {
    width: 12,
    height: 12,
    borderRadius: 12,
    background: Colors.rgb.light_green,
  },
  loginOptionTitle: {
    fontSize: theme.fontSize.XL,
    color: Colors.rgb.white,
    cursor: 'pointer',
  },
  loginOptionText: {
    fontSize: theme.fontSize.M,
    color: Colors.rgb.white,
    marginTop: theme.spacing(2),
  },
  contentContainer: {
    width: 700,
    background: Colors.rgba(Colors.rgb.contentTextSuperHighlighted, 80),
    borderRadius: 10,
    padding: 20,
    marginLeft: 60,

    [theme.breakpoints.only('md')]: {
      width: 520,
      marginLeft: 20,
    },

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      background: Colors.rgb.contentTextSuperHighlighted,
      width: '90%',
      boxSizing: 'border-box',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      paddingTop: 0,
    },

    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  formContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  formTitleContainer: {
    textAlign: 'left',
    marginBottom: theme.spacing(4),
    width: '100%',
    color: Colors.rgb.white,

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  formTitle: {
    fontFamily: theme.fontFamily.semibold,
    fontSize: theme.fontSize.L,
  },
  formText: {
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.M,
    marginTop: 10,
  },
  form: {
    width: '100%',
  },
  formTabsContainer: {
    display: 'flex',
    width: '100%',
    height: 45,
    alignItems: 'center',
    overflow: 'hidden',
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    borderBottom: `1px solid ${Colors.rgb.black}`,
  },
  formTab: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    background: Colors.rgb.white,
    color: Colors.rgb.contentText,
    height: '100%',
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.M,
    transition: 'background 0.2s linear, color 0.2s linear',
    cursor: 'pointer',
    textAlign: 'center',
    position: 'relative',

    '& > div': {
      backgroundColor: Colors.rgb.dark_gray,
    },

    '&:hover': {
      '& > div': {
        width: '100%',
        left: 0,
      },
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0 24px',
      lineHeight: '16px',
    },
  },
  formTabIndicator: {
    position: 'absolute',
    bottom: 0,
    width: 0,
    height: 3,
    transition: 'all .3s',
    left: '50%',
  },
  formTabSelected: {
    background: Colors.rgb.primary,
    color: Colors.rgb.white,

    '& > div': {
      backgroundColor: Colors.rgb.white,
    },
  },
  formContent: {
    background: Colors.rgb.primary,
    width: '100%',
    padding: theme.spacing(4),
    boxSizing: 'border-box',
    borderRadius: 7,
  },
  formContentWithTab: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  formVerificationCode: {
    width: '60%',
    margin: '0 auto',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formInput: {
    width: '100%',
    fontFamily: `${theme.fontFamily.light} !important`,
    fontSize: theme.fontSize.L,
    background: Colors.rgb.white,
    borderRadius: 4,
  },
  formInputSeparator: {
    height: theme.spacing(2),
  },
  formSubmitContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  codeFormSubmitContainer: {
    marginTop: theme.spacing(4),
  },
  formSubmitButton: {
    height: 40,
    display: 'flex',
    flex: 1,
  },
  formSubmitButtonSeparator: {
    width: theme.spacing(3),
  },
  formSubtitle: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.S,
    width: '55%',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    color: Colors.rgb.white,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  verificationCodeInput: {
    textAlign: 'center',
    fontSize: '1.3rem',
    height: 43,
    background: Colors.rgb.white,
    borderRadius: 4,

    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },
  helpContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(3),
    color: Colors.rgb.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: theme.fontSize.S,
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.9,
    },

    [theme.breakpoints.down('sm')]: {
      top: 'unset',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  helpIcon: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));

export default makeClasses;
