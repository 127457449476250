import { makeStyles } from '@material-ui/core';
import { THEME_LAYOUT } from '../../theme';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  loansHeader: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > img': {
      width: 100,
    },

    '& > div': {
      letterSpacing: 1,
      fontWeight: '500',
      textAlign: 'right',
    },
  },
  loansContentWrapper: {
    display: 'flex',
    alignItems: 'flex-start',

    '& > div': {
      display: 'flex',
      flex: 1,
      marginRight: theme.spacing(4),
      '&:last-child': {
        marginRight: 0,
      },
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  contentTile: {
    padding: 0,
    width: '100%',

    '& *': {
      fontFamily: theme.fontFamily.light,
      fontSize: theme.fontSize.L,
      color: Colors.rgb.contentTextHighlighted,
    },
  },
  contentItem: {
    padding: `${theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.S)} ${theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.M)}`,

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',

      '& > div:nth-child(1)': {
        '& > span': {
          fontSize: theme.fontSize.S,
        },
      },

      '& > div:nth-child(2)': {
        fontSize: theme.fontSize.M,
        fontFamily: theme.fontFamily.regular,
      },
    },
  },
  notAvailableContent: {
    marginTop: theme.spacing(2),
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.light,
    color: Colors.rgb.contentTextSoft,
  },
}));

export default makeClasses;
