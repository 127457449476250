import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../utils';

const makeClasses = makeStyles(theme => ({
  contentWrapper: {
    display: 'flex',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  contentDetails: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  contentImage: {
    width: '30%',
    display: 'flex',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    flexWrap: 'wrap',
    background: Colors.rgb.contentBackgroundLight,
    padding: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      flex: 1,
      width: 'auto',
      borderLeft: 'none',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  contentActionsWrapper: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    borderTop: `1px solid ${Colors.rgb.contentLineSoft}`,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  contentActionSeparator: {
    width: theme.spacing(3),
  },
  shareableContentWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  shareableContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: theme.spacing(8),

    '&:last-child': {
      marginRight: 0,
    },

    '& > div': {
      '&:nth-child(1)': {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(4),
      },
      '&:nth-child(2)': {
        display: 'flex',
        alignItems: 'center',
      },
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginRight: 0,
      marginBottom: theme.spacing(4),

      '& > div': {
        '&:nth-child(1)': {
          marginRight: `0 !important`,
          marginBottom: `${theme.spacing(2)} !important`,
        },
      },
    },
  },
  shareableLinkWrapper: {
    width: '100%',
    display: 'flex',
    marginLeft: 0,

    [theme.breakpoints.down('sm')]: {},
  },
  paymentLinkContentImageWrapper: {
    background: Colors.rgb.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 115,
    height: 115,
    boxSizing: 'border-box',
    border: `1px solid ${Colors.rgb.contentLine}`,
  },
  paymentLinkContentImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  paymentLinkContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: `calc(100% - 125px)`,
    marginLeft: theme.spacing(2),
  },
  paymentLinkContentLabel: {
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.XS,
    color: Colors.rgb.contentTextSoft,
  },
  paymentLinkContentValue: {
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.M,
    color: Colors.rgb.contentText,
  },
  paymentLintContentSeparator: {
    height: theme.spacing(1),
  },
  socialActions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  socialAction: {
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.2s',
    cursor: 'pointer',

    '&:hover': {
      transform: 'translateY(-2px)',
    },

    '& > img': {
      width: '70%',
      height: '70%',
    },
  },
  shareAction: {
    '& > img': {
      height: 100,
      width: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      '& > img': {
        height: 40,
        width: 'auto',
      },
    },
  },
  shareableLinkInput: {
    '& > div': {
      borderRadius: 0,

      '& > input': {
        height: 40,
        boxSizing: 'border-box',
        fontFamily: theme.fontFamily.regular,
        fontSize: theme.fontSize.M,
        color: Colors.rgb.contentText,
      },
    },
  },
  shareableLinkButton: {
    marginTop: 0,
    textTransform: 'none',
    height: 40,
    border: 'none',
    padding: '0 15px',
    borderRadius: 0,
  },
}));

export default makeClasses;
