import { fork, all } from 'redux-saga/effects';

import CommonSagas from './Common.sagas';
import UserSagas from './User.sagas';
import BusinessSagas from './Business.sagas';
import LanguageSagas from './Language.sagas';

export default function* rootSaga() {
	yield all([ fork(CommonSagas), fork(UserSagas), fork(BusinessSagas), fork(LanguageSagas) ]);
}
