import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { goBack, push } from "connected-react-router";

import ROUTES from "../../../../routes";
import {
  BoxSeparator,
  ContentBox,
  Page,
  TitleBox,
  Button,
  ForbiddenSection,
  Confirm,
} from "../../../../components";
import {
  user as userActions,
} from "../../../../actions";
import { Language } from "../../../../utils";

import {
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { toast } from "react-toastify";

import makeClasses from "./styles";
import { Policies } from "../../../../utils/Policies";
import { Alert, AlertTitle } from "@material-ui/lab";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import clsx from "clsx";

const AddHandyTapScreen = () => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
  const [branches, setBranches] = useState([]);
  const [inputDeviceIdentifier, setInputDeviceIdentifier] = useState("");
  const [inputDeviceName, setInputDeviceName] = useState("");
  const [inputBranch, setInputBranch] = useState(0);

  const [forbiddenSection, setForbbidenSection] = useState(false);
  const { policies, authTime, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    authTime: state.user.userData.authTime || Date.now(),
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const canSubmitForm =
    inputDeviceIdentifier?.length > 0 && inputBranch.length > 0 && inputDeviceName?.length > 0;

  useEffect(() => {
    window.scrollTo(0, 0);

    const exiredSession =
      Math.floor((new Date() - new Date(authTime * 1000)) / (1000 * 60)) >= 15;
    if (exiredSession) {
      return _toggleSessionExpiredModal(true);
    }

    if (policies && policies.includes(Policies.types.SENSITIVE_DATA)) {
      dispatch(
        userActions.getProfileData((profile, branches) => {
          setBranches(branches);

          if (branches.length === 1) {
            setInputBranch(branches[0].traceId);
          }
        })
      );
    } else {
      setForbbidenSection(true);
    }
  }, [policies, authTime]);

  const _goBack = () => {
    dispatch(goBack());
  };

  const _goToHandyTaps = () => {
    dispatch(
      push({
        pathname: ROUTES.MANAGE_HANDY_TAP.path,
      })
    );
  };

  const _submitForm = () => {
    if (!canSubmitForm) {
      return false;
    }

    dispatch(
      userActions.addHandyTap(
        inputBranch,
        {
          deviceIdentifier: inputDeviceIdentifier,
          name: inputDeviceName,
        },
        (success) => {
          if (success) {
            toast.success(i18n.get("AddHandyTap.AddHandyTapSuccess"));
            setTimeout(() => {
              _goToHandyTaps();
            }, 2000);
          } else {
            toast.error(i18n.get("AddHandyTap.AddHandyTapError"));
          }
        }
      )
    );
  };

  const _toggleSessionExpiredModal = (value) => {
    setShowSessionExpiredModal(value);
  };

  const _logOut = () => {
    dispatch(userActions.logOut());
  };

  const renderAddHandyTapForm = () => {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.userFormWrapper}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form
            layout="vertical"
            onSubmit={_submitForm}
            className={classes.userForm}
          >
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.formInput}
                      label={i18n.get("AddHandyTap.InputDeviceIdLabel")}
                      placeholder={i18n.get(
                        "AddHandyTap.InputDeviceIdPlaceholder"
                      )}
                      onChange={(e) => setInputDeviceIdentifier(e.target.value)}
                      value={inputDeviceIdentifier}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel className={classes.formSelectLabel} shrink>
                      {i18n.get("AddHandyTap.InputBranchLabel")}
                    </InputLabel>
                    <Select
                      className={classes.formSelect}
                      onChange={(e) => setInputBranch(e.target.value)}
                      value={inputBranch}
                    >
                      <MenuItem value={0} className={classes.formSelectOption}>
                        {i18n.get("AddHandyTap.DefaultBranchOption")}
                      </MenuItem>
                      {branches.map((branch, index) => (
                        <MenuItem
                          key={index}
                          value={branch.traceId}
                          className={classes.formSelectOption}
                        >
                          {branch.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.formInput}
                      label={i18n.get("AddHandyTap.InputDeviceNameLabel")}
                      placeholder={i18n.get(
                        "AddHandyTap.InputDeviceNamePlaceholder"
                      )}
                      onChange={(e) => setInputDeviceName(e.target.value)}
                      value={inputDeviceName}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get("AddHandyTap.Title")}
        withActivePage={ROUTES.MANAGE_HANDY_TAP.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get("AddHandyTap.Title")}
      withActivePage={ROUTES.MANAGE_POS_REQUESTS.id}
    >
      <TitleBox
        title={i18n.get("AddHandyTap.BoxTitle")}
        back
        backTitle={i18n.get("AddHandyTap.BoxBackButton")}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <ContentBox title={i18n.get("AddHandyTap.BoxContentTitle")} titleBold>
        {renderAddHandyTapForm()}
        <div>
          <div className={classes.contentActionsWrapper}>
            <Button variant="outlined" onClick={_goBack}>
              {i18n.get("AddHandyTap.CancelButton")}
            </Button>
            <div className={classes.contentActionSeparator} />
            <Button disabled={!canSubmitForm} onClick={_submitForm}>
              {i18n.get("AddHandyTap.ContinueButton")}
            </Button>
          </div>
        </div>
      </ContentBox>
      <Confirm
        open={showSessionExpiredModal}
        onClose={_goBack}
        title={i18n.get("AddHandyTap.ExpiredSessionDialogTitle")}
        onConfirm={_logOut}
        confirmText={i18n.get(
          "AddHandyTap.ExpiredSessionDialogActionConfirm"
        )}
        cancelText={i18n.get("AddHandyTap.ExpiredSessionDialogActionCancel")}
        onCancel={_goBack}
      >
        <Alert
          severity="info"
          icon={false}
          className={clsx(classes.alert, classes.alertInfo)}
        >
          <div>
            <InfoOutlinedIcon className={classes.alertIcon} />
          </div>
          <div className={classes.alertMessage}>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get("AddHandyTap.ExpiredSessionDialogContentTitle")}
            </AlertTitle>
            {i18n.get("AddHandyTap.ExpiredSessionDialogContentMessage")}
          </div>
        </Alert>
      </Confirm>
    </Page>
  );
};

AddHandyTapScreen.id = "com.Handy.AddHandyTap";

export default AddHandyTapScreen;
