import React from 'react';
import { useSelector } from 'react-redux';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { Language } from '../../utils';
import makeClasses from './styles';
import clsx from 'clsx';

const FiltersSelector = ({
  options = [],
  selected,
  defaultOption = null,
  showDefaultOption = true,
  idField = 'id',
  nameField = 'name',
  onSelected,
  align,
  fullWidth = false,
  disabled = false,
}) => {
  const classes = makeClasses();

  const { languageTexts } = useSelector(state => ({
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const _handleOnChange = e => {
    if (typeof onSelected === 'function') {
      onSelected(e.target.value);
    }
  };

  return (
    <div
      className={clsx(
        classes.container,
        align === 'right' ? classes.alignRight : null,
        fullWidth ? classes.selectContainerFullWidth : null
      )}
    >
      <Select
        value={selected}
        onChange={_handleOnChange}
        className={clsx(classes.selectContainer, fullWidth ? classes.selectContainerFullWidth : null)}
        disabled={disabled}
      >
        {showDefaultOption ? (
          <MenuItem value="all" className={classes.selectOption}>
            {defaultOption || i18n.get('Components.FiltersSelector.DefaultOption')}
          </MenuItem>
        ) : null}
        {options.map((option, index) => (
          <MenuItem key={index} value={option[idField]} className={classes.selectOption}>
            {option.translate ? i18n.get(option[nameField]) : option[nameField]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default FiltersSelector;
