import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  helpParagraph: {
    marginBottom: theme.spacing(5),
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentText,
    fontFamily: theme.fontFamily.light,

    '& strong': {
      fontFamily: theme.fontFamily.bold,
    },

    '&:last-child': {
      marginBottom: 0,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.M,
    },
  },
  helpContentTitle: {
    fontFamily: theme.fontFamily.bold,
    fontSize: theme.fontSize.XL,
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.L,
    },
  },
  helpTitleItalic: {
    fontStyle: 'italic',
  },
  helpTitleBold: {
    fontFamily: theme.fontFamily.bold,
  },
}));

export default makeClasses;
