import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import qs from 'query-string';
import { push } from 'connected-react-router';

import ROUTES from '../../routes';
import {
  Page,
  BranchesSelector,
  Calendar,
  TitleBox,
  BoxSeparator,
  ContentBox,
  EmptyMessage,
  ForbiddenSection,
} from '../../components';
import { Language } from '../../utils';
import { business as businessActions } from '../../actions';

import makeClasses from './styles';
import { Policies } from '../../utils/Policies';

const YourMoneyScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const currentDate = moment();
  const searchParams = qs.parse(history.location.search);
  const [ selectedBranch, setSelectedBranch ] = useState(searchParams.bid || 'all');
  const [ selectedMonth, setSelectedMonth ] = useState(
    searchParams.month ? parseInt(searchParams.month) : currentDate.month()
  );
  const [ selectedYear, setSelectedYear ] = useState(
    searchParams.year ? parseInt(searchParams.year) : currentDate.year()
  );
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const { policies, branches, paymentSchedule, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    branches: state.business.branches || [],
    paymentSchedule: state.business.paymentSchedule || {},
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(
    () => {
      if (policies && policies.includes(Policies.types.YOUR_MONEY)) {
        const branchId = selectedBranch !== 'all' ? selectedBranch : null;
        dispatch(businessActions.getPaymentSchedule(branchId, selectedMonth + 1, selectedYear));
      } else {
        setForbbidenSection(true);
      }
    },
    [ selectedBranch, selectedMonth, selectedYear ]
  );

  const _handleCalendarTdOnSelect = date => e => {
    e.preventDefault();

    const branchId = selectedBranch !== 'all' ? selectedBranch : null;

    if (branchId) {
      dispatch(push(`/pagos/detalles/${date}/${branchId}`));
    } else {
      dispatch(push(`/pagos/detalles/${date}`));
    }
  };

  const _handleOnDisplayOptionChange = displayOption => {
    const searchParams = qs.parse(history.location.search) || {};
    searchParams.display = displayOption;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnBranchSelected = branch => {
    setSelectedBranch(branch);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.bid = branch;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _setSelectedMonth = month => {
    setSelectedMonth(month);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.month = month;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _setSelectedYear = year => {
    setSelectedYear(year);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.year = year;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleCalendarOnNavigationPrev = (month, year) => () => {
    _setSelectedMonth(month);
    _setSelectedYear(year);
  };

  const _handleCalendarOnNavigationNext = (month, year) => () => {
    _setSelectedMonth(month);
    _setSelectedYear(year);
  };

  const renderBranchesSelector = () => (
    <BranchesSelector branches={branches} selectedBranch={selectedBranch} onBranchSelected={_handleOnBranchSelected} />
  );

  if (forbiddenSection) {
    return (
      <Page withHeader withSidebar withHeaderTitle={i18n.get('YourSales.Title')} withActivePage={ROUTES.YOUR_MONEY.id}>
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('YourSales.Title')} withActivePage={ROUTES.YOUR_MONEY.id}>
      <TitleBox title={i18n.get('YourSales.BoxTitle')} />
      <BoxSeparator />
      <ContentBox title={i18n.get('YourSales.BoxFiltersTitle')} titleBold>
        {renderBranchesSelector()}
      </ContentBox>
      <BoxSeparator size="small" />
      <div className={classes.calendarContainer}>
        <Calendar
          month={selectedMonth}
          year={selectedYear}
          paymentSchedule={paymentSchedule}
          initialDisplayOption={searchParams.display || 'calendar'}
          onDisplayOptionChange={_handleOnDisplayOptionChange}
          onNavigationPrev={_handleCalendarOnNavigationPrev}
          onNavigationNext={_handleCalendarOnNavigationNext}
          onCalendarItemClick={_handleCalendarTdOnSelect}
        />
      </div>
    </Page>
  );
};

YourMoneyScreen.id = 'com.Handy.YourMoney';

export default YourMoneyScreen;
