import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
    fontFamily: theme.fontFamily.light,
    color: Colors.rgb.contentTextSoft,
    fontSize: theme.fontSize.M,
  },
  containerTextOnly: {
    paddingTop: theme.spacing(3),
  },
}));

export default makeClasses;
