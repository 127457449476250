import { takeEvery, all, call, put, select } from 'redux-saga/effects';
import { languageTypes, language as languageActions } from '../actions';
import moment from 'moment';
import API, { API_PATHS, API_RESPONSES } from '../utils/Api';

function* initialize() {
  const requesting = yield select(({ language }) => language.requesting);
  if (!requesting) {
    const locale = yield select(({ language }) => language.locale);
    yield call(fetchLanguageTexts, locale);
    yield call(setMomentLocale, locale);
  }
}

function* fetchLanguageTexts(locale) {
  yield put(languageActions.setRequesting(true));
  /*try {
    const result = yield API.get(API_PATHS().LANGUAGES(locale.toUpperCase()));
    if (result.status === API_RESPONSES.SUCCESS) {
      yield put(languageActions.setLanguageTexts(result.data));
    } else {
      throw result.message;
    }
  } catch (e) {*/
  const defaultLanguageTexts = require('../utils/i18n/locales/es_es.json');
  yield put(languageActions.setLanguageTexts(defaultLanguageTexts));
  //console.log(`NO_${locale.toUpperCase()}_LANGUAGE_FOUND`);
  //}
  yield put(languageActions.setRequesting(false));
}

function setMomentLocale(locale) {
  switch (locale) {
    case 'es':
      require('../../node_modules/moment/locale/es');
      break;
    default:
      require('../../node_modules/moment/locale/es');
      break;
  }

  moment.locale(locale);
}

export default function* LanguageSagas() {
  yield all([ takeEvery(languageTypes.INITIALIZE, initialize) ]);
}
