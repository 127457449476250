import { userTypes as types } from '../actions';

const initialState = {
  userData: {},
  loading: false,
};

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_USER_DATA:
      return {
        ...state,
        userData: action.data,
      };
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case types.CLEAR:
      return initialState;
    default:
      return state;
  }
}
