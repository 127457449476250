import clsx from 'clsx';
import React from 'react';

import makeClasses from './styles';

const Box = ({
  top = true,
  right = true,
  bottom = true,
  left = true,
  background = null,
  direction = 'row',
  align = null,
  onClick = null,
  border = false,
  borderWidth = null,
  borderColor = null,
  isTitle = false,
  contentOnly = false,
  children,
  fullHeight = false,
  customClass,
  showShadow = true,
}) => {
  const classes = makeClasses({
    top,
    right,
    bottom,
    left,
    background,
    direction,
    align,
    border,
    borderWidth,
    borderColor,
    isTitle,
    contentOnly,
    fullHeight,
    showShadow,
  })();

  return (
    <div className={clsx(classes.box, onClick ? classes.boxClickable : null, customClass || null)} onClick={onClick}>
      {children}
    </div>
  );
};

export default Box;
