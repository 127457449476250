import React from 'react';
import makeClasses from './styles';

const FiltersSeparator = ({ horizontal = false }) => {
  const classes = makeClasses();

  return <div className={horizontal ? classes.filtersSeparatorHorizontal : classes.filtersSeparator} />;
};

export default FiltersSeparator;
