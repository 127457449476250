import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  notAvailableContent: {
    marginTop: theme.spacing(2),
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.light,
    color: Colors.rgb.contentTextSoft,

    '& a': {
      color: Colors.rgb.contentTextSoft,
    },
  },
  contentBoxItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  contentBoxItemLabel: {
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentText,
  },
  contentBoxItemValue: {
    fontFamily: theme.fontFamily.bold,
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentTextHighlighted,

    '& > span': {
      fontSize: theme.fontSize.S,
    },
  },
  contentBoxItemSeparator: {
    margin: `0 ${theme.spacing(2)}`,
    display: 'flex',
    flex: 1,
    background: Colors.rgb.contentLine,
  },
  searchFiltersContainer: {
    display: 'flex',
    marginTop: theme.spacing(4),
    borderTop: `1px solid ${Colors.rgb.contentLineSoft}`,
    paddingTop: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      paddingTop: theme.spacing(3),
      flexDirection: 'column',
    },
  },
  searchFilterSeparator: {
    width: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      width: 0,
      height: theme.spacing(2),
    },
  },
  searchBarToggle: {
    padding: 0,
    marginRight: `-${theme.spacing(1)}`,
  },
}));

export default makeClasses;
