import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  BoxSeparator,
  ContentBox,
  FiltersToggle,
  ForbiddenSection,
  Page,
  TitleBox,
} from "../../components";
import ROUTES from "../../routes";
import { Language } from "../../utils";
import makeClasses from "./styles";
import AccountIcon from "../../static/images/icons/account-round.svg";
import { business as businessActions } from "../../actions";
import { formatCurrency } from "../../utils/Utils";
import qs from "query-string";
import { push } from "connected-react-router";
import _ from "lodash";
import { Divider, Hidden, List, ListItem } from "@material-ui/core";
import { Policies } from "../../utils/Policies";

const viewFilterOptions = [
  { id: 0, label: "Account.ViewFilter.BY_BRANCH" },
  { id: 1, label: "Account.ViewFilter.BY_CURRENCY" },
];

const AccountScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);

  const { languageTexts, policies } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const [accounts, setAccounts] = useState([]);
  const [selectedView, setSelectedView] = useState(
    searchParams.status ? parseInt(searchParams.status) : -1
  );
  const [paginationPage, setPaginationPage] = useState(
    searchParams.page ? parseInt(searchParams.page) : 1
  );
  const [forbiddenSection, setForbbidenSection] = useState(true);

  useEffect(() => {
    if (policies && policies.includes(Policies.types.ACCOUNT)) {
      setForbbidenSection(false);
    }
  }, [policies]);

  useEffect(() => {
    if (!forbiddenSection) {
      dispatch(
        businessActions.getAccounts(paginationPage, (accounts) => {
          setAccounts(accounts);
        })
      );
    }
  }, [paginationPage, forbiddenSection]);

  const _handleOnStatusChange = (status) => {
    setSelectedView(status);
    setPaginationPage(1);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.status = status;
    searchParams.page = 1;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _goToAccountDetails = (accountTraceId) => (e) => {
    e && e.preventDefault();

    dispatch(
      push(
        ROUTES.ACCOUNT_DETAILS.path.replace(`:accountTraceId`, accountTraceId)
      )
    );
  };

  const renderAccounts = () => {
    if (!accounts || accounts.length === 0) {
      return null;
    }

    if (selectedView === viewFilterOptions[0].id) {
      const accountsByBranchTraceId = _.groupBy(
        accounts,
        (a) => a.branch.traceId
      );
      const accountsByBranch = [];

      Object.keys(accountsByBranchTraceId).forEach((branchTraceId) => {
        accountsByBranch.push({
          branchTraceId,
          branchName: accountsByBranchTraceId[branchTraceId][0].branch.name,
          accounts: accountsByBranchTraceId[branchTraceId],
        });
      });

      return accountsByBranch.map((branch, index) => (
        <React.Fragment key={index}>
          {index !== 0 ? <BoxSeparator size="small" /> : null}
          <ContentBox
            title={`${i18n.get("Account.BoxItemBranch")} ${branch.branchName}`}
            titleBold
            iconLeft={AccountIcon}
            accordion
          >
            <div className={classes.contentWrapper}>
              {branch.accounts.map((account, index) => (
                <React.Fragment key={index}>
                  {index !== 0 ? (
                    <Divider className={classes.boxItemSeparator} />
                  ) : null}
                  <List
                    component="nav"
                    className={classes.boxItem}
                    onClick={_goToAccountDetails(account.traceId)}
                  >
                    <ListItem button classes={{ root: classes.boxItemRoot }}>
                      <div className={classes.boxItemWrapper}>
                        <div>
                          <span className={classes.boxItemBold}>
                            {account.name}
                          </span>
                        </div>
                        <div>
                          <span>{i18n.get("Account.BoxItemBalance")}</span>
                          <span>{formatCurrency(account.netBalance)}</span>
                        </div>
                      </div>
                    </ListItem>
                  </List>
                </React.Fragment>
              ))}
            </div>
          </ContentBox>
        </React.Fragment>
      ));
    }

    if (selectedView === viewFilterOptions[1].id) {
      const accountsByCurrencyName = _.groupBy(accounts, "currency");
      const accountsByCurrency = [];

      Object.keys(accountsByCurrencyName).forEach((currencyName) => {
        accountsByCurrency.push({
          currency: currencyName,
          accounts: accountsByCurrencyName[currencyName],
          balance:
            accountsByCurrencyName[currencyName]
              .map((a) => a.netBalance)
              .reduce((a, b) => a + b) || 0,
        });
      });

      return accountsByCurrency.map((currency, index) => (
        <React.Fragment key={index}>
          {index !== 0 ? <BoxSeparator size="small" /> : null}
          <ContentBox
            title={() => (
              <React.Fragment>
                <Hidden xsDown>
                  <div className={classes.boxItemTitle}>
                    <span>
                      {i18n.get("Account.BoxItemAccountsByCurrency")}{" "}
                      {currency.currency}
                    </span>
                    <span>|</span>
                    <span>{i18n.get("Account.BoxItemTotalBalance")}</span>
                    <span>{formatCurrency(currency.balance)}</span>
                  </div>
                </Hidden>
                <Hidden smUp>
                  <div className={classes.boxItemTitleSm}>
                    <div>
                      {i18n.get("Account.BoxItemAccountsByCurrency")}{" "}
                      {currency.currency}
                    </div>
                  </div>
                </Hidden>
              </React.Fragment>
            )}
            titleBold
            iconLeft={AccountIcon}
            accordion
          >
            <div className={classes.contentWrapper}>
              {currency.accounts.map((account, index) => (
                <React.Fragment key={index}>
                  {index !== 0 ? (
                    <Divider className={classes.boxItemSeparator} />
                  ) : null}
                  <List
                    component="nav"
                    className={classes.boxItem}
                    onClick={_goToAccountDetails(account.traceId)}
                  >
                    <ListItem button classes={{ root: classes.boxItemRoot }}>
                      <div className={classes.boxItemWrapper}>
                        <div>
                          <span className={classes.boxItemBold}>{`${i18n.get(
                            "Account.BoxItemBranch"
                          )} ${account.branch.name}`}</span>
                        </div>
                        <div>
                          <span>{i18n.get("Account.BoxItemBalance")}</span>
                          <span>{formatCurrency(account.netBalance)}</span>
                        </div>
                      </div>
                    </ListItem>
                  </List>
                </React.Fragment>
              ))}
            </div>
          </ContentBox>
        </React.Fragment>
      ));
    }

    return accounts.map((account, index) => (
      <React.Fragment key={index}>
        {index !== 0 ? <BoxSeparator size="small" /> : null}
        <Box onClick={_goToAccountDetails(account.traceId)}>
          <Hidden xsDown>
            <div className={classes.boxItemWrapper}>
              <div>
                <img
                  src={AccountIcon}
                  alt="Account Icon"
                  className={classes.boxItemIcon}
                />
                <span>
                  {i18n.get("Account.BoxItemBranch")} {account.branch.name}
                </span>
                <span>|</span>
                <span>{i18n.get("Account.BoxItemCurrency")}</span>
                <span>{account.currency}</span>
              </div>
              <div>
                <span>{i18n.get("Account.BoxItemBalance")}</span>
                <span>{formatCurrency(account.netBalance)}</span>
              </div>
            </div>
          </Hidden>
          <Hidden smUp>
            <div className={classes.boxItemWrapperSm}>
              <div>
                <img
                  src={AccountIcon}
                  alt="Account Icon"
                  className={classes.boxItemIcon}
                />
                <span>
                  {i18n.get("Account.BoxItemBranch")} {account.branch.name}
                </span>
              </div>
              <div>
                <div>
                  <span>{i18n.get("Account.BoxItemCurrency")}</span>
                  <span>{account.currency}</span>
                </div>
                <div>
                  <span>{i18n.get("Account.BoxItemBalance")}</span>
                  <span>{formatCurrency(account.netBalance)}</span>
                </div>
              </div>
            </div>
          </Hidden>
        </Box>
      </React.Fragment>
    ));
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get("Account.Title")}
        withActivePage={ROUTES.ACCOUNT.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get("Account.Title")}
      withActivePage={ROUTES.ACCOUNT.id}
    >
      <TitleBox title={i18n.get("Account.BoxTitle")} />
      <BoxSeparator />
      <Box>
        <div className={classes.viewFilterWrapper}>
          <div>{i18n.get("Account.ViewFilterLabel")}</div>
          <div>
            <FiltersToggle
              showLabel={false}
              defaultOption={i18n.get("Account.ViewFilter.ALL")}
              options={viewFilterOptions}
              onChange={_handleOnStatusChange}
              selected={selectedView}
              showAsColumnSm
            />
          </div>
        </div>
      </Box>
      <BoxSeparator size="small" />
      {renderAccounts()}
    </Page>
  );
};

AccountScreen.id = "com.Handy.Account";

export default AccountScreen;
