import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  detailsItem: {
    marginBottom: theme.spacing(1),
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.M,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  detailsItemLabel: {
    color: Colors.rgb.contentText,
  },
  detailsItemValue: {
    color: Colors.rgb.contentTextHighlighted,
  },
}));

export default makeClasses;
