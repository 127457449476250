import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { connectRouter, routerMiddleware, push } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import stateReconciler from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import * as reducers from '../reducers';
import rootSaga from '../sagas';
import { user as userActions, language as languageActions } from '../actions';
import Amplify from 'aws-amplify';

Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
  oauth: {},
});

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler,
  blacklist: [ 'router', 'language' ],
};

const configureStore = (initialState = {}, history) => {
  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [ routerMiddleware(history) ];

  const enhancers = [ applyMiddleware(...middlewares) ];

  const store = createStore(
    connectRouter(history)(persistedReducer),
    applyMiddleware(sagaMiddleware),
    compose(...enhancers)
  );

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store, null, () => {
    try {
      store.dispatch(languageActions.initialize());
      store.dispatch(userActions.setLoading(false));

      const state = store.getState();
      if (state && state.user && Object.keys(state.user.userData).length > 0) {
        store.dispatch(userActions.initialize());
        if (/login|registro/.test(window.location.pathname)) {
          store.dispatch(push('/inicio'));
        }
      } else {
        if (!/login|registro/.test(window.location.pathname)) {
          store.dispatch(push('/'));
          store.dispatch(push(userActions.logOut()));
        }
      }
    } catch (e) {
      store.dispatch(push('/'));
      store.dispatch(push(userActions.logOut()));
    }
  });

  return { store, persistor };
};

export default configureStore;
