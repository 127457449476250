import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BoxSeparator,
  ForbiddenSection,
  Page,
  TitleBox,
  ContentBox,
  StatusPill,
} from "../../../../../components";
import ROUTES from "../../../../../routes";
import { Language, Utils } from "../../../../../utils";
import { business as businessActions } from "../../../../../actions";
import { goBack } from "connected-react-router";
import makeClasses from "./styles";
import { Grid } from "@material-ui/core";

import { Policies } from "../../../../../utils/Policies";
import moment from "moment";

const CURRENCY_FILTER_OPTIONS = [
  { id: "URUGUAYAN_PESO", name: "Currency.UYU" },
  { id: "DOLLAR", name: "Currency.USD" },
];

const FIELD_TYPES_OPTIONS = [
  {
    id: "STRING",
    name: "ProvidersManageSubscribersRequestNewCharge.AdditionalFieldTypeOption1",
  },
  {
    id: "INTEGER",
    name: "ProvidersManageSubscribersRequestNewCharge.AdditionalFieldTypeOption2",
  },
];

const ProvidersSubscriptionsManageTransactionDetailsScreen = ({
  history,
  match,
}) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const [transaction, setTransaction] = useState(null);
  const [forbiddenSection, setForbbidenSection] = useState(false);

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const transactionTraceId =
    match && match.params ? match.params.traceId : null;

  useEffect(() => {
    if (!policies || !policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS)) {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (!forbiddenSection) {
      loadData();
    }
  }, [forbiddenSection]);

  const loadData = () => {
    if (transactionTraceId) {
      dispatch(
        businessActions.getProvidersSubscriptionsTransactions(
          transactionTraceId,
          null,
          null,
          null,
          null,
          null,
          null,
          (data) => {
            setTransaction(data.elements?.length > 0 ? data.elements[0] : null);
          }
        )
      );
    }
  };

  const handleOnGoBackClick = () => {
    dispatch(goBack());
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get(
          "ProvidersSubscriptionsManageTransactionDetails.Title"
        )}
        withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get(
        "ProvidersSubscriptionsManageTransactionDetails.Title"
      )}
      withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
    >
      <TitleBox
        title={i18n.get(
          "ProvidersSubscriptionsManageTransactionDetails.BoxTitle"
        )}
        back
        backTitle={i18n.get(
          "ProvidersSubscriptionsManageTransactionDetails.BackButton"
        )}
        onBackClick={handleOnGoBackClick}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      {transaction ? (
        <React.Fragment>
          <ContentBox
            title={i18n.get(
              "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxDataLabel"
            )}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxDateLabel"
                    )}
                    :
                  </div>
                  {transaction.transactionDate
                    ? moment(transaction.transactionDate).format(
                        `${i18n.get("DateFormat")} ${i18n.get("TimeFormat")}`
                      )
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxPlanLabel"
                    )}
                    :
                  </div>
                  {transaction.subscriptionPlan
                    ? transaction.subscriptionPlan?.code
                      ? `${transaction.subscriptionPlan?.name} - ${transaction.subscriptionPlan?.code}`
                      : transaction.subscriptionPlan?.name
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxBranchLabel"
                    )}
                    :
                  </div>
                  {transaction.branch ? transaction.branch?.name || "-" : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxSubscriberLabel"
                    )}
                    :
                  </div>
                  {transaction.subscriber
                    ? `${transaction.subscriber?.document || "-"} - ${
                        transaction.subscriber?.firstName || "-"
                      } ${transaction.subscriber?.lastName || "-"}`
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxStatusLabel"
                    )}
                    :
                  </div>
                  {transaction.transactionStatus ? (
                    <StatusPill data={transaction.transactionStatus} />
                  ) : (
                    "-"
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxPlanAmountLabel"
                    )}
                    :
                  </div>
                  {transaction.amount
                    ? `${
                        i18n.get(
                          CURRENCY_FILTER_OPTIONS.find(
                            (c) => c.id === transaction.currency
                          )?.name
                        ) || ""
                      } ${Utils.formatCurrency(transaction.amount)}`
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxCardLabel"
                    )}
                    :
                  </div>
                  {transaction.instrument
                    ? transaction.instrument.maskedPan
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxAuthorizationNumberLabel"
                    )}
                    :
                  </div>
                  {transaction.authorization || "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxCancellationDateLabel"
                    )}
                    :
                  </div>
                  {transaction.cancellationDate
                    ? moment(transaction.cancellationDate).format(
                        `${i18n.get("DateFormat")} ${i18n.get("TimeFormat")}`
                      )
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxCancellationNumberLabel"
                    )}
                    :
                  </div>
                  {transaction.cancellationAuthorization || "-"}
                </div>
              </Grid>
            </Grid>
          </ContentBox>
          {transaction.transactionPlan?.additionalFields &&
          transaction.transactionPlan?.additionalFields.length > 0 ? (
            <React.Fragment>
              <BoxSeparator size="small" />
              <ContentBox
                title={i18n.get(
                  "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxAdditionalDataLabel"
                )}
              >
                {transaction.transactionPlan?.additionalFields.map(
                  (field, key) => (
                    <Grid container spacing={4}>
                      <Grid key={key} item xs={12} sm={12} md={3}>
                        <div className={classes.suscriptionDetailsItem}>
                          <div>
                            {i18n.get(
                              "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxAdditionalDataNameLabel"
                            )}
                            :
                          </div>
                          {field.name || "-"}
                        </div>
                      </Grid>
                      <Grid key={key} item xs={12} sm={12} md={3}>
                        <div className={classes.suscriptionDetailsItem}>
                          <div>
                            {i18n.get(
                              "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxAdditionalDataInternalNameLabel"
                            )}
                            :
                          </div>
                          {field.key || "-"}
                        </div>
                      </Grid>
                      <Grid key={key} item xs={12} sm={12} md={3}>
                        <div className={classes.suscriptionDetailsItem}>
                          <div>
                            {i18n.get(
                              "ProvidersSubscriptionsManageTransactionDetails.TransactionBoxAdditionalDataTypeLabel"
                            )}
                            :
                          </div>
                          {field.type
                            ? i18n.get(
                                FIELD_TYPES_OPTIONS.find(
                                  (s) => s.id === field.type
                                ).name
                              )
                            : "-"}
                        </div>
                      </Grid>
                    </Grid>
                  )
                )}
              </ContentBox>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : null}
    </Page>
  );
};

ProvidersSubscriptionsManageTransactionDetailsScreen.id =
  "com.Handy.ProvidersSubscriptionsManageTransactionDetails";

export default ProvidersSubscriptionsManageTransactionDetailsScreen;
