import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton, InputBase } from '@material-ui/core';

import { Language } from '../../utils';

import makeClasses from './styles';

const SearchBox = ({ initialValue, value, placeholder, onSearch, onInputChange, withMargin = false }) => {
  const classes = makeClasses();

  const { languageTexts } = useSelector(state => ({
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  return (
    <Paper
      component="form"
      className={clsx(classes.searchWrapper, withMargin ? classes.searchWrapperWithMargin : null)}
      onSubmit={onSearch}
    >
      <IconButton type="submit" className={classes.searchIconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        value={value}
        className={classes.searchInput}
        placeholder={placeholder || i18n.get('SearchBox.Placeholder')}
        onChange={onInputChange}
        defaultValue={value}
      />
    </Paper>
  );
};

export default SearchBox;
