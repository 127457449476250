import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  inputContainer: {
    width: 'auto',
    textAlign: 'left',
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentText,

    '& > div::before': {
      borderBottomColor: `${Colors.rgb.contentLine} !important`,
    },

    '& input': {
      fontSize: theme.fontSize.M,
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inputContainerFullWidth: {
    width: '100%',
  },
}));

export default makeClasses;
