import { makeStyles } from '@material-ui/core';
import { THEME_LAYOUT } from '../../theme';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  calendarContainer: {
    padding: theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.L),
    paddingTop: theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.S),
    background: Colors.rgb.contentBackgroundSecondary,
  },
}));

export default makeClasses;
