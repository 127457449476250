import clsx from 'clsx';
import React from 'react';
import makeClasses from './styles';

const BoxSeparator = ({ size = 'normal', type = 'white-space', width = null, noPadding = false }) => {
  const classes = makeClasses();

  if (type === 'white-space') {
    return <div className={clsx(classes.separator, size === 'small' ? classes.separatorSm : null)} />;
  }

  if (type === 'line') {
    return (
      <div
        className={clsx(
          classes.lineSeparatorWrapper,
          width === 'full' ? classes.lineSeparatorWrapperFull : null,
          noPadding === true ? classes.lineSeparatorWrapperWithNoPadding : null
        )}
      >
        <div className={classes.lineSeparator} />
      </div>
    );
  }

  return null;
};

export default BoxSeparator;
