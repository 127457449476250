import React from 'react';

import PLANS from '../../static/data/plans.json';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Table from '@material-ui/core/Table';
import {
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { Colors, Language } from '../../utils';
import makeClasses from './styles';
import { useSelector } from 'react-redux';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: Colors.rgb.white,
    },
  },
}))(TableRow);

const StyledTableWhiteRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: Colors.rgb.grey,
    },
    '&:nth-of-type(even)': {
      backgroundColor: Colors.rgb.white,
    },
  },
}))(TableRow);

const PlansModal = ({ open, branch, onClose, hasRUT = false }) => {
  const classes = makeClasses();
  const planDetails = branch ? PLANS[branch.plan.labelId] || PLANS['PLAN:Basico'] : [];

  const { languageTexts } = useSelector((state) => ({
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const renderAdditionalsTable = () => {
    if (hasRUT) {
      return (
        <React.Fragment>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{i18n.get('Components.PlansModal.BranchesSectionAditionalTablePOSTitle')}</TableCell>
                <TableCell align="right">
                  {i18n.get('Components.PlansModal.BranchesSectionAdditionalTablePrice')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {PLANS.ADDITIONALS_RUT_POS.map((detail, index) => (
                <StyledTableRow key={index}>
                  <TableCell>{detail.label}</TableCell>
                  <TableCell align="right">{detail.price}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <div className={classes.plansSeparator} />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{i18n.get('Components.PlansModal.BranchesSectionAditionalTableTitle')}</TableCell>
                <TableCell align="right">
                  {i18n.get('Components.PlansModal.BranchesSectionAdditionalTablePrice')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {PLANS.ADDITIONALS_RUT.map((detail, index) => (
                <StyledTableRow key={index}>
                  <TableCell>{detail.label}</TableCell>
                  <TableCell align="right">{detail.price}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <div className={classes.plansPlan712AdditionalsInfo}>
            <div>{i18n.get('Components.PlansModal.BranchesSectionAdditionalTableLegendRUTLine1')}</div>
            <br/>
            <div>{i18n.get('Components.PlansModal.BranchesSectionAdditionalTableLegendRUTLine2')}</div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{i18n.get('Components.PlansModal.BranchesSectionAditionalTableTitle')}</TableCell>
              <TableCell align="right">
                {i18n.get('Components.PlansModal.BranchesSectionAdditionalTablePrice')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {PLANS.ADDITIONALS_CI.map((detail, index) => (
              <StyledTableRow key={index}>
                <TableCell>{detail.label}</TableCell>
                <TableCell align="right">{detail.price}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.plansPlan712AdditionalsInfo}>
          <div>{i18n.get('Components.PlansModal.BranchesSectionAdditionalTableLegend')}</div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitleWrapper}>
        <div className={clsx(classes.dialogTitle, classes.dialogTitleWithCloseButton)}>
          {i18n.get('Components.PlansModal.BranchesSectionPlanTitle')}
        </div>
        <div className={classes.dialogTitleClose} onClick={onClose}>
          <CancelOutlinedIcon className={classes.dialogTitleCloseIcon} color="inherit" />
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContentWrapper}>
        <Accordion expanded className={classes.plansWrapper} classes={{ expanded: classes.plansWrapperExpanded }}>
          <AccordionDetails className={classes.plansLinesAccordion}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="left">{i18n.get('Components.PlansModal.BranchesSectionPlanTableFee')}</TableCell>
                  <TableCell align="right">
                    {i18n.get('Components.PlansModal.BranchesSectionPlanTablePeriod')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {planDetails.map((detail, index) =>
                  detail.type === 'full' ? (
                    <StyledTableRow key={index}>
                      <TableCell colSpan={3}>{detail.label}</TableCell>
                    </StyledTableRow>
                  ) : (
                    <StyledTableRow key={index}>
                      <TableCell>{detail.label}</TableCell>
                      <TableCell align="left">{detail.fee}</TableCell>
                      <TableCell align="right">{detail.period}</TableCell>
                    </StyledTableRow>
                  )
                )}
              </TableBody>
            </Table>
            <div className={classes.plansPlan712AdditionalsContainer}>
              <Accordion className={classes.plansWrapper} classes={{ expanded: classes.plansWrapperExpanded }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className={classes.plansPlan712AdditionalsTitle}>
                    {i18n.get('Components.PlansModal.BranchesSectionPlan712AditionalTableTitle')}
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.plansLinesAccordion}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {i18n.get('Components.PlansModal.BranchesSectionPlan712AditionalTableInstallments')}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.get('Components.PlansModal.BranchesSectionPlan712AditionalTableUYU')}
                        </TableCell>
                        <TableCell align="right">
                          {i18n.get('Components.PlansModal.BranchesSectionPlan712AditionalTableUSD')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {PLANS.PLAN_7_12_ADITIONALS.map((detail, index) => (
                        <StyledTableWhiteRow key={index}>
                          <TableCell align="left">{detail.installments}</TableCell>
                          <TableCell align="center">{detail.UYU}</TableCell>
                          <TableCell align="right">{detail.USD}</TableCell>
                        </StyledTableWhiteRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className={classes.plansPlan712AdditionalsInfo}>
                    <div>{i18n.get('Components.PlansModal.BranchesSectionPlan712AditionalInfoLine1')}</div>
                    <div>{i18n.get('Components.PlansModal.BranchesSectionPlan712AditionalInfoLine2')}</div>
                    <div>{i18n.get('Components.PlansModal.BranchesSectionPlan712AditionalInfoLine3')}</div>
                    <div>{i18n.get('Components.PlansModal.BranchesSectionPlan712AditionalInfoLine4')}</div>
                    <div>{i18n.get('Components.PlansModal.BranchesSectionPlan712AditionalInfoLine5')}</div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            {renderAdditionalsTable()}
          </AccordionDetails>
        </Accordion>
      </DialogContent>
    </Dialog>
  );
};

export default PlansModal;
