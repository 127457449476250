import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../../utils';

const makeClasses = makeStyles((theme) => ({
  gridTitleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Colors.rgb.contentTextHighlighted,

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& > span': {
        '&:nth-child(2)': {
          fontFamily: theme.fontFamily.bold,
          marginLeft: theme.spacing(1),
        },
        '&:nth-child(3)': {
          fontFamily: theme.fontFamily.regular,
          margin: `0 ${theme.spacing(2)}`,
        },
        '&:nth-child(5)': {
          fontFamily: theme.fontFamily.bold,
          marginLeft: theme.spacing(1),
        },
      },
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',

      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',

        '&:nth-child(1)': {
          '& > span': {
            fontFamily: theme.fontFamily.bold,
            marginLeft: `0 !important`,
            fontSize: theme.fontSize.L,
            display: 'block',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: 'calc(100% - 20px)',
            overflow: 'hidden',
          },
        },
        '&:nth-child(2)': {
          justifyContent: 'flex-end',
          marginTop: theme.spacing(1),

          '& > span': {
            fontFamily: `${theme.fontFamily.bold} !important`,
            marginLeft: theme.spacing(1),
            fontSize: theme.fontSize.M,

            '&:nth-child(1)': {
              fontFamily: `${theme.fontFamily.regular} !important`,
            },

            '&:nth-child(3)': {
              marginRight: 0,
              marginLeft: theme.spacing(1),
            },
          },
        },
      },
    },
  },
  gridTitleIcon: {
    width: 20,
    marginRight: theme.spacing(1),
  },
  viewFilterWrapper: {
    display: 'flex',
    alignItems: 'center',

    '& > div:nth-child(1)': {
      color: Colors.rgb.contentTextHighlighted,
      fontSize: theme.fontSize.XL,
      fontFamily: theme.fontFamily.bold,
      marginRight: theme.spacing(4),
    },
  },
  gridTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '& > div': {
      display: 'flex',
      alignItems: 'center',

      '&:nth-child(1)': {
        '& > div:nth-child(1)': {
          color: Colors.rgb.contentTextHighlighted,
          fontSize: theme.fontSize.XL,
          fontFamily: theme.fontFamily.bold,
          marginRight: theme.spacing(4),

          [theme.breakpoints.down('sm')]: {
            fontSize: theme.fontSize.M,
            marginRight: theme.spacing(2),
          },
        },
      },
    },
  },
  gridWrapper: {
    marginTop: theme.spacing(3),
  },
  gridSeparator: {
    background: Colors.rgb.contentLine,
    height: 1,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  accordionTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSize.L,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.M,
    },
  },
  accordionSeparator: {
    height: theme.spacing(1),
  },
  accordionContainer: {
    borderRadius: 0,
    boxShadow: 'none',

    '&::before': {
      display: 'none',
      height: 0,
    },
  },
  accordionContainerExpanded: {
    margin: '0 !important',
  },
  accordionSummary: {
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    minHeight: 0,
    height: 30,
    background: Colors.rgb.contentText,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  accordionSummaryContent: {
    margin: 0,
    color: Colors.rgb.white,
    fontFamily: theme.fontFamily.bold,
    fontSize: theme.fontSize.L,
  },
  accordionSummaryExpanded: {
    minHeight: '0 !important',
    margin: '0 !important',
  },
  accordionSummaryExpandIcon: {
    color: Colors.rgb.white,
    padding: 0,
    marginRight: `-${theme.spacing(1)} !important`,

    '& > span > svg': {
      fontSize: theme.fontSize.XXL2,
    },
  },
  accordionDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  accordionGridHeader: {
    background: Colors.rgb.grey,
    padding: theme.spacing(3),
    borderBottom: `1px solid ${Colors.rgb.contentLineSoft}`,
    fontSize: theme.fontSize.L,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      fontSize: theme.fontSize.M,
    },
  },
  accordionGridItemCentered: {
    textAlign: 'center',
  },
  accordionGridRow: {
    borderBottom: `1px solid ${Colors.rgb.contentLine}`,
  },
  accordionGridItem: {
    padding: theme.spacing(3),
    fontSize: theme.fontSize.L,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      fontSize: theme.fontSize.M,
    },
  },
  accordionGridItemLabel: {
    paddingLeft: theme.spacing(8),

    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  accordionGridItemTotalPositive: {
    color: Colors.rgb.green,
  },
  accordionGridItemTotalNegative: {
    color: Colors.rgb.red,
  },
  alert: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    width: '100%',
    boxSizing: 'border-box',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  alertTitle: {
    fontFamily: theme.fontFamily.bold,
  },
  alertIcon: {
    fontSize: 50,
    marginRight: theme.spacing(2),
  },
  alertDanger: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
  suscriptionDetailsItem: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentTextHighlighted,
    fontSize: theme.fontSize.M,

    '& > div': {
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.S,
    },

    '& a': {
      color: Colors.rgb.primary
    }
  },
  subscriptionItemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  subscriptionItemDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentTextHighlighted,
      fontSize: theme.fontSize.L,

      '& > span': {
        margin: `0 ${theme.spacing(2)}`,
      },
    },
  },
  subscriptionItemExtraDetails: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentText,
    fontSize: `${theme.fontSize.M} !important`,
    marginTop: theme.spacing(1),

    '& span': {
      color: Colors.rgb.contentTextSoft,
      marginRight: theme.spacing(1),
    },

    '& > div:nth-child(even)': {
      color: Colors.rgb.contentTextSoft,
      margin: `0 ${theme.spacing(2)}`,
    },
  },
  subscriptionItemStatus: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: 5,
    fontSize: theme.fontSize.S,
    marginLeft: theme.spacing(1),
    background: '#e2e3e5',
    border: '1px solid #e2e3e5',
    color: '#3d4245',
  },
  subscriptionItemStatusActive: {
    background: Colors.rgb.alertSuccessBackground,
    border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
    color: `${Colors.rgb.alertSuccessText} !important`,
  },
  subscriptionItemSeparator: {
    height: 1,
    width: '100%',
    background: Colors.rgb.contentLine,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',

    '& > div': {
      marginRight: theme.spacing(2),

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  additionalDataContainer: {
    marginBottom: theme.spacing(2),

    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

export default makeClasses;
