import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import _, { replace } from 'lodash';
import { toast } from 'react-toastify';

import ROUTES from '../../../../routes';
import { BoxSeparator, ContentBox, Page, TitleBox, Button, ForbiddenSection } from '../../../../components';
import { user as userActions } from '../../../../actions';
import { Language, Colors } from '../../../../utils';

import {
  Grid,
  TextField,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  withStyles,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import makeClasses from './styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { checkForExpiredSession, inputs } from '../../../../utils/Utils';
import { Policies } from '../../../../utils/Policies';
import clsx from 'clsx';

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: Colors.rgb.grey,
    },
    '&:nth-of-type(even)': {
      backgroundColor: Colors.rgb.white,
    },
  },
}))(TableRow);

const AddUserScreen = () => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [ userName, setName ] = useState('');
  const [ userUsername, setUserUsername ] = useState('');
  const [ userPassword, setUserPassword ] = useState('');
  const [ showUserPassword, setShowUserPassword ] = useState(false);
  const [ showUserConfirmPassword, setShowUserConfirmPassword ] = useState(false);
  const [ userConfirmPassword, setUserConfirmPassword ] = useState('');
  const [ userRoles, setUserRoles ] = useState([]);
  const [ userRole, setUserRole ] = useState(null);
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const { policies, authTime, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    authTime: state.user.userData.authTime || Date.now(),
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const canSubmitForm =
    userName.length > 0 &&
    userUsername.length > 0 &&
    userPassword.length > 0 &&
    userConfirmPassword.length > 0 &&
    userRole &&
    userRole.traceId;

  useEffect(
    () => {
      const exiredSession = checkForExpiredSession(authTime);

      if (exiredSession) {
        return dispatch(replace(ROUTES.MANAGE_USERS_CHANGE_PASSWORD.path));
      }
    },
    [ authTime ]
  );

  useEffect(
    () => {
      window.scrollTo(0, 0);

      if (policies && policies.includes(Policies.types.MANAGE_USERS_WRITE)) {
        dispatch(
          userActions.getUserRoles(roles => {
            setUserRoles(roles.elements ? _.orderBy(roles.elements, [ 'name' ], [ 'asc' ]) : []);
          })
        );
      } else {
        setForbbidenSection(true);
      }
    },
    [ policies ]
  );

  const _goBack = e => {
    e.preventDefault();
    dispatch(goBack());
  };

  const _handleUserNameOnChange = e => setName(e.target.value.substring(0, 100));

  const _handleUserUsernameOnChange = e => {
    setUserUsername(e.target.value);
  };

  const _handleUserPasswordOnChange = e => {
    setUserPassword(e.target.value);
  };

  const _handleUserConfirmPasswordOnChange = e => {
    setUserConfirmPassword(e.target.value);
  };

  const _toggleUserPasswordVisibility = () => {
    setShowUserPassword(!showUserPassword);
  };

  const _toggleUserConfirmPasswordVisibility = () => {
    setShowUserConfirmPassword(!showUserConfirmPassword);
  };

  const _handleOnChangeUserRole = role => {
    if (userRole && userRole.traceId === role.traceId) {
      setUserRole(null);
    } else {
      setUserRole(role);
    }
  };

  const _submitForm = () => {
    if (!canSubmitForm) {
      return false;
    }

    if (!inputs.password.regex.test(userPassword)) {
      return toast.error(i18n.get('ManageUsersAdd.ValidationPasswordError'));
    }
    if (userPassword !== userConfirmPassword) {
      return toast.error(i18n.get('ManageUsersAdd.ValidationConfirmPasswordError'));
    }
    if (!inputs.username.regex.test(userUsername)) {
      return toast.error(i18n.get('ManageUsersAdd.ValidationUsernameError'));
    }

    dispatch(
      userActions.createUser(
        {
          name: userName,
          username: userUsername,
          password: userPassword,
          roleTraceId: userRole.traceId,
        },
        () => {
          toast.success(i18n.get('ManageUsersAdd.SuccessMessage'));
          setTimeout(() => dispatch(push(ROUTES.MANAGE_USERS.path)), 1000);
        }
      )
    );
  };

  const renderManageUsersAddForm = () => {
    return (
      <Grid container justify="center" alignItems="center" className={classes.userFormWrapper}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form layout="vertical" onSubmit={_submitForm} className={classes.userForm}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={i18n.get('ManageUsersAdd.UserNameInputLabel')}
                  placeholder={i18n.get('ManageUsersAdd.UserNameInputPlaceholder')}
                  className={classes.userFormInput}
                  onChange={_handleUserNameOnChange}
                  value={userName}
                  InputProps={{
                    endAdornment:
                      userName.length > 0 ? (
                        <InputAdornment position="start">
                          <span className={classes.userFormInputEndAdornment}>{userName.length} / 100</span>
                        </InputAdornment>
                      ) : null,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.userFormInput}
                  label={i18n.get('ManageUsersAdd.UserUsernameInputLabel')}
                  placeholder={i18n.get('ManageUsersAdd.UserUsernameInputPlaceholder')}
                  onChange={_handleUserUsernameOnChange}
                  value={userUsername}
                />
              </Grid>
            </Grid>
            <div className={classes.userFormInputSeparator} />
            <div className={classes.userFormInputSeparator} />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={_toggleUserPasswordVisibility}>
                          {showUserPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={showUserPassword ? 'text' : 'password'}
                  className={classes.userFormInput}
                  label={i18n.get('ManageUsersAdd.UserPasswordInputLabel')}
                  placeholder={i18n.get('ManageUsersAdd.UserPasswordInputPlaceholder')}
                  onChange={_handleUserPasswordOnChange}
                  value={userPassword}
                  helperText={i18n.get('ManageUsersAdd.UserPasswordInputHelperText')}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={_toggleUserConfirmPasswordVisibility}>
                          {showUserConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={showUserConfirmPassword ? 'text' : 'password'}
                  className={classes.userFormInput}
                  label={i18n.get('ManageUsersAdd.UserConfirmPasswordInputLabel')}
                  placeholder={i18n.get('ManageUsersAdd.UserConfirmPasswordInputPlaceholder')}
                  onChange={_handleUserConfirmPasswordOnChange}
                  value={userConfirmPassword}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  };

  const renderRolesForm = () => {
    if (isSmallScreen) {
      return (
        <div>
          {userRoles.map((role, index) => (
            <React.Fragment key={role.traceId}>
              {index === 0 ? null : <div className={classes.boxItemSeparator} />}
              <div className={classes.boxContainer}>
                <div className={classes.boxContainerCheckboxWrapper}>
                  <Checkbox
                    checked={userRole && userRole.traceId === role.traceId ? true : false}
                    onChange={() => _handleOnChangeUserRole(role)}
                  />
                </div>
                <div>
                  <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                    <span>{i18n.get('ManageUsersAdd.RolesTableHeaderRole')}:</span>
                    <span>{role.name}</span>
                  </div>
                  <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                    <span>{i18n.get('ManageUsersAdd.RolesTableHeaderRoleDescription')}:</span>
                    <span>{role.description}</span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }

    return (
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get('ManageUsersAdd.RolesTableHeaderRole')}
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                {i18n.get('ManageUsersAdd.RolesTableHeaderRoleDescription')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userRoles.map(role => (
              <StyledTableRow key={role.traceId}>
                <TableCell className={classes.tableBodyCell}>
                  <Checkbox
                    checked={userRole && userRole.traceId === role.traceId ? true : false}
                    onChange={() => _handleOnChangeUserRole(role)}
                  />
                </TableCell>
                <TableCell className={classes.tableBodyCell}>{role.name}</TableCell>
                <TableCell className={classes.tableBodyCell}>{role.description}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ManageUsersAdd.Title')}
        withActivePage={ROUTES.MANAGE_USERS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ManageUsersAdd.Title')}
      withActivePage={ROUTES.MANAGE_USERS.id}
    >
      <TitleBox
        title={i18n.get('ManageUsersAdd.BoxTitle')}
        back
        backTitle={i18n.get('ManageUsersAdd.BoxBackButton')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <ContentBox title={i18n.get('ManageUsersAdd.BoxContentTitle')} titleBold>
        {renderManageUsersAddForm()}
      </ContentBox>
      <ContentBox title={i18n.get('ManageUsersAdd.BoxRolesTitle')} titleBold>
        {renderRolesForm()}
        <div>
          <div className={classes.contentActionsWrapper}>
            <Button variant="outlined" onClick={_goBack}>
              {i18n.get('ManageUsersAdd.CancelButton')}
            </Button>
            <div className={classes.contentActionSeparator} />
            <Button disabled={!canSubmitForm} onClick={_submitForm}>
              {i18n.get('ManageUsersAdd.ConfirmButton')}
            </Button>
          </div>
        </div>
      </ContentBox>
    </Page>
  );
};

AddUserScreen.id = 'com.Handy.ManageUsersAdd';

export default AddUserScreen;
