import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push, replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import ROUTES from '../../../../routes';
import { BoxSeparator, ContentBox, Page, TitleBox, Button, ForbiddenSection } from '../../../../components';
import { business as businessActions } from '../../../../actions';
import { Constants, Language } from '../../../../utils';
import { TextField } from '@material-ui/core';
import { InsertPhotoOutlined as InsertPhotoOutlinedIcon } from '@material-ui/icons';
import makeClasses from './styles';
import whatsAppIcon from '../../../../static/images/icons/social_whatsapp.svg';
import telegramIcon from '../../../../static/images/icons/social_telegram.svg';
import emailIcon from '../../../../static/images/icons/social_email.svg';
import qrDesktopIcon from '../../../../static/images/icons/qr-desktop.svg';
import QRModal from '../../../../components/QRModal';
import clsx from 'clsx';
import { Policies } from '../../../../utils/Policies';

const SharePaymentLinkScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const linkInputRef = useRef(null);

  const [ paymentLink, setPaymentLink ] = useState({});
  const [ qrCodeModalOpen, setQrCodeModalOpen ] = useState(false);
  const [ shareablePaymentLink, setShareablePaymentLink ] = useState('');
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const { policies, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const hasPaymentLink = Object.keys(paymentLink).length > 0;

  useEffect(
    () => {
      window.scrollTo(0, 0);

      const traceId = match.params.traceId || null;

      if (!traceId) {
        dispatch(goBack());
      }

      if (policies && policies.includes(Policies.types.HANDY_LINK_WRITE)) {
        dispatch(
          businessActions.getPaymentLink(traceId, paymentLink => {
            setPaymentLink(paymentLink);
            setShareablePaymentLink(Constants.SHAREABLE_LINK.replace(`{traceId}`, paymentLink.traceId));
          })
        );
      } else {
        setForbbidenSection(true);
      }
    },
    [ policies ]
  );

  const _copyLink = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(shareablePaymentLink)
        toast.success(i18n.get('SharePaymentLink.LinkCopied'));
      } catch (err) {
        console.error('Failed to copy text to clipboard.', err);
      }
    } else {
      linkInputRef.current.focus();
      linkInputRef.current.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          toast.success(i18n.get('SharePaymentLink.LinkCopied'));
        }
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
    }
  };

  const _goToAddPaymentLink = () => {
    dispatch(push(ROUTES.PAYMENT_LINK_NEW.path));
  };

  const _goToList = () => {
    dispatch(replace(ROUTES.PAYMENT_LINK.path));
  };

  const _toggleQRCodeModal = () => {
    setQrCodeModalOpen(!qrCodeModalOpen);
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('SharePaymentLink.Title')}
        withActivePage={ROUTES.PAYMENT_LINK.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('SharePaymentLink.Title')}
      withActivePage={ROUTES.PAYMENT_LINK.id}
    >
      <TitleBox title={i18n.get('SharePaymentLink.BoxTitle')} />
      <BoxSeparator />
      {hasPaymentLink ? (
        <div className={classes.contentWrapper}>
          <div className={classes.contentDetails}>
            <ContentBox title={i18n.get('SharePaymentLink.BoxContentTitle')} titleBold>
              <div className={classes.shareableContentWrapper}>
                <div className={classes.shareableContent}>
                  <div>{i18n.get('SharePaymentLink.LinkButtonLabel')}</div>
                  <div className={classes.shareableLinkWrapper}>
                    <TextField
                      inputRef={linkInputRef}
                      variant="outlined"
                      value={shareablePaymentLink}
                      contentEditable={false}
                      classes={{ root: classes.shareableLinkInput }}
                    />
                    <Button
                      className={classes.shareableLinkButton}
                      variant="contained"
                      color="primary"
                      onClick={_copyLink}
                    >
                      {i18n.get('SharePaymentLink.CopyLinkButton')}
                    </Button>
                  </div>
                </div>
                <div className={classes.shareableContent}>
                  <div>{i18n.get('SharePaymentLink.ShareTextTitle')}</div>
                  <div style={{ marginTop: -3 }}>
                    <a
                      href={Constants.SHAREABLE_WHATSAPP_LINK
                        .replace('{text}', i18n.get('SharePaymentLink.ShareText'))
                        .replace('{link}', shareablePaymentLink)}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.socialAction}
                    >
                      <img src={whatsAppIcon} alt="whatsappIcon" />
                    </a>
                    <a
                      href={Constants.SHAREABLE_TELEGRAM_LINK
                        .replace('{link}', shareablePaymentLink)
                        .replace('{text}', i18n.get('SharePaymentLink.ShareText'))}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.socialAction}
                    >
                      <img src={telegramIcon} alt="telegramIcon" />
                    </a>
                    <a
                      href={Constants.SHAREABLE_EMAIL_LINK
                        .replace('{text}', i18n.get('SharePaymentLink.ShareText'))
                        .replace('{link}', shareablePaymentLink)}
                      className={classes.socialAction}
                    >
                      <img src={emailIcon} alt="emailIcon" />
                    </a>
                  </div>
                </div>
                <div className={classes.shareableContent}>
                  <div>{i18n.get('SharePaymentLink.QRTitle')}</div>
                  <div className={clsx(classes.socialAction, classes.shareAction)} onClick={_toggleQRCodeModal}>
                    <img src={qrDesktopIcon} alt="qrIcon" style={{ height: 40, marginTop: -7 }} />
                  </div>
                </div>
              </div>
              <div className={classes.contentActionsWrapper}>
                <Button variant="outlined" onClick={_goToList}>
                  {i18n.get('SharePaymentLink.GoToListButton')}
                </Button>
                <div className={classes.contentActionSeparator} />
                <Button variant="outlined" onClick={_goToAddPaymentLink}>
                  {i18n.get('SharePaymentLink.CreateNewButton')}
                </Button>
              </div>
            </ContentBox>
          </div>
          <div className={classes.contentImage}>
            <div className={classes.paymentLinkContentImageWrapper}>
              {paymentLink.bundleImageUrl ? (
                <img src={paymentLink.bundleImageUrl} className={classes.paymentLinkContentImage} alt="payment" />
              ) : (
                <div className={classes.paymentLinkContentImagePlaceholder}>
                  <InsertPhotoOutlinedIcon color="primary" fontSize="large" />
                </div>
              )}
            </div>
            <div className={classes.paymentLinkContentWrapper}>
              <div className={classes.paymentLinkContentLabel}>{i18n.get('SharePaymentLink.ContentTitleLabel')}</div>
              <div className={classes.paymentLinkContentValue}>{paymentLink.productName}</div>
              {paymentLink.currency ? (
                <React.Fragment>
                  <div className={classes.paymentLintContentSeparator} />
                  <div className={classes.paymentLinkContentLabel}>
                    {i18n.get('SharePaymentLink.ContentPriceLabel')}
                  </div>
                  <div className={classes.paymentLinkContentValue}>
                    {paymentLink.currency.alpha} {paymentLink.amount}
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      {shareablePaymentLink ? (
        <QRModal open={qrCodeModalOpen} onClose={_toggleQRCodeModal} data={shareablePaymentLink} />
      ) : null}
    </Page>
  );
};

SharePaymentLinkScreen.id = 'com.Handy.SharePaymentLink';

export default SharePaymentLinkScreen;
