import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 31,
    marginTop: -10,
  },
  headerWrapperVertical: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.fontSize.M,
    },
  }
  },
  titleWrapperFullWidth: {
    width: '100%',
    justifyContent: 'space-between',
  },
  titleWrapperInlineIcon: {
    justifyContent: 'flex-start',
  },
  title: {
    color: Colors.rgb.contentTextHighlighted,
    fontSize: theme.fontSize.XL,
    fontFamily: theme.fontFamily.regular,
  
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.L,
      width: '100%',
    },
  },
  titleBold: {
    fontFamily: theme.fontFamily.bold,
   
  },
  titleSemibold: {
    fontFamily: theme.fontFamily.semibold,
  },
  titleLight: {
    fontFamily: theme.fontFamily.light,
  },
  titleMdLight: {
    fontFamily: theme.fontFamily.bold,
    fontSize: theme.fontSize.L,
  },
  iconLeft: {
    width: 20,
    marginRight: theme.spacing(2),
  },
  iconRight: {
    width: 20,
  },
  contentSeparator: {
    background: Colors.rgb.contentLine,
    height: 1,
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  contentSeparatorSm: {
    marginBottom: 0,
  },
  contentWrapper: {
    marginTop: 0,
  },
  accordionContainer: {
    borderRadius: 0,
    boxShadow: 'none',

    '&::before': {
      display: 'none',
      height: 0,
    },
  },
  accordionContainerExpanded: {
    margin: '0 !important',
  },
  accordionSummary: {
    padding: theme.spacing(4),
    minHeight: 0,
    height: 30,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  accordionSummaryContent: {
    margin: 0,
  },
  accordionSummaryExpanded: {
    minHeight: '0 !important',
    margin: '0 !important',
    paddingBottom: 0,
  },
  accordionSummaryExpandIcon: {
    padding: 0,
    marginRight: `-${theme.spacing(1)} !important`,

    '& > span > svg': {
      fontSize: theme.fontSize.XXL2,
    },
  },
  accordionDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(4)} ${theme.spacing(4)}`,

    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
    },
  },
  alertWrapper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
  },
  alertSuccess: {
    background: Colors.rgb.alertSuccessBackground,
    border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
    color: `${Colors.rgb.alertSuccessText} !important`,
  },
  alertWarning: {
    background: Colors.rgb.alertWarningBackground,
    border: `1px solid ${Colors.rgb.alertWarningBorder}`,
    color: `${Colors.rgb.alertWarningText} !important`,
  },
  alertDanger: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
  alertTitle: {
    fontFamily: theme.fontFamily.bold,
    fontSize: theme.fontSize.XL,
  },
  alertMessage: {
    marginTop: theme.spacing(2),
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.L,
  },
  leftIndicator: {
    width: 2,
    height: 22,
    marginRight: theme.spacing(1),
    background: Colors.rgb.light_black,
  },
}));

export default makeClasses;
