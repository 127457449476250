import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push, replace } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';

import ROUTES from '../../../../routes';
import { BoxSeparator, ContentBox, Page, TitleBox, Button, ForbiddenSection } from '../../../../components';
import { user as userActions } from '../../../../actions';
import { Language } from '../../../../utils';

import { Grid, TextField, InputAdornment, IconButton } from '@material-ui/core';

import makeClasses from './styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { checkForExpiredSession, inputs } from '../../../../utils/Utils';
import { Policies } from '../../../../utils/Policies';

const ChangePasswordScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const [ userPassword, setUserPassword ] = useState('');
  const [ showUserPassword, setShowUserPassword ] = useState(false);
  const [ userConfirmPassword, setUserConfirmPassword ] = useState('');
  const [ showUserConfirmPassword, setShowUserConfirmPassword ] = useState(false);
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const { policies, authTime, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    authTime: state.user.userData.authTime || Date.now(),
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const canSubmitForm = userPassword.length > 0 && userConfirmPassword.length > 0;

  useEffect(
    () => {
      const exiredSession = checkForExpiredSession(authTime);

      if (exiredSession) {
        return dispatch(replace(ROUTES.MANAGE_USERS_CHANGE_PASSWORD.path));
      }
    },
    [ authTime ]
  );

  useEffect(
    () => {
      window.scrollTo(0, 0);

      if (!policies || !policies.includes(Policies.types.MANAGE_USERS_WRITE)) {
        setForbbidenSection(true);
      }
    },
    [ policies ]
  );

  const _goBack = e => {
    e.preventDefault();
    dispatch(goBack());
  };

  const _handleUserPasswordOnChange = e => {
    setUserPassword(e.target.value);
  };

  const _handleUserConfirmPasswordOnChange = e => {
    setUserConfirmPassword(e.target.value);
  };

  const _toggleUserPasswordVisibility = () => {
    setShowUserPassword(!showUserPassword);
  };

  const _toggleUserConfirmPasswordVisibility = () => {
    setShowUserConfirmPassword(!showUserConfirmPassword);
  };

  const _submitForm = () => {
    if (!canSubmitForm) {
      return false;
    }

    if (!inputs.password.regex.test(userPassword)) {
      return toast.error(i18n.get('ManageUsersChangePassword.ValidationPasswordError'));
    }
    if (userPassword !== userConfirmPassword) {
      return toast.error(i18n.get('ManageUsersChangePassword.ValidationConfirmPasswordError'));
    }

    dispatch(
      userActions.updatePassword(match.params.traceId, userPassword, () => {
        toast.success(i18n.get('ManageUsersChangePassword.SuccessMessage'));
        setTimeout(() => dispatch(push(ROUTES.MANAGE_USERS.path)), 1000);
      })
    );
  };

  const renderManageUsersChangePasswordForm = () => {
    return (
      <Grid container justify="center" alignItems="center" className={classes.userFormWrapper}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form layout="vertical" onSubmit={_submitForm} className={classes.userForm}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={_toggleUserPasswordVisibility}>
                          {showUserPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={showUserPassword ? 'text' : 'password'}
                  className={classes.userFormInput}
                  label={i18n.get('ManageUsersChangePassword.UserPasswordInputLabel')}
                  placeholder={i18n.get('ManageUsersChangePassword.UserPasswordInputPlaceholder')}
                  onChange={_handleUserPasswordOnChange}
                  value={userPassword}
                  helperText={i18n.get('ManageUsersChangePassword.UserPasswordInputHelperText')}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={_toggleUserConfirmPasswordVisibility}>
                          {showUserConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={showUserConfirmPassword ? 'text' : 'password'}
                  className={classes.userFormInput}
                  label={i18n.get('ManageUsersChangePassword.UserConfirmPasswordInputLabel')}
                  placeholder={i18n.get('ManageUsersChangePassword.UserConfirmPasswordInputPlaceholder')}
                  onChange={_handleUserConfirmPasswordOnChange}
                  value={userConfirmPassword}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ManageUsersChangePassword.Title')}
        withActivePage={ROUTES.MANAGE_USERS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ManageUsersChangePassword.Title')}
      withActivePage={ROUTES.MANAGE_USERS.id}
    >
      <TitleBox
        title={i18n.get('ManageUsersChangePassword.BoxTitle')}
        back
        backTitle={i18n.get('ManageUsersChangePassword.BoxBackButton')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <ContentBox title={i18n.get('ManageUsersChangePassword.BoxContentTitle')} titleBold>
        {renderManageUsersChangePasswordForm()}
        <div>
          <div className={classes.contentActionsWrapper}>
            <Button variant="outlined" onClick={_goBack}>
              {i18n.get('ManageUsersChangePassword.CancelButton')}
            </Button>
            <div className={classes.contentActionSeparator} />
            <Button disabled={!canSubmitForm} onClick={_submitForm}>
              {i18n.get('ManageUsersChangePassword.ConfirmButton')}
            </Button>
          </div>
        </div>
      </ContentBox>
    </Page>
  );
};

ChangePasswordScreen.id = 'com.Handy.ManageUsersChangePassword';

export default ChangePasswordScreen;
