import { commonTypes as types } from '../actions';

const initialState = {
	loading: false,
	sideMenuOpen: false
};

export default function common(state = initialState, action = {}) {
	switch (action.type) {
		case types.SET_LOADING:
			return {
				...state,
				loading: action.value
			};
		case types.SET_SIDEMENU_OPEN:
			return {
				...state,
				sideMenuOpen: action.value
			};
		case types.CLEAR:
			return initialState;
		default:
			return state;
	}
}
