import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../../utils';

const makeClasses = makeStyles((theme) => ({
  formWrapper: {},
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  formInput: {
    width: '100%',

    '& > label': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentTextSoft,
      fontSize: theme.fontSize.L,
    },

    '& > div:before': {
      borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,
    },
    '& > div:after': {
      borderBottom: `1px solid ${Colors.rgb.primary}`,
    },
    '& > div:hover': {
      '&:before': {
        borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted} !important`,
      },
    },

    '& input': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.L,
    },
  },
  formInputSelect: {
    padding: '0 !important',
  },
  formInputSeparator: {
    height: theme.spacing(2),
  },
  contentActionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',

    '& > button:last-child': {
      marginRight: 0,
    },
  },
  contentActionSeparator: {
    width: theme.spacing(3),
  },
  suscriptionDetailsItem: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentTextHighlighted,
    fontSize: theme.fontSize.L,

    '& > div': {
      color: Colors.rgb.contentTextSoft,
      fontSize: theme.fontSize.M,
    },
  },
  suscriptionDetailsItemDisabled: {
    opacity: 0.5,
  },
  suscriptionDetailsStatus: {
    display: 'inline-block',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: 5,
    fontSize: theme.fontSize.S,
  },
  suscriptionDetailsStatusActive: {
    background: Colors.rgb.alertSuccessBackground,
    border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
    color: `${Colors.rgb.alertSuccessText} !important`,
  },
  suscriptionDetailsStatusInactive: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
  gridContainer: {},
}));

export default makeClasses;
