import React from 'react';
import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles(theme => ({
  container: {
  },
  title: {
    color: '#004185',
    fontFamily: theme.fontFamily.bold
  },
  downloadContainer: {
    margin: '5px 0 10px',
    display: 'flex',
    alignItems: 'center',

    '& a' : {
      textDecoration: 'none',
      margin: '0 5px',

      '& > img': {
        height: 30
      }
    },

    '& > div': {
      display: 'flex',
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',

      '& > span': {
        display: 'block',
        marginBottom: 5,
        mariginTop: 20
      },
    }
  }
}));

const DownloadApp = () => {
  const classes = makeClasses();

  return (
    <div className={classes.container}>
      <div className={classes.title}>¡Ya podés gestionar tu negocio desde tu celular con la nueva app Handy!</div>
      <div className={classes.downloadContainer}>
        <span>Descargala ahora desde</span>
        <div>
          <a target="_blank" rel="noreferrer" href="https://bit.ly/4gsbCHo">
            <img src="/download-app-store.png" alt="App Store Badge" />
          </a>
          o
          <a target="_blank" rel="noreferrer" href="https://bit.ly/3MNREcF">
            <img src="/download-play-store.png" alt="Google Play Store Badge" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default DownloadApp;