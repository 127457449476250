import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { toast } from 'react-toastify';

import ROUTES from '../../../../../routes';
import { BoxSeparator, ContentBox, Page, TitleBox, Button, ForbiddenSection } from '../../../../../components';
import { business as businessActions } from '../../../../../actions';
import { Language } from '../../../../../utils';

import { Grid, TextField } from '@material-ui/core';

import makeClasses from './styles';
import { Policies } from '../../../../../utils/Policies';
import moment from 'moment';
import clsx from 'clsx';

const EditServiceScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const productTraceId = match.params && match.params.traceId ? match.params.traceId : null;
  const isEditMode = /\/editar$/.test(match.path);

  const [inputName, setInputName] = useState('');
  const [inputInternalName, setInputInternalName] = useState('');
  const [inputDescription, setInputDescription] = useState('');

  const [serviceDate, setServiceDate] = useState('');
  const [serviceStatus, setServiceStatus] = useState('');
  const [serviceStatusValue, setServiceStatusValue] = useState(false);
  const [serviceBranch, setServiceBranch] = useState('');
  const [serviceCurrency, setServiceCurrency] = useState('');
  const [servicePartialChargeAllowed, setServicePartialChargeAllowed] = useState('');
  const [serviceAutoApprovalAllowed, setServiceAutoApprovalAllowed] = useState('');
  const [serviceRateType, setServiceRateType] = useState('');
  const [serviceRateTypeLabel, setServiceRateTypeLabel] = useState('');
  const [serviceRate, setServiceRate] = useState(0);
  const [serviceFixedChargedAmount, setServiceFixedChargedAmount] = useState(0);
  const [serviceEnableCallback, setServiceEnableCallback] = useState(false);
  const [serviceCallbackUrl, setServiceCallbackUrl] = useState('');

  const [forbiddenSection, setForbbidenSection] = useState(false);

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const validInputs = inputName.length > 0 && inputInternalName.length > 0;

  const canSubmitForm = isEditMode ? validInputs : validInputs && inputDescription.length > 0;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (policies && policies.includes(Policies.types.PROVIDER)) {
      dispatch(
        businessActions.getProduct(productTraceId, (data) => {
          setInputName(data.name || '');
          setInputInternalName(data.internalName || '');
          setInputDescription(data.description || '');
          setServiceDate(moment(data.createdAt).format(i18n.get('DateFormat')));
          setServiceBranch(data.branch.name);
          setServiceStatusValue(data.isActive);
          setServiceStatus(
            data.isActive
              ? i18n.get('ProvidersManageServicesEdit.StatusValueValid')
              : i18n.get('ProvidersManageServicesEdit.StatusValueInvalid')
          );
          setServiceCurrency(data.currency);
          setServicePartialChargeAllowed(
            data.partialChargeAllowed
              ? i18n.get('ProvidersManageServicesEdit.PartialChargeAllowedValueEnabled')
              : i18n.get('ProvidersManageServicesEdit.PartialChargeAllowedValueDisabled')
          );
          setServiceAutoApprovalAllowed(
            data.autoApprovalAllowed
              ? i18n.get('ProvidersManageServicesEdit.PartialChargeAllowedValueEnabled')
              : i18n.get('ProvidersManageServicesEdit.PartialChargeAllowedValueDisabled')
          );
          setServiceRateType(data.rateType);
          setServiceRateTypeLabel(
            data.rateType ? i18n.get(`ProvidersManageServicesEdit.RateTypeValue_${data.rateType}`) : '-'
          );
          setServiceRate(data.rateValue);
          setServiceFixedChargedAmount(data.fixedChargeAmount);
          setServiceEnableCallback(data.enableCallback);
          setServiceCallbackUrl(data.callback);
        })
      );
    } else {
      setForbbidenSection(true);
    }
  }, [policies]);

  const _goBack = (e) => {
    e.preventDefault();
    dispatch(goBack());
  };

  const _submitForm = () => {
    if (!canSubmitForm) {
      return false;
    }

    if (!inputName) {
      return toast.error(i18n.get('ProvidersManageServicesEdit.ValidationNameError'));
    }
    if (!inputInternalName) {
      return toast.error(i18n.get('ProvidersManageServicesEdit.ValidationInernalNameError'));
    }

    const payload = {
      name: inputName,
      internalName: inputInternalName,
      description: inputDescription,
    };

    dispatch(
      businessActions.updateProduct(productTraceId, payload, (result) => {
        if (result) {
          toast.success(i18n.get('ProvidersManageServicesEdit.SuccessUpdateMessage'));
          setTimeout(() => dispatch(push(ROUTES.MANAGE_PROVIDERS_SERVICES.path)), 1000);
        }
      })
    );
  };

  const renderManageProvidersEditServiceForm = () => {
    return (
      <Grid container justify="center" alignItems="center" className={isEditMode ? classes.formWrapper : null}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form layout="vertical" onSubmit={_submitForm} className={classes.form}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={4}>
                {isEditMode ? (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.formInput}
                    label={i18n.get('ProvidersManageServicesEdit.NameInputLabel')}
                    placeholder={i18n.get('ProvidersManageServicesEdit.NameInputPlaceholder')}
                    onChange={(e) => setInputName(e.target.value)}
                    value={inputName}
                  />
                ) : (
                  <div className={classes.serviceDetailsItem}>
                    <div>{i18n.get('ProvidersManageServicesEdit.NameInputLabel')}</div>
                    {inputName}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {isEditMode ? (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.formInput}
                    label={i18n.get('ProvidersManageServicesEdit.InternalNameInputLabel')}
                    placeholder={i18n.get('ProvidersManageServicesEdit.InternalNameInputPlaceholder')}
                    onChange={(e) => setInputInternalName(e.target.value)}
                    value={inputInternalName}
                  />
                ) : (
                  <div className={classes.serviceDetailsItem}>
                    <div>{i18n.get('ProvidersManageServicesEdit.InternalNameInputLabel')}</div>
                    {inputInternalName}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {isEditMode ? (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.formInput}
                    label={i18n.get('ProvidersManageServicesEdit.DescriptionInputLabel')}
                    placeholder={i18n.get('ProvidersManageServicesEdit.DescriptionInputPlaceholder')}
                    onChange={(e) => setInputDescription(e.target.value)}
                    value={inputDescription}
                  />
                ) : (
                  <div className={classes.serviceDetailsItem}>
                    <div>{i18n.get('ProvidersManageServicesEdit.DescriptionInputLabel')}</div>
                    {inputDescription}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div
                  className={clsx(classes.serviceDetailsItem, isEditMode ? classes.serviceDetailsItemDisabled : null)}
                >
                  <div>{i18n.get('ProvidersManageServicesEdit.DateLabel')}</div>
                  {serviceDate}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div
                  className={clsx(classes.serviceDetailsItem, isEditMode ? classes.serviceDetailsItemDisabled : null)}
                >
                  <div>{i18n.get('ProvidersManageServicesEdit.BranchLabel')}</div>
                  {serviceBranch}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div
                  className={clsx(classes.serviceDetailsItem, isEditMode ? classes.serviceDetailsItemDisabled : null)}
                >
                  <div>{i18n.get('ProvidersManageServicesEdit.StatusLabel')}</div>
                  <span
                    className={clsx(
                      classes.serviceDetailsStatus,
                      serviceStatus !== ''
                        ? serviceStatusValue
                          ? classes.serviceDetailsStatusActive
                          : classes.serviceDetailsStatusInactive
                        : null
                    )}
                  >
                    {serviceStatus}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div
                  className={clsx(classes.serviceDetailsItem, isEditMode ? classes.serviceDetailsItemDisabled : null)}
                >
                  <div>{i18n.get('ProvidersManageServicesEdit.CurrencyLabel')}</div>
                  {serviceCurrency}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div
                  className={clsx(classes.serviceDetailsItem, isEditMode ? classes.serviceDetailsItemDisabled : null)}
                >
                  <div>{i18n.get('ProvidersManageServicesEdit.PartialChargeAllowedLabel')}</div>
                  {servicePartialChargeAllowed}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div
                  className={clsx(classes.serviceDetailsItem, isEditMode ? classes.serviceDetailsItemDisabled : null)}
                >
                  <div>{i18n.get('ProvidersManageServicesEdit.AutoApprovalAllowedLabel')}</div>
                  {serviceAutoApprovalAllowed}
                </div>
              </Grid>
              {serviceEnableCallback ? (
                <Grid item xs={12} sm={12} md={4}>
                  <div
                    className={clsx(classes.serviceDetailsItem, isEditMode ? classes.serviceDetailsItemDisabled : null)}
                  >
                    <div>{i18n.get('ProvidersManageServicesEdit.CallbackLabel')}</div>
                    {serviceCallbackUrl || '-'}
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={12} md={4}>
                <div
                  className={clsx(classes.serviceDetailsItem, isEditMode ? classes.serviceDetailsItemDisabled : null)}
                >
                  <div>{i18n.get('ProvidersManageServicesEdit.RateTypeLabel')}</div>
                  {serviceRateTypeLabel}
                </div>
              </Grid>
              {serviceRateType === 'FIXED' ? (
                <React.Fragment>
                  <Grid item xs={12} sm={12} md={4}>
                    <div
                      className={clsx(
                        classes.serviceDetailsItem,
                        isEditMode ? classes.serviceDetailsItemDisabled : null
                      )}
                    >
                      <div>{i18n.get('ProvidersManageServicesEdit.CurrencyLabel')}</div>
                      {serviceCurrency}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div
                      className={clsx(
                        classes.serviceDetailsItem,
                        isEditMode ? classes.serviceDetailsItemDisabled : null
                      )}
                    >
                      <div>{i18n.get('ProvidersManageServicesEdit.RateTypeLabel')}</div>
                      {serviceRateType}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div
                      className={clsx(
                        classes.serviceDetailsItem,
                        isEditMode ? classes.serviceDetailsItemDisabled : null
                      )}
                    >
                      <div>{i18n.get('ProvidersManageServicesEdit.FixedRateLabel')}</div>
                      {serviceFixedChargedAmount}
                    </div>
                  </Grid>
                </React.Fragment>
              ) : serviceRateType === 'PERCENTAGE' ? (
                <React.Fragment>
                  <Grid item xs={12} sm={12} md={4}>
                    <div
                      className={clsx(
                        classes.serviceDetailsItem,
                        isEditMode ? classes.serviceDetailsItemDisabled : null
                      )}
                    >
                      <div>{i18n.get('ProvidersManageServicesEdit.RateLabel')}</div>
                      {serviceRate ? `${serviceRate}%` : '-'}
                    </div>
                  </Grid>
                </React.Fragment>
              ) : null}
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ProvidersManageServicesEdit.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ProvidersManageServicesEdit.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS.id}
    >
      <TitleBox
        title={
          isEditMode
            ? i18n.get('ProvidersManageServicesEdit.BoxTitle')
            : i18n.get('ProvidersManageServicesEdit.BoxTitleView')
        }
        back
        backTitle={i18n.get('ProvidersManageServicesEdit.BoxBackButton')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <ContentBox
        title={
          isEditMode
            ? i18n.get('ProvidersManageServicesEdit.BoxContentTitle')
            : i18n.get('ProvidersManageServicesEdit.BoxContentTitleView')
        }
        titleBold
      >
        {renderManageProvidersEditServiceForm()}
        {isEditMode ? (
          <div>
            <div className={classes.contentActionsWrapper}>
              <Button variant="outlined" onClick={_goBack}>
                {i18n.get('ProvidersManageServicesEdit.CancelButton')}
              </Button>
              <div className={classes.contentActionSeparator} />
              <Button disabled={!canSubmitForm} onClick={_submitForm}>
                {i18n.get('ProvidersManageServicesEdit.SaveButton')}
              </Button>
            </div>
          </div>
        ) : null}
      </ContentBox>
    </Page>
  );
};

EditServiceScreen.id = 'com.Handy.ManageProvidersEditService';

export default EditServiceScreen;
