import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { Language } from "../../utils";
import makeClasses from "./styles";
import clsx from "clsx";

const FiltersToggle = ({
  label = null,
  selected,
  options,
  defaultOption = null,
  showDefault = true,
  onChange,
  align,
  showLabel = true,
  flexOptions = true,
  showAsColumnSm = false,
}) => {
  const classes = makeClasses();

  const [statuses, setStatuses] = useState([]);
  const { languageTexts } = useSelector((state) => ({
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  useEffect(() => {
    setStatuses(options);
  }, []);

  const _handleOnChange = (e, value) => {
    if (
      value !== selected &&
      value !== null &&
      typeof onChange === "function"
    ) {
      onChange(value);
    }
  };

  return (
    <div
      className={clsx(
        classes.container,
        align === "right" ? classes.alignRight : null
      )}
    >
      {showLabel ? (
        <div className={classes.label}>
          {label || i18n.get("Components.FiltersToggle.FilterByLabel")}
        </div>
      ) : null}
      <ToggleButtonGroup
        value={selected}
        exclusive
        onChange={_handleOnChange}
        className={clsx(
          classes.selectContainer,
          showAsColumnSm ? classes.selectContainerColumn : null
        )}
      >
        {showDefault ? (
          <ToggleButton
            value={-1}
            className={[
              classes.selectOption,
              flexOptions ? classes.selectOptionFlex : null,
            ]}
            classes={{ selected: classes.selectOptionSelected }}
          >
            {defaultOption ||
              i18n.get("Components.FiltersToggle.DefaultOption")}
          </ToggleButton>
        ) : null}
        {statuses.map((status, index) => (
          <ToggleButton
            key={index}
            value={status.id}
            className={[
              classes.selectOption,
              flexOptions ? classes.selectOptionFlex : null,
            ]}
            classes={{ selected: classes.selectOptionSelected }}
          >
            {i18n.get(status.label)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default FiltersToggle;
