import { businessTypes as types } from '../actions';

const initialState = {
  loading: false,
  businessData: {},
  summary: {},
  branches: [],
  branchesWithNoPaymentMethod: [],
  paymentSchedule: {},
  activity: {},
  reversals: {},
  vouchers: {},
  history: {},
  paymentLinks: [],
  dismissedBanners: [],
  notification: [],
  loans: [],
  loanAvailability: {},
};

export default function business(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case types.SET_BUSINESS_DATA:
      return {
        ...state,
        businessData: action.data,
      };
    case types.SET_SUMMARY:
      return {
        ...state,
        summary: action.data,
      };
    case types.SET_BRANCHES:
      return {
        ...state,
        branches: action.data,
      };
    case types.SET_BRANCHES_WITH_NO_PAYMENT_METHOD:
      return {
        ...state,
        branchesWithNoPaymentMethod: action.data,
      };
    case types.SET_PAYMENT_SCHEDULE:
      return {
        ...state,
        paymentSchedule: action.data,
      };
    case types.SET_ACTIVITY:
      return {
        ...state,
        activity: action.data,
      };
    case types.SET_REVERSALS:
      return {
        ...state,
        reversals: action.data,
      };
    case types.SET_HISTORY:
      return {
        ...state,
        history: action.data,
      };
    case types.SET_PAYMENT_LINKS:
      return {
        ...state,
        paymentLinks: action.data,
      };
    case types.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.data,
      };
    case types.REMOVE_NOTIFICATION:
      const notificationToRemoveIndex = state.notifications.findIndex(n => n.id === action.notificationId);
      return {
        ...state,
        notifications: [
          ...state.notifications.slice(0, notificationToRemoveIndex),
          ...state.notifications.slice(notificationToRemoveIndex + 1),
        ],
      };
    case types.SET_LOANS:
      return {
        ...state,
        loans: action.data,
      };
    case types.SET_LOAN_AVAILABILITY:
      return {
        ...state,
        loanAvailability: action.data,
      };
    case types.CLEAR:
      return initialState;
    default:
      return state;
  }
}
