import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  filtersSeparator: {
    height: theme.spacing(5),
    width: 2,
    background: Colors.rgb.contentLineHighlighted,
    marginLeft: theme.spacing(31),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      background: 'none',
      height: 0,
      marginTop: 0,
    },
  },
  filtersSeparatorHorizontal: {
    height: 1,
    width: '100%',
    background: Colors.rgb.contentLineHighlighted,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default makeClasses;
