import { createMuiTheme } from '@material-ui/core/styles';
import { Colors } from './utils';

export const THEME_SIDEMENU_WIDTH = 260;

export const THEME_LAYOUT = {
  HORIZONTAL: {
    OUTER: {
      XS: 'layouts.horizontal.outer.xs',
      S: 'layouts.horizontal.outer.s',
    },
    INNER: {
      M: 'layouts.horizontal.inner.m',
      L: 'layouts.horizontal.inner.l',
      XL: 'layouts.horizontal.inner.xl',
    },
  },
  VERTICAL: {
    INNER: {
      XS: 'layouts.vertical.inner.xs',
      S: 'layouts.vertical.inner.s',
      M: 'layouts.vertical.inner.m',
      L: 'layouts.vertical.inner.l',
    },
    OUTER: {
      L: 'layouts.vertical.outer.l',
    },
  },
};

const theme = createMuiTheme({
  typography: {
    fontFamily: `"Muli", sans-serif`,
    fontSize: 14,
  },
  fontFamily: {
    regular: 'Muli',
    light: 'MuliLight',
    bold: 'MuliBold',
    semibold: 'MuliSemiBold',
  },
  palette: {
    primary: {
      main: Colors.rgb.primary,
    },
    secondary: {
      main: Colors.rgb.secondary,
    },
  },
  fontSize: {
    XXS: '9px',
    XS: '11px',
    S: '12px',
    M: '14px',
    L: '16px',
    XL: '18px',
    XXL: '24px',
    XXL2: '32px',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1024,
      xl: 1536,
    },
  },
  spacing: (layout, index) => {
    // 5 = XS | 10 = S | 15 = M | 20 = L | 25 = XL | 30 = XXL
    const spacings = [ 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50 ];

    switch (layout) {
      case THEME_LAYOUT.HORIZONTAL.OUTER.XS:
        return `${5}px`;
      case THEME_LAYOUT.HORIZONTAL.OUTER.S:
        return `${10}px`;
      case THEME_LAYOUT.HORIZONTAL.INNER.M:
        return `${15}px`;
      case THEME_LAYOUT.HORIZONTAL.INNER.L:
        return `${20}px`;
      case THEME_LAYOUT.HORIZONTAL.INNER.XL:
        return `${25}px`;
      case THEME_LAYOUT.VERTICAL.INNER.XS:
        return `${5}px`;
      case THEME_LAYOUT.VERTICAL.INNER.S:
        return `${10}px`;
      case THEME_LAYOUT.VERTICAL.INNER.M:
        return `${15}px`;
      case THEME_LAYOUT.VERTICAL.INNER.L:
        return `${20}px`;
      case THEME_LAYOUT.VERTICAL.OUTER.L:
        return `${20}px`;
      default:
        return `${spacings[index || layout || 1]}px`;
    }
  },
});

export default theme;
