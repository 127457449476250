import React, { useState, useEffect } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { Utils, Language } from '../../utils';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import { Button } from '../';

import makeClasses from './styles';

const Calendar = ({
  month,
  year,
  paymentSchedule = {},
  initialDisplayOption,
  onNavigationPrev,
  onNavigationNext,
  onCalendarItemClick,
  onDisplayOptionChange,
}) => {
  const classes = makeClasses();
  const currentDate = moment();
  const currentCalendarDate =
    typeof month === 'number' && typeof year === 'number' ? moment().month(month).year(year) : currentDate.clone();
  const [ monthDates, setMonthDates ] = useState([]);
  const [ expandedCalendarItem, setExpandedCalendarItem ] = useState(null);
  const { languageTexts } = useSelector(state => ({
    languageTexts: state.language.texts || {},
  }));
  const [ displayOption, setDisplayOption ] = useState(initialDisplayOption || 'calendar');

  const i18n = Language(languageTexts);

  useEffect(
    () => {
      setMonthDates(Utils.getMonthDates(currentCalendarDate.month(), currentCalendarDate.year()));
    },
    [ month ]
  );

  const _handleCalendarItemOnSelect = (calendarItem, isExpanded) => () => {
    setExpandedCalendarItem(isExpanded ? null : calendarItem);
  };

  const _preventClick = e => {
    e.preventDefault();
  };

  const _setDisplayOption = displayOption => () => {
    setDisplayOption(displayOption);
    onDisplayOptionChange(displayOption);
  };

  const renderViewOptions = () => (
    <Hidden smDown>
      <div className={classes.headerDisplayOptions}>
        <span>{i18n.get('Calendar.DisplayOption')}</span>
        <div className={classes.calendarHeaderButtonSeparator} />
        <Button
          active={displayOption === 'calendar'}
          onClick={displayOption === 'calendar' ? null : _setDisplayOption('calendar')}
          size="sm"
        >
          {i18n.get('Calendar.DisplayOptionCalendar')}
        </Button>
        <div className={classes.calendarHeaderButtonSeparator} />
        <Button
          active={displayOption === 'list'}
          onClick={displayOption === 'list' ? null : _setDisplayOption('list')}
          size="sm"
        >
          {i18n.get('Calendar.DisplayOptionList')}
        </Button>
      </div>
    </Hidden>
  );

  const renderCalendarNavigationPreviousMonth = () => {
    const previousDate = currentCalendarDate.clone().subtract(1, 'month');
    const disabled = currentCalendarDate.isSameOrBefore(currentDate);

    return (
      <Button
        onClick={!disabled ? onNavigationPrev(previousDate.month(), previousDate.year()) : null}
        disabled={disabled}
        size="sm"
      >
        {i18n.get('Calendar.NavigationPrevious')}
      </Button>
    );
  };

  const renderCalendarNavigationNextMonth = () => {
    const nextDate = currentCalendarDate.clone().add(1, 'month');

    return (
      <Button onClick={onNavigationNext(nextDate.month(), nextDate.year())} size="sm">
        {i18n.get('Calendar.NavigationNext')}
      </Button>
    );
  };

  const renderCalendarNavigation = () => {
    const currentMonthName = Utils.capitalize(currentCalendarDate.format('MMMM'));
    const currentYear = currentCalendarDate.year();

    return (
      <div className={classes.calendarHeader}>
        <div className={clsx(classes.calendarHeaderSide, classes.calendarHeaderSideLeft)}>
          {renderCalendarNavigationPreviousMonth()}
          <div className={classes.calendarHeaderButtonSeparator} />
          {renderCalendarNavigationNextMonth()}
        </div>
        <div className={classes.calendarHeaderTitle}>
          {currentMonthName} {currentYear}
        </div>
        <div className={clsx(classes.calendarHeaderSide, classes.calendarHeaderSideRight)}>{renderViewOptions()}</div>
      </div>
    );
  };

  const renderCalendarHeader = () => {
    const weekdays = [ 6, 0, 1, 2, 3, 4, 5 ];

    const _renderCalendarTh = (weekday, index) => (
      <Grid item key={index} style={{ width: '14.28%', flexGrow: '14.28%' }}>
        <div className={clsx(classes.calendarTh, index === 0 ? classes.calendarThFirst : null)}>
          {Utils.capitalize(moment().weekday(weekday).format('dddd'))}
        </div>
      </Grid>
    );

    return <Grid container>{weekdays.map((weekday, index) => _renderCalendarTh(weekday, index))}</Grid>;
  };

  const renderCalendarBody = () => {
    const body = [];
    const dailyTotals = paymentSchedule.dailyTotal;
    const isCurrentMonth = currentDate.month() === currentCalendarDate.month();

    if (!dailyTotals) {
      return null;
    }

    for (let i = 0, l = monthDates.length; i < l; i += 1) {
      body.push(
        <Grid container key={i}>
          {monthDates[i].map((date, index) => {
            if (date === null) {
              return (
                <Grid item key={index} style={{ width: '14.28%', flexGrow: '14.28%' }}>
                  <div
                    className={clsx(
                      classes.calendarTd,
                      index === 0 ? classes.calendarTdFirst : null,
                      classes.calendarTdDisabled
                    )}
                  />
                </Grid>
              );
            }

            date = moment(date);

            const isBefore = isCurrentMonth && date.date() <= currentDate.date();
            const isNextDay = isCurrentMonth && currentDate.clone().add(1, 'day').date() === date.date();
            const isFutureDate = !isBefore && !isNextDay;

            const dailyTotalForDate = dailyTotals.filter(total => total.dayNumber === date.date())[0];
            const dailyTotalForNextDate = dailyTotals.filter(total => total.dayNumber === date.date())[0];
            const isClickable = dailyTotalForDate && (dailyTotalForDate.balance.uyu || dailyTotalForDate.balance.usd);

            return (
              <Grid item key={index} style={{ width: '14.28%', flexGrow: '14.28%' }}>
                <Link
                  href="#"
                  onClick={isClickable ? onCalendarItemClick(date.format('YYYYMMDD')) : _preventClick}
                  underline="none"
                  color="textPrimary"
                >
                  <div
                    className={clsx(
                      classes.calendarTd,
                      index === 0 ? classes.calendarTdFirst : null,
                      isBefore
                        ? classes.calendarTdPast
                        : isNextDay && dailyTotalForNextDate ? classes.calendarTdNext : null,
                      isClickable ? classes.calendarTdClickable : null
                    )}
                  >
                    <div className={classes.calendarDateNumber}>{date.date()}</div>
                    {isClickable ? (
                      <React.Fragment>
                        <div
                          className={clsx(
                            classes.calendarTdContent,
                            isFutureDate ? classes.calendarTdContentFuture : null
                          )}
                        >
                          {i18n.get('Currency.UYU')}{' '}
                          {dailyTotalForDate.balance.uyu ? Utils.formatCurrency(dailyTotalForDate.balance.uyu) : 0}
                        </div>
                        <div
                          className={clsx(
                            classes.calendarTdContent,
                            isFutureDate ? classes.calendarTdContentFuture : null,
                            classes.calendarTdContentLast
                          )}
                        >
                          {i18n.get('Currency.USD')}{' '}
                          {dailyTotalForDate.balance.usd ? Utils.formatCurrency(dailyTotalForDate.balance.usd) : 0}
                        </div>
                      </React.Fragment>
                    ) : null}
                    {isNextDay && dailyTotalForNextDate ? (
                      <div className={classes.calendarTdCaption} variant="caption">
                        {i18n.get('Calendar.Tomorrow')}
                      </div>
                    ) : null}
                  </div>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      );
    }

    return body;
  };

  const renderCalendarBodyAsList = () => {
    const body = [];
    const dailyTotals = _.orderBy(paymentSchedule.dailyTotal, [ 'dayNumber' ], [ 'asc' ]);
    const isCurrentMonth = currentDate.month() === currentCalendarDate.month();

    if (!dailyTotals) {
      return null;
    }

    for (let i = 0, l = dailyTotals.length; i < l; i += 1) {
      const dailyTotal = dailyTotals[i];
      const dailyTotalDate = moment().month(currentCalendarDate.month()).date(dailyTotal.dayNumber);
      const isCurrentDate = isCurrentMonth && dailyTotalDate.date() === currentDate.date();
      const isNextDay = isCurrentMonth && currentDate.clone().add(1, 'day').date() === dailyTotalDate.date();
      const isExpaneded = expandedCalendarItem === dailyTotalDate.date();
      const hasBalance = dailyTotal.balance.uyu || dailyTotal.balance.usd;
      const isFutureDate = !isCurrentDate && !isNextDay;

      if (hasBalance) {
        const bodyItem = (
          <Accordion
            key={i}
            expanded={isExpaneded}
            onChange={_handleCalendarItemOnSelect(dailyTotalDate.date(), isExpaneded)}
            className={classes.calendarBodyListItemWrapper}
          >
            <AccordionSummary
              className={clsx(
                classes.calendarBodyListItem,
                isCurrentDate
                  ? classes.calendarBodyListItemToday
                  : isNextDay ? classes.calendarBodyListItemTomorrow : null,
                isFutureDate ? classes.calendarBodyListItemFuture : null,
                isExpaneded ? classes.calendarBodyListItemExpanded : null
              )}
            >
              <div className={classes.calendarBodyListItemDate}>
                {Utils.capitalize(dailyTotalDate.format('dddd'))}, {dailyTotalDate.date()}
                {isCurrentDate ? <span>({i18n.get('Calendar.Today')})</span> : null}
                {isNextDay ? <span>({i18n.get('Calendar.Tomorrow')})</span> : null}
              </div>
              <div className={classes.calendarBodyListItemAmount}>
                {dailyTotal.balance.uyu ? (
                  <span>
                    {i18n.get('Currency.UYU')} {Utils.formatCurrency(dailyTotal.balance.uyu)}
                  </span>
                ) : null}
                {dailyTotal.balance.usd ? (
                  <span>
                    {i18n.get('Currency.USD')} {Utils.formatCurrency(dailyTotal.balance.usd)}
                  </span>
                ) : null}
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.calendarBodyListItemDetailsWrapper}>
              {hasBalance ? (
                <React.Fragment>
                  <div className={classes.calendarBodyListItemDetails}>
                    <div className={classes.calendarBodyListItemDate}>{i18n.get('Calendar.CalendarListSale')}</div>
                    <div className={classes.calendarBodyListItemAmount}>
                      <span>
                        {i18n.get('Currency.UYU')} {Utils.formatCurrency(dailyTotal.positiveBalance.uyu || 0)}
                      </span>
                      <span>
                        {i18n.get('Currency.USD')} {Utils.formatCurrency(dailyTotal.positiveBalance.usd || 0)}
                      </span>
                    </div>
                  </div>
                  <div className={classes.calendarBodyListItemDetails}>
                    <div className={classes.calendarBodyListItemDate}>{i18n.get('Calendar.CalendarListCharges')}</div>
                    <div className={classes.calendarBodyListItemAmount}>
                      <span>
                        {i18n.get('Currency.UYU')} {Utils.formatCurrency(dailyTotal.negativeBalance.uyu || 0)}
                      </span>
                      <span>
                        {i18n.get('Currency.USD')} {Utils.formatCurrency(dailyTotal.negativeBalance.usd || 0)}
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
              <div className={classes.calendarBodyListItemLinkWrapper}>
                <Link href="#" onClick={onCalendarItemClick(dailyTotalDate.format('YYYYMMDD'))}>
                  {i18n.get('Calendar.ButtonDetails')}
                </Link>
              </div>
            </AccordionDetails>
          </Accordion>
        );

        body.push(bodyItem);
      }
    }

    return body;
  };

  if (displayOption === 'calendar') {
    return (
      <React.Fragment>
        {renderCalendarNavigation()}
        <Hidden smDown>
          {renderCalendarHeader()}
          {renderCalendarBody()}
        </Hidden>
        <Hidden mdUp>{renderCalendarBodyAsList()}</Hidden>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {renderCalendarNavigation()}
        {renderCalendarBodyAsList()}
      </React.Fragment>
    );
  }
};

export default Calendar;
