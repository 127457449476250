import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  dialog: {
    boxShadow: 'none',
    width: '65%',
    maxWidth: '65%',
    minHeight: 500,

    [theme.breakpoints.down('sm')]: {
      width: '95%',
      maxWidth: '95%',
      margin: 0,
      minHeight: '50%',
    },
  },
  dialogTitleWrapper: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,

    '& > h2': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
  },
  dialogTitleClose: {
    position: 'absolute',
    right: 0,
    height: 23,
    cursor: 'pointer',
  },
  dialogTitleCloseIcon: {
    color: Colors.rgb.black_light,
  },
  dialogContentWrapper: {
    padding: 0,
  },
  dialogActionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  },
  checkboxWrapper: {
    margin: 0,
  },
  checkbox: {
    padding: 0,
    marginRight: theme.spacing(2),
  },
  checkboxLabel: {
    fontSize: theme.fontSize.M,
    marginTop: -3,
  },
}));

export default makeClasses;
