import React, { createRef } from 'react';
import { useState, useRef, useEffect } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';

import { Language, Utils } from '../../utils';
import { user as userActions } from '../../actions';
import { Page, Button, Confirm } from '../../components';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';

import makeClasses from './styles';

import appLogo from '../../static/images/logo.svg';
import HelpIcon from '../../static/images/icons/help.svg';
import clsx from 'clsx';
import { Policies } from '../../utils/Policies';
import ROUTES from '../../routes';

const LoginScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);

  const CIPrefix = 'UY.CI';
  const RUTPrefix = 'UY.RUT';

  const { loading, languageTexts } = useSelector(state => ({
    loading: state.user.loading,
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const [ rut, setRut ] = useState('');
  const [ ci, setCI ] = useState('');
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ temporaryCognitoUser, setTemporaryCognitoUser ] = useState(null);
  const [ selectedLoginOption, setSelectedLoginOption ] = useState(
    searchParams.option === 'ci' ? 'secondary' : 'primary'
  );
  const [ selectedTab, setSelectedTab ] = useState('root');
  const [ showHelp, setShowHelp ] = useState(false);
  const verificationCodeRefs = useRef(new Array(6).fill('').map(() => createRef()));
  const [ verificationCode, setVerificationCode ] = useState(new Array(6).fill(''));
  const [ askForVerificationCodeRootUser, setAskForVerificationCodeRootUser ] = useState(false);
  const [ askForVerificationCodeSecondaryRootUser, setAskForVerificationCodeSecondaryRootUser ] = useState(false);
  const canValidateVerificationCode = verificationCode.filter(code => /[0-9]/.test(code)).length;

  useEffect(() => _validateVerificationCode(), [ canValidateVerificationCode ]);

  const setActiveTab = tabId => () => {
    setSelectedTab(tabId);
  };

  const renderPrimaryLoginOptionRootUser = () => {
    if (askForVerificationCodeRootUser) {
      return renderVerificationCodeForm(true);
    }

    const hasValidRut = Utils.inputs.rut.regex.test(rut);

    return (
      <React.Fragment>
        <div className={clsx(classes.formContent, classes.formContentWithTab)}>
          <div className={classes.formTitleContainer}>
            <div className={classes.formTitle}>{i18n.get('Login.UserFormRootTitle')}</div>
            <div className={classes.formText}>{i18n.get('Login.UserFormRootText')}</div>
          </div>
          <form layout="vertical" onSubmit={_submitPrimaryRootForm} className={classes.form}>
            <TextField
              placeholder={i18n.get('Login.LoginInputTitle')}
              className={classes.formInput}
              onChange={e => setRut(e.target.value)}
              classes={{ root: classes.formInput }}
              variant="outlined"
            />
          </form>
        </div>
        <div className={classes.formSubmitContainer}>
          <Button className={classes.formSubmitButton} variant="secondary" onClick={_goBack} disabled={true}>
            {i18n.get('Login.ReturnButton')}
          </Button>
          <div className={classes.formSubmitButtonSeparator} />
          <Button
            className={classes.formSubmitButton}
            onClick={_submitPrimaryRootForm}
            disabled={loading || !hasValidRut}
          >
            {i18n.get('Login.NextButton')}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const renderPrimaryLoginOptionAdditionalUser = () => {
    const hasValidRut = Utils.inputs.rut.regex.test(rut);
    const hasValidUsername = Utils.inputs.username.regex.test(username);
    const hasValidPassword = Utils.inputs.password.regex.test(password);

    return (
      <React.Fragment>
        <div className={clsx(classes.formContent, classes.formContentWithTab)}>
          <div className={classes.formTitleContainer}>
            <div className={classes.formTitle}>{i18n.get('Login.UserFormAdditionalTitle')}</div>
            <div className={classes.formText}>{i18n.get('Login.UserFormAdditionalText')}</div>
          </div>
          <form layout="vertical" onSubmit={_submitAdditionalUserForm} className={classes.form}>
            <TextField
              placeholder={i18n.get('Login.LoginInputTitle')}
              className={classes.formInput}
              onChange={e => setRut(e.target.value)}
              classes={{ root: classes.formInput }}
              variant="outlined"
            />
            <div className={classes.formInputSeparator} />
            <div className={classes.formInputSeparator} />
            <TextField
              placeholder={i18n.get('Login.UsernameInputTitle')}
              className={classes.formInput}
              onChange={e => setUsername(e.target.value)}
              classes={{ root: classes.formInput }}
              variant="outlined"
            />
            <div className={classes.formInputSeparator} />
            <TextField
              placeholder={i18n.get('Login.PasswordInputTitle')}
              className={classes.formInput}
              onChange={e => setPassword(e.target.value)}
              classes={{ root: classes.formInput }}
              variant="outlined"
              type="password"
            />
          </form>
        </div>
        <div className={classes.formSubmitContainer}>
          <Button className={classes.formSubmitButton} variant="secondary" onClick={_goBack} disabled>
            {i18n.get('Login.ReturnButton')}
          </Button>
          <div className={classes.formSubmitButtonSeparator} />
          <Button
            className={classes.formSubmitButton}
            onClick={_submitAdditionalUserForm}
            disabled={loading || !hasValidRut || !hasValidUsername || !hasValidPassword}
          >
            {i18n.get('Login.LoginButton')}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const renderSecondaryLoginOptionRootUser = () => {
    if (askForVerificationCodeSecondaryRootUser) {
      return renderVerificationCodeForm(false);
    }

    const hasValidCI = Utils.inputs.ci.regex.test(ci);

    return (
      <React.Fragment>
        <div className={classes.formContent}>
          <div className={classes.formTitleContainer}>
            <div className={classes.formTitle}>{i18n.get('Login.UserFormSecondaryRootTitle')}</div>
            <div className={classes.formText}>{i18n.get('Login.UserFormSecondaryRootText')}</div>
          </div>
          <form layout="vertical" onSubmit={_submitSecondaryRootForm} className={classes.form}>
            <TextField
              placeholder={i18n.get('Login.LoginInputCITitle')}
              className={classes.formInput}
              onChange={e => setCI(e.target.value)}
              classes={{ root: classes.formInput }}
              variant="outlined"
            />
          </form>
        </div>
        <div className={classes.formSubmitContainer}>
          <Button className={classes.formSubmitButton} variant="secondary" onClick={_goBack} disabled>
            {i18n.get('Login.ReturnButton')}
          </Button>
          <div className={classes.formSubmitButtonSeparator} />
          <Button
            className={classes.formSubmitButton}
            onClick={_submitSecondaryRootForm}
            disabled={loading || !hasValidCI}
          >
            {i18n.get('Login.LoginButton')}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const renderAuthenticationForm = () => {
    if (selectedLoginOption === 'primary') {
      return (
        <div className={classes.formContainer}>
          <div className={classes.formTabsContainer}>
            <div
              className={clsx(classes.formTab, selectedTab === 'root' ? classes.formTabSelected : null)}
              onClick={setActiveTab('root')}
            >
              {i18n.get('Login.UserRootFromTabOptionRoot')}
              <div className={classes.formTabIndicator} />
            </div>
            <div
              className={clsx(classes.formTab, selectedTab === 'additional' ? classes.formTabSelected : null)}
              onClick={setActiveTab('additional')}
            >
              {i18n.get('Login.UserRootFromTabOptionAdditional')}
              <div className={classes.formTabIndicator} />
            </div>
          </div>
          {selectedTab === 'root' ? renderPrimaryLoginOptionRootUser() : null}
          {selectedTab === 'additional' ? renderPrimaryLoginOptionAdditionalUser() : null}
        </div>
      );
    }

    if (selectedLoginOption === 'secondary') {
      return <div className={classes.formContainer}>{renderSecondaryLoginOptionRootUser()}</div>;
    }

    return null;
  };

  const renderVerificationCodeForm = rootUser => {
    const hasValidVerificationCode = verificationCode.filter(code => /[0-9]/.test(code)).length === 6;

    return (
      <React.Fragment>
        <div className={clsx(classes.formContent, rootUser ? classes.formContentWithTab : null)}>
          <div className={classes.formTitleContainer}>
            <div className={classes.formTitle}>{i18n.get('Login.CodeTitle')}</div>
            <div className={classes.formText}>{i18n.get('Login.CodeInfo')}</div>
          </div>
          <form
            layout="vertical"
            onSubmit={_submitPrimaryRootForm}
            className={clsx(classes.form, classes.formVerificationCode)}
          >
            <Grid container spacing={1}>
              {verificationCode.map((code, index) => (
                <Grid key={index} item xs={2} sm={2} md={2}>
                  <Input
                    value={code}
                    onBlur={_handleVerificationCodeOnBlur(index)}
                    onChange={_handleVerificationCodeOnChange(index)}
                    inputProps={{ className: classes.verificationCodeInput, inputMode: 'numeric' }}
                    inputRef={verificationCodeRefs.current[index]}
                    onPaste={_handleVerificationCondeOnPaste}
                    disableUnderline={true}
                  />
                </Grid>
              ))}
            </Grid>
          </form>
        </div>
        <div className={clsx(classes.formSubmitContainer, classes.codeFormSubmitContainer)}>
          <Button
            className={classes.formSubmitButton}
            variant="secondary"
            onClick={_resetForm(rootUser)}
            disabled={loading}
          >
            {i18n.get('Login.ReturnButton')}
          </Button>
          <div className={classes.formSubmitButtonSeparator} />
          <Button
            className={classes.formSubmitButton}
            onClick={_validateVerificationCode}
            disabled={loading || !hasValidVerificationCode}
          >
            {i18n.get('Login.CodeButton')}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const _submitPrimaryRootForm = e => {
    e && e.preventDefault();

    const hasValidRut = Utils.inputs.rut.regex.test(rut);

    if (!hasValidRut) {
      return alert(i18n.get('Login.LoginInvalidRut'));
    }

    dispatch(
      userActions.login(`${RUTPrefix}.${rut.trim()}`, cognitoUser => {
        setTemporaryCognitoUser(cognitoUser);
        setAskForVerificationCodeRootUser(true);
      })
    );
  };

  const _submitAdditionalUserForm = e => {
    e && e.preventDefault();

    const hasValidRut = Utils.inputs.rut.regex.test(rut) && Utils.checkRUT(rut);
    const hasValidUsername = Utils.inputs.username.regex.test(username);
    const hasValidPassword = Utils.inputs.password.regex.test(password);

    if (!hasValidRut) {
      return alert(i18n.get('Login.LoginInvalidRut'));
    }
    if (!hasValidUsername) {
      return alert(i18n.get('Login.LoginInvalidUsername'));
    }
    if (!hasValidPassword) {
      return alert(i18n.get('Login.LoginInvalidPassword'));
    }

    dispatch(
      userActions.loginAdditional(`${RUTPrefix}.${rut.trim()}`, username, password, cognitoUser => {
        dispatch(
          userActions.verifyAccountAdditional(
            cognitoUser,
            policies => {
              if (policies.includes(Policies.types.HOME)) {
                dispatch(push(ROUTES.HOME.path));
              } else if (policies.includes(Policies.types.ACCOUNT)) {
                dispatch(push(ROUTES.ACCOUNT.path));
              } else if (policies.includes(Policies.types.ACTIVITY)) {
                dispatch(push(ROUTES.ACTIVITY.path));
              } else if (policies.includes(Policies.types.HANDY_LINK)) {
                dispatch(push(ROUTES.PAYMENT_LINK.path));
              }
            },
            () => {
              setVerificationCode(new Array(6).fill(''));
              verificationCodeRefs.current[0].current.focus();
            }
          )
        );
      })
    );
  };

  const _submitSecondaryRootForm = e => {
    e && e.preventDefault();

    const hasValidCI = Utils.inputs.ci.regex.test(ci);

    if (!hasValidCI) {
      return alert(i18n.get('Login.LoginInvalidCI'));
    }

    dispatch(
      userActions.login(`${CIPrefix}.${ci.trim()}`, cognitoUser => {
        setTemporaryCognitoUser(cognitoUser);
        setAskForVerificationCodeSecondaryRootUser(true);
      })
    );
  };

  const _resetForm = rootUser => () => {
    setVerificationCode(new Array(6).fill(''));
    if (rootUser && askForVerificationCodeRootUser) {
      setAskForVerificationCodeRootUser(false);
      setRut('');
    }
    if (!rootUser && askForVerificationCodeSecondaryRootUser) {
      setAskForVerificationCodeSecondaryRootUser(false);
      setCI('');
    }
  };

  const _goBack = () => {
    setSelectedLoginOption('');
  };

  const _validateVerificationCode = () => {
    const hasValidVerificationCode = verificationCode.filter(code => /[0-9]/.test(code)).length === 6;

    if (canValidateVerificationCode && hasValidVerificationCode) {
      dispatch(
        userActions.verifyAccount(temporaryCognitoUser, verificationCode.join(''), policies => {
          if (policies.includes(Policies.types.HOME)) {
            dispatch(push(ROUTES.HOME.path));
          } else if (policies.includes(Policies.types.ACCOUNT)) {
            dispatch(push(ROUTES.ACCOUNT.path));
          } else if (policies.includes(Policies.types.ACTIVITY)) {
            dispatch(push(ROUTES.ACTIVITY.path));
          } else if (policies.includes(Policies.types.HANDY_LINK)) {
            dispatch(push(ROUTES.PAYMENT_LINK.path));
          }
        })
      );
    }
  };

  const _handleVerificationCodeOnBlur = index => e => {
    if (e.target.value.length > 0 && !/[0-9]/.test(e.target.value)) {
      const newVerificationCode = verificationCode.slice();
      newVerificationCode[index] = '';
      setVerificationCode(newVerificationCode);
      verificationCodeRefs.current[index].current.focus();
    }
  };

  const _handleVerificationCodeOnChange = index => e => {
    if (e.target.value.length === 0) {
      const newVerificationCode = verificationCode.slice();
      newVerificationCode[index] = '';
      return setVerificationCode(newVerificationCode);
    }

    if (!/[0-9]/.test(e.target.value)) {
      return;
    }

    const newVerificationCode = verificationCode.slice();
    newVerificationCode[index] = e.target.value.substring(0, 1);
    setVerificationCode(newVerificationCode);

    if (index + 1 < 6) {
      verificationCodeRefs.current[index + 1].current.focus();
    } else if (index === 5) {
      setVerificationCode(newVerificationCode);
    }
  };

  const _handleVerificationCondeOnPaste = e => {
    const pastedText = e.clipboardData.getData('text').trim();

    if (!/[0-9]{6}/.test(pastedText)) {
      return alert(i18n.get('Login.CodeInvalidCode'));
    }

    const newVerificationCode = verificationCode.slice();
    for (let i = 0, l = 6; i < l; i += 1) {
      newVerificationCode[i] = pastedText[i];
    }

    setVerificationCode(newVerificationCode);
  };

  const toggleShowHelp = () => {
    setShowHelp(!showHelp);
  };

  return (
    <Page
      withHeader={false}
      withSideMenu={false}
      withBanner={false}
      withBoxBanner={false}
      showBranchWithNoPaymentMethodWarning={false}
    >
      <div className={classes.container}>
        <div className={classes.contentWrapper}>
          <div className={classes.brandContainer}>
            <img src={appLogo} className={classes.brandLogo} alt="Handy Logo" />
            <div className={classes.brandSeparator} />
            <div className={classes.brandTitle}>{i18n.get('Login.PageTitle')}</div>
          </div>
          <div className={classes.loginOptionsContainer}>
            <div
              className={clsx(
                classes.loginOption,
                selectedLoginOption === 'primary' ? classes.loginOptionSelected : null
              )}
              onClick={() => setSelectedLoginOption('primary')}
            >
              <div
                className={clsx(
                  classes.loginOptionCheckbox,
                  selectedLoginOption === 'primary' ? classes.loginOptionCheckboxSelected : null
                )}
              >
                {selectedLoginOption === 'primary' ? (
                  <div className={classes.loginOptionCheckboxSelectedIndicator} />
                ) : null}
              </div>
              <div className={classes.loginOptionTitle}>{i18n.get('Login.UserBoxRootTitle')}</div>
              <div className={classes.loginOptionText}>{i18n.get('Login.UserBoxRootText')}</div>
            </div>
            <div
              className={clsx(
                classes.loginOption,
                selectedLoginOption === 'secondary' ? classes.loginOptionSelected : null
              )}
              onClick={() => setSelectedLoginOption('secondary')}
            >
              <div
                className={clsx(
                  classes.loginOptionCheckbox,
                  selectedLoginOption === 'secondary' ? classes.loginOptionCheckboxSelected : null
                )}
              >
                {selectedLoginOption === 'secondary' ? (
                  <div className={classes.loginOptionCheckboxSelectedIndicator} />
                ) : null}
              </div>
              <div className={classes.loginOptionTitle}>{i18n.get('Login.UserBoxUserTitle')}</div>
              <div className={classes.loginOptionText}>{i18n.get('Login.UserBoxUserText')}</div>
            </div>
          </div>
        </div>
        {selectedLoginOption !== '' ? (
          <div className={classes.contentContainer}>{renderAuthenticationForm()}</div>
        ) : null}
        <div className={classes.helpContainer} onClick={toggleShowHelp}>
          {i18n.get('Login.UserHelpTitle')}
          <img src={HelpIcon} className={classes.helpIcon} alt="ayuda" />
        </div>
      </div>
      <Confirm
        open={showHelp}
        onClose={toggleShowHelp}
        title={i18n.get('Login.UserHelpDialogTitle')}
        text={i18n.get('Login.UserHelpDialogText')}
        confirmText={i18n.get('Login.UserHelpDialogButton')}
        onConfirm={toggleShowHelp}
      />
    </Page>
  );
};

LoginScreen.id = 'com.Handy.Login';

export default LoginScreen;
