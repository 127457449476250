import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../../utils';

const makeClasses = makeStyles((theme) => ({
  formWrapper: {
    paddingBottom: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  formInput: {
    width: '100%',

    '& > label': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentTextSoft,
      fontSize: theme.fontSize.L,
    },

    '& > div:before': {
      borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,
    },
    '& > div:after': {
      borderBottom: `1px solid ${Colors.rgb.primary}`,
    },
    '& > div:hover': {
      '&:before': {
        borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted} !important`,
      },
    },

    '& input': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.L,
    },
  },
  formInputSelect: {
    padding: '0 !important',
    width: '100%',

    '&:before': {
      borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted}`,
    },
    '&:after': {
      borderBottom: `1px solid ${Colors.rgb.primary}`,
    },
    '&:hover': {
      '&:before': {
        borderBottom: `1px solid ${Colors.rgb.contentLineHighlighted} !important`,
      },
    },

    '& > div': {
      fontFamily: theme.fontFamily.regular,
      color: Colors.rgb.contentText,
      fontSize: theme.fontSize.L,
    },
  },
  formSeparator: {
    height: theme.spacing(1),
  },
  contentActionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    borderTop: `1px solid ${Colors.rgb.contentLineSoft}`,

    '& > button:last-child': {
      marginRight: 0,
    },
  },
  contentActionSeparator: {
    width: theme.spacing(3),
  },
  //
  inputFile: {
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentText,
    fontSize: theme.fontSize.L,
    borderBottom: `1px solid ${Colors.rgb.contentLine}`,
    paddingBottom: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& > label': {
      position: 'relative',
    },

    '& > label > div': {
      color: Colors.rgb.contentTextSoft,
      fontSize: theme.fontSize.S,
    },

    '& > input[type="file"]': {
      height: 0,
      position: 'absolute',
    },
  },
  inputFileIcon: {
    position: 'absolute',
    marginTop: 20,
    right: 0,
    top: 0,
  },
}));

export default makeClasses;
