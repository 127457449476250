import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import qs from 'query-string';

import ROUTES from '../../../routes';
import {
  Box,
  BoxMenu,
  BoxSeparator,
  BranchesSelector,
  Button,
  Confirm,
  ContentBox,
  FiltersContainer,
  FiltersDateSelector,
  FiltersSelector,
  FiltersSeparator,
  ForbiddenSection,
  Page,
  PaginationSimple,
  TitleBox,
} from '../../../components';
import { business as businessActions } from '../../../actions';
import { Language, Utils } from '../../../utils';

import ListItemText from '@material-ui/core/ListItemText';
import {
  Add as AddIcon,
  Visibility as VisisbilityIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import MoreVerticalIcon from '../../../static/images/icons/more_vertical.svg';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import makeClasses from './styles';
import { IconButton, ListItemIcon, Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { Alert, AlertTitle } from '@material-ui/lab';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { Policies } from '../../../utils/Policies';
import moment from 'moment';
import { CUSTOM_FILTER, getDateRangeForFilter } from '../../../components/FiltersDateSelector';
import { toISOString } from '../../../utils/Utils';

const STATUS_FILTER_OPTIONS = [
  { id: 'ACTIVE', name: 'ManageSubscriptions.ValueStatusActive' },
  { id: 'INACTIVE', name: 'ManageSubscriptions.ValueStatusInactive' },
];

const ManageSubscriptionsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [subscriptions, setSubscriptions] = useState({});

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [showCancelSubscriptionDialog, setShowCancelSubscriptionDialog] = useState(false);
  const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);
  const [paginationPage, setPaginationPage] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(searchParams.bid || 'all');
  const [selectedProvider, setSelectedProvider] = useState(searchParams.pid || 'all');
  const [selectedService, setSelectedService] = useState(searchParams.sid || 'all');
  const [subscriptionProvidersOptions, setSubscriptionProvidersOptions] = useState([]);
  const [subscriptionServicesOptions, setSubscriptionServicesOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(searchParams.fid ? parseInt(searchParams.fid) : 0);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState(
    parseInt(searchParams.fid) === CUSTOM_FILTER ? { startDate: searchParams.fsd, endDate: searchParams.fed } : {}
  );
  const [selectedStatus, setSelectedStatus] = useState(searchParams.tid ? searchParams.tid : 'all');
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const { policies, languageTexts, branches } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
    branches: state.business.branches || [],
  }));
  const i18n = Language(languageTexts);
  const hasSubscriptions = subscriptions && subscriptions.elements && subscriptions.elements.length > 0;
  const canExport = policies.includes(Policies.types.SUBSCRIBER) && policies.includes(Policies.types.SUPER_READ);
  const canManage = policies.includes(Policies.types.SUBSCRIBER_WRITE);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (policies && policies.includes(Policies.types.SUBSCRIBER)) {
      loadSubscriptions();
    } else {
      setForbbidenSection(true);
    }
  }, [
    policies,
    paginationPage,
    selectedBranch,
    selectedFilter,
    selectedCustomFilter,
    selectedProvider,
    selectedService,
    selectedStatus,
  ]);

  const loadSubscriptions = () => {
    if (!filtersLoaded) {
      loadFiltersData();
    }

    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.getSubscriptions(
        paginationPage,
        selectedBranch !== 'all' ? selectedBranch : null,
        selectedProvider !== 'all' ? selectedProvider : null,
        selectedService !== 'all' ? selectedService : null,
        startDate,
        endDate,
        selectedStatus === 'ACTIVE' ? true : selectedStatus === 'INACTIVE' ? false : null,
        (data) => {
          if (data) {
            setSubscriptions(data);
          }
        }
      )
    );
  };

  const loadFiltersData = () => {
    dispatch(
      businessActions.getSubscriptionProviders((data) => {
        if (data) {
          const options = data.elements
            ? data.elements.filter((e) => e).map((e) => ({ id: e.traceId, name: e.name }))
            : [];
          setSubscriptionProvidersOptions(options);
        }
      })
    );
    dispatch(
      businessActions.getSubscriptionServices((data) => {
        if (data) {
          const options = data.elements
            ? data.elements.filter((e) => e).map((e) => ({ id: e.traceId, name: e.name }))
            : [];
          setSubscriptionServicesOptions(options);
        }
      })
    );
    setFiltersLoaded(true);
  };

  const handleOnClickNewSubscription = () => {
    dispatch(push(ROUTES.MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS_ADD.path));
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const handleOnViewSubscription = (subscription) => () => {
    toggleMenu()();
    dispatch(push(ROUTES.MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS_VIEW.path.replace(':traceId', subscription.traceId)));
  };

  const handleOnEditSubscription = (subscription) => () => {
    toggleMenu()();
    dispatch(push(ROUTES.MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS_EDIT.path.replace(':traceId', subscription.traceId)));
  };

  const handleOnCancelSubscription = (subscription) => () => {
    toggleMenu()();
    toggleCancelSubscriptionDialog();
    setSubscriptionToCancel(subscription.traceId);
  };

  const toggleCancelSubscriptionDialog = () => {
    setShowCancelSubscriptionDialog(!showCancelSubscriptionDialog);
  };

  const confirmCancelSubscription = () => {
    toggleCancelSubscriptionDialog();
    dispatch(
      businessActions.cancelSubscription(subscriptionToCancel, (result) => {
        if (result) {
          toast.success(i18n.get('ManageSubscriptions.SuccessCancelMessage'));
          loadSubscriptions();
        } else {
          toast.error(i18n.get('ManageSubscriptions.ErrorCancelMessage'));
        }
      })
    );
  };

  const handleOnFilterSelected = (filter, startDate, endDate) => {
    setSelectedFilter(filter);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnPaginationPressNext = () => {
    setPaginationPage(paginationPage + 1);
    handleOnPaginationChange(paginationPage + 1);
  };

  const handleOnPaginationPressPrevious = () => {
    setPaginationPage(paginationPage - 1);
    handleOnPaginationChange(paginationPage - 1);
  };

  const handleOnBranchSelected = (branch) => {
    setSelectedBranch(branch);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.bid = branch;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnProviderSelected = (provider) => {
    setSelectedProvider(provider);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.pid = provider;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnServiceSelected = (service) => {
    setSelectedService(service);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.sid = service;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnStatusSelected = (status) => {
    setSelectedStatus(status);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.tid = status;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnExport = () => {
    if (selectedFilter === 0) {
      toast.error(i18n.get('ManageSubscriptions.ExportErrorNoDateFilterSelected'));
      return;
    }

    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.exportSubscriptions(
        selectedBranch !== 'all' ? selectedBranch : null,
        paginationPage,
        startDate,
        endDate,
        selectedProvider !== 'all' ? selectedProvider : null,
        selectedService !== 'all' ? selectedService : null,
        selectedStatus === 'ACTIVE' ? true : selectedStatus === 'INACTIVE' ? false : null,
        (file) =>
          Utils.downloadFile(
            file,
            'PagoProveedores_Suscripciones_' +
              dateFilter.startDate.format(i18n.get('ExportDateFormat')) +
              '_' +
              dateFilter.endDate.format(i18n.get('ExportDateFormat')) +
              '.xlsx'
          )
      )
    );
  };

  const handleOnMenuItemClick = (item) => {
    dispatch(push(item.path));
  };

  const renderBranchesSelector = () => (
    <BranchesSelector branches={branches} selectedBranch={selectedBranch} onBranchSelected={handleOnBranchSelected} />
  );

  const renderFiltersDateSelector = () => (
    <FiltersDateSelector
      selectedFilter={selectedFilter}
      startDate={selectedCustomFilter.startDate}
      endDate={selectedCustomFilter.endDate}
      onFilterSelected={handleOnFilterSelected}
      align="right"
      dateFormat={i18n.get('DateFormat', false)}
      showNoFilterOption
    />
  );

  const renderProvidersSelector = () => {
    return (
      <FiltersSelector
        options={subscriptionProvidersOptions}
        selected={selectedProvider}
        onSelected={handleOnProviderSelected}
        defaultOption={i18n.get('ManageSubscriptions.FilterProvidersDefaultOption')}
      />
    );
  };

  const renderServicesSelector = () => {
    return (
      <FiltersSelector
        options={subscriptionServicesOptions}
        selected={selectedService}
        onSelected={handleOnServiceSelected}
        defaultOption={i18n.get('ManageSubscriptions.FilterServicesDefaultOption')}
      />
    );
  };

  const renderStatusesSelector = () => {
    return (
      <FiltersSelector
        options={STATUS_FILTER_OPTIONS.map((s) => ({ ...s, name: i18n.get(s.name) }))}
        selected={selectedStatus}
        onSelected={handleOnStatusSelected}
        defaultOption={i18n.get('ManageSubscriptions.FilterStatusesDefaultOption')}
      />
    );
  };

  const renderSubscriptions = () => {
    if (isSmallScreen) {
      return (
        <div>
          {subscriptions.elements.map((subscription, index) => (
            <React.Fragment key={index}>
              {index === 0 ? null : <div className={classes.boxItemSeparator} />}
              <div className={clsx(classes.boxContainer, index % 2 === 0 ? classes.boxContainerEven : null)}>
                <div className={classes.boxItemTitle}>
                  {subscription.productName} - {subscription.providerName}
                </div>
                {subscription.description ? (
                  <div className={classes.boxItemDescription}>
                    <span>{subscription.description}</span>
                  </div>
                ) : null}
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ManageSubscriptions.LabelBranch')}:</span>
                  <span>{subscription.branchName || '-'}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ManageSubscriptions.LabelDate')}:</span>
                  <span>{moment(subscription.createdAt).format(i18n.get('DateFormat'))}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ManageSubscriptions.LabelStatus')}:</span>
                  <span
                    className={clsx(
                      classes.subscriptionItemStatus,
                      subscription.isActive ? classes.subscriptionItemStatusActive : null
                    )}
                  >
                    {subscription.isActive
                      ? i18n.get('ManageSubscriptions.ValueStatusActive')
                      : i18n.get('ManageSubscriptions.ValueStatusInactive')}
                  </span>
                </div>
                <div className={classes.listItemActions}>
                  <IconButton onClick={toggleMenu(index)}>
                    <img src={MoreVerticalIcon} alt="More" />
                  </IconButton>
                </div>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={menuOpenIndex === index}
                  onClose={toggleMenu()}
                  className={classes.listItemMenu}
                >
                  <MenuItem onClick={handleOnViewSubscription(subscription)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ManageSubscriptions.ActionViewDetails')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  {canManage ? (
                    <MenuItem onClick={handleOnEditSubscription(subscription)} className={classes.listItemMenuItem}>
                      <ListItemIcon className={classes.menuItemIcon}>
                        <EditIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.get('ManageSubscriptions.ActionEdit')}
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                  {subscription.isActive && subscription.canMerchantUnsubscribe && canManage ? (
                    <MenuItem onClick={handleOnCancelSubscription(subscription)} className={classes.listItemMenuItem}>
                      <ListItemIcon className={classes.menuItemIcon}>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.get('ManageSubscriptions.ActionCancel')}
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                </Menu>
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }

    return subscriptions.elements.map((subscription, index) => (
      <React.Fragment key={subscription.traceId}>
        {index !== 0 ? <div className={classes.subscriptionItemSeparator} /> : null}

        <div className={classes.subscriptionItemWrapper}>
          <div className={classes.subscriptionItemDetails}>
            <div>
              {subscription.productName} <span>|</span> {subscription.providerName}{' '}
              {subscription.description ? <span>| {subscription.description}</span> : null}
            </div>
            <div className={classes.subscriptionItemExtraDetails}>
              <div>
                <span>{i18n.get('ManageSubscriptions.LabelBranch')}:</span> {subscription.branchName || '-'}
              </div>
              <div>|</div>
              <div>
                <span>{i18n.get('ManageSubscriptions.LabelDate')}:</span>
                {moment(subscription.createdAt).format(i18n.get('DateFormat'))}
              </div>
              <div>|</div>
              <div>
                <span>{i18n.get('ManageSubscriptions.LabelStatus')}:</span>
                <span
                  className={clsx(
                    classes.subscriptionItemStatus,
                    subscription.isActive ? classes.subscriptionItemStatusActive : null
                  )}
                >
                  {subscription.isActive
                    ? i18n.get('ManageSubscriptions.ValueStatusActive')
                    : i18n.get('ManageSubscriptions.ValueStatusInactive')}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.listItemActions}>
              <IconButton onClick={toggleMenu(index)}>
                <img src={MoreVerticalIcon} alt="More" />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={menuOpenIndex === index}
                onClose={toggleMenu()}
                className={classes.listItemMenu}
              >
                <MenuItem onClick={handleOnViewSubscription(subscription)} className={classes.listItemMenuItem}>
                  <ListItemIcon className={classes.menuItemIcon}>
                    <VisisbilityIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={i18n.get('ManageSubscriptions.ActionViewDetails')}
                    className={classes.menuItemText}
                  />
                </MenuItem>
                {canManage ? (
                  <MenuItem onClick={handleOnEditSubscription(subscription)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ManageSubscriptions.ActionEdit')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                ) : null}
                {subscription.isActive && subscription.canMerchantUnsubscribe && canManage ? (
                  <MenuItem onClick={handleOnCancelSubscription(subscription)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ManageSubscriptions.ActionCancel')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                ) : null}
              </Menu>
            </div>
          </div>
        </div>
      </React.Fragment>
    ));
  };

  const renderExportButton = () => {
    if (!canExport) {
      return null;
    }

    return (
      <Button rightIcon={() => <SaveAltIcon fontSize="small" />} onClick={handleOnExport} disabled={!hasSubscriptions}>
        {!isSmallScreen ? i18n.get('ManageSubscriptions.ExportButton') : null}
      </Button>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ManageSubscriptions.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS_PAYMENTS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ManageSubscriptions.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS_PAYMENTS.id}
    >
      <BoxMenu
        items={[
          { ...ROUTES.MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS, label: i18n.get('ManageSubscriptions.BoxMenu.Item1') },
          { ...ROUTES.MANAGE_PROVIDERS_PAYMENTS_PAYMENTS, label: i18n.get('ManageSubscriptions.BoxMenu.Item2') },
        ]}
        selectedItem={ROUTES.MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS.id}
        onClickItem={handleOnMenuItemClick}
      />
      <TitleBox
        title={i18n.get('ManageSubscriptions.BoxTitle')}
        buttonRight={canManage}
        buttonRightIcon={() => <AddIcon fontSize="small" />}
        buttonRightText={isSmallScreen ? null : i18n.get('ManageSubscriptions.BoxTitleButtonText')}
        buttonRightOnClick={handleOnClickNewSubscription}
      />
      <BoxSeparator />
      <React.Fragment>
        <ContentBox
          title={i18n.get('ManageSubscriptions.BoxFiltersTitle')}
          titleBold
          customRightAction={renderExportButton}
        >
          <FiltersContainer>
            {renderBranchesSelector()}
            <FiltersSeparator />
            {renderProvidersSelector()}
            <FiltersSeparator />
            {renderServicesSelector()}
            <FiltersSeparator />
            {renderStatusesSelector()}
            <FiltersSeparator />
            {renderFiltersDateSelector()}
          </FiltersContainer>
        </ContentBox>
        <BoxSeparator size="small" />
        {hasSubscriptions ? (
          <Box direction="column" top={!isSmallScreen}>
            {renderSubscriptions()}
            {subscriptions && subscriptions.elements && subscriptions.elements.length > 0 ? (
              <PaginationSimple
                currentPage={paginationPage}
                hasMorePages={subscriptions.hasNext}
                onPressNext={handleOnPaginationPressNext}
                onPressPrevious={handleOnPaginationPressPrevious}
              />
            ) : null}
          </Box>
        ) : (
          <Box>
            <div className={classes.noDataMessage}>{i18n.get('ManageSubscriptions.NoSubscriptionsMessage')}</div>
          </Box>
        )}
      </React.Fragment>
      <Confirm
        open={showCancelSubscriptionDialog}
        onClose={toggleCancelSubscriptionDialog}
        title={i18n.get('ManageSubscriptions.CancelSubscriptionDialogTitle')}
        confirmText={i18n.get('ManageSubscriptions.CancelSubscriptionDialogActionConfirm')}
        onConfirm={confirmCancelSubscription}
        cancelText={i18n.get('ManageSubscriptions.CancelSubscriptionDialogActionCancel')}
        onCancel={toggleCancelSubscriptionDialog}
      >
        <Alert severity="error" icon={false} className={clsx(classes.alert, classes.alertDanger)}>
          <div>
            <ErrorOutlineOutlinedIcon className={classes.alertIcon} />
          </div>
          <div>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get('ManageSubscriptions.CancelSubscriptionDialogContentTitle')}
            </AlertTitle>
            {i18n.get('ManageSubscriptions.CancelSubscriptionDialogContentText')}
          </div>
        </Alert>
      </Confirm>
    </Page>
  );
};

ManageSubscriptionsScreen.id = 'com.Handy.ManageSubscriptions';

export default ManageSubscriptionsScreen;
