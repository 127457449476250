import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import makeClasses from './styles';
import { useSelector } from 'react-redux';
import { Constants, Language } from '../../utils';
import Button from '../Button';
import ContentBox from '../ContentBox';
import QRModal from '../QRModal';
import { toast } from 'react-toastify';
import whatsAppIcon from '../../static/images/icons/social_whatsapp.svg';
import telegramIcon from '../../static/images/icons/social_telegram.svg';
import emailIcon from '../../static/images/icons/social_email.svg';
import qrDesktopIcon from '../../static/images/icons/qr-desktop.svg';
import clsx from 'clsx';

const ShareModal = ({ open, onClose, headerTitle, bodyTitle, link, shareText }) => {
  const classes = makeClasses();
  const linkInputRef = useRef(null);

  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);

  const { languageTexts } = useSelector(({ language }) => ({
    languageTexts: language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const _copyLink = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(link)
        toast.success(i18n.get('Components.ShareModal.LinkCopied'));
      } catch (err) {
        console.error('Failed to copy text to clipboard.', err);
      }
    } else {
      linkInputRef.current.focus();
      linkInputRef.current.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          toast.success(i18n.get('Components.ShareModal.LinkCopied'));
        }
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
    }
  };

  const _toggleQRCodeModal = () => {
    setQrCodeModalOpen(!qrCodeModalOpen);
  };

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitleWrapper}>
        <div className={classes.dialogTitle}>{headerTitle || i18n.get('Components.ShareModal.HeaderTitle')}</div>
      </DialogTitle>
      <DialogContent className={classes.dialogContentWrapper}>
        {link ? (
          <div className={classes.contentWrapper}>
            <div className={classes.contentDetails}>
              <ContentBox title={bodyTitle || i18n.get('Components.ShareModal.BodyTitle')}>
                <div className={classes.shareableContentWrapper}>
                  <div className={classes.shareableContent}>
                    <div>{i18n.get('Components.ShareModal.LinkButtonLabel')}</div>
                    <div className={classes.shareableLinkWrapper}>
                      <TextField
                        inputRef={linkInputRef}
                        variant="outlined"
                        value={link}
                        contentEditable={false}
                        classes={{ root: classes.shareableLinkInput }}
                      />
                      <Button
                        className={classes.shareableLinkButton}
                        variant="contained"
                        color="primary"
                        onClick={_copyLink}
                      >
                        {i18n.get('Components.ShareModal.CopyLinkButton')}
                      </Button>
                    </div>
                  </div>
                  <div className={classes.shareableContent}>
                    <div>{i18n.get('Components.ShareModal.QRTitle')}</div>
                    <div className={clsx(classes.socialAction, classes.shareAction)} onClick={_toggleQRCodeModal}>
                      <img src={qrDesktopIcon} alt="qrIcon" style={{ height: 40, marginTop: -7 }} />
                    </div>
                  </div>
                  <div className={classes.shareableContent}>
                    <div>{i18n.get('Components.ShareModal.ShareTextTitle')}</div>
                    <div style={{ marginTop: -3 }}>
                      <a
                        href={Constants.SHAREABLE_WHATSAPP_LINK.replace(
                          '{text}',
                          shareText || i18n.get('Components.ShareModal.ShareText')
                        ).replace('{link}', link)}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.socialAction}
                      >
                        <img src={whatsAppIcon} alt="whatsappIcon" />
                      </a>
                      <a
                        href={Constants.SHAREABLE_TELEGRAM_LINK.replace('{link}', link).replace(
                          '{text}',
                          shareText || i18n.get('Components.ShareModal.ShareText')
                        )}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.socialAction}
                      >
                        <img src={telegramIcon} alt="telegramIcon" />
                      </a>
                      <a
                        href={Constants.SHAREABLE_EMAIL_LINK.replace(
                          '{text}',
                          shareText || i18n.get('Components.ShareModal.ShareText')
                        ).replace('{link}', link)}
                        className={classes.socialAction}
                      >
                        <img src={emailIcon} alt="emailIcon" />
                      </a>
                    </div>
                  </div>
                </div>
              </ContentBox>
            </div>
          </div>
        ) : null}
        {link ? (
          <QRModal open={qrCodeModalOpen} onClose={_toggleQRCodeModal} data={link} showPriceDisclaimer={false} />
        ) : null}
      </DialogContent>
      <DialogActions className={classes.dialogActionsWrapper}>
        <Button onClick={onClose}>{i18n.get('Components.ShareModal.ButtonClose')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareModal;
