import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';
import ROUTES from '../../routes';
import { Page, TitleBox, BoxSeparator, ContentBox, Button, Confirm, ForbiddenSection } from '../../components';
import { business as businessActions } from '../../actions';
import { Colors, Language, Utils } from '../../utils';
import makeClasses from './styles';
import { Grid, TextField, Input, FormControl, InputLabel, Select, MenuItem, Hidden } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Policies } from '../../utils/Policies';

function LegalIdField(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholder={props.placeholder}
      mask={[ /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/ ]}
      showMask={false}
    />
  );
}

function Last4Field(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholder={props.placeholder}
      mask={[ '*', '*', '*', '*', ' ', '*', '*', '*', '*', ' ', '*', '*', '*', '*', ' ', /\d/, /\d/, /\d/, /\d/ ]}
      showMask={false}
    />
  );
}

const ItauSaleScreen = () => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const [ saleStatus, setSaleStatus ] = useState(0);
  const [ form, setForm ] = useState({});
  const [ itauBranches, setItauBranches ] = useState([]);
  const [ branch, setBranch ] = useState(null);
  const [ currencies, setCurrencies ] = useState([]);
  const [ confirmDialogOpen, setConfirmDialogOpen ] = useState(false);
  const [ saleResultMessage, setSaleResultMessage ] = useState(null);
  const [ forbiddenSection, setForbiddenSection ] = useState(true);

  const { policies, languageTexts, branches } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
    branches: state.business.branches || [],
  }));
  const i18n = Language(languageTexts);
  const isValidForm =
    branch && form.legalId && form.last4 && form.invoiceNumber && form.currency && form.amount && form.taxedAmount;

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(
    () => {
      if (policies && policies.includes(Policies.types.ITAU)) {
        setForbiddenSection(false);
      }
    },
    [ policies ]
  );

  useEffect(
    () => {
      if (!forbiddenSection) {
        dispatch(
          businessActions.getItauBranches(itauBranches => {
            const itauBranchesTraceIds = itauBranches.map(b => b.traceId);
            const _branches = branches.filter(b => itauBranchesTraceIds.includes(b.traceId));
            if (_branches.length > 0) {
              setItauBranches(_branches);
              setBranch(_branches[0].traceId);
            }
          })
        );
      }
    },
    [ forbiddenSection ]
  );

  useEffect(
    () => {
      if (branch) {
        dispatch(
          businessActions.getBranchIssuers(branch, issuers => {
            const itauIssuer = issuers.find(i => i.name === 'ITAU_FINANCING');
            if (itauIssuer) {
              if (itauIssuer.availableCurrencies.length > 0) {
                setCurrencies(itauIssuer.availableCurrencies);
                setForm({ ...form, currency: itauIssuer.availableCurrencies[0] });
              }
            }
          })
        );
      }
    },
    [ branch ]
  );

  const _handleChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const _handleOnAmountBlur = () => {
    setForm({
      ...form,
      taxedAmount: Utils.taxedAmount(form.amount),
    });
  };

  const _goBack = () => {
    dispatch(goBack());
  };

  const _toggleConfirmDialog = () => {
    setConfirmDialogOpen(!confirmDialogOpen);
  };

  const _submitForm = () => {
    if (saleStatus !== 0) {
      setForm({
        currency: currencies[0],
      });
      setBranch(itauBranches[0].traceId);
      setSaleStatus(0);
    } else {
      _toggleConfirmDialog();
    }
  };

  const _confirmSubmitForm = () => {
    _toggleConfirmDialog();

    if (!branch) {
      return false;
    }

    dispatch(
      businessActions.makeItauSale(branch, form, (saleResult, message) => {
        setSaleStatus(saleResult);
        setSaleResultMessage(message);
      })
    );
  };

  const renderAlert = () => {
    if (saleStatus === 1) {
      return (
        <React.Fragment>
          <Alert severity="success" icon={false} className={classes.alertSuccess}>
            <AlertTitle>{i18n.get('ItauSale.AlertSuccessTitle')}</AlertTitle>
            {saleResultMessage || i18n.get('ItauSale.AlertSuccessMessage')}
          </Alert>
          <BoxSeparator size="small" />
        </React.Fragment>
      );
    }
    if (saleStatus === 2) {
      return (
        <React.Fragment>
          <Alert severity="error" icon={false} className={classes.alertError}>
            <AlertTitle>{i18n.get('ItauSale.AlertErrorTitle')}</AlertTitle>
            {saleResultMessage || i18n.get('ItauSale.AlertErrorMessage')}
          </Alert>
          <BoxSeparator size="small" />
        </React.Fragment>
      );
    }
    if (saleStatus === 3) {
      return (
        <React.Fragment>
          <Alert severity="warning" icon={false} className={classes.alertPending}>
            <AlertTitle>{i18n.get('ItauSale.AlertPendingTitle')}</AlertTitle>
            {saleResultMessage || i18n.get('ItauSale.AlertPendingMessage')}
          </Alert>
          <BoxSeparator size="small" />
        </React.Fragment>
      );
    }
    return null;
  };

  if (forbiddenSection) {
    return (
      <Page withHeader withSidebar withHeaderTitle={i18n.get('ItauSale.Title')} withActivePage={ROUTES.ITAU.id}>
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('ItauSale.Title')} withActivePage={ROUTES.ITAU.id}>
      {renderAlert()}
      <TitleBox
        title={i18n.get('ItauSale.BoxTitle')}
        back
        backTitle={i18n.get('ItauSale.BackButton')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <ContentBox title={i18n.get('ItauSale.FormTitle')} titleBold>
        <Grid container spacing={5} className={classes.formWrapper}>
          <Grid item xs={12} sm={12} md={12}>
            <InputLabel className={classes.productFormSelectLabel} shrink>
              {i18n.get('ItauSale.FormBranchLabel')}
            </InputLabel>
            <Select className={classes.formSelect} onChange={_handleChange} value={branch || ''} name="branch">
              <MenuItem value={''} disabled className={classes.formSelectOption}>
                {i18n.get('ItauSale.FormSelectDefaultLabel')}
              </MenuItem>
              {itauBranches.map((branch, index) => (
                <MenuItem key={index} value={branch.traceId} className={classes.formSelectOption}>
                  {branch.branchName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormControl fullWidth className={classes.formInput}>
              <InputLabel htmlFor="legalId" shrink>
                {i18n.get('ItauSale.FormLegalIdLabel')}
              </InputLabel>
              <Input
                value={form.legalId || null}
                onChange={_handleChange}
                name="legalId"
                id="legalId"
                placeholder={i18n.get('ItauSale.FormLegalIdPlaceholder')}
                inputComponent={LegalIdField}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <FormControl fullWidth className={classes.formInput}>
              <InputLabel htmlFor="last4" shrink>
                {i18n.get('ItauSale.FormLast4Label')}
              </InputLabel>
              <Input
                value={form.last4 || null}
                onChange={_handleChange}
                name="last4"
                id="last4"
                inputComponent={Last4Field}
                placeholder={i18n.get('ItauSale.FormLast4Placeholder')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.formInput}
              label={i18n.get('ItauSale.FormInvoiceNumberLabel')}
              placeholder={i18n.get('ItauSale.FormInvoiceNumberPlaceholder')}
              onChange={_handleChange}
              name="invoiceNumber"
              value={form.invoiceNumber || ''}
            />
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
            <InputLabel className={classes.productFormSelectLabel} shrink>
              {i18n.get('ItauSale.FormCurrencyLabel')}
            </InputLabel>
            <Select
              className={classes.formSelect}
              onChange={_handleChange}
              value={form.currency || ''}
              displayEmpty
              name="currency"
              renderValue={value => (
                <div style={{ color: !value ? Colors.rgba(Colors.rgb.black, 50) : null }}>
                  {value ? value : i18n.get('ItauSale.FormSelectDefaultLabel')}
                </div>
              )}
            >
              <MenuItem value={''} disabled className={classes.formSelectOption}>
                {i18n.get('ItauSale.FormSelectDefaultLabel')}
              </MenuItem>
              {currencies.map((currency, index) => (
                <MenuItem key={index} value={currency} className={classes.formSelectOption}>
                  {currency}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={8} sm={5} md={5}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.formInput}
              type="number"
              name="amount"
              label={i18n.get('ItauSale.FormAmountLabel')}
              placeholder={i18n.get('ItauSale.FormAmountPlaceholder')}
              onChange={_handleChange}
              onBlur={_handleOnAmountBlur}
              value={form.amount || ''}
            />
          </Grid>
          <Hidden smUp>
            <Grid item xs={4} />
          </Hidden>
          <Grid item xs={8} sm={5} md={5}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.formInput}
              type="number"
              name="taxedAmount"
              label={i18n.get('ItauSale.FormTaxedAmountLabel')}
              placeholder={i18n.get('ItauSale.FormTaxedAmountPlaceholder')}
              onChange={_handleChange}
              value={form.taxedAmount || ''}
            />
          </Grid>
        </Grid>
        <div className={classes.formActionsContainer}>
          <Button variant="outlined" onClick={_goBack}>
            {saleStatus !== 0 ? i18n.get('ItauSale.FormBackToListButton') : i18n.get('ItauSale.FormCancelButton')}
          </Button>
          <div className={classes.formActionsSeparator} />
          <Button onClick={_submitForm} disabled={!isValidForm}>
            {saleStatus !== 0 ? i18n.get('ItauSale.FormNewSaleButton') : i18n.get('ItauSale.FormNextButton')}
          </Button>
        </div>
      </ContentBox>
      <Confirm
        open={confirmDialogOpen}
        onClose={_toggleConfirmDialog}
        title={i18n.get('ItauSale.ConfirmDialogTitle')}
        htmlText={i18n.get('ItauSale.ConfirmDialogMessage')}
        confirmText={i18n.get('ItauSale.ConfirmDialogActionConfirm')}
        onConfirm={_confirmSubmitForm}
        cancelText={i18n.get('ItauSale.ConfirmDialogActionCancel')}
        onCancel={_toggleConfirmDialog}
      />
    </Page>
  );
};

ItauSaleScreen.id = 'com.Handy.ItauSale';

export default ItauSaleScreen;
