import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import makeClasses from './styles';
import { useSelector } from 'react-redux';
import { Language } from '../../utils';
import Button from '../Button';
import { Alert, AlertTitle } from '@material-ui/lab';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';

const OnboardingWarningModal = ({ open, onClose }) => {
  const classes = makeClasses();

  const { languageTexts } = useSelector(({ language }) => ({
    languageTexts: language.texts || {},
  }));
  const i18n = Language(languageTexts);

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitleWrapper}>
        <div className={classes.dialogTitle}>{i18n.get('Components.OnboardingWarningModal.HeaderTitle')}</div>
      </DialogTitle>
      <DialogContent className={classes.dialogContentWrapper}>
        <Alert severity="warning" icon={false} className={classes.alert}>
          <div className={classes.alertIconWrapper}>
            <FlagOutlinedIcon className={classes.alertIcon} />
          </div>
          <div>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get('Components.OnboardingWarningModal.BodyTitle')}
            </AlertTitle>
            <div
              className={classes.alertBodyText}
              dangerouslySetInnerHTML={{ __html: i18n.get('Components.OnboardingWarningModal.BodyText') }}
            />
          </div>
        </Alert>
      </DialogContent>
      <DialogActions className={classes.dialogActionsWrapper}>
        <Button onClick={onClose}>{i18n.get('Components.OnboardingWarningModal.ButtonAccept')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OnboardingWarningModal;
