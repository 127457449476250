import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { user as userActions, common as commonActions } from '../../actions';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import LogoutIcon from '../../static/images/icons/logout.svg';

import makeClasses from './styles';
import { Confirm } from '..';
import { Language } from '../../utils';
import appLogo from '../../static/images/logo.svg';
import clsx from 'clsx';

const AppHeader = ({ title = '', withBanner = false, withBoxBanner = false, withBoxBannerMd = false }) => {
  const dispatch = useDispatch();

  const [ logoutDialogOpen, setLogoutDialogOpen ] = useState(false);

  const { user, languageTexts } = useSelector(({ user, language }) => ({
    user: user.userData,
    languageTexts: language.texts || {},
  }));

  const i18n = Language(languageTexts);

  const classes = makeClasses();

  const _handleSideMenuOpen = () => {
    dispatch(commonActions.setSideMenuOpen(true));
  };

  const _toggleLogoutDialog = () => {
    setLogoutDialogOpen(!logoutDialogOpen);
  };

  const _confirmLogout = () => {
    dispatch(userActions.logOut());
  };

  return (
    <AppBar
      elevation={0}
      position="fixed"
      className={clsx(
        classes.appBar,
        withBanner ? classes.withBanner : null,
        withBoxBanner ? classes.withBoxBanner : null,
        withBoxBannerMd ? classes.withBoxBannerMd : null
      )}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={_handleSideMenuOpen} edge="start">
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden xsDown>
          <div className={classes.toolbarTitle}>
            {i18n.get('Components.AppHeader.Location')} {title}
          </div>
        </Hidden>
        <Hidden smUp>
          <img src={appLogo} className={classes.appLogo} alt="App Logo" />
        </Hidden>
      </Toolbar>
      {user.name ? (
        <div className={classes.userContainer}>
          <div className={classes.userName}>{user.name}</div>
          <div className={classes.logout} onClick={_toggleLogoutDialog}>
            <img className={classes.logoutIcon} src={LogoutIcon} alt="Salir" />
          </div>
        </div>
      ) : null}
      <Confirm
        open={logoutDialogOpen}
        onClose={_toggleLogoutDialog}
        title={i18n.get('Components.AppHeader.DialogTitle')}
        text={i18n.get('Components.AppHeader.DialogContentText')}
        confirmText={i18n.get('Components.AppHeader.DialogActionConfirm')}
        onConfirm={_confirmLogout}
        cancelText={i18n.get('Components.AppHeader.DialogActionCancel')}
        onCancel={_toggleLogoutDialog}
      />
    </AppBar>
  );
};

export default AppHeader;
