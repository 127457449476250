import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.98,
    },
  },
  banner: {
    width: '100%',
    marginBottom: 25,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    position: 'relative',
    zIndex: 0,
  },
}));

export default makeClasses;
