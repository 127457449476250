import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  contentTile: {
    padding: 0,
    width: '100%',
    marginTop: `-${theme.spacing(2)}`,

    '& *': {
      fontFamily: theme.fontFamily.regular,
      fontSize: theme.fontSize.M,
      color: Colors.rgb.contentText,
    },
  },
  contentItemColumn: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column !important',
      alignItems: 'flex-start !important',
      justifyContent: 'center !important',
      paddingBottom: theme.spacing(3),
    },
  },
  contentItem: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentPos: {
    padding: `0 0 0 ${theme.spacing(6)}`,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentPosItem: {
    '& *': {
      fontFamily: theme.fontFamily.regular,
      fontSize: theme.fontSize.S,
      color: Colors.rgb.contentText,
    },
    '& span': {
      color: Colors.rgb.contentTextHighlighted,
      marginLeft: theme.spacing(1),
    },
    borderBottom: `1px solid ${Colors.rgb.contentLineSoft}`,
    padding: `${theme.spacing(2)} 0`,
  },
  contentPosLastItem: {
    padding: `${theme.spacing(1)} 0`,
  },
  contentPosItemFull: {
    '& *': {
      fontFamily: theme.fontFamily.regular,
      fontSize: `${theme.fontSize.M} !important`,
      color: `${Colors.rgb.contentTextHighlighted} !important`,
    },
  },
  contentItemText: {
    color: Colors.rgb.contentTextHighlighted,
    display: 'flex',
    alignItems: 'center',

    '& > div:nth-child(2)': {
      marginLeft: theme.spacing(2),
    },
  },
  contentItemColumnText: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  contentItemButton: {
    padding: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: theme.spacing(2),
      transform: 'translateY(50%)',
      marginTop: -53,
    },
  },
  contentItemButtonIcon: {
    color: 'white !important',
    fontSize: 18,

    '& > path': {
      color: 'white !important',
    },
  },
  contentItemEven: {
    background: Colors.rgb.grey,
  },
  contentItemLink: {
    color: Colors.rgb.primary,
  },
  contentItemWithSubItems: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    background: Colors.rgb.grey,
    paddingBottom: 0,
  },
  contentItemSubItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    borderTop: '1px solid white',
    marginTop: 10,
    boxSizing: 'border-box',

    '& > div': {
      padding: '10px 20px',
      boxSizing: 'border-box',
      borderBottom: '1px solid white',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      margin: '0 !important',
      fontSize: theme.fontSize.S,

      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  contentItemWarning: {
    background: Colors.rgb.alertWarningBackground,

    '& > div': {
      color: `${Colors.rgb.alertWarningText} !important`,
    },
  },
  alert: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    width: '100%',
    boxSizing: 'border-box',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)} !important`,
      '& > div': {
        alignItems: 'flex-start',
      },
    },
  },
  alertTitle: {
    fontFamily: theme.fontFamily.bold,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.M,
    },
  },
  alertMessage: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.S,
    },
  },
  alertIcon: {
    fontSize: 50,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
  },
  alertInfo: {
    background: Colors.rgb.alertInfoBackground,
    border: `1px solid ${Colors.rgb.alertInfoBorder}`,
    color: `${Colors.rgb.alertInfoText} !important`,
  },
  accordionContainer: {
    borderRadius: 0,
    boxShadow: 'none',

    '&::before': {
      display: 'none',
      height: 0,
    },
  },
  accordionContainerExpanded: {
    margin: '0 !important',
  },
  accordionSummary: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    minHeight: 0,
    height: 30,
    borderBottom: `1px solid ${Colors.rgb.contentLine}`,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  accordionSummaryContent: {
    margin: 0,
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.M,
    color: Colors.rgb.contentText,
  },
  accordionSummaryExpanded: {
    minHeight: '0 !important',
    margin: '0 !important',
  },
  accordionSummaryExpandIcon: {
    color: Colors.rgb.contentText,
    padding: 0,
    marginRight: `-${theme.spacing(1)} !important`,

    '& > span > svg': {
      fontSize: theme.fontSize.XXL,
    },
  },
  accordionDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
}));

export default makeClasses;
