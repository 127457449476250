import React from 'react';
import Button from '../Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import makeClasses from './styles';

const PaginationSimple = ({ currentPage, hasMorePages, onPressNext, onPressPrevious }) => {
  const classes = makeClasses();

  return (
    <div className={classes.container}>
      <Button variant="outlined" disabled={currentPage === 1} onClick={onPressPrevious}>
        <ChevronLeftIcon />
      </Button>
      <div style={{ width: 5 }} />
      <Button variant="outlined" disabled={!hasMorePages} onClick={onPressNext}>
        <ChevronRightIcon />
      </Button>
    </div>
  );
};

export default PaginationSimple;
