import { makeStyles } from '@material-ui/core';
import { THEME_LAYOUT } from '../../theme';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  button: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    background: Colors.rgb.secondary,
    color: Colors.rgb.white,
    fontFamily: theme.fontFamily.regular,
    borderRadius: theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.XS),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${Colors.rgb.secondary}`,
    boxSizing: 'border-box',
    transition: 'border-color 0.3s, background-color 0.3s, color 0.3s',

    '&:hover': {
      background: Colors.rgb.white,
      color: Colors.rgb.dark_gray,
      borderColor: Colors.rgb.primaryHover,

      '& > svg path': {
        color: `${Colors.rgb.primaryHover} !important`,
      },
    },
  },
  buttonSecondary: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    background: Colors.rgb.white,
    color: Colors.rgb.contentText,
    fontFamily: theme.fontFamily.regular,
    borderRadius: theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.XS),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${Colors.rgb.white}`,
    boxSizing: 'border-box',
    transition: 'border-color 0.3s, background-color 0.3s, color 0.3s',

    '&:hover': {
      background: Colors.rgb.contentTextSoft,
      color: Colors.rgb.white,
      borderColor: Colors.rgb.contentTextSoft,
    },
  },
  buttonTextOnly: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    background: 'transparent',
    color: Colors.rgb.primary,
    fontFamily: theme.fontFamily.regular,
    borderRadius: theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.XS),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${Colors.rgb.white}`,
    boxSizing: 'border-box',
    transition: 'border-color 0.3s, background-color 0.3s, color 0.3s',

    '&:hover': {
      background: Colors.rgb.contentTextSoft,
      color: Colors.rgb.white,
      borderColor: Colors.rgb.contentTextSoft,
    },
  },
  buttonSm: {
    fontSize: theme.fontSize.S,
  },
  buttonMd: {
    fontSize: theme.fontSize.M,
  },
  buttonWithNoText: {
    padding: theme.spacing(1),
  },
  buttonDisabled: {
    background: Colors.rgba(Colors.rgb.primary, 40),
    cursor: 'default',
    borderColor: 'transparent',

    '&:hover': {
      background: Colors.rgba(Colors.rgb.primary, 40),
      color: Colors.rgb.white,
      borderColor: 'transparent',
    },
  },
  buttonSecondaryDisabled: {
    background: Colors.rgba(Colors.rgb.white, 50),
    cursor: 'default',
    color: Colors.rgba(Colors.rgb.white, 50),
    border: 'none',

    '&:hover': {
      background: Colors.rgba(Colors.rgb.white, 50),
      color: Colors.rgba(Colors.rgb.white, 50),
      border: 'none',
    },
  },
  buttonActive: {
    background: Colors.rgb.primary,
  },
  buttonOutlined: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    background: Colors.rgb.white,
    border: `1px solid ${Colors.rgb.contentLine}`,
    fontSize: theme.fontSize.M,
    color: Colors.rgb.contentText,
    fontFamily: theme.fontFamily.regular,
    borderRadius: theme.spacing(THEME_LAYOUT.HORIZONTAL.OUTER.XS),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    transition: 'border-color 0.5s, background-color 0.5s, color 0.5s',

    '&:hover': {
      background: Colors.rgb.contentTextSoft,
      color: Colors.rgb.white,
      borderColor: Colors.rgb.contentTextSoft,
    },
  },
  buttonOutlinedDisabled: {
    cursor: 'default',
    color: Colors.rgba(Colors.rgb.contentTextSoft, 80),

    '&:hover': {
      background: 'none',
      color: Colors.rgba(Colors.rgb.contentTextSoft, 80),
      borderColor: Colors.rgb.contentLine,
    },
  },
  buttonOutlinedActive: {
    border: `1px solid ${Colors.rgb.primary}`,
    color: Colors.rgb.primary,
  },
  buttonLeftIconWrapper: {
    display: 'flex',
    marginLeft: -2,
    marginRight: 5,
  },
  buttonRightIconWrapper: {
    display: 'flex',
    marginLeft: theme.spacing(1),
  },
  buttonIconWithNoText: {
    margin: 0,
  },
  buttonLoadingIndicator: {
    width: '20px !important',
    height: '20px !important',
  },
}));

export default makeClasses;
