import clsx from 'clsx';
import React from 'react';
import { Box } from '..';
import makeClasses from './styles';

const EmptyMessage = ({ textOnly = false, showShadow = true, children }) => {
  const classes = makeClasses();

  if (textOnly) {
    return <div className={clsx(classes.container, classes.containerTextOnly)}>{children}</div>;
  }

  return (
    <Box showShadow={showShadow}>
      <div className={classes.container}>{children}</div>
    </Box>
  );
};

export default EmptyMessage;
